var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var yV,zV,rV,hW,gW,Jka;$CLJS.sV=function(a,b){a=rV(a,b);return $CLJS.m(a)?a:$CLJS.yl};$CLJS.tV=function(a,b){$CLJS.jj.F($CLJS.JH,$CLJS.vG,a,b)};$CLJS.uV=function(a,b,c){a=$CLJS.bz.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.vV=function(a,b){return $CLJS.uV(a,b,null)};yV=function(){$CLJS.pL(gW,$CLJS.uh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.im],null),$CLJS.Qe($CLJS.Me,$CLJS.r(xV))))};
zV=function(){return $CLJS.uh.j(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xu,new $CLJS.k(null,2,[$CLJS.Ck,$CLJS.C,$CLJS.Iv,function(a){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.xl);return $CLJS.qe(a)?["Invalid ",$CLJS.gj.l($CLJS.G([$CLJS.C(a)]))," clause: ",$CLJS.gj.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null)],null),$CLJS.xg.g(function(a){return new $CLJS.S(null,2,5,$CLJS.T,[a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.Ui.h("mbql.clause",$CLJS.Ai(a))],null)],null)}),$CLJS.r(xV))};
$CLJS.AV=function(a,b){var c=$CLJS.Ui.h("mbql.clause",$CLJS.Ai(a));$CLJS.pL(c,b);$CLJS.Je($CLJS.r(xV),a)||$CLJS.jj.j(xV,$CLJS.mf,a);return null};$CLJS.BV=function(a,b,c){$CLJS.AV(a,c);$CLJS.GL.o(null,a,function(){return b});return null};$CLJS.EV=function(a,b){$CLJS.Q.F(CV,DV,a,b)};$CLJS.GV=function(a,b){$CLJS.Q.F(CV,FV,a,b)};
rV=function rV(a,b){return $CLJS.F.h(a,$CLJS.yl)?null:$CLJS.F.h(b,$CLJS.yl)?null:$CLJS.XH(a,b)?b:$CLJS.XH(b,a)?a:$CLJS.ag(function(d){return $CLJS.ag(function(e){return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[d,e],null),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))?null:rV.h?rV.h(d,e):rV.call(null,d,e)},$CLJS.pf(b,$CLJS.uj(b)))},$CLJS.pf(a,$CLJS.uj(a)))};hW=new $CLJS.N("metabase.lib.schema.mbql-clause","clause*","metabase.lib.schema.mbql-clause/clause*",1127493678);
$CLJS.iW=new $CLJS.N(null,"short","short",1928760516);gW=new $CLJS.N("metabase.lib.schema.mbql-clause","tag","metabase.lib.schema.mbql-clause/tag",-405459143);$CLJS.pW=new $CLJS.N("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);Jka=new $CLJS.N("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);var xV=$CLJS.ij.g($CLJS.ui);$CLJS.OH(xV,Jka,function(){yV();return $CLJS.pL(hW,zV())});yV();$CLJS.pL(hW,zV());$CLJS.pL($CLJS.aM,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,gW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.um,$CLJS.Lb],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,hW],null)],null));
var FV=function FV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return FV.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
FV.l=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,$CLJS.uh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.lu,new $CLJS.k(null,1,[$CLJS.ql,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,a],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Tk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.XL],null)],null)],null)],null),b)],null)};FV.v=1;
FV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var DV=function DV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return DV.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
DV.l=function(a,b){return $CLJS.uh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vu,new $CLJS.k(null,1,[$CLJS.ql,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.XL],null)],null),b)};DV.v=1;DV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var CV=function CV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return CV.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};CV.l=function(a,b,c){c=$CLJS.F.h($CLJS.C(c),$CLJS.bv)?$CLJS.pf($CLJS.$d(c),$CLJS.qg(2,c)):$CLJS.pf(null,c);var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);a=$CLJS.Q.j(a,b,d);return $CLJS.m(c)?$CLJS.BV(b,c,a):$CLJS.AV(b,a)};CV.v=2;
CV.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};