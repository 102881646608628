var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var vN,wN,tN;
$CLJS.dga=function(a,b,c){var d=$CLJS.sN.g(a),e=$CLJS.wM(a,b),f=$CLJS.m(d)?function(l){try{var n=d.g?d.g(l):d.call(null,l)}catch(q){if(q instanceof Object)n=tN;else throw q;}return $CLJS.F.h(tN,n)?$CLJS.oM($CLJS.jM($CLJS.km.g(a),l,new $CLJS.dj(function(){return new $CLJS.jf(null,$CLJS.$i.g($CLJS.fM(d)),new $CLJS.jf(null,$CLJS.eM(l),null,1,null),2,null)}),$CLJS.RM)):$CLJS.m(n)?e.g?e.g(l):e.call(null,l):c.g?c.g(l):c.call(null,l)}:e,g=$CLJS.uN.g(a);return $CLJS.m(g)?function(l){l=f.g?f.g(l):f.call(null,
l);var n=$CLJS.qM(l);return $CLJS.m(n)?$CLJS.oM(g.g?g.g(n):g.call(null,n)):l}:f};$CLJS.EM=function(a,b,c,d,e,f,g){this.na=a;this.options=b;this.Yb=c;this.Rb=d;this.D=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};$CLJS.GM=function(a,b){return $CLJS.FM(a,b,null)};$CLJS.FM=function(a,b,c){return $CLJS.HM(a,b,c,null)};
$CLJS.HM=function(a,b,c,d){if(!$CLJS.m($CLJS.m(c)?c:null==$CLJS.sN.g($CLJS.ae(b))))throw Error($CLJS.dM("when last option has a guard, err-f must be provided"));return new $CLJS.EM(a,b,c,d,null,null,null)};vN=new $CLJS.N(null,"err-f","err-f",651620941);wN=new $CLJS.N(null,"post","post",269697687);tN=new $CLJS.N("schema.spec.variant","exception","schema.spec.variant/exception",-253680523);$CLJS.uN=new $CLJS.N(null,"wrap-error","wrap-error",536732809);$CLJS.sN=new $CLJS.N(null,"guard","guard",-873147811);$CLJS.h=$CLJS.EM.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "pre":return this.na;case "options":return this.options;case "err-f":return this.Yb;case "post":return this.Rb;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Nd=$CLJS.La(55);
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#schema.spec.variant.VariantSpec{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qN,this.na],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Tk,this.options],null),new $CLJS.S(null,2,5,$CLJS.T,[vN,this.Yb],null),new $CLJS.S(null,2,5,$CLJS.T,[wN,this.Rb],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qN,$CLJS.Tk,vN,wN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1524878868^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.na,b.na)&&$CLJS.F.h(this.options,b.options)&&$CLJS.F.h(this.Yb,b.Yb)&&$CLJS.F.h(this.Rb,b.Rb)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,4,[$CLJS.qN,null,vN,null,wN,null,$CLJS.Tk,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new $CLJS.EM(this.na,this.options,this.Yb,this.Rb,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "pre":case "options":case "err-f":case "post":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.qN,b):$CLJS.O.call(null,$CLJS.qN,b))?new $CLJS.EM(c,this.options,this.Yb,this.Rb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Tk,b):$CLJS.O.call(null,$CLJS.Tk,b))?new $CLJS.EM(this.na,c,this.Yb,this.Rb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(vN,b):$CLJS.O.call(null,vN,b))?new $CLJS.EM(this.na,this.options,c,this.Rb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(wN,b):$CLJS.O.call(null,wN,b))?new $CLJS.EM(this.na,
this.options,this.Yb,c,this.D,this.m,null):new $CLJS.EM(this.na,this.options,this.Yb,this.Rb,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Ke($CLJS.qN,this.na),new $CLJS.Ke($CLJS.Tk,this.options),new $CLJS.Ke(vN,this.Yb),new $CLJS.Ke(wN,this.Rb)],null),this.m))};$CLJS.h.N=function(a,b){return new $CLJS.EM(this.na,this.options,this.Yb,this.Rb,b,this.m,this.G)};
$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};