var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var k4,G4,H4,I4;k4=function(a,b,c){var d=$CLJS.W0(a,b);d=$CLJS.Uf(d);d=$CLJS.M.h(d,$CLJS.hQ);d=$CLJS.DW($CLJS.Um.j($CLJS.vi([c]),$CLJS.MH,$CLJS.$d),d);if(!$CLJS.m(d))throw $CLJS.Ej($CLJS.cI("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Cu,c,$CLJS.QO,a,$CLJS.U1,b],null));return d};G4=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
H4=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);I4=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$CLJS.qqa=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.B1.o(null,$CLJS.hQ,function(a,b){var c=$CLJS.Vf($CLJS.hQ.g($CLJS.W0(a,b)));return $CLJS.m(c)?$CLJS.e2($CLJS.dI("and"),function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);a:for(var u=0;;)if(u<n){var v=$CLJS.ce(l,u);v=$CLJS.c1.F(a,b,v,$CLJS.w1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}q=$CLJS.C(g);return $CLJS.pf($CLJS.c1.F(a,
b,q,$CLJS.w1),f($CLJS.Ad(g)))}return null}},null,null)}(c)}()):null});$CLJS.a1.o(null,$CLJS.hQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Uf(d);d=$CLJS.M.h(e,$CLJS.bH);e=$CLJS.M.h(e,$CLJS.rk);c=$CLJS.J(c,2,null);c=k4(a,b,c);return $CLJS.pn.l($CLJS.G([$CLJS.b1(a,b,c),new $CLJS.k(null,2,[$CLJS.E0,$CLJS.q1,$CLJS.b2,$CLJS.MH.g($CLJS.$d(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.bH,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.rk,e],null):null]))});
$CLJS.y1.o(null,$CLJS.hQ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.c1.F(a,b,k4(a,b,c),d)});$CLJS.tV(H4,I4);
for(var L4=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VD,$CLJS.hK],null)),M4=null,N4=0,O4=0;;)if(O4<N4){var Xpa=M4.$(null,O4);$CLJS.tV(Xpa,H4);O4+=1}else{var Q4=$CLJS.A(L4);if(Q4){var R4=Q4;if($CLJS.re(R4)){var S4=$CLJS.$c(R4),rqa=$CLJS.ad(R4),sqa=S4,tqa=$CLJS.E(S4);L4=rqa;M4=sqa;N4=tqa}else{var uqa=$CLJS.C(R4);$CLJS.tV(uqa,H4);L4=$CLJS.D(R4);M4=null;N4=0}O4=0}else break}
$CLJS.y1.o(null,H4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.c1.F(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.cI("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.cI("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.dI("Count");case "cum-count":return $CLJS.dI("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.z1.o(null,H4,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.a1.o(null,H4,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.yj($CLJS.a1,I4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Ol,$CLJS.nJ)});$CLJS.y1.o(null,$CLJS.uJ,function(){return $CLJS.dI("Case")});
$CLJS.z1.o(null,$CLJS.uJ,function(){return"case"});$CLJS.tV(G4,I4);for(var T4=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.YJ,$CLJS.AL,$CLJS.hJ,$CLJS.em,$CLJS.QK,$CLJS.wk,$CLJS.VK,$CLJS.OK,$CLJS.lJ],null)),U4=null,V4=0,W4=0;;)if(W4<V4){var wqa=U4.$(null,W4);$CLJS.tV(wqa,G4);W4+=1}else{var X4=$CLJS.A(T4);if(X4){var $4=X4;if($CLJS.re($4)){var j5=$CLJS.$c($4),xqa=$CLJS.ad($4),yqa=j5,Eqa=$CLJS.E(j5);T4=xqa;U4=yqa;V4=Eqa}else{var Gqa=$CLJS.C($4);$CLJS.tV(Gqa,G4);T4=$CLJS.D($4);U4=null;V4=0}W4=0}else break}
$CLJS.z1.o(null,G4,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.y1.o(null,G4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.c1.F(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.cI("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.cI("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.cI("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.cI("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.cI("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.cI("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.cI("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.cI("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.cI("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.y1.o(null,$CLJS.IJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.cI("{0}th percentile of {1}",$CLJS.G([c,$CLJS.c1.F(a,b,e,d)]))});$CLJS.z1.o(null,$CLJS.IJ,function(){return"percentile"});
$CLJS.tV($CLJS.IJ,I4);$CLJS.y1.o(null,$CLJS.xL,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.cI("Sum of {0} matching condition",$CLJS.G([$CLJS.c1.F(a,b,e,d)]))});$CLJS.z1.o(null,$CLJS.xL,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.A1.j(a,b,d))].join("")});$CLJS.tV($CLJS.xL,I4);$CLJS.y1.o(null,$CLJS.tK,function(){return $CLJS.dI("Share of rows matching condition")});
$CLJS.z1.o(null,$CLJS.tK,function(){return"share"});$CLJS.tV($CLJS.tK,I4);$CLJS.y1.o(null,$CLJS.AK,function(){return $CLJS.dI("Count of rows matching condition")});$CLJS.z1.o(null,$CLJS.AK,function(){return"count-where"});$CLJS.tV($CLJS.AK,I4);
$CLJS.a1.o(null,I4,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.pn.l;d=$CLJS.m(d)?$CLJS.ri($CLJS.b1(a,b,d),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.HR],null)):null;var f=$CLJS.yj($CLJS.a1,$CLJS.Uj);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.pn,$CLJS.G([d,a]))});$CLJS.jW.o(null,$CLJS.hQ,function(a){return a});$CLJS.y1.o(null,$CLJS.OW,function(a,b,c){a=$CLJS.Uf(c);a=$CLJS.M.h(a,$CLJS.RW);return $CLJS.oJ.g(a.A?a.A():a.call(null))});
$CLJS.E1.o(null,$CLJS.OW,function(a,b,c){var d=$CLJS.Uf(c);a=$CLJS.M.h(d,$CLJS.iW);b=$CLJS.M.h(d,$CLJS.RW);c=$CLJS.M.h(d,$CLJS.PW);d=$CLJS.M.h(d,$CLJS.k1);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.$1,$CLJS.oG(a),$CLJS.G([$CLJS.Z1,c]));return null!=d?$CLJS.U.j(a,$CLJS.P1,d):a});