var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var XN,Uga,$N,aO,bO,cO,eO,fO,gO,hO,kO,lO,mO,nO,oO,pO,qO,sO,tO,cha,dha,eha,fha,gha,hha,IO,iha,jha,kha,lha,KO,LO,MO,NO,mha,OO,PO,nha,oha,pha,RO,TO,qha,UO,VO,rha,sha,WO,XO,tha,YO,ZO,aP,bP,uha,cP,vha,eP,wha,xha,fP,hP,yha,zha,jP,kP,Aha,Bha,nP,Cha,oP,Dha,rP,sP,tP,Eha,Fha,Gha,uP,vP,wP,Hha,Iha,xP,Jha,Kha,Lha,yP,AP,BP,Mha,Nha,Oha,Pha,Qha,CP,DP,EP,Rha,FP,Sha,Tha,Uha,GP,Vha,Wha,Xha,Yha,HP,Zha,IP,$ha,KP,LP,MP,NP,OP,aia,SP,bia,cia,TP,UP,dia,VP,WP,eia,YP,ZP,$P,bQ,cQ,fia,dQ,gia,hia,iia,eQ,fQ,gQ,jQ,
jia,lQ,mQ,nQ,oQ,kia,lia,mia,qQ,rQ,nia,sQ,oia,pia,qia,ria,wQ,sia,tia,xQ,yQ,zQ,uia,AQ,BQ,CQ,via,DQ,wia,xia,EQ,FQ,GQ,IQ,JQ,yia,LQ,MQ,NQ,OQ,zia,QQ,RQ,Aia,TQ,UQ,VQ,WQ,XQ,YQ,Bia,Cia,Dia,Eia,Fia,$Q,Gia,Hia,aR,bR,cR,Iia,dR,Jia,eR,Kia,Lia,fR,Mia,Nia,Oia,Pia,hR,iR,Qia,Ria,Sia,lR,Tia,mR,Uia,nR,Via,pR,qR,Wia,rR,sR,tR,uR,vR,Xia,wR,Yia,xR,Zia,$ia,yR,zR,AR,aja,bja,BR,cja,dja,CR,eja,fja,gja,hja,FR,ija,jja,kja,lja,mja,nja,oja,JR,KR,LR,MR,NR,pja,OR,PR,QR,RR,qja,rja,sja,tja,SR,TR,UR,uja,VR,WR,vja,wja,YR,xja,yja,ZR,
zja,aS,bS,Aja,cS,Bja,eS,fS,Cja,gS,hS,Dja,iS,Eja,jS,Fja,lS,mS,nS,oS,Gja,pS,Hja,qS,Ija,rS,sS,uS,Jja,Kja,Lja,wS,xS,Mja,yS,Nja,Oja,Aka,zS,Bka,BS,Cka,bV,oV,Dka,Eka,HV,IV,Fka,JV,KV,LV,MV,Gka,Hka;XN=function(a,b){if(null!=a&&null!=a.Nd)a=a.Nd(a,b);else{var c=XN[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=XN._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Ob("CoreSpec.checker",a);}return a};
Uga=function(a,b){var c=new $CLJS.k(null,3,[$CLJS.TM,a,$CLJS.mN,!1,$CLJS.lN,$CLJS.ij.g($CLJS.P)],null);return a.h?a.h(b,c):a.call(null,b,c)};$CLJS.YN=function(a,b){return $CLJS.Rb(function(c,d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Je(a,e)?$CLJS.U.j(c,d,$CLJS.M.h(a,e)):c},$CLJS.Q.j($CLJS.Mm,a,$CLJS.mi(b)),b)};$CLJS.ZN=function(a,b){return new $CLJS.pN(a,!1,b,null,null,null)};$N=function(a){return new $CLJS.gN(a,null,null,null)};
aO=function(a,b,c,d){this.Kc=a;this.D=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};bO=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return bO.l(0<b.length?new $CLJS.y(b.slice(0),0,null):null)};cO=function(a,b,c,d,e){this.fc=a;this.mc=b;this.D=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};eO=function(a,b,c){return dO.l($CLJS.G([a,b,$CLJS.gg(!0),c]))};fO=function(a,b,c,d){this.wc=a;this.D=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};
gO=function(a){if(!(null!=a?a.C&32768||$CLJS.t===a.Lf||(a.C?0:$CLJS.Mb($CLJS.xA,a)):$CLJS.Mb($CLJS.xA,a)))throw Error($CLJS.cM("Not an IDeref: %s",$CLJS.G([a])));return new fO(a,null,null,null)};hO=function(a,b,c,d){this.X=a;this.D=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.iO=function(a){return new hO(a,null,null,null)};$CLJS.jO=function(a){return $CLJS.Um.h($CLJS.qM,Uga(function(b,c){return XN($CLJS.VM(b),c)},a))};
kO=function(a,b){if($CLJS.qe(b)){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null);switch(c instanceof $CLJS.N?c.S:null){case "optional":return new $CLJS.pN($CLJS.iO(d),!0,a,null,null,null);case "rest":return $CLJS.cN(d,a);default:return $CLJS.ZN(b,a)}}else return $CLJS.ZN(b,a)};
lO=function(a,b){return $CLJS.Sg($CLJS.pf($CLJS.ZN($CLJS.ZM(a),a),function(){return function e(d){return new $CLJS.Cf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Ff(l);a:for(var q=0;;)if(q<l){var u=$CLJS.ce(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=kO(v,u);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.If($CLJS.Kf(n),e($CLJS.ad(f))):$CLJS.If($CLJS.Kf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.pf(kO(n,g),
e($CLJS.Ad(f)))}return null}},null,null)}($CLJS.dv(2,2,b))}()))};mO=function(a,b){var c=$CLJS.ne(b);return c?(c=$CLJS.C(b)instanceof $CLJS.N)?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.yi(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c:c};
nO=function(a){return $CLJS.cN($CLJS.Q.h(dO,$CLJS.We($CLJS.wg,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hg(mO,u),q],null));n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,
1,null);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hg(mO,l),f],null),d($CLJS.Ad(e)))}return null}},null,null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.Ov.h(", ",$CLJS.xg.h($CLJS.C,a))].join(""))};oO=function(a){return $CLJS.eN(a,$CLJS.A,"Non-empty")};pO=function(a){return $CLJS.A(a)?$CLJS.Q.h($CLJS.yv,a):!0};qO=function(a){return"string"===typeof a?$CLJS.qi.h(Date.parse(a),NaN):null};
$CLJS.zM.prototype.Nd=$CLJS.Ma(56,function(){var a=this;return function(b){var c=a.na.g?a.na.g(b):a.na.call(null,b);return $CLJS.m(c)?c:b}});
$CLJS.EM.prototype.Nd=$CLJS.Ma(55,function(a,b){var c=this,d=this,e=$CLJS.Rb(function(f,g){return $CLJS.dga(g,b,f)},function(f){return $CLJS.oM($CLJS.jM(d,f,new $CLJS.dj(function(){var g=$CLJS.eM(f);return c.Yb.g?c.Yb.g(g):c.Yb.call(null,g)}),null))},$CLJS.nf(c.options));return $CLJS.m(c.Rb)?function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.pM(g))return g;f=$CLJS.m($CLJS.mN.g(b))?g:f;f=c.Rb.g?c.Rb.g(f):c.Rb.call(null,f);return $CLJS.m(f)?
f:g}:function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);return $CLJS.m(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.MM.prototype.Nd=$CLJS.Ma(54,function(a,b){var c=this,d=$CLJS.m($CLJS.mN.g(b))?c.pc:function(){return null},e=$CLJS.KM(c.elements,b,function(f,g){return g});return function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;var l=$CLJS.ij.g($CLJS.Rg);g=e.h?e.h(l,f):e.call(null,l,f);l=$CLJS.r(l);var n=(n=$CLJS.A(g))?n:$CLJS.ag($CLJS.pM,l);return $CLJS.m(n)?$CLJS.oM(c.$b.j?c.$b.j(f,l,g):c.$b.call(null,f,l,g)):d.g?d.g(l):d.call(null,l)}});$CLJS.rO={};sO={};tO={};$CLJS.h=aO.prototype;
$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "vs":return this.Kc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wO,this.Kc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wO],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2002701468^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Kc,b.Kc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.wO,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new aO(this.Kc,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "vs":return!0;default:return $CLJS.Je(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.wO,b):$CLJS.O.call(null,$CLJS.wO,b))?new aO(c,this.D,this.m,null):new aO(this.Kc,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Ke($CLJS.wO,this.Kc)],null),this.m))};$CLJS.h.N=function(a,b){return new aO(this.Kc,b,this.m,this.G)};
$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h.Ab=function(){var a=this;return $CLJS.AM($CLJS.sM(this,function(b){return $CLJS.Je(a.Kc,b)},function(b){return new $CLJS.jf(null,a.Kc,new $CLJS.jf(null,b,null,1,null),2,null)}))};$CLJS.h.Ea=function(){return $CLJS.pf($CLJS.Yga,this.Kc)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Ea(null))};bO.l=function(a){return new aO($CLJS.yi(a),null,null,null)};bO.v=0;
bO.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=cO.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":return this.fc;case "error-symbol":return this.mc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yO,this.fc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xO,this.mc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yO,$CLJS.xO],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1418435858^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.fc,b.fc)&&$CLJS.F.h(this.mc,b.mc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.yO,null,$CLJS.xO,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new cO(this.fc,this.mc,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.yO,b):$CLJS.O.call(null,$CLJS.yO,b))?new cO(c,this.mc,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.xO,b):$CLJS.O.call(null,$CLJS.xO,b))?new cO(this.fc,c,this.D,this.m,null):new cO(this.fc,this.mc,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Ke($CLJS.yO,this.fc),new $CLJS.Ke($CLJS.xO,this.mc)],null),this.m))};
$CLJS.h.N=function(a,b){return new cO(this.fc,this.mc,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};
$CLJS.h.Ab=function(){var a=this;return $CLJS.FM($CLJS.UM,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.k(null,2,[$CLJS.sN,u,$CLJS.km,q],null));n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,
null);return $CLJS.pf(new $CLJS.k(null,2,[$CLJS.sN,l,$CLJS.km,f],null),d($CLJS.Ad(e)))}return null}},null,null)}(a.fc)}(),function(b){var c=a.mc;c=$CLJS.m(c)?c:$CLJS.F.h(1,$CLJS.E(a.fc))?$CLJS.$i.g($CLJS.fM($CLJS.C($CLJS.C(a.fc)))):$CLJS.xga;return new $CLJS.jf(null,c,new $CLJS.jf(null,b,null,1,null),2,null)})};
$CLJS.h.Ea=function(){return $CLJS.pf($CLJS.yga,$CLJS.wg.h($CLJS.yg(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$i.g($CLJS.fM(b)),$CLJS.WM(a)],null)},$CLJS.G([this.fc])),$CLJS.m(this.mc)?new $CLJS.S(null,1,5,$CLJS.T,[this.mc],null):null))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Ea(null))};
var dO=function dO(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return dO.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};
dO.l=function(a){if(!$CLJS.A(a)||!($CLJS.eg($CLJS.E(a))||$CLJS.ae(a)instanceof $CLJS.w))throw Error($CLJS.cM("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.G([$CLJS.E(a)])));return new cO($CLJS.Sg(function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.J(q,0,null),v=$CLJS.J(q,1,null);q=l;if(!$CLJS.ze(u))throw Error($CLJS.dM(["Conditional predicate ",
$CLJS.p.g(u)," must be a function"].join("")));u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(u,$CLJS.Cv)?$CLJS.gg(!0):u,v],null);q.add(u);n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);if(!$CLJS.ze(l))throw Error($CLJS.dM(["Conditional predicate ",$CLJS.p.g(l)," must be a function"].join("")));l=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(l,$CLJS.Cv)?$CLJS.gg(!0):l,f],null);return $CLJS.pf(l,d($CLJS.Ad(e)))}return null}},
null,null)}($CLJS.dv(2,2,a))}()),$CLJS.eg($CLJS.E(a))?null:$CLJS.ae(a),null,null,null)};dO.v=0;dO.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=fO.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return this.wc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AO,this.wc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.AO],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1521498755^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.wc,b.wc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.AO,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new fO(this.wc,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return!0;default:return $CLJS.Je(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.AO,b):$CLJS.O.call(null,$CLJS.AO,b))?new fO(c,this.D,this.m,null):new fO(this.wc,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Ke($CLJS.AO,this.wc)],null),this.m))};$CLJS.h.N=function(a,b){return new fO(this.wc,b,this.m,this.G)};
$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h.Ab=function(){return $CLJS.GM($CLJS.UM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.km,$CLJS.r(this.wc)],null)],null))};
$CLJS.h.Ea=function(){if(this.wc instanceof $CLJS.ud){var a=$CLJS.ge(this.wc);var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.Jk);b=$CLJS.M.h(b,$CLJS.V);a=$CLJS.$i.g([$CLJS.p.g(a),"/",$CLJS.p.g(b)].join(""));a=new $CLJS.jf(null,$CLJS.Pl,new $CLJS.jf(null,a,null,1,null),2,null)}else a=$CLJS.Zga;return new $CLJS.jf(null,$CLJS.Mga,new $CLJS.jf(null,a,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Ea(null))};$CLJS.h=hO.prototype;
$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,this.X],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.km],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-805411239^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.km,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new hO(this.X,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":return!0;default:return $CLJS.Je(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.km,b):$CLJS.O.call(null,$CLJS.km,b))?new hO(c,this.D,this.m,null):new hO(this.X,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Ke($CLJS.km,this.X)],null),this.m))};$CLJS.h.N=function(a,b){return new hO(this.X,b,this.m,this.G)};
$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h.Ab=function(){return $CLJS.GM($CLJS.UM,new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.sN,$CLJS.Bb,$CLJS.km,$CLJS.ZM(null)],null),new $CLJS.k(null,1,[$CLJS.km,this.X],null)],null))};$CLJS.h.Ea=function(){return new $CLJS.jf(null,$CLJS.Nga,new $CLJS.jf(null,$CLJS.WM(this.X),null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Ea(null))};
cha=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);dha=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);$CLJS.HO=new $CLJS.N(null,"right-join","right-join",-56349359);eha=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);fha=new $CLJS.N(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
gha=new $CLJS.N(null,"query-hash","query-hash",-1524484965);hha=new $CLJS.N(null,"from","from",1815293044);IO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);iha=new $CLJS.N(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);jha=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);kha=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);
$CLJS.JO=new $CLJS.N(null,"aggregation-options","aggregation-options",-1904917550);lha=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);KO=new $CLJS.N("location","zip_code","location/zip_code",1641155222);LO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);MO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);NO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);mha=new $CLJS.N(null,"lon-max","lon-max",1590224717);
OO=new $CLJS.w(null,"stddev","stddev",775056588,null);PO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.QO=new $CLJS.N(null,"query","query",-1288509510);nha=new $CLJS.N(null,"lat-field","lat-field",-830652957);oha=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);pha=new $CLJS.w(null,"FieldOptions","FieldOptions",-1536320891,null);RO=new $CLJS.N("location","country","location/country",1666636202);
$CLJS.SO=new $CLJS.N(null,"fields","fields",-1932066230);TO=new $CLJS.w("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);qha=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);UO=new $CLJS.N(null,"unary","unary",-989314568);VO=new $CLJS.w(null,"ceil","ceil",-184398425,null);rha=new $CLJS.N(null,"lon-min","lon-min",-787291357);sha=new $CLJS.N(null,"match","match",1220059550);
WO=new $CLJS.w(null,"count-where","count-where",2025939247,null);XO=new $CLJS.N(null,"max-results-bare-rows","max-results-bare-rows",2096475889);tha=new $CLJS.N(null,"embedded-question","embedded-question",-2146473954);YO=new $CLJS.w(null,"sum","sum",1777518341,null);ZO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.$O=new $CLJS.N("date","range","date/range",1647265776);aP=new $CLJS.w(null,"between","between",-1523336493,null);
bP=new $CLJS.N(null,"clause-form","clause-form",1820463737);uha=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);cP=new $CLJS.w(null,"field","field",338095027,null);vha=new $CLJS.N(null,"segment-id","segment-id",1810133590);$CLJS.dP=new $CLJS.N(null,"filter","filter",-948537934);eP=new $CLJS.w(null,"not-null","not-null",313812992,null);wha=new $CLJS.N(null,"template-tag","template-tag",310841038);
xha=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);fP=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.gP=new $CLJS.N(null,"context","context",-830191113);hP=new $CLJS.w(null,"get-year","get-year",704520253,null);yha=new $CLJS.N(null,"format-rows?","format-rows?",992129486);$CLJS.iP=new $CLJS.N(null,"parameters","parameters",-1229919748);zha=new $CLJS.N(null,"json-download","json-download",-971130133);
jP=new $CLJS.N(null,"string-or-field","string-or-field",-1970678542);kP=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Aha=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Bha=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$CLJS.lP=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.mP=new $CLJS.N("date","month-year","date/month-year",1948031290);
nP=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);Cha=new $CLJS.w("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);oP=new $CLJS.w("s","recursive","s/recursive",-1935549792,null);$CLJS.pP=new $CLJS.N(null,"joins","joins",1033962699);Dha=new $CLJS.N(null,"slug","slug",2029314850);$CLJS.qP=new $CLJS.N(null,"source-field","source-field",933829534);rP=new $CLJS.w(null,"Field","Field",430385967,null);
sP=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);tP=new $CLJS.w("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);Eha=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Fha=new $CLJS.N(null,"disable-max-results?","disable-max-results?",857693204);Gha=new $CLJS.N(null,"items","items",1031954938);uP=new $CLJS.N(null,"more","more",-2058821800);
vP=new $CLJS.N(null,"first-clause","first-clause",-20953491);wP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);Hha=new $CLJS.w("metabase.mbql.schema","NumericExpression*","metabase.mbql.schema/NumericExpression*",-254856038,null);Iha=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);xP=new $CLJS.w(null,"contains","contains",-1977535957,null);Jha=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);
Kha=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);Lha=new $CLJS.w(null,"BooleanExpression","BooleanExpression",1027887421,null);yP=new $CLJS.N("number","\x3d","number/\x3d",-2094581309);$CLJS.zP=new $CLJS.N(null,"fingerprint","fingerprint",598613022);AP=new $CLJS.w(null,"is-null","is-null",-356519403,null);BP=new $CLJS.N(null,"other-clauses","other-clauses",1570511021);Mha=new $CLJS.N(null,"map-tiles","map-tiles",1961865797);
Nha=new $CLJS.N(null,"required","required",1807647006);Oha=new $CLJS.w(null,"Filter*","Filter*",-1193205185,null);Pha=new $CLJS.N(null,"datetime-x","datetime-x",1519265947);Qha=new $CLJS.N(null,"datetime-y","datetime-y",-1666955771);CP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);DP=new $CLJS.N(null,"date-arithmetics","date-arithmetics",-1832808309);EP=new $CLJS.N("string","contains","string/contains",1602423827);
Rha=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);FP=new $CLJS.N("string","!\x3d","string/!\x3d",-1083772573);Sha=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);Tha=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);Uha=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);
GP=new $CLJS.w(null,"share","share",1051097594,null);Vha=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Wha=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Xha=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);Yha=new $CLJS.N(null,"xlsx-download","xlsx-download",-1622892009);
HP=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);Zha=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);IP=new $CLJS.N(null,"collection","collection",-683361892);$CLJS.JP=new $CLJS.N("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);$ha=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);KP=new $CLJS.N("number","\x3c\x3d","number/\x3c\x3d",-1499316353);
LP=new $CLJS.w(null,"Aggregation","Aggregation",-955933538,null);MP=new $CLJS.w(null,"metric","metric",2049329604,null);NP=new $CLJS.w(null,"concat","concat",-467652465,null);OP=new $CLJS.N(null,"variable","variable",-281346492);$CLJS.PP=new $CLJS.N("date","quarter-year","date/quarter-year",-1453950150);aia=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.QP=new $CLJS.N("date","relative","date/relative",25987732);$CLJS.RP=new $CLJS.N(null,"alias","alias",-2039751630);
SP=new $CLJS.N("location","city","location/city",-1746973325);bia=new $CLJS.N(null,"public-dashboard","public-dashboard",645968405);cia=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);TP=new $CLJS.N("number","\x3e\x3d","number/\x3e\x3d",-1670691032);UP=new $CLJS.N("number","between","number/between",97700581);dia=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);VP=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
WP=new $CLJS.w("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);$CLJS.XP=new $CLJS.N(null,"semantic_type","semantic_type",272485089);eia=new $CLJS.N(null,"metric-id","metric-id",-686486942);YP=new $CLJS.w(null,"*","*",345799209,null);ZP=new $CLJS.w(null,"+","+",-740910886,null);$P=new $CLJS.w(null,"-","-",-471816912,null);$CLJS.aQ=new $CLJS.N(null,"breakout","breakout",-732419050);bQ=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);
cQ=new $CLJS.N(null,"allowed-for","allowed-for",122724334);fia=new $CLJS.N(null,"question","question",-1411720117);dQ=new $CLJS.w(null,"asc","asc",1997386096,null);gia=new $CLJS.w("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);hia=new $CLJS.N(null,"pulse-id","pulse-id",1331432237);iia=new $CLJS.w("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);
eQ=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);fQ=new $CLJS.w(null,"\x3c","\x3c",993667236,null);gQ=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);$CLJS.hQ=new $CLJS.N(null,"aggregation","aggregation",1597476696);$CLJS.iQ=new $CLJS.N(null,"source-metadata","source-metadata",-477816085);jQ=new $CLJS.N("string","ends-with","string/ends-with",302681156);jia=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);
$CLJS.kQ=new $CLJS.w("s","Str","s/Str",907974338,null);lQ=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);mQ=new $CLJS.w(null,"and","and",668631710,null);nQ=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);oQ=new $CLJS.w(null,"round","round",-645002441,null);kia=new $CLJS.N(null,"to","to",192099007);$CLJS.pQ=new $CLJS.N("date","single","date/single",1554682003);lia=new $CLJS.w(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);
mia=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);qQ=new $CLJS.w(null,"exp","exp",1378825265,null);rQ=new $CLJS.w(null,"Filter","Filter",-424893332,null);nia=new $CLJS.w("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);sQ=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);oia=new $CLJS.N(null,"dashboard-id","dashboard-id",1965414288);
pia=new $CLJS.w(null,"BooleanExpression*","BooleanExpression*",623370113,null);$CLJS.tQ=new $CLJS.N(null,"source-table","source-table",-225307692);qia=new $CLJS.N(null,"embedded-dashboard","embedded-dashboard",-485078014);$CLJS.uQ=new $CLJS.N("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);ria=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);$CLJS.vQ=new $CLJS.N(null,"metric","metric",408798077);
wQ=new $CLJS.w(null,"floor","floor",-772394748,null);sia=new $CLJS.N(null,"middleware","middleware",1462115504);tia=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);xQ=new $CLJS.N(null,"requires-features","requires-features",-101116256);yQ=new $CLJS.N(null,"clause-name","clause-name",-996419059);zQ=new $CLJS.w(null,"now","now",-9994004,null);uia=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);
AQ=new $CLJS.w(null,"avg","avg",1837937727,null);BQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);CQ=new $CLJS.N(null,"max-results","max-results",-32858165);via=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);DQ=new $CLJS.w(null,"case","case",-1510733573,null);wia=new $CLJS.w("js","Date","js/Date",946858373,null);xia=new $CLJS.w(null,"StringExpression*","StringExpression*",1110382599,null);
EQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);FQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);GQ=new $CLJS.w(null,"DatetimeExpression","DatetimeExpression",1944354145,null);$CLJS.HQ=new $CLJS.N(null,"join-alias","join-alias",1454206794);IQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);JQ=new $CLJS.w(null,"StringExpression","StringExpression",-233088561,null);yia=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
$CLJS.KQ=new $CLJS.w("s","pred","s/pred",-727014287,null);LQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);MQ=new $CLJS.N("number","!\x3d","number/!\x3d",-673025509);NQ=new $CLJS.w(null,"abs","abs",1394505050,null);OQ=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);zia=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$CLJS.PQ=new $CLJS.w("s","cond-pre","s/cond-pre",-923707600,null);
QQ=new $CLJS.N(null,"date","date",-1463434462);RQ=new $CLJS.N(null,"second-clause","second-clause",-461435645);$CLJS.SQ=new $CLJS.N(null,"left-join","left-join",-672831855);Aia=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);TQ=new $CLJS.w(null,"rtrim","rtrim",979195078,null);UQ=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);VQ=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);
WQ=new $CLJS.w("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);XQ=new $CLJS.w(null,"or","or",1876275696,null);YQ=new $CLJS.w("s","Int","s/Int",-2116888657,null);$CLJS.ZQ=new $CLJS.N(null,"field","field",-1302436500);Bia=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Cia=new $CLJS.w(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);Dia=new $CLJS.N(null,"constraints","constraints",422775616);
Eia=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);Fia=new $CLJS.N(null,"csv-download","csv-download",2141432084);$Q=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);Gia=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Hia=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);
aR=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);bR=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);cR=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Iia=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);dR=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);
Jia=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);eR=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Kia=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);Lia=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);fR=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
Mia=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Nia=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Oia=new $CLJS.N(null,"display_name","display_name",-1494335013);Pia=new $CLJS.N(null,"snippet-id","snippet-id",1987785841);$CLJS.gR=new $CLJS.N(null,"database","database",1849087575);hR=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
iR=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);Qia=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.jR=new $CLJS.N(null,"expressions","expressions",255689909);Ria=new $CLJS.N(null,"ad-hoc","ad-hoc",-2033634036);Sia=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$CLJS.kR=new $CLJS.N(null,"temporal-unit","temporal-unit",-1892306358);
lR=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);Tia=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);mR=new $CLJS.w(null,"get-day","get-day",1768100384,null);Uia=new $CLJS.w(null,"Fields","Fields",430617156,null);nR=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.oR=new $CLJS.N(null,"native","native",-613060878);Via=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);
pR=new $CLJS.N(null,"page","page",849072397);qR=new $CLJS.w(null,"length","length",-2065447907,null);Wia=new $CLJS.N(null,"dashboard","dashboard",-631747508);rR=new $CLJS.w(null,"get-week","get-week",752472178,null);sR=new $CLJS.w(null,"get-month","get-month",1271156796,null);tR=new $CLJS.w(null,"dimension","dimension",-2111181571,null);uR=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
vR=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Xia=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);wR=new $CLJS.w(null,"substring","substring",-1513569493,null);Yia=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);xR=new $CLJS.N(null,"internal","internal",-854870097);Zia=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);
$ia=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);yR=new $CLJS.N(null,"declared","declared",92336021);zR=new $CLJS.N(null,"more-values-or-fields","more-values-or-fields",-886177554);AR=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);aja=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);bja=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
BR=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);cja=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);dja=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);CR=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.DR=new $CLJS.N(null,"template-tags","template-tags",1853115685);eja=new $CLJS.N(null,"public-question","public-question",629369976);
fja=new $CLJS.w("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);$CLJS.ER=new $CLJS.N(null,"fk-field-id","fk-field-id",1124127357);gja=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);hja=new $CLJS.N(null,"binary","binary",-1802232288);FR=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);ija=new $CLJS.N(null,"userland-query?","userland-query?",-123699383);
$CLJS.GR=new $CLJS.N(null,"source-query","source-query",198004422);$CLJS.HR=new $CLJS.N(null,"settings","settings",1556144875);jja=new $CLJS.w("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);$CLJS.IR=new $CLJS.N(null,"inner-join","inner-join",659431740);kja=new $CLJS.w(null,"Field*","Field*",-2104480026,null);lja=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);mja=new $CLJS.w("s","eq","s/eq",1021992833,null);
nja=new $CLJS.N(null,"executed-by","executed-by",-739811161);oja=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);JR=new $CLJS.N(null,"amount","amount",364489504);KR=new $CLJS.w(null,"percentile","percentile",1039342775,null);LR=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);MR=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);NR=new $CLJS.w(null,"trim","trim",-1880116002,null);
pja=new $CLJS.w(null,"MBQLQuery","MBQLQuery",-1395590243,null);OR=new $CLJS.w(null,"NumericExpression","NumericExpression",-2082340941,null);PR=new $CLJS.w("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",-859895030,null);QR=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);RR=new $CLJS.N("string","\x3d","string/\x3d",983744235);qja=new $CLJS.N(null,"skip-results-metadata?","skip-results-metadata?",251010463);
rja=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sja=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);tja=new $CLJS.w(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);SR=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);TR=new $CLJS.N(null,"value-or-field","value-or-field",-1387286309);UR=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);
uja=new $CLJS.N(null,"lat-min","lat-min",1630784161);VR=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);WR=new $CLJS.w(null,"inside","inside",-681932758,null);vja=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);wja=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);YR=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);
xja=new $CLJS.N(null,"process-viz-settings?","process-viz-settings?",-173592315);yja=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);ZR=new $CLJS.N(null,"variadic","variadic",882626057);$CLJS.$R=new $CLJS.N(null,"category","category",-593092832);zja=new $CLJS.w(null,"NumericExpression*","NumericExpression*",-1459070895,null);aS=new $CLJS.w(null,"upper","upper",1886775433,null);bS=new $CLJS.N(null,"field-or-expression","field-or-expression",-1409494368);
Aja=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);cS=new $CLJS.N(null,"sugar","sugar",-73788488);Bja=new $CLJS.N(null,"lat-max","lat-max",841568226);$CLJS.dS=new $CLJS.N(null,"full-join","full-join",1305476385);eS=new $CLJS.w(null,"power","power",702679448,null);fS=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);Cja=new $CLJS.w("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);
gS=new $CLJS.w(null,"median","median",-2084869638,null);hS=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Dja=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);iS=new $CLJS.N(null,"y","y",-1757859776);Eja=new $CLJS.w("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",-823028985,null);jS=new $CLJS.w("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);
Fja=new $CLJS.w("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);$CLJS.kS=new $CLJS.N(null,"binning","binning",1709835866);lS=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);mS=new $CLJS.N(null,"b","b",1482224470);nS=new $CLJS.N(null,"a","a",-2123407586);oS=new $CLJS.w(null,"replace","replace",853943757,null);Gja=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
pS=new $CLJS.w(null,"segment","segment",675610331,null);Hja=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);qS=new $CLJS.w(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);Ija=new $CLJS.w(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);rS=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);sS=new $CLJS.N("string","starts-with","string/starts-with",1266861170);
$CLJS.tS=new $CLJS.N(null,"order-by","order-by",1527318070);uS=new $CLJS.N(null,"advanced-math-expressions","advanced-math-expressions",1076581757);Jja=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Kja=new $CLJS.N("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);Lja=new $CLJS.w("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);$CLJS.vS=new $CLJS.N(null,"condition","condition",1668437652);
wS=new $CLJS.N("string","does-not-contain","string/does-not-contain",-1536178964);xS=new $CLJS.w("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);Mja=new $CLJS.N(null,"report-timezone","report-timezone",-2053796389);yS=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);Nja=new $CLJS.N(null,"card-name","card-name",-2035606807);
Oja=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Aka=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);zS=new $CLJS.w(null,"log","log",45015523,null);Bka=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);BS=new $CLJS.w(null,"time","time",-1268547887,null);
Cka=new $CLJS.w("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);$CLJS.CS=new $CLJS.N(null,"database_type","database_type",-54700895);bV=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);oV=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.pV=new $CLJS.N(null,"clause","clause",1479668060);$CLJS.qV=new $CLJS.N(null,"expression-name","expression-name",-1798450709);
$CLJS.wV=new $CLJS.N("date","all-options","date/all-options",549325958);Dka=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Eka=new $CLJS.w(null,"WidgetType","WidgetType",-1985035186,null);HV=new $CLJS.N("location","state","location/state",-114378652);IV=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);Fka=new $CLJS.N(null,"lon-field","lon-field",517872067);
JV=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);KV=new $CLJS.N(null,"numeric","numeric",-1495594714);LV=new $CLJS.w(null,"variable","variable",1359185035,null);MV=new $CLJS.w(null,"lower","lower",-1534114948,null);$CLJS.NV=new $CLJS.N(null,"limit","limit",-1355822363);Gka=new $CLJS.w("s","enum","s/enum",-975416934,null);Hka=new $CLJS.N(null,"pulse","pulse",-244494476);var DS,ES,FS,Pja,Qja,Rja,Sja,IS,JS,Tja,Uja,Vja,KS,Wja,LS,MS,NS,Xja,OS,Yja,PS,QS,Zja,$ja,US,aka,XS,ZS,$S,aT,bT,cT,fT,hT,jT,kT,bka,lT,mT,nT,oT,pT,qT,rT,sT,tT,uT,vT,wT,xT,yT,zT,AT,BT,CT,DT,ET,FT,GT,HT,dT,JT,KT,LT,MT,NT,OT,PT,QT,RT,ST,TT,UT,VT,WT,XT,YT,ZT,YS,bU,cU,dU,eU,fU,gU,hU,iU,jU,kU,lU,mU,nU,oU,pU,qU,rU,sU,tU,uU,vU,wU,xU,cka,yU,zU,iT,AU,BU,dka,eka,CU,IT,$T,EU,FU,GU,HU,IU,JU,KU,LU,MU,NU,OU,PU,QU,RU,SU,TU,UU,VU,fka,XU,YU,$U,gka,hka,aV,ika,jka,kka,lka,mka,cV,eV,fV,hV,nka,oka,pka,iV,jV,qka,AS,kV,lV,
mV,rka,ska,tka,uka,vka,wka,xka,yka,zka;DS=new $CLJS.ti(null,new $CLJS.k(null,12,[$CLJS.dg,null,$CLJS.zv,null,$CLJS.dl,null,$CLJS.Uj,null,$CLJS.sk,null,$CLJS.rl,null,$CLJS.jn,null,$CLJS.Dl,null,$CLJS.Be,null,$CLJS.Al,null,$CLJS.Zj,null,$CLJS.mk,null],null),null);ES=new $CLJS.ti(null,new $CLJS.k(null,7,[$CLJS.iv,null,$CLJS.yn,null,$CLJS.Uj,null,$CLJS.gk,null,$CLJS.Lt,null,$CLJS.Xk,null,$CLJS.Mt,null],null),null);FS=$CLJS.lA.h(DS,ES);Pja=$CLJS.cN($CLJS.Q.h(bO,DS),"date-bucketing-unit");
Qja=$CLJS.cN($CLJS.Q.h(bO,ES),"time-bucketing-unit");$CLJS.GS=$CLJS.cN($CLJS.Q.h(bO,FS),"datetime-bucketing-unit");$CLJS.HS=$CLJS.cN($CLJS.Q.h(bO,$CLJS.bM.tz.names()),"timezone-id");Rja=$CLJS.cN($CLJS.Q.h(bO,new $CLJS.ti(null,new $CLJS.k(null,11,[$CLJS.OL,null,$CLJS.sk,null,$CLJS.gk,null,$CLJS.jn,null,$CLJS.Dl,null,$CLJS.BJ,null,$CLJS.dJ,null,$CLJS.EL,null,$CLJS.sJ,null,$CLJS.mk,null,$CLJS.Xk,null],null),null)),"temporal-extract-units");
Sja=$CLJS.cN($CLJS.Q.h(bO,new $CLJS.ti(null,new $CLJS.k(null,8,[$CLJS.dg,null,$CLJS.zv,null,$CLJS.iv,null,$CLJS.dl,null,$CLJS.yn,null,$CLJS.rl,null,$CLJS.Be,null,$CLJS.Mt,null],null),null)),"datetime-diff-units");IS=$CLJS.cN($CLJS.Q.h(bO,new $CLJS.ti(null,new $CLJS.k(null,3,[$CLJS.dK,null,$CLJS.RI,null,$CLJS.MJ,null],null),null)),"extract-week-modes");
JS=$CLJS.cN($CLJS.Q.h(bO,new $CLJS.ti(null,new $CLJS.k(null,8,[$CLJS.dg,null,$CLJS.zv,null,$CLJS.iv,null,$CLJS.dl,null,$CLJS.Uj,null,$CLJS.rl,null,$CLJS.Be,null,$CLJS.Mt,null],null),null)),"relative-datetime-unit");Tja=$CLJS.eN($CLJS.SN,qO,"valid ISO-8601 datetime string literal");Uja=$CLJS.eN($CLJS.SN,qO,"valid ISO-8601 datetime string literal");Vja=$CLJS.eN($CLJS.SN,qO,"valid ISO-8601 time string literal");
KS=lO($CLJS.NJ,$CLJS.G(["n",$CLJS.RN($CLJS.G([$CLJS.ZM($CLJS.WB),$CLJS.NN])),"unit",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,JS],null)]));Wja=lO($CLJS.EJ,$CLJS.G(["n",$CLJS.NN,"unit",JS]));LS=dO.l($CLJS.G([function(a){return $CLJS.Fb(mO($CLJS.aJ,a))},lO($CLJS.aJ,$CLJS.G(["t",Date,"unit",$CLJS.GS])),function(a){return $CLJS.$d(a)instanceof Date},lO($CLJS.aJ,$CLJS.G(["date",Date,"unit",Pja])),$CLJS.Cv,lO($CLJS.aJ,$CLJS.G(["datetime",Date,"unit",$CLJS.GS]))]));
MS=lO($CLJS.IE,$CLJS.G(["time",Date,"unit",Qja]));NS=dO.l($CLJS.G([$CLJS.hg(mO,$CLJS.aJ),LS,qO,Uja,qO,Tja,$CLJS.Cv,$CLJS.RN($CLJS.G([Date]))]));Xja=dO.l($CLJS.G([$CLJS.hg(mO,$CLJS.IE),MS,qO,Vja,$CLJS.Cv,$CLJS.RN($CLJS.G([Date]))]));OS=$CLJS.RN($CLJS.G([Xja,NS]));
nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof LS?new $CLJS.ud(function(){return LS},$CLJS.K(Eha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,xR,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lR,"metabase/mbql/schema.cljc",69,$CLJS.aJ,1,!0,192,192,$CLJS.yd,"Schema for an `:absolute-datetime` clause.",$CLJS.m(LS)?LS.J:null])):null));return $CLJS.m(a)?a:lR}(),LS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof KS?new $CLJS.ud(function(){return KS},$CLJS.K(Dka,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,
$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LQ,"metabase/mbql/schema.cljc",29,$CLJS.NJ,1,174,174,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.NJ,$CLJS.bD,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.PQ,$CLJS.Y(mja,$CLJS.WB),YQ)),$CLJS.bk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,lQ))],null),$CLJS.yd,"Schema for a valid relative-datetime clause.",$CLJS.m(KS)?KS.J:null])):null));return $CLJS.m(a)?
a:LQ}(),KS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof MS?new $CLJS.ud(function(){return MS},$CLJS.K(Lia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,xR,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BS,"metabase/mbql/schema.cljc",27,$CLJS.IE,1,!0,225,225,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.IE,$CLJS.IE,$CLJS.Y($CLJS.Ll,wia),$CLJS.bk,$CLJS.Y($CLJS.Ll,aia)],null),$CLJS.yd,"Schema for a valid time clause.",$CLJS.m(MS)?MS.J:null])):null));return $CLJS.m(a)?a:BS}(),MS],null)]));Yja=$CLJS.Tf([$N($CLJS.CS),$CLJS.iO($CLJS.SN),$N($CLJS.PJ),$CLJS.iO($CLJS.TN),$N($CLJS.XP),$CLJS.iO($CLJS.UN),$N($CLJS.bk),$CLJS.iO($CLJS.GS),$N($CLJS.V),$CLJS.iO($CLJS.SN),$CLJS.ON,$CLJS.JN]);
PS=lO($CLJS.xl,$CLJS.G(["value",$CLJS.JN,"type-info",$CLJS.iO(Yja)]));QS=lO($CLJS.ZG,$CLJS.G(["expression-name",$CLJS.SN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,$CLJS.aN($CLJS.oe,"map")],null)]));Zja=bO.l($CLJS.G([$CLJS.YI,$CLJS.DL,$CLJS.Uj]));
$ja=function(a){return $CLJS.eN(a,function(b){var c=$CLJS.Uf(b);b=$CLJS.M.h(c,$CLJS.LL);c=$CLJS.M.h(c,$CLJS.YI);return $CLJS.F.h(b,$CLJS.YI)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.eN(a,function(b){var c=$CLJS.Uf(b);b=$CLJS.M.h(c,$CLJS.LL);c=$CLJS.M.h(c,$CLJS.DL);return $CLJS.F.h(b,$CLJS.DL)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.Tf([$CLJS.LL,Zja,$N($CLJS.YI),$CLJS.VN,$N($CLJS.DL),$CLJS.eN($CLJS.MN,$CLJS.fg($CLJS.gf),
"bin width must be \x3e\x3d 0."),$CLJS.ON,$CLJS.JN])));$CLJS.RS=function RS(a){switch(arguments.length){case 1:return RS.g(arguments[0]);case 2:return RS.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.RS.g=function(a){var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.bH);b=$CLJS.M.h(b,$CLJS.kR);return $CLJS.RS.h(a,b)};
$CLJS.RS.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.XH(a,$CLJS.jJ)?DS:$CLJS.XH(a,$CLJS.ML)?ES:$CLJS.XH(a,$CLJS.nK)?FS:null:null;return $CLJS.m(a)?$CLJS.Je(a,b):!0};$CLJS.RS.v=2;
var SS=$CLJS.eN($CLJS.eN($CLJS.Tf([$N($CLJS.bH),$CLJS.iO($CLJS.TN),$N($CLJS.qP),$CLJS.iO($CLJS.RN($CLJS.G([$CLJS.VN,$CLJS.SN]))),$N($CLJS.kR),$CLJS.iO($CLJS.GS),$N($CLJS.HQ),$CLJS.iO($CLJS.SN),$N($CLJS.kS),$CLJS.iO($ja),$CLJS.ON,$CLJS.JN]),$CLJS.RS,"Invalid :temporal-unit for the specified :base-type."),$CLJS.fg($CLJS.LL),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),TS=function(a){return $CLJS.eN(a,function(b){$CLJS.J(b,0,null);
var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);b=$CLJS.Uf(b);b=$CLJS.M.h(b,$CLJS.bH);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(lO($CLJS.ZQ,$CLJS.G(["id-or-name",$CLJS.RN($CLJS.G([$CLJS.VN,$CLJS.SN])),"options",$CLJS.iO(gO(new $CLJS.ud(function(){return SS},Cka,$CLJS.Yh([$CLJS.Rl,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[!0,$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pha,"metabase/mbql/schema.cljc",28,1,383,383,$CLJS.yd,null,$CLJS.m(SS)?SS.J:null]))))])));$CLJS.eN(TS,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ae(a)},"Must be a :field with an integer Field ID.");
US=nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof QS?new $CLJS.ud(function(){return QS},$CLJS.K(Kha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.lP,"metabase/mbql/schema.cljc",60,$CLJS.ZG,1,318,318,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ZG,$CLJS.qV,$CLJS.Y($CLJS.Ll,jS),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,$CLJS.Y($CLJS.KQ,$CLJS.kk,"map")))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(QS)?QS.J:null])):null));return $CLJS.m(a)?a:$CLJS.lP}(),QS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof TS?
new $CLJS.ud(function(){return TS},$CLJS.K(Uha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.gl,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],["0.39.0",$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",51,$CLJS.ZQ,1,429,429,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(TS)?TS.J:null])):null));return $CLJS.m(a)?
a:cP}(),TS],null)]));$CLJS.VS=gO(new $CLJS.ud(function(){return US},fja,$CLJS.Yh([$CLJS.Rl,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[!0,$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kja,"metabase/mbql/schema.cljc",22,1,445,445,$CLJS.yd,null,$CLJS.m(US)?US.J:null])));
aka=lO($CLJS.hQ,$CLJS.G(["aggregation-clause-index",$CLJS.NN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,$CLJS.aN($CLJS.oe,"map")],null)]));$CLJS.WS=eO($CLJS.hg(mO,$CLJS.hQ),aka,$CLJS.VS);XS=new $CLJS.ti(null,new $CLJS.k(null,11,[$CLJS.jv,null,$CLJS.bL,null,$CLJS.wI,null,$CLJS.IL,null,$CLJS.KI,null,$CLJS.lK,null,$CLJS.uJ,null,$CLJS.Mv,null,$CLJS.XK,null,$CLJS.jK,null,$CLJS.NL,null],null),null);
ZS=dO.l($CLJS.G([$CLJS.Jb,$CLJS.KN,$CLJS.hg(mO,XS),gO(new $CLJS.ud(function(){return YS},xS,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(YS)?YS.J:null]))),$CLJS.hg(mO,$CLJS.xl),PS,$CLJS.Cv,$CLJS.VS]));
$S=new $CLJS.ti(null,new $CLJS.k(null,26,[$CLJS.FI,null,$CLJS.CL,null,$CLJS.um,null,$CLJS.KK,null,$CLJS.CK,null,$CLJS.KJ,null,$CLJS.bv,null,$CLJS.uI,null,$CLJS.SK,null,$CLJS.yK,null,$CLJS.lK,null,$CLJS.zL,null,$CLJS.FK,null,$CLJS.qK,null,$CLJS.II,null,$CLJS.ZI,null,$CLJS.tm,null,$CLJS.PI,null,$CLJS.uJ,null,$CLJS.tI,null,$CLJS.MD,null,$CLJS.$I,null,$CLJS.wK,null,$CLJS.DJ,null,$CLJS.BL,null,$CLJS.bK,null],null),null);
aT=new $CLJS.ti(null,new $CLJS.k(null,9,[$CLJS.Bm,null,$CLJS.rm,null,$CLJS.Hk,null,$CLJS.Zk,null,$CLJS.Am,null,$CLJS.YK,null,$CLJS.Qj,null,$CLJS.om,null,$CLJS.pm,null],null),null);bT=new $CLJS.ti(null,new $CLJS.k(null,17,[$CLJS.wk,null,$CLJS.VK,null,$CLJS.AK,null,$CLJS.hK,null,$CLJS.xL,null,$CLJS.AL,null,$CLJS.hJ,null,$CLJS.IJ,null,$CLJS.JO,null,$CLJS.lJ,null,$CLJS.QK,null,$CLJS.tK,null,$CLJS.em,null,$CLJS.VD,null,$CLJS.YJ,null,$CLJS.vQ,null,$CLJS.OK,null],null),null);
cT=new $CLJS.ti(null,new $CLJS.k(null,5,[$CLJS.ZJ,null,$CLJS.QI,null,$CLJS.gL,null,$CLJS.tm,null,$CLJS.zK,null],null),null);
fT=dO.l($CLJS.G([$CLJS.Eb,$CLJS.MN,$CLJS.hg(mO,$S),gO(new $CLJS.ud(function(){return dT},PR,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(dT)?dT.J:null]))),$CLJS.hg(mO,bT),gO(new $CLJS.ud(function(){return $CLJS.eT},TO,$CLJS.Yh([$CLJS.Jk,
$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.eT)?$CLJS.eT.J:null]))),$CLJS.hg(mO,$CLJS.xl),PS,$CLJS.Cv,$CLJS.VS]));
hT=dO.l($CLJS.G([$CLJS.hg(mO,bT),gO(new $CLJS.ud(function(){return $CLJS.eT},TO,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.eT)?$CLJS.eT.J:null]))),$CLJS.hg(mO,$CLJS.xl),PS,$CLJS.hg(mO,cT),gO(new $CLJS.ud(function(){return $CLJS.gT},
WP,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.gT)?$CLJS.gT.J:null]))),$CLJS.Cv,$CLJS.RN($CLJS.G([NS,$CLJS.VS]))]));
jT=dO.l($CLJS.G([$CLJS.Eb,$CLJS.MN,$CLJS.ve,$CLJS.LN,$CLJS.hg(mO,aT),gO(new $CLJS.ud(function(){return iT},Cha,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Lha,"metabase/mbql/schema.cljc",27,1,523,!0,523,$CLJS.yd,null,$CLJS.m(iT)?iT.J:null]))),$CLJS.hg(mO,$S),gO(new $CLJS.ud(function(){return dT},
PR,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(dT)?dT.J:null]))),$CLJS.hg(mO,cT),gO(new $CLJS.ud(function(){return $CLJS.gT},WP,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],
[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.gT)?$CLJS.gT.J:null]))),$CLJS.Jb,$CLJS.KN,$CLJS.hg(mO,XS),gO(new $CLJS.ud(function(){return YS},xS,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(YS)?YS.J:null]))),$CLJS.hg(mO,$CLJS.xl),PS,$CLJS.Cv,$CLJS.VS]));kT=eO($CLJS.hg(mO,$CLJS.EJ),Wja,fT);bka=eO($CLJS.Eb,$CLJS.VN,fT);lT=lO($CLJS.lK,$CLJS.G(["a",jT,"b",jT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,jT],null)]));mT=lO($CLJS.wI,$CLJS.G(["s",ZS,"start",bka,"length",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,fT],null)]));nT=lO($CLJS.MD,$CLJS.G(["s",ZS]));oT=lO($CLJS.XK,$CLJS.G(["s",ZS]));
pT=lO($CLJS.NL,$CLJS.G(["s",ZS]));qT=lO($CLJS.bL,$CLJS.G(["s",ZS]));rT=lO($CLJS.jv,$CLJS.G(["s",ZS]));sT=lO($CLJS.Mv,$CLJS.G(["s",ZS]));tT=lO($CLJS.IL,$CLJS.G(["s",ZS,"match",$CLJS.KN,"replacement",$CLJS.KN]));uT=lO($CLJS.KI,$CLJS.G(["a",ZS,"b",ZS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,ZS],null)]));vT=lO($CLJS.jK,$CLJS.G(["s",ZS,"pattern",$CLJS.KN]));wT=lO($CLJS.tm,$CLJS.G(["x",kT,"y",kT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,kT],null)]));
xT=lO($CLJS.bv,$CLJS.G(["x",fT,"y",kT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,kT],null)]));yT=lO($CLJS.zL,$CLJS.G(["x",fT,"y",fT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,fT],null)]));zT=lO($CLJS.um,$CLJS.G(["x",fT,"y",fT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,fT],null)]));AT=lO($CLJS.bK,$CLJS.G(["x",fT]));BT=lO($CLJS.FI,$CLJS.G(["x",fT]));CT=lO($CLJS.qK,$CLJS.G(["x",fT]));DT=lO($CLJS.PI,$CLJS.G(["x",fT]));ET=lO($CLJS.II,$CLJS.G(["x",fT,"y",fT]));FT=lO($CLJS.KJ,$CLJS.G(["x",fT]));
GT=lO($CLJS.uI,$CLJS.G(["x",fT]));HT=lO($CLJS.BL,$CLJS.G(["x",fT]));dT=gO(new $CLJS.ud(function(){return IT},Hha,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zja,"metabase/mbql/schema.cljc",28,1,664,!0,664,$CLJS.yd,null,$CLJS.m(IT)?IT.J:null])));
JT=lO($CLJS.DJ,$CLJS.G(["datetime-x",hT,"datetime-y",hT,"unit",Sja]));KT=lO($CLJS.KK,$CLJS.G(["datetime",hT,"unit",Rja,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,IS],null)]));LT=lO($CLJS.$I,$CLJS.G(["date",hT]));MT=lO($CLJS.CL,$CLJS.G(["date",hT]));NT=lO($CLJS.tI,$CLJS.G(["date",hT]));OT=lO($CLJS.yK,$CLJS.G(["date",hT,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,IS],null)]));PT=lO($CLJS.wK,$CLJS.G(["date",hT]));QT=lO($CLJS.FK,$CLJS.G(["date",hT]));RT=lO($CLJS.SK,$CLJS.G(["datetime",hT]));
ST=lO($CLJS.CK,$CLJS.G(["datetime",hT]));TT=lO($CLJS.ZI,$CLJS.G(["datetime",hT]));UT=lO($CLJS.QI,$CLJS.G(["datetime",hT,"to",$CLJS.HS,"from",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,$CLJS.HS],null)]));VT=$CLJS.cN($CLJS.Q.h(bO,new $CLJS.ti(null,new $CLJS.k(null,9,[$CLJS.dg,null,$CLJS.zv,null,$CLJS.iv,null,$CLJS.dl,null,$CLJS.yn,null,$CLJS.rl,null,$CLJS.Be,null,$CLJS.Lt,null,$CLJS.Mt,null],null),null)),"arithmetic-datetime-unit");WT=lO($CLJS.zK,$CLJS.G(["datetime",hT,"amount",fT,"unit",VT]));
XT=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return lO(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.ZJ);YT=lO($CLJS.gL,$CLJS.G(["datetime",hT,"amount",fT,"unit",VT]));
ZT=nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof wT?new $CLJS.ud(function(){return wT},$CLJS.K(eR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZP,"metabase/mbql/schema.cljc",51,$CLJS.tm,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.tm,$CLJS.NH,$CLJS.Y($CLJS.Ll,hR),iS,$CLJS.Y($CLJS.Ll,hR),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,hR))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(wT)?wT.J:null])):null));return $CLJS.m(a)?a:ZP}(),wT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof WT?new $CLJS.ud(function(){return WT},
$CLJS.K(uia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[DP,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",67,$CLJS.zK,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.zK,$CLJS.XJ,$CLJS.Y($CLJS.Ll,JV),JR,$CLJS.Y($CLJS.Ll,
MR),$CLJS.bk,$CLJS.Y($CLJS.Ll,VR)],null),$CLJS.yd,"Schema for a valid datetime-add clause.",$CLJS.m(WT)?WT.J:null])):null));return $CLJS.m(a)?a:$Q}(),WT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof YT?new $CLJS.ud(function(){return YT},$CLJS.K(cha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,
$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[DP,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IV,"metabase/mbql/schema.cljc",72,$CLJS.gL,1,736,736,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.gL,$CLJS.XJ,$CLJS.Y($CLJS.Ll,JV),JR,$CLJS.Y($CLJS.Ll,MR),$CLJS.bk,$CLJS.Y($CLJS.Ll,VR)],null),$CLJS.yd,"Schema for a valid datetime-subtract clause.",$CLJS.m(YT)?YT.J:null])):null));return $CLJS.m(a)?
a:IV}(),YT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof UT?new $CLJS.ud(function(){return UT},$CLJS.K(wja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.QI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sP,"metabase/mbql/schema.cljc",71,$CLJS.QI,1,719,719,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QI,$CLJS.XJ,$CLJS.Y($CLJS.Ll,JV),kia,$CLJS.Y($CLJS.Ll,lS),hha,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,lS))],null),$CLJS.yd,"Schema for a valid convert-timezone clause.",$CLJS.m(UT)?UT.J:null])):null));return $CLJS.m(a)?a:sP}(),UT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof XT?new $CLJS.ud(function(){return XT},
$CLJS.K(Xia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.ZJ,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zQ,"metabase/mbql/schema.cljc",45,$CLJS.ZJ,1,734,734,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ZJ],null),$CLJS.yd,"Schema for a valid now clause.",
$CLJS.m(XT)?XT.J:null])):null));return $CLJS.m(a)?a:zQ}(),XT],null)]));$CLJS.gT=gO(new $CLJS.ud(function(){return ZT},gia,$CLJS.Yh([$CLJS.Rl,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[!0,$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lia,"metabase/mbql/schema.cljc",35,1,741,741,$CLJS.yd,null,$CLJS.m(ZT)?ZT.J:null])));
YS=gO(new $CLJS.ud(function(){return $T},Cja,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xia,"metabase/mbql/schema.cljc",27,1,748,!0,748,$CLJS.yd,null,$CLJS.m($T)?$T.J:null])));
bU=lO($CLJS.Qj,$CLJS.G(["first-clause",gO(new $CLJS.ud(function(){return $CLJS.aU},WQ,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.aU)?$CLJS.aU.J:null]))),"second-clause",gO(new $CLJS.ud(function(){return $CLJS.aU},WQ,$CLJS.Yh([$CLJS.Jk,
$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.aU)?$CLJS.aU.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,gO(new $CLJS.ud(function(){return $CLJS.aU},WQ,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,
$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.aU)?$CLJS.aU.J:null])))],null)]));
cU=lO($CLJS.Hk,$CLJS.G(["first-clause",gO(new $CLJS.ud(function(){return $CLJS.aU},WQ,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.aU)?$CLJS.aU.J:null]))),"second-clause",gO(new $CLJS.ud(function(){return $CLJS.aU},WQ,$CLJS.Yh([$CLJS.Jk,
$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.aU)?$CLJS.aU.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,gO(new $CLJS.ud(function(){return $CLJS.aU},WQ,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,
$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.aU)?$CLJS.aU.J:null])))],null)]));
dU=lO($CLJS.Zk,$CLJS.G(["clause",gO(new $CLJS.ud(function(){return $CLJS.aU},WQ,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.aU)?$CLJS.aU.J:null])))]));eU=eO($CLJS.hg(mO,$CLJS.NJ),KS,$CLJS.VS);
fU=$CLJS.iO($CLJS.RN($CLJS.G([$CLJS.LN,$CLJS.MN,$CLJS.KN,OS,eU,jT,PS])));gU=eO($CLJS.hg(mO,$CLJS.xl),PS,$CLJS.RN($CLJS.G([$CLJS.MN,$CLJS.KN,OS,jT,eU])));hU=lO($CLJS.om,$CLJS.G(["field",fU,"value-or-field",fU,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,fU],null)]));iU=lO($CLJS.YK,$CLJS.G(["field",fU,"value-or-field",fU,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,fU],null)]));jU=lO($CLJS.pm,$CLJS.G(["field",gU,"value-or-field",gU]));
kU=lO($CLJS.rm,$CLJS.G(["field",gU,"value-or-field",gU]));lU=lO($CLJS.Bm,$CLJS.G(["field",gU,"value-or-field",gU]));mU=lO($CLJS.Am,$CLJS.G(["field",gU,"value-or-field",gU]));nU=lO($CLJS.eJ,$CLJS.G(["field",gU,"min",gU,"max",gU]));oU=lO($CLJS.pJ,$CLJS.G(["lat-field",gU,"lon-field",gU,"lat-max",gU,"lon-min",gU,"lat-min",gU,"lon-max",gU]));pU=lO($CLJS.TI,$CLJS.G(["field",$CLJS.VS]));qU=lO($CLJS.JJ,$CLJS.G(["field",$CLJS.VS]));rU=lO($CLJS.fJ,$CLJS.G(["field",$CLJS.VS]));
sU=lO($CLJS.vJ,$CLJS.G(["field",$CLJS.VS]));tU=$CLJS.Tf([$N($CLJS.vK),$CLJS.LN]);uU=lO($CLJS.QJ,$CLJS.G(["field",ZS,"string-or-field",ZS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,tU],null)]));vU=lO($CLJS.IK,$CLJS.G(["field",ZS,"string-or-field",ZS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,tU],null)]));wU=lO($CLJS.fK,$CLJS.G(["field",ZS,"string-or-field",ZS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,tU],null)]));
xU=lO($CLJS.RJ,$CLJS.G(["field",ZS,"string-or-field",ZS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,tU],null)]));cka=$CLJS.Tf([$N($CLJS.TK),$CLJS.LN]);yU=lO($CLJS.MK,$CLJS.G(["field",$CLJS.VS,"n",$CLJS.RN($CLJS.G([$CLJS.NN,bO.l($CLJS.G([$CLJS.WB,$CLJS.ZD,$CLJS.mJ]))])),"unit",JS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,cka],null)]));zU=lO($CLJS.JI,$CLJS.G(["segment-id",$CLJS.RN($CLJS.G([$CLJS.VN,$CLJS.SN]))]));
iT=gO(new $CLJS.ud(function(){return AU},iia,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pia,"metabase/mbql/schema.cljc",28,1,883,!0,883,$CLJS.yd,null,$CLJS.m(AU)?AU.J:null])));
AU=nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(fS,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mQ,"metabase/mbql/schema.cljc",15,$CLJS.Qj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Qj,vP,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),RQ,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),BP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:mQ}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==
typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(UQ,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",14,$CLJS.Hk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Hk,vP,$CLJS.Y($CLJS.Ll,
$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),RQ,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),BP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:XQ}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(vR,new $CLJS.k(null,
1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.LM,"metabase/mbql/schema.cljc",15,$CLJS.Zk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Zk,$CLJS.pV,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(dU)?dU.J:
null])):null));return $CLJS.m(a)?a:$CLJS.LM}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof jU?new $CLJS.ud(function(){return jU},$CLJS.K(cR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fQ,"metabase/mbql/schema.cljc",13,$CLJS.pm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.pm,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(jU)?jU.J:null])):null));return $CLJS.m(a)?a:fQ}(),jU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof lU?new $CLJS.ud(function(){return lU},$CLJS.K(yS,new $CLJS.k(null,1,
[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",14,$CLJS.Bm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Bm,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(lU)?lU.J:null])):
null));return $CLJS.m(a)?a:eQ}(),lU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof kU?new $CLJS.ud(function(){return kU},$CLJS.K(aR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gQ,"metabase/mbql/schema.cljc",13,$CLJS.rm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rm,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(kU)?kU.J:null])):null));return $CLJS.m(a)?a:gQ}(),kU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof mU?new $CLJS.ud(function(){return mU},$CLJS.K(rS,new $CLJS.k(null,1,
[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",14,$CLJS.Am,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Am,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(mU)?mU.J:null])):
null));return $CLJS.m(a)?a:nQ}(),mU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof hU?new $CLJS.ud(function(){return hU},$CLJS.K(bR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.vO,"metabase/mbql/schema.cljc",13,$CLJS.om,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.om,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,bV),TR,$CLJS.Y($CLJS.Ll,bV),zR,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,bV))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(hU)?hU.J:null])):null));return $CLJS.m(a)?a:$CLJS.vO}(),hU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof iU?new $CLJS.ud(function(){return iU},
$CLJS.K(ZO,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",14,$CLJS.YK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.YK,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,bV),TR,$CLJS.Y($CLJS.Ll,bV),zR,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,bV))],null),
$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(iU)?iU.J:null])):null));return $CLJS.m(a)?a:kP}(),iU],null)]));
BU=dO.l($CLJS.G([$CLJS.hg(mO,cT),$CLJS.gT,$CLJS.hg(mO,$S),dT,$CLJS.hg(mO,XS),YS,$CLJS.hg(mO,aT),iT,$CLJS.Cv,nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(fS,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",15,$CLJS.Qj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Qj,vP,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),RQ,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),BP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:mQ}(),bU],
null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(UQ,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XQ,"metabase/mbql/schema.cljc",14,$CLJS.Hk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Hk,vP,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),RQ,$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))),BP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:XQ}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==
typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(vR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.LM,"metabase/mbql/schema.cljc",15,$CLJS.Zk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Zk,$CLJS.pV,
$CLJS.Y($CLJS.Ll,$CLJS.Y(oP,$CLJS.Y($CLJS.Pl,rQ)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(dU)?dU.J:null])):null));return $CLJS.m(a)?a:$CLJS.LM}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof hU?new $CLJS.ud(function(){return hU},$CLJS.K(bR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,
bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.vO,"metabase/mbql/schema.cljc",13,$CLJS.om,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.om,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,bV),TR,$CLJS.Y($CLJS.Ll,bV),zR,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,bV))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(hU)?hU.J:null])):null));return $CLJS.m(a)?a:$CLJS.vO}(),hU],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof iU?new $CLJS.ud(function(){return iU},$CLJS.K(ZO,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",
14,$CLJS.YK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.YK,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,bV),TR,$CLJS.Y($CLJS.Ll,bV),zR,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,bV))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(iU)?iU.J:null])):null));return $CLJS.m(a)?a:kP}(),iU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof jU?new $CLJS.ud(function(){return jU},$CLJS.K(cR,new $CLJS.k(null,
1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",13,$CLJS.pm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.pm,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(jU)?jU.J:null])):null));
return $CLJS.m(a)?a:fQ}(),jU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof kU?new $CLJS.ud(function(){return kU},$CLJS.K(aR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gQ,"metabase/mbql/schema.cljc",13,$CLJS.rm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rm,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(kU)?kU.J:null])):null));return $CLJS.m(a)?a:gQ}(),kU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof lU?new $CLJS.ud(function(){return lU},$CLJS.K(yS,new $CLJS.k(null,1,
[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",14,$CLJS.Bm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Bm,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(lU)?lU.J:null])):
null));return $CLJS.m(a)?a:eQ}(),lU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof mU?new $CLJS.ud(function(){return mU},$CLJS.K(rS,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nQ,"metabase/mbql/schema.cljc",14,$CLJS.Am,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Am,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),TR,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(mU)?mU.J:null])):null));return $CLJS.m(a)?a:nQ}(),mU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof nU?new $CLJS.ud(function(){return nU},$CLJS.K(Nia,new $CLJS.k(null,
1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",19,$CLJS.eJ,1,820,820,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.eJ,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,wP),$CLJS.wk,$CLJS.Y($CLJS.Ll,wP),$CLJS.em,$CLJS.Y($CLJS.Ll,wP)],null),$CLJS.yd,"Schema for a valid between clause.",
$CLJS.m(nU)?nU.J:null])):null));return $CLJS.m(a)?a:aP}(),nU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof uU?new $CLJS.ud(function(){return uU},$CLJS.K(Qia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YR,"metabase/mbql/schema.cljc",23,$CLJS.QJ,1,843,843,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QJ,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,SR),jP,$CLJS.Y($CLJS.Ll,SR),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,uR))],null),$CLJS.yd,"Schema for a valid starts-with clause.",$CLJS.m(uU)?uU.J:null])):null));return $CLJS.m(a)?a:YR}(),uU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof vU?new $CLJS.ud(function(){return vU},
$CLJS.K(Iha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",21,$CLJS.IK,1,844,844,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.IK,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,SR),jP,$CLJS.Y($CLJS.Ll,SR),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,uR))],
null),$CLJS.yd,"Schema for a valid ends-with clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:nP}(),vU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof wU?new $CLJS.ud(function(){return wU},$CLJS.K(Zha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",20,$CLJS.fK,1,845,845,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.fK,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,SR),jP,$CLJS.Y($CLJS.Ll,SR),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,uR))],null),$CLJS.yd,"Schema for a valid contains clause.",$CLJS.m(wU)?wU.J:null])):null));return $CLJS.m(a)?a:xP}(),wU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==
typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof xU?new $CLJS.ud(function(){return xU},$CLJS.K(Aka,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",36,$CLJS.RJ,1,848,848,!0,new $CLJS.S(null,
7,5,$CLJS.T,[$CLJS.RJ,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,SR),jP,$CLJS.Y($CLJS.Ll,SR),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,uR))],null),$CLJS.yd,"Schema for a valid does-not-contain clause.",$CLJS.m(xU)?xU.J:null])):null));return $CLJS.m(a)?a:IO}(),xU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof oU?new $CLJS.ud(function(){return oU},$CLJS.K(sja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),
$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WR,"metabase/mbql/schema.cljc",26,$CLJS.pJ,1,823,823,!0,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.pJ,nha,$CLJS.Y($CLJS.Ll,wP),Fka,$CLJS.Y($CLJS.Ll,wP),Bja,$CLJS.Y($CLJS.Ll,wP),rha,$CLJS.Y($CLJS.Ll,wP),uja,$CLJS.Y($CLJS.Ll,wP),mha,$CLJS.Y($CLJS.Ll,wP)],
null),$CLJS.yd,"Schema for a valid inside clause.",$CLJS.m(oU)?oU.J:null])):null));return $CLJS.m(a)?a:WR}(),oU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof rU?new $CLJS.ud(function(){return rU},$CLJS.K($ha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IQ,"metabase/mbql/schema.cljc",28,$CLJS.fJ,1,837,837,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fJ,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,rP)],null),$CLJS.yd,"Schema for a valid is-empty clause.",$CLJS.m(rU)?rU.J:null])):null));return $CLJS.m(a)?a:IQ}(),rU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&
"undefined"!==typeof sU?new $CLJS.ud(function(){return sU},$CLJS.K(cja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",29,$CLJS.vJ,1,838,838,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vJ,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,rP)],null),
$CLJS.yd,"Schema for a valid not-empty clause.",$CLJS.m(sU)?sU.J:null])):null));return $CLJS.m(a)?a:nR}(),sU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof pU?new $CLJS.ud(function(){return pU},$CLJS.K(Jja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",27,$CLJS.TI,1,832,832,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TI,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,rP)],null),$CLJS.yd,"Schema for a valid is-null clause.",$CLJS.m(pU)?pU.J:null])):null));return $CLJS.m(a)?a:AP}(),pU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&
"undefined"!==typeof qU?new $CLJS.ud(function(){return qU},$CLJS.K(mia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",28,$CLJS.JJ,1,833,833,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JJ,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,rP)],null),
$CLJS.yd,"Schema for a valid not-null clause.",$CLJS.m(qU)?qU.J:null])):null));return $CLJS.m(a)?a:eP}(),qU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof yU?new $CLJS.ud(function(){return yU},$CLJS.K(dha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FR,"metabase/mbql/schema.cljc",33,$CLJS.MK,1,868,868,!0,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.MK,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,rP),$CLJS.bD,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.PQ,YQ,$CLJS.Y(Gka,$CLJS.WB,$CLJS.ZD,$CLJS.mJ))),$CLJS.bk,$CLJS.Y($CLJS.Ll,lQ),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,Tia))],null),$CLJS.yd,"Schema for a valid time-interval clause.",$CLJS.m(yU)?yU.J:null])):null));
return $CLJS.m(a)?a:FR}(),yU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof zU?new $CLJS.ud(function(){return zU},$CLJS.K(yia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pS,"metabase/mbql/schema.cljc",27,$CLJS.JI,1,881,881,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JI,vha,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.PQ,tP,jS))],null),$CLJS.yd,"Schema for a valid segment clause.",$CLJS.m(zU)?zU.J:null])):null));return $CLJS.m(a)?a:pS}(),zU],null)]))]));
$CLJS.aU=gO(new $CLJS.ud(function(){return BU},Lja,$CLJS.Yh([$CLJS.Rl,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[!0,$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Oha,"metabase/mbql/schema.cljc",23,1,892,892,$CLJS.yd,null,$CLJS.m(BU)?BU.J:null])));
dka=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZN($CLJS.aU,"pred"),$CLJS.ZN(jT,"expr")],null)],null);eka=$CLJS.Tf([$N($CLJS.Uj),jT]);CU=lO($CLJS.uJ,$CLJS.G(["clauses",dka,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,eka],null)]));
IT=nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof wT?new $CLJS.ud(function(){return wT},$CLJS.K(eR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZP,"metabase/mbql/schema.cljc",51,$CLJS.tm,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.tm,$CLJS.NH,$CLJS.Y($CLJS.Ll,hR),iS,$CLJS.Y($CLJS.Ll,hR),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,hR))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(wT)?wT.J:null])):null));return $CLJS.m(a)?a:ZP}(),wT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof xT?new $CLJS.ud(function(){return xT},
$CLJS.K(Jia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",51,$CLJS.bv,1,633,633,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.bv,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR),iS,
$CLJS.Y($CLJS.Ll,hR),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,hR))],null),$CLJS.yd,"Schema for a valid - clause.",$CLJS.m(xT)?xT.J:null])):null));return $CLJS.m(a)?a:$P}(),xT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO?new $CLJS.ud(function(){return yT},$CLJS.K(Iia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,yQ,$CLJS.Gk,$CLJS.cl,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,
new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Dv,"metabase/mbql/schema.cljc",$CLJS.zL,1,636,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.zL,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR),iS,$CLJS.Y($CLJS.Ll,MR),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,MR))],null),$CLJS.yd,"Schema for a valid / clause.",$CLJS.m(yT)?yT.J:null])):null));return $CLJS.m(a)?a:$CLJS.Dv}(),yT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof zT?new $CLJS.ud(function(){return zT},$CLJS.K(Kia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YP,"metabase/mbql/schema.cljc",51,$CLJS.um,1,638,638,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.um,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR),iS,$CLJS.Y($CLJS.Ll,MR),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,MR))],null),$CLJS.yd,"Schema for a valid * clause.",$CLJS.m(zT)?zT.J:null])):null));return $CLJS.m(a)?a:YP}(),zT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},
$CLJS.K(BR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QR,"metabase/mbql/schema.cljc",58,$CLJS.lK,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.lK,nS,$CLJS.Y($CLJS.Ll,LO),mS,$CLJS.Y($CLJS.Ll,
LO),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,LO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:QR}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof nT?new $CLJS.ud(function(){return nT},$CLJS.K(bja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,
$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",56,$CLJS.MD,1,603,603,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MD,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR)],null),$CLJS.yd,"Schema for a valid length clause.",$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:qR}(),nT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof AT?new $CLJS.ud(function(){return AT},$CLJS.K(Jha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wQ,"metabase/mbql/schema.cljc",55,$CLJS.bK,1,640,640,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bK,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid floor clause.",$CLJS.m(AT)?AT.J:null])):null));return $CLJS.m(a)?a:wQ}(),AT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof BT?new $CLJS.ud(function(){return BT},$CLJS.K(Bha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),
$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",54,$CLJS.FI,1,643,643,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FI,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid ceil clause.",$CLJS.m(BT)?
BT.J:null])):null));return $CLJS.m(a)?a:VO}(),BT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof CT?new $CLJS.ud(function(){return CT},$CLJS.K(Wha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",55,$CLJS.qK,1,646,646,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qK,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid round clause.",$CLJS.m(CT)?CT.J:null])):null));return $CLJS.m(a)?a:oQ}(),CT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&
"undefined"!==typeof DT?new $CLJS.ud(function(){return DT},$CLJS.K(Bka,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",53,$CLJS.PI,1,649,649,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.PI,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid abs clause.",$CLJS.m(DT)?DT.J:null])):null));return $CLJS.m(a)?a:NQ}(),DT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof ET?new $CLJS.ud(function(){return ET},$CLJS.K(yja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,
$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[uS,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eS,"metabase/mbql/schema.cljc",69,$CLJS.II,1,652,652,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.II,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR),iS,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid power clause.",$CLJS.m(ET)?ET.J:null])):null));return $CLJS.m(a)?a:eS}(),ET],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof FT?new $CLJS.ud(function(){return FT},$CLJS.K(ria,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[uS,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VP,"metabase/mbql/schema.cljc",68,$CLJS.KJ,1,655,655,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.KJ,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid sqrt clause.",$CLJS.m(FT)?FT.J:null])):null));return $CLJS.m(a)?a:VP}(),FT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof GT?new $CLJS.ud(function(){return GT},$CLJS.K(Aja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,
$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[uS,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",67,$CLJS.uI,1,658,658,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uI,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid exp clause.",$CLJS.m(GT)?GT.J:null])):
null));return $CLJS.m(a)?a:qQ}(),GT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof HT?new $CLJS.ud(function(){return HT},$CLJS.K(uha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[uS,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zS,"metabase/mbql/schema.cljc",67,$CLJS.BL,1,661,661,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BL,$CLJS.NH,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid log clause.",$CLJS.m(HT)?HT.J:null])):null));return $CLJS.m(a)?a:zS}(),HT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==
typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(PO,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",61,$CLJS.uJ,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,
[$CLJS.uJ,$CLJS.eD,$CLJS.Y($CLJS.Ll,CP),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,VQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:DQ}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof JT?new $CLJS.ud(function(){return JT},$CLJS.K(xha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,
yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.DJ,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",65,$CLJS.DJ,1,680,680,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.DJ,Pha,$CLJS.Y($CLJS.Ll,JV),Qha,$CLJS.Y($CLJS.Ll,JV),$CLJS.bk,$CLJS.Y($CLJS.Ll,tja)],null),$CLJS.yd,"Schema for a valid datetime-diff clause.",
$CLJS.m(JT)?JT.J:null])):null));return $CLJS.m(a)?a:OQ}(),JT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof KT?new $CLJS.ud(function(){return KT},$CLJS.K(gja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",71,$CLJS.KK,1,685,685,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.KK,$CLJS.XJ,$CLJS.Y($CLJS.Ll,JV),$CLJS.bk,$CLJS.Y($CLJS.Ll,Cia),$CLJS.jk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,qS))],null),$CLJS.yd,"Schema for a valid temporal-extract clause.",$CLJS.m(KT)?KT.J:null])):null));return $CLJS.m(a)?a:fR}(),KT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof LT?new $CLJS.ud(function(){return LT},$CLJS.K(jia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hP,"metabase/mbql/schema.cljc",71,$CLJS.$I,1,691,691,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$I,QQ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-year clause.",$CLJS.m(LT)?LT.J:null])):null));return $CLJS.m(a)?a:hP}(),LT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof MT?new $CLJS.ud(function(){return MT},$CLJS.K(Xha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),
$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dR,"metabase/mbql/schema.cljc",74,$CLJS.CL,1,694,694,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CL,QQ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-quarter clause.",
$CLJS.m(MT)?MT.J:null])):null));return $CLJS.m(a)?a:dR}(),MT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof NT?new $CLJS.ud(function(){return NT},$CLJS.K(Hia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",72,$CLJS.tI,1,697,697,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tI,QQ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-month clause.",$CLJS.m(NT)?NT.J:null])):null));return $CLJS.m(a)?a:sR}(),NT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==
typeof tO&&"undefined"!==typeof OT?new $CLJS.ud(function(){return OT},$CLJS.K(Hja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",71,$CLJS.yK,1,700,700,
!0,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.yK,QQ,$CLJS.Y($CLJS.Ll,JV),$CLJS.jk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,qS))],null),$CLJS.yd,"Schema for a valid get-week clause.",$CLJS.m(OT)?OT.J:null])):null));return $CLJS.m(a)?a:rR}(),OT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof PT?new $CLJS.ud(function(){return PT},$CLJS.K(kha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,
$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",70,$CLJS.wK,1,704,704,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wK,QQ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-day clause.",$CLJS.m(PT)?PT.J:
null])):null));return $CLJS.m(a)?a:mR}(),PT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof QT?new $CLJS.ud(function(){return QT},$CLJS.K(Aia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iR,"metabase/mbql/schema.cljc",78,$CLJS.FK,1,707,707,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FK,QQ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-day-of-week clause.",$CLJS.m(QT)?QT.J:null])):null));return $CLJS.m(a)?a:iR}(),QT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&
"undefined"!==typeof RT?new $CLJS.ud(function(){return RT},$CLJS.K(zia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",71,$CLJS.SK,1,710,710,!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.SK,$CLJS.XJ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-hour clause.",$CLJS.m(RT)?RT.J:null])):null));return $CLJS.m(a)?a:MO}(),RT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof ST?new $CLJS.ud(function(){return ST},$CLJS.K(lja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,
cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oV,"metabase/mbql/schema.cljc",73,$CLJS.CK,1,713,713,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CK,$CLJS.XJ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-minute clause.",$CLJS.m(ST)?ST.J:null])):null));return $CLJS.m(a)?a:oV}(),ST],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof TT?new $CLJS.ud(function(){return TT},$CLJS.K(via,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.KK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FQ,"metabase/mbql/schema.cljc",73,$CLJS.ZI,1,716,716,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZI,$CLJS.XJ,$CLJS.Y($CLJS.Ll,JV)],null),$CLJS.yd,"Schema for a valid get-second clause.",$CLJS.m(TT)?TT.J:null])):null));return $CLJS.m(a)?a:FQ}(),TT],null)]));
$T=nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof mT?new $CLJS.ud(function(){return mT},$CLJS.K($ia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wR,"metabase/mbql/schema.cljc",59,$CLJS.wI,1,600,600,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.wI,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR),$CLJS.zB,$CLJS.Y($CLJS.Ll,Sha),$CLJS.MD,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,MR))],null),$CLJS.yd,"Schema for a valid substring clause.",$CLJS.m(mT)?mT.J:null])):null));return $CLJS.m(a)?a:wR}(),mT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof oT?new $CLJS.ud(function(){return oT},
$CLJS.K(Dja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",54,$CLJS.XK,1,606,606,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XK,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR)],null),
$CLJS.yd,"Schema for a valid trim clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:NR}(),oT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof qT?new $CLJS.ud(function(){return qT},$CLJS.K(Bia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,
1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AR,"metabase/mbql/schema.cljc",55,$CLJS.bL,1,612,612,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bL,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR)],null),$CLJS.yd,"Schema for a valid ltrim clause.",$CLJS.m(qT)?qT.J:null])):null));return $CLJS.m(a)?a:AR}(),qT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&
"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof pT?new $CLJS.ud(function(){return pT},$CLJS.K(aja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",
55,$CLJS.NL,1,609,609,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NL,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR)],null),$CLJS.yd,"Schema for a valid rtrim clause.",$CLJS.m(pT)?pT.J:null])):null));return $CLJS.m(a)?a:TQ}(),pT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof tT?new $CLJS.ud(function(){return tT},$CLJS.K(eha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,
$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",57,$CLJS.IL,1,621,621,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.IL,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR),sha,$CLJS.Y($CLJS.Ll,$CLJS.kQ),$CLJS.FF,$CLJS.Y($CLJS.Ll,$CLJS.kQ)],null),$CLJS.yd,"Schema for a valid replace clause.",
$CLJS.m(tT)?tT.J:null])):null));return $CLJS.m(a)?a:oS}(),tT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof sT?new $CLJS.ud(function(){return sT},$CLJS.K(dja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MV,"metabase/mbql/schema.cljc",55,$CLJS.Mv,1,618,618,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mv,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR)],null),$CLJS.yd,"Schema for a valid lower clause.",$CLJS.m(sT)?sT.J:null])):null));return $CLJS.m(a)?a:MV}(),sT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==
typeof tO&&"undefined"!==typeof rT?new $CLJS.ud(function(){return rT},$CLJS.K(Gja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aS,"metabase/mbql/schema.cljc",55,$CLJS.jv,1,615,615,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.jv,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR)],null),$CLJS.yd,"Schema for a valid upper clause.",$CLJS.m(rT)?rT.J:null])):null));return $CLJS.m(a)?a:aS}(),rT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof uT?new $CLJS.ud(function(){return uT},$CLJS.K(Sia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,
bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",56,$CLJS.KI,1,624,624,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.KI,nS,$CLJS.Y($CLJS.Ll,SR),mS,$CLJS.Y($CLJS.Ll,SR),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,SR))],null),$CLJS.yd,"Schema for a valid concat clause.",$CLJS.m(uT)?uT.J:null])):
null));return $CLJS.m(a)?a:NP}(),uT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof vT?new $CLJS.ud(function(){return vT},$CLJS.K(Zia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.jR,"null",$CLJS.Wea,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",74,$CLJS.jK,1,627,627,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.jK,$CLJS.cE,$CLJS.Y($CLJS.Ll,SR),$CLJS.bF,$CLJS.Y($CLJS.Ll,$CLJS.kQ)],null),$CLJS.yd,"Schema for a valid regex-match-first clause.",$CLJS.m(vT)?vT.J:null])):null));return $CLJS.m(a)?a:fP}(),vT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&
"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},$CLJS.K(BR,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.jR,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QR,"metabase/mbql/schema.cljc",
58,$CLJS.lK,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.lK,nS,$CLJS.Y($CLJS.Ll,LO),mS,$CLJS.Y($CLJS.Ll,LO),uP,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.zk,LO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:QR}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(PO,new $CLJS.k(null,
1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",61,$CLJS.uJ,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.uJ,$CLJS.eD,$CLJS.Y($CLJS.Ll,CP),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,
VQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:DQ}(),CU],null)]));$CLJS.DU=dO.l($CLJS.G([$CLJS.hg(mO,$S),dT,$CLJS.hg(mO,XS),YS,$CLJS.hg(mO,aT),iT,$CLJS.hg(mO,cT),$CLJS.gT,$CLJS.hg(mO,$CLJS.uJ),CU,$CLJS.Cv,$CLJS.VS]));EU=lO($CLJS.VD,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,$CLJS.VS],null)]));FU=lO($CLJS.hK,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,$CLJS.VS],null)]));
GU=lO($CLJS.YJ,$CLJS.G(["field-or-expression",$CLJS.DU]));HU=lO($CLJS.AL,$CLJS.G(["field-or-expression",$CLJS.DU]));IU=lO($CLJS.hJ,$CLJS.G(["field-or-expression",$CLJS.DU]));JU=lO($CLJS.OK,$CLJS.G(["field-or-expression",$CLJS.DU]));KU=lO($CLJS.wk,$CLJS.G(["field-or-expression",$CLJS.DU]));LU=lO($CLJS.em,$CLJS.G(["field-or-expression",$CLJS.DU]));MU=lO($CLJS.xL,$CLJS.G(["field-or-expression",$CLJS.DU,"pred",$CLJS.aU]));NU=lO($CLJS.AK,$CLJS.G(["pred",$CLJS.aU]));OU=lO($CLJS.tK,$CLJS.G(["pred",$CLJS.aU]));
PU=lO($CLJS.VK,$CLJS.G(["field-or-expression",$CLJS.DU]));QU=lO($CLJS.lJ,$CLJS.G(["field-or-expression",$CLJS.DU]));RU=lO($CLJS.QK,$CLJS.G(["field-or-expression",$CLJS.DU]));SU=lO($CLJS.IJ,$CLJS.G(["field-or-expression",$CLJS.DU,"percentile",fT]));TU=lO($CLJS.vQ,$CLJS.G(["metric-id",$CLJS.RN($CLJS.G([$CLJS.VN,$CLJS.SN]))]));
UU=eO($CLJS.hg(mO,$S),dT,nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof GU?new $CLJS.ud(function(){return GU},$CLJS.K(Yia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",60,$CLJS.YJ,1,955,955,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YJ,bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid avg clause.",$CLJS.m(GU)?GU.J:null])):null));return $CLJS.m(a)?a:AQ}(),GU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof HU?
new $CLJS.ud(function(){return HU},$CLJS.K(dia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hS,"metabase/mbql/schema.cljc",64,$CLJS.AL,1,956,956,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AL,
bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid cum-sum clause.",$CLJS.m(HU)?HU.J:null])):null));return $CLJS.m(a)?a:hS}(),HU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof IU?new $CLJS.ud(function(){return IU},$CLJS.K(Vha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],
[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EQ,"metabase/mbql/schema.cljc",65,$CLJS.hJ,1,957,957,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hJ,bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid distinct clause.",$CLJS.m(IU)?IU.J:null])):null));return $CLJS.m(a)?a:EQ}(),IU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof PU?new $CLJS.ud(function(){return PU},$CLJS.K(vja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.GK,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OO,"metabase/mbql/schema.cljc",76,$CLJS.VK,1,971,971,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VK,bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid stddev clause.",$CLJS.m(PU)?PU.J:null])):null));return $CLJS.m(a)?a:OO}(),PU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof JU?new $CLJS.ud(function(){return JU},$CLJS.K(Mia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,
$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",60,$CLJS.OK,1,958,958,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OK,bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid sum clause.",$CLJS.m(JU)?JU.J:null])):
null));return $CLJS.m(a)?a:YO}(),JU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof KU?new $CLJS.ud(function(){return KU},$CLJS.K(oha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.pa,"metabase/mbql/schema.cljc",60,$CLJS.wk,1,959,959,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid min clause.",$CLJS.m(KU)?KU.J:null])):null));return $CLJS.m(a)?a:$CLJS.pa}(),KU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&
"undefined"!==typeof LU?new $CLJS.ud(function(){return LU},$CLJS.K(jha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.za,"metabase/mbql/schema.cljc",60,$CLJS.em,1,960,960,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.em,bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid max clause.",$CLJS.m(LU)?LU.J:null])):null));return $CLJS.m(a)?a:$CLJS.za}(),LU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof TU?new $CLJS.ud(function(){return TU},$CLJS.K(Rha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,
$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",26,$CLJS.vQ,1,989,989,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vQ,eia,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.PQ,tP,jS))],null),$CLJS.yd,"Schema for a valid metric clause.",$CLJS.m(TU)?TU.J:null])):null));return $CLJS.m(a)?a:MP}(),TU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==
typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof OU?new $CLJS.ud(function(){return OU},$CLJS.K(tia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
GP,"metabase/mbql/schema.cljc",62,$CLJS.tK,1,968,968,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tK,$CLJS.El,$CLJS.Y($CLJS.Ll,rQ)],null),$CLJS.yd,"Schema for a valid share clause.",$CLJS.m(OU)?OU.J:null])):null));return $CLJS.m(a)?a:GP}(),OU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof NU?new $CLJS.ud(function(){return NU},$CLJS.K(qha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,
$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",68,$CLJS.AK,1,965,965,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AK,$CLJS.El,$CLJS.Y($CLJS.Ll,rQ)],null),$CLJS.yd,"Schema for a valid count-where clause.",$CLJS.m(NU)?
NU.J:null])):null));return $CLJS.m(a)?a:WO}(),NU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof MU?new $CLJS.ud(function(){return MU},$CLJS.K(Oja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BQ,"metabase/mbql/schema.cljc",66,$CLJS.xL,1,962,962,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xL,bS,$CLJS.Y($CLJS.Ll,UR),$CLJS.El,$CLJS.Y($CLJS.Ll,rQ)],null),$CLJS.yd,"Schema for a valid sum-where clause.",$CLJS.m(MU)?MU.J:null])):null));return $CLJS.m(a)?a:BQ}(),MU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&
"undefined"!==typeof tO&&"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(PO,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",61,$CLJS.uJ,
1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.uJ,$CLJS.eD,$CLJS.Y($CLJS.Ll,CP),$CLJS.Tk,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,VQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:DQ}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof RU?new $CLJS.ud(function(){return RU},$CLJS.K(oja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,
$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.$J,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",68,$CLJS.QK,1,977,977,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QK,bS,$CLJS.Y($CLJS.Ll,UR)],null),$CLJS.yd,"Schema for a valid median clause.",$CLJS.m(RU)?RU.J:null])):
null));return $CLJS.m(a)?a:gS}(),RU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof SU?new $CLJS.ud(function(){return SU},$CLJS.K(Gia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.$J,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KR,"metabase/mbql/schema.cljc",72,$CLJS.IJ,1,980,980,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.IJ,bS,$CLJS.Y($CLJS.Ll,UR),$CLJS.IJ,$CLJS.Y($CLJS.Ll,MR)],null),$CLJS.yd,"Schema for a valid percentile clause.",$CLJS.m(SU)?SU.J:null])):null));return $CLJS.m(a)?a:KR}(),SU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&
"undefined"!==typeof tO&&"undefined"!==typeof QU?new $CLJS.ud(function(){return QU},$CLJS.K(Aha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",77,$CLJS.lJ,1,974,974,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lJ,bS,$CLJS.Y($CLJS.Ll,
UR)],null),$CLJS.yd,"Schema for a valid var clause.",$CLJS.m(QU)?QU.J:null])):null));return $CLJS.m(a)?a:NO}(),QU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof FU?new $CLJS.ud(function(){return FU},$CLJS.K(lha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,
new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",74,$CLJS.hK,1,945,945,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hK,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,rP))],null),$CLJS.yd,"Schema for a valid cum-count clause.",$CLJS.m(FU)?FU.J:null])):null));return $CLJS.m(a)?a:sQ}(),FU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof EU?new $CLJS.ud(function(){return EU},$CLJS.K(Tha,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([xQ,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,cS,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.hk,"metabase/mbql/schema.cljc",70,$CLJS.VD,1,944,944,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VD,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.EO,rP))],null),$CLJS.yd,"Schema for a valid count clause.",$CLJS.m(EU)?EU.J:null])):null));return $CLJS.m(a)?a:$CLJS.hk}(),EU],null)])));
VU=gO(new $CLJS.ud(function(){return UU},Fja,$CLJS.Yh([$CLJS.Rl,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[!0,$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Ija,"metabase/mbql/schema.cljc",35,1,995,995,$CLJS.yd,null,$CLJS.m(UU)?UU.J:null])));fka=$CLJS.Tf([$N($CLJS.V),$CLJS.SN,$N($CLJS.oJ),$CLJS.SN,$CLJS.ON,$CLJS.JN]);
$CLJS.WU=lO($CLJS.JO,$CLJS.G(["aggregation",VU,"options",fka]));$CLJS.eT=eO($CLJS.hg(mO,$CLJS.JO),$CLJS.WU,VU);XU=lO($CLJS.aK,$CLJS.G(["field",$CLJS.WS]));YU=lO($CLJS.OJ,$CLJS.G(["field",$CLJS.WS]));
$CLJS.ZU=nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof XU?new $CLJS.ud(function(){return XU},$CLJS.K(rja,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dQ,"metabase/mbql/schema.cljc",15,$CLJS.aK,1,1033,1033,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aK,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,HP)],null),$CLJS.yd,"Schema for a valid asc clause.",$CLJS.m(XU)?XU.J:null])):null));return $CLJS.m(a)?a:dQ}(),XU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof YU?new $CLJS.ud(function(){return YU},$CLJS.K(cia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),
$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CR,"metabase/mbql/schema.cljc",16,$CLJS.OJ,1,1034,1034,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OJ,$CLJS.ZQ,$CLJS.Y($CLJS.Ll,HP)],null),$CLJS.yd,"Schema for a valid desc clause.",$CLJS.m(YU)?YU.J:null])):null));return $CLJS.m(a)?a:CR}(),YU],null)]));
bO.l($CLJS.G([$CLJS.HI,$CLJS.kJ,$CLJS.yI,$CLJS.Uz,$CLJS.LH,QQ]));$U=$CLJS.Tf([$N($CLJS.Mk),$CLJS.SN,$CLJS.V,$CLJS.SN,$CLJS.oJ,$CLJS.SN,$CLJS.ON,$CLJS.JN]);gka=$CLJS.pn.l($CLJS.G([$U,$CLJS.Tf([$CLJS.bl,$CLJS.ZM($CLJS.HI),$CLJS.AI,$CLJS.SN,Pia,$CLJS.VN,$N($CLJS.gR),$CLJS.VN])]));hka=$CLJS.pn.l($CLJS.G([$U,new $CLJS.k(null,2,[$CLJS.bl,$CLJS.ZM($CLJS.kJ),$CLJS.dL,$CLJS.VN],null)]));aV=$CLJS.pn.l($CLJS.G([$U,$CLJS.Tf([$N($CLJS.Uj),$CLJS.JN,$N(Nha),$CLJS.LN])]));
ika=$CLJS.pn.l($CLJS.G([aV,$CLJS.Tf([$CLJS.bl,$CLJS.ZM($CLJS.yI),$CLJS.yI,TS,$CLJS.yJ,gO(new $CLJS.ud(function(){return AS},Eja,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Eka,"metabase/mbql/schema.cljc",34,1,1130,!0,1130,$CLJS.yd,null,$CLJS.m(AS)?AS.J:null]))),$N($CLJS.Tk),$CLJS.Tf([$CLJS.ON,
$CLJS.JN])])]));jka=$CLJS.Q.h(bO,new $CLJS.ti(null,new $CLJS.k(null,4,[QQ,null,$CLJS.Uz,null,$CLJS.qk,null,$CLJS.LH,null],null),null));kka=$CLJS.pn.l($CLJS.G([aV,new $CLJS.k(null,1,[$CLJS.bl,jka],null)]));lka=dO.l($CLJS.G([function(a){return $CLJS.F.h($CLJS.bl.g(a),$CLJS.yI)},ika,function(a){return $CLJS.F.h($CLJS.bl.g(a),$CLJS.HI)},gka,function(a){return $CLJS.F.h($CLJS.bl.g(a),$CLJS.kJ)},hka,$CLJS.Cv,kka]));
mka=$CLJS.eN($CLJS.Tf([$CLJS.SN,lka]),function(a){return $CLJS.$f(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.V.g(b))},a)},"keys in template tag map must match the :name of their values");cV=$CLJS.Tf([$CLJS.QO,$CLJS.JN,$N($CLJS.DR),mka,$N(IP),$CLJS.iO($CLJS.SN),$CLJS.ON,$CLJS.JN]);
eV=eO($CLJS.Vm.h($CLJS.oe,$CLJS.oR),$CLJS.YN(cV,new $CLJS.k(null,1,[$CLJS.QO,$CLJS.oR],null)),gO(new $CLJS.ud(function(){return $CLJS.dV},nia,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pja,"metabase/mbql/schema.cljc",25,1,1212,!0,1212,$CLJS.yd,null,$CLJS.m($CLJS.dV)?$CLJS.dV.J:null]))));
fV=$CLJS.Tf([$CLJS.V,$CLJS.SN,$CLJS.PJ,$CLJS.TN,Oia,$CLJS.SN,$N($CLJS.XP),$CLJS.iO($CLJS.UN),$N($CLJS.zP),$CLJS.iO($CLJS.Sga),$CLJS.JN,$CLJS.JN]);$CLJS.gV=/^card__[1-9]\d*$/;hV=$CLJS.RN($CLJS.G([$CLJS.VN,$CLJS.gV]));nka=$CLJS.Q.h(bO,new $CLJS.ti(null,new $CLJS.k(null,4,[$CLJS.dS,null,$CLJS.HO,null,$CLJS.SQ,null,$CLJS.IR,null],null),null));
oka=$CLJS.eN($CLJS.Yh([$CLJS.ON,$N($CLJS.iQ),$N($CLJS.tQ),$N($CLJS.RP),$N($CLJS.SO),$CLJS.vS,$N($CLJS.GR),$N($CLJS.ER),$N($CLJS.LL)],[$CLJS.JN,$CLJS.iO(new $CLJS.S(null,1,5,$CLJS.T,[fV],null)),hV,$CLJS.SN,$CLJS.cN($CLJS.RN($CLJS.G([bO.l($CLJS.G([$CLJS.eE,$CLJS.YE])),gO(new $CLJS.ud(function(){return iV},jja,$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,yR,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Uia,"metabase/mbql/schema.cljc",16,1,1259,!0,1259,$CLJS.yd,null,$CLJS.m(iV)?iV.J:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.aU,eV,$CLJS.iO($CLJS.VN),nka]),$CLJS.Vm.h($CLJS.Wm.h($CLJS.tQ,$CLJS.GR),$CLJS.fg($CLJS.Vm.h($CLJS.tQ,$CLJS.GR))),"Joins must have either a `source-table` or `source-query`, but not both.");
pka=$CLJS.eN(oO(new $CLJS.S(null,1,5,$CLJS.T,[oka],null)),function(a){return pO($CLJS.Ag($CLJS.Hb,$CLJS.xg.h($CLJS.RP,a)))},"All join aliases must be unique.");iV=$CLJS.cN($CLJS.eN(oO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.VS],null)),pO,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.dV=$CLJS.eN($CLJS.eN($CLJS.Yh([$CLJS.ON,$N($CLJS.tS),$N($CLJS.iQ),$N($CLJS.pP),$N($CLJS.tQ),$N($CLJS.dP),$N($CLJS.SO),$N($CLJS.hQ),$N($CLJS.aQ),$N($CLJS.GR),$N($CLJS.NV),$N(pR),$N($CLJS.jR)],[$CLJS.JN,$CLJS.eN(oO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ZU],null)),pO,"distinct"),$CLJS.iO(new $CLJS.S(null,1,5,$CLJS.T,[fV],null)),pka,hV,$CLJS.aU,iV,oO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.eT],null)),oO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.VS],null)),eV,$CLJS.WN,new $CLJS.k(null,2,[pR,$CLJS.VN,Gha,$CLJS.VN],
null),$CLJS.Tf([$CLJS.SN,$CLJS.DU])]),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.ri(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GR,$CLJS.tQ],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.aQ);b=$CLJS.M.h(b,$CLJS.SO);return $CLJS.je($CLJS.sA.h($CLJS.yi(a),$CLJS.yi(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
jV=$CLJS.Yh([$CLJS.$R,QQ,FP,yP,jQ,HV,UP,$CLJS.wV,$CLJS.Uz,TP,RO,RR,wS,$CLJS.$O,sS,EP,$CLJS.pQ,SP,$CLJS.Mk,$CLJS.QP,KO,$CLJS.mP,$CLJS.PP,MQ,$CLJS.qk,$CLJS.LH,KP],[new $CLJS.k(null,1,[cQ,new $CLJS.ti(null,new $CLJS.k(null,5,[$CLJS.$R,null,QQ,null,$CLJS.Uz,null,$CLJS.qk,null,$CLJS.LH,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,QQ,cQ,new $CLJS.ti(null,new $CLJS.k(null,5,[$CLJS.$R,null,QQ,null,$CLJS.wV,null,$CLJS.pQ,null,$CLJS.Mk,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,$CLJS.Ul,
$CLJS.kK,ZR,cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[FP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,KV,$CLJS.kK,ZR,cQ,new $CLJS.ti(null,new $CLJS.k(null,5,[$CLJS.$R,null,yP,null,$CLJS.Uz,null,$CLJS.Mk,null,KO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,$CLJS.Ul,$CLJS.kK,UO,cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[jQ,null],null),null)],null),new $CLJS.k(null,1,[cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[HV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,KV,$CLJS.kK,hja,cQ,new $CLJS.ti(null,
new $CLJS.k(null,1,[UP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,QQ,cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.wV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,KV,cQ,new $CLJS.ti(null,new $CLJS.k(null,5,[$CLJS.$R,null,yP,null,$CLJS.Uz,null,$CLJS.Mk,null,KO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,KV,$CLJS.kK,UO,cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[TP,null],null),null)],null),new $CLJS.k(null,1,[cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[RO,null],null),null)],
null),new $CLJS.k(null,3,[$CLJS.bl,$CLJS.Ul,$CLJS.kK,ZR,cQ,new $CLJS.ti(null,new $CLJS.k(null,8,[$CLJS.$R,null,HV,null,RO,null,RR,null,SP,null,$CLJS.Mk,null,KO,null,$CLJS.LH,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,$CLJS.Ul,$CLJS.kK,UO,cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[wS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,QQ,cQ,new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.wV,null,$CLJS.$O,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,$CLJS.Ul,$CLJS.kK,UO,cQ,new $CLJS.ti(null,
new $CLJS.k(null,1,[sS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,$CLJS.Ul,$CLJS.kK,UO,cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[EP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,QQ,cQ,new $CLJS.ti(null,new $CLJS.k(null,5,[$CLJS.$R,null,QQ,null,$CLJS.wV,null,$CLJS.pQ,null,$CLJS.Mk,null],null),null)],null),new $CLJS.k(null,1,[cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[SP,null],null),null)],null),new $CLJS.k(null,1,[cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.Mk,null],null),null)],
null),new $CLJS.k(null,2,[$CLJS.bl,QQ,cQ,new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.wV,null,$CLJS.QP,null],null),null)],null),new $CLJS.k(null,1,[cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[KO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,QQ,cQ,new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.wV,null,$CLJS.mP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,QQ,cQ,new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.wV,null,$CLJS.PP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,KV,$CLJS.kK,ZR,
cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[MQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,$CLJS.qk,cQ,new $CLJS.ti(null,new $CLJS.k(null,3,[$CLJS.$R,null,$CLJS.Mk,null,$CLJS.qk,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.bl,$CLJS.Ul,cQ,new $CLJS.ti(null,new $CLJS.k(null,8,[$CLJS.$R,null,HV,null,RO,null,RR,null,SP,null,$CLJS.Mk,null,KO,null,$CLJS.LH,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.bl,KV,$CLJS.kK,UO,cQ,new $CLJS.ti(null,new $CLJS.k(null,1,[KP,null],null),null)],null)]);
qka=$CLJS.Q.h(bO,$CLJS.mi(jV));AS=$CLJS.Q.h(bO,$CLJS.pf($CLJS.YE,$CLJS.mi(jV)));kV=lO(wha,$CLJS.G(["tag-name",$CLJS.RN($CLJS.G([$CLJS.SN,new $CLJS.k(null,1,[$CLJS.Mk,$CLJS.SN],null)]))]));lV=lO($CLJS.yI,$CLJS.G(["target",$CLJS.RN($CLJS.G([$CLJS.VS,kV]))]));mV=lO(OP,$CLJS.G(["target",kV]));
rka=$CLJS.RN($CLJS.G([$CLJS.VS,nO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof lV?new $CLJS.ud(function(){return lV},$CLJS.K(Eia,new $CLJS.k(null,1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tR,"metabase/mbql/schema.cljc",21,$CLJS.yI,1,1557,1557,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yI,$CLJS.PL,$CLJS.Y($CLJS.Ll,$CLJS.Y($CLJS.PQ,rP,bQ))],null),$CLJS.yd,"Schema for a valid dimension clause.",$CLJS.m(lV)?lV.J:null])):null));return $CLJS.m(a)?a:tR}(),lV],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=yQ.g($CLJS.ge("undefined"!==typeof $CLJS.rO&&"undefined"!==typeof sO&&"undefined"!==typeof tO&&"undefined"!==typeof mV?new $CLJS.ud(function(){return mV},$CLJS.K(Via,new $CLJS.k(null,
1,[$CLJS.am,!0],null)),$CLJS.Yh([$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,yQ,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,bP,$CLJS.ek,$CLJS.W,$CLJS.lm],[$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LV,"metabase/mbql/schema.cljc",20,OP,1,1560,1560,new $CLJS.S(null,3,5,$CLJS.T,[OP,$CLJS.PL,$CLJS.Y($CLJS.Ll,bQ)],null),$CLJS.yd,"Schema for a valid variable clause.",$CLJS.m(mV)?mV.J:null])):null));return $CLJS.m(a)?a:LV}(),
mV],null)]))]));ska=$CLJS.Tf([$CLJS.bl,qka,$N($CLJS.Mk),$CLJS.SN,$N($CLJS.PL),rka,$N($CLJS.xl),$CLJS.JN,$N($CLJS.V),$CLJS.SN,$N(Dha),$CLJS.SN,$N($CLJS.Uj),$CLJS.JN,$CLJS.ON,$CLJS.JN]);tka=new $CLJS.S(null,1,5,$CLJS.T,[ska],null);uka=$CLJS.Tf([$N(Mja),$CLJS.SN,$CLJS.ON,$CLJS.JN]);vka=$CLJS.eN($CLJS.Tf([$N(CQ),$CLJS.WN,$N(XO),$CLJS.WN,$CLJS.ON,$CLJS.JN]),function(a){var b=$CLJS.Uf(a);a=$CLJS.M.h(b,CQ);b=$CLJS.M.h(b,XO);return $CLJS.Fb($CLJS.m(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
wka=$CLJS.Tf([$N(qja),$CLJS.LN,$N(yha),$CLJS.LN,$N(fha),$CLJS.LN,$N(Fha),$CLJS.LN,$N(ija),$CLJS.iO($CLJS.LN),$N(iha),$CLJS.iO($CLJS.LN),$N(xja),$CLJS.iO($CLJS.LN),$CLJS.ON,$CLJS.JN]);xka=bO.l($CLJS.G([Ria,IP,Fia,Wia,qia,tha,zha,Mha,bia,eja,Hka,fia,Yha]));
yka=$CLJS.Yh([$CLJS.ON,$N(Nja),$N(hia),$N($CLJS.JP),$N(nja),$N(oia),$N(gha),$N(Kja),$N($CLJS.dL),$N($CLJS.gP)],[$CLJS.JN,$CLJS.iO($CLJS.SN),$CLJS.iO($CLJS.VN),$CLJS.iO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Tf([$CLJS.JN,$CLJS.JN])],null)),$CLJS.iO($CLJS.VN),$CLJS.iO($CLJS.VN),$CLJS.iO($CLJS.JN),$CLJS.iO($CLJS.Tf([$CLJS.JN,$CLJS.JN])),$CLJS.iO($CLJS.VN),$CLJS.iO(xka)]);zka=$CLJS.RN($CLJS.G([$CLJS.ZM(-1337),$CLJS.VN]));
$CLJS.nV=$CLJS.eN($CLJS.eN($CLJS.eN($CLJS.Yh([$CLJS.ON,$N($CLJS.oR),$N($CLJS.HR),$N($CLJS.JE),$N(Dia),$N(sia),$CLJS.bl,$N($CLJS.QO),$CLJS.gR,$N($CLJS.iP)],[$CLJS.JN,cV,$CLJS.iO(uka),$CLJS.iO(yka),$CLJS.iO(vka),$CLJS.iO(wka),bO.l($CLJS.G([$CLJS.QO,$CLJS.oR])),$CLJS.dV,zka,tka]),$CLJS.Vm.h($CLJS.Wm.h($CLJS.oR,$CLJS.QO),$CLJS.fg($CLJS.Vm.h($CLJS.oR,$CLJS.QO))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.oR);var c=$CLJS.M.h(b,$CLJS.QO);
b=$CLJS.M.h(b,$CLJS.bl);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.fg($CLJS.iQ),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$CLJS.jO(a);return function(c){var d=b(c);if($CLJS.m(d))throw $CLJS.Ej($CLJS.cM("Value does not match schema: %s",$CLJS.G([$CLJS.gj.l($CLJS.G([d]))])),new $CLJS.k(null,4,[$CLJS.bl,$CLJS.Iga,$CLJS.km,a,$CLJS.xl,c,$CLJS.Gv,d],null));return c}})($CLJS.nV);