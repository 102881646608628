var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var $X,aY,bY,dY,eY,fY,gY;$X=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);aY=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
bY=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.cY=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);dY=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
eY=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);fY=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);gY=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.EV($CLJS.EJ,$CLJS.G([$CLJS.bv,$CLJS.HJ,$CLJS.ik,$CLJS.xW]));$CLJS.GL.o(null,$CLJS.cY,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.HL(a);return $CLJS.le(a)?(a=$CLJS.sA.h(a,new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.jJ,null,$CLJS.nK,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.tV($CLJS.cY,$CLJS.BM);
for(var iY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zK,$CLJS.gL],null)),jY=null,kY=0,lY=0;;)if(lY<kY){var mY=jY.$(null,lY);$CLJS.EV(mY,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null),$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.xW],null)]));$CLJS.tV(mY,$CLJS.cY);lY+=1}else{var nY=$CLJS.A(iY);if(nY){var oY=nY;if($CLJS.re(oY)){var pY=$CLJS.$c(oY),lma=$CLJS.ad(oY),mma=pY,nma=$CLJS.E(pY);iY=lma;jY=mma;kY=nma}else{var qY=$CLJS.C(oY);$CLJS.EV(qY,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.qq,$CLJS.rM],null),$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.xW],null)]));$CLJS.tV(qY,$CLJS.cY);iY=$CLJS.D(oY);jY=null;kY=0}lY=0}else break}
for(var rY=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.$I,$CLJS.tI,$CLJS.wK,$CLJS.SK,$CLJS.CK,$CLJS.ZI,$CLJS.CL],null)),sY=null,tY=0,uY=0;;)if(uY<tY){var oma=sY.$(null,uY);$CLJS.EV(oma,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)]));uY+=1}else{var vY=$CLJS.A(rY);if(vY){var wY=vY;if($CLJS.re(wY)){var xY=$CLJS.$c(wY),pma=$CLJS.ad(wY),qma=xY,rma=$CLJS.E(xY);rY=pma;sY=qma;tY=rma}else{var sma=$CLJS.C(wY);$CLJS.EV(sma,$CLJS.G([$CLJS.bv,
$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)]));rY=$CLJS.D(wY);sY=null;tY=0}uY=0}else break}$CLJS.EV($CLJS.DJ,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.tW],null)]));
for(var yY=$CLJS.A(new $CLJS.ti(null,new $CLJS.k(null,8,[$CLJS.CL,null,$CLJS.CK,null,$CLJS.SK,null,$CLJS.FK,null,$CLJS.ZI,null,$CLJS.tI,null,$CLJS.$I,null,$CLJS.wK,null],null),null)),zY=null,AY=0,BY=0;;)if(BY<AY){var tma=zY.$(null,BY);$CLJS.EV(tma,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)]));BY+=1}else{var CY=$CLJS.A(yY);if(CY){var DY=CY;if($CLJS.re(DY)){var FY=$CLJS.$c(DY),uma=$CLJS.ad(DY),vma=FY,wma=$CLJS.E(FY);
yY=uma;zY=vma;AY=wma}else{var xma=$CLJS.C(DY);$CLJS.EV(xma,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)]));yY=$CLJS.D(DY);zY=null;AY=0}BY=0}else break}$CLJS.pL(gY,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.im,$CLJS.RI,$CLJS.MJ,$CLJS.dK],null));
$CLJS.GV($CLJS.yK,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,gY],null)],null)],null)],null)]));
$CLJS.pL(fY,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,$CLJS.TL,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,2,[$CLJS.ql,"valid timezone ID",$CLJS.Iv,function(a){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.xl);return["invalid timezone ID: ",$CLJS.gj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Qe($CLJS.Me,$CLJS.bM.tz.names()))],null));
$CLJS.GV($CLJS.QI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,fY],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UE,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,fY],null)],null)],null)],null)]));
$CLJS.tV($CLJS.QI,$CLJS.cY);$CLJS.EV($CLJS.ZJ,$CLJS.G([$CLJS.bv,$CLJS.rJ]));$CLJS.pL(bY,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.ZL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.ag(function(b){return $CLJS.XH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jJ,$CLJS.nK],null))}],null)],null));
$CLJS.pL($X,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.XL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bH,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,bY],null)],null)],null)],null));
$CLJS.AV($CLJS.aJ,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.iu,new $CLJS.k(null,1,[$CLJS.ql,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.aJ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$X],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ku,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.iu,new $CLJS.k(null,1,[$CLJS.ql,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.VX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.XX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.QX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.Uj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.vW],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.iu,new $CLJS.k(null,1,[$CLJS.ql,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.WB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.ZX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.Uj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.qW],null)],null)],null)],null)],null)],null));
$CLJS.GL.o(null,$CLJS.aJ,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.WB)?$CLJS.F.h(a,$CLJS.Uj)?$CLJS.nK:$CLJS.m($CLJS.vV($CLJS.vW,a))?$CLJS.jJ:$CLJS.nK:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Ji($CLJS.KX,b))?$CLJS.jJ:$CLJS.m($CLJS.Ji($CLJS.LX,b))?$CLJS.jJ:null:null;if($CLJS.m(a))return a;b=$CLJS.HL(b);b=$CLJS.le(b)?$CLJS.uh.j($CLJS.ui,$CLJS.zg(function(c){return $CLJS.XH(c,$CLJS.Ql)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.pL(eY,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.WB],null),$CLJS.ik],null));
$CLJS.GV($CLJS.NJ,$CLJS.G([$CLJS.bv,$CLJS.nK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bD,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,eY],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.xW],null)],null)],null)],null)]));
$CLJS.EV($CLJS.IE,$CLJS.G([$CLJS.bv,$CLJS.ML,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.DM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.uW],null)]));$CLJS.pL(aY,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.im,$CLJS.BJ,$CLJS.mk,$CLJS.jn,$CLJS.sJ,$CLJS.EL,$CLJS.OL,$CLJS.Dl,$CLJS.sk,$CLJS.gk,$CLJS.Xk,$CLJS.dJ],null));$CLJS.pL(dY,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.im,$CLJS.RI,$CLJS.MJ,$CLJS.dK],null));
$CLJS.GV($CLJS.KK,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,aY],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,dY],null)],null)],
null)],null)]));