var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var ega,xN,zN,yN,AN,BN;
ega=function(a,b,c){if($CLJS.qe(a)){var d=$CLJS.C(a);d=d instanceof $CLJS.N?d.S:null;switch(d){case "schema.spec.collection/optional":return a=$CLJS.D(a),$CLJS.KM.j?$CLJS.KM.j(a,b,c):$CLJS.KM.call(null,a,b,c);case "schema.spec.collection/remaining":if(!$CLJS.F.h(2,$CLJS.E(a)))throw Error($CLJS.dM("remaining can have only one schema."));var e=$CLJS.wM($CLJS.$d(a),b);return function(g,l){$CLJS.jj.j(g,$CLJS.uh,$CLJS.xg.h(e,l));return c.h?c.h(g,null):c.call(null,g,null)};default:throw Error(["No matching clause: ",
$CLJS.p.g(d)].join(""));}}else{var f=$CLJS.vk.g(a);e=$CLJS.wM(a,b);return function(g,l){var n=function(){function q(u){return $CLJS.jj.j(g,$CLJS.mf,$CLJS.pM(u)?u:e.g?e.g(u):e.call(null,u))}return f.h?f.h(q,l):f.call(null,q,l)}();return c.h?c.h(g,n):c.call(null,g,n)}}};$CLJS.KM=function(a,b,c){if(!$CLJS.bg(function(d){return $CLJS.qe(d)&&$CLJS.F.h($CLJS.C(d),xN)},$CLJS.zi(a)))throw Error($CLJS.dM("Remaining schemas must be in tail position."));return $CLJS.Rb(function(d,e){return ega(e,b,d)},c,$CLJS.nf(a))};
$CLJS.MM=function(a,b,c,d,e,f,g){this.na=a;this.pc=b;this.elements=c;this.$b=d;this.D=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};$CLJS.NM=function(a,b,c,d){return new $CLJS.MM(a,b,c,d,null,null,null)};$CLJS.OM=function(a){return new $CLJS.S(null,2,5,$CLJS.T,[xN,new $CLJS.k(null,2,[$CLJS.km,a,$CLJS.vk,function(){throw Error("should never be not called");}],null)],null)};$CLJS.QM=function(a,b,c){b=new $CLJS.k(null,2,[$CLJS.km,b,$CLJS.vk,c],null);return $CLJS.m(a)?b:$CLJS.Sg($CLJS.pf(yN,$CLJS.G([b])))};
$CLJS.fga=function(a,b,c){return $CLJS.uh.h($CLJS.Sg($CLJS.pf(yN,$CLJS.G([new $CLJS.k(null,2,[$CLJS.km,a,$CLJS.vk,b],null)]))),c)};xN=new $CLJS.N("schema.spec.collection","remaining","schema.spec.collection/remaining",-421177821);zN=new $CLJS.N(null,"elements","elements",657646735);yN=new $CLJS.N("schema.spec.collection","optional","schema.spec.collection/optional",-854614927);AN=new $CLJS.N(null,"on-error","on-error",1728533530);BN=new $CLJS.N(null,"konstructor","konstructor",-1422324557);$CLJS.h=$CLJS.MM.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "pre":return this.na;case "konstructor":return this.pc;case "elements":return this.elements;case "on-error":return this.$b;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Nd=$CLJS.La(54);
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#schema.spec.collection.CollectionSpec{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qN,this.na],null),new $CLJS.S(null,2,5,$CLJS.T,[BN,this.pc],null),new $CLJS.S(null,2,5,$CLJS.T,[zN,this.elements],null),new $CLJS.S(null,2,5,$CLJS.T,[AN,this.$b],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qN,BN,zN,AN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1800698765^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.na,b.na)&&$CLJS.F.h(this.pc,b.pc)&&$CLJS.F.h(this.elements,b.elements)&&$CLJS.F.h(this.$b,b.$b)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,4,[$CLJS.qN,null,zN,null,BN,null,AN,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new $CLJS.MM(this.na,this.pc,this.elements,this.$b,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "pre":case "konstructor":case "elements":case "on-error":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.qN,b):$CLJS.O.call(null,$CLJS.qN,b))?new $CLJS.MM(c,this.pc,this.elements,this.$b,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(BN,b):$CLJS.O.call(null,BN,b))?new $CLJS.MM(this.na,c,this.elements,this.$b,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(zN,b):$CLJS.O.call(null,zN,b))?new $CLJS.MM(this.na,this.pc,c,this.$b,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(AN,b):$CLJS.O.call(null,AN,b))?new $CLJS.MM(this.na,this.pc,this.elements,
c,this.D,this.m,null):new $CLJS.MM(this.na,this.pc,this.elements,this.$b,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Ke($CLJS.qN,this.na),new $CLJS.Ke(BN,this.pc),new $CLJS.Ke(zN,this.elements),new $CLJS.Ke(AN,this.$b)],null),this.m))};$CLJS.h.N=function(a,b){return new $CLJS.MM(this.na,this.pc,this.elements,this.$b,b,this.m,this.G)};
$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};