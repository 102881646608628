var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./schema.core.js");
'use strict';var PN,QN;PN=function(a){if(null!=a&&null!=a.Md)a=a.Md(a);else{var b=PN[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=PN._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Ob("HasPrecondition.precondition",a);}return a};QN=function(a,b,c,d){this.Pc=a;this.D=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.RN=function(a){return new QN(a,null,null,null)};$CLJS.zM.prototype.Md=$CLJS.Ma(59,function(){return $CLJS.fg(this.na)});
$CLJS.EM.prototype.Md=$CLJS.Ma(58,function(){var a=this;return $CLJS.Vm.h($CLJS.fg(a.na),$CLJS.Q.h($CLJS.Wm,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.Uf(q);q=$CLJS.M.h(u,$CLJS.sN);u=$CLJS.M.h(u,$CLJS.km);q=$CLJS.m(q)?$CLJS.Vm.h(q,PN($CLJS.VM(u))):PN($CLJS.VM(u));l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):
$CLJS.If($CLJS.Kf(l),null)}l=$CLJS.C(e);f=$CLJS.Uf(l);l=$CLJS.M.h(f,$CLJS.sN);f=$CLJS.M.h(f,$CLJS.km);return $CLJS.pf($CLJS.m(l)?$CLJS.Vm.h(l,PN($CLJS.VM(f))):PN($CLJS.VM(f)),d($CLJS.Ad(e)))}return null}},null,null)}(a.options)}()))});$CLJS.MM.prototype.Md=$CLJS.Ma(57,function(){return $CLJS.fg(this.na)});$CLJS.h=QN.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schemas":return this.Pc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#schema.core.CondPre{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FN,this.Pc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.FN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-56613580^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Pc,b.Pc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.FN,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new QN(this.Pc,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schemas":return!0;default:return $CLJS.Je(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.FN,b):$CLJS.O.call(null,$CLJS.FN,b))?new QN(c,this.D,this.m,null):new QN(this.Pc,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Ke($CLJS.FN,this.Pc)],null),this.m))};$CLJS.h.N=function(a,b){return new QN(this.Pc,b,this.m,this.G)};
$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};
$CLJS.h.Ab=function(){var a=this;return $CLJS.FM($CLJS.UM,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n);q=new $CLJS.k(null,2,[$CLJS.sN,PN($CLJS.VM(q)),$CLJS.km,q],null);l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}l=$CLJS.C(e);return $CLJS.pf(new $CLJS.k(null,2,[$CLJS.sN,PN($CLJS.VM(l)),
$CLJS.km,l],null),d($CLJS.Ad(e)))}return null}},null,null)}(a.Pc)}(),function(b){return new $CLJS.jf(null,$CLJS.Pga,new $CLJS.jf(null,b,null,1,null),2,null)})};$CLJS.h.Ea=function(){return $CLJS.pf($CLJS.Hga,$CLJS.xg.h($CLJS.WM,this.Pc))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Ea(null))};$CLJS.SN=$CLJS.eN($CLJS.KN,$CLJS.fg($CLJS.bG),"Non-blank string");$CLJS.TN=$CLJS.aN(function(a){return $CLJS.XH(a,$CLJS.yl)},"Valid field type");$CLJS.UN=$CLJS.aN(function(a){return $CLJS.XH(a,$CLJS.il)||$CLJS.XH(a,$CLJS.yf)},"Valid semantic type");$CLJS.VN=$CLJS.eN($CLJS.NN,$CLJS.ef,"positive integer");$CLJS.WN=$CLJS.eN($CLJS.NN,$CLJS.fg($CLJS.gf),"integer \x3e\x3d 0");$CLJS.Sga=$CLJS.Tf([$CLJS.JN,$CLJS.JN]);$CLJS.Tga=$CLJS.RN($CLJS.G([$CLJS.ON,$CLJS.KN]));