var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.stage.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var hwa=function(a){return $CLJS.Vc($CLJS.Rb(function(b,c){return $CLJS.xh.j(b,c,$CLJS.M.j(b,c,0)+1)},$CLJS.Tc($CLJS.P),a))},I9=function(a,b){return new $CLJS.dj(function(){try{return $CLJS.F9.h(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.hG($CLJS.Gv);$CLJS.m($CLJS.mG("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.nG("metabase.lib.js.metadata",d,$CLJS.Y_("Error parsing %s objects: %s",$CLJS.G([a,$CLJS.YZ(c)])),c):$CLJS.nG("metabase.lib.js.metadata",d,$CLJS.Y_(c,$CLJS.G(["Error parsing %s objects: %s",
a,$CLJS.YZ(c)])),null));return null}throw e;}})},iwa=function(a,b){return function e(d){return new $CLJS.Cf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Ff(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.ce(g,x);$CLJS.J(z,0,null);var B=$CLJS.J(z,1,null),H=function(){var I=B;return null==I?null:$CLJS.r(I)}();$CLJS.m(function(){var I=H;return $CLJS.m(I)?$CLJS.F.h($CLJS.I0.g(H),b):I}())&&n.add(H);x+=1}else return!0}()?$CLJS.If($CLJS.Kf(n),
e($CLJS.ad(f))):$CLJS.If($CLJS.Kf(n),null)}var q=$CLJS.C(f);$CLJS.J(q,0,null);var u=$CLJS.J(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=v;return $CLJS.m(x)?$CLJS.F.h($CLJS.I0.g(v),b):x}()))return $CLJS.pf(v,e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.SO.g(a);return null==d?null:$CLJS.r(d)}())},jwa=function(a,b){return function e(d){return new $CLJS.Cf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=
$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Ff(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.ce(g,x);$CLJS.J(z,0,null);var B=$CLJS.J(z,1,null),H=function(){var I=B;return null==I?null:$CLJS.r(I)}();$CLJS.m(function(){var I=H;return $CLJS.m(I)?$CLJS.F.h($CLJS.I0.g(H),b):I}())&&n.add(H);x+=1}else return!0}()?$CLJS.If($CLJS.Kf(n),e($CLJS.ad(f))):$CLJS.If($CLJS.Kf(n),null)}var q=$CLJS.C(f);$CLJS.J(q,0,null);var u=$CLJS.J(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=
v;return $CLJS.m(x)?$CLJS.F.h($CLJS.I0.g(v),b):x}()))return $CLJS.pf(v,e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.W9.g(a);return null==d?null:$CLJS.r(d)}())},Z9=function(a,b,c,d){this.Ig=a;this.kj=b;this.dd=c;this.hk=d;this.C=393216;this.K=0},K9=function(a,b){if($CLJS.v0(b))a=b;else{var c=new $CLJS.k(null,6,[$CLJS.kwa,I9($CLJS.gR,b),$CLJS.H0,I9($CLJS.J0,b),$CLJS.SO,I9($CLJS.ZQ,b),$CLJS.Ava,I9($CLJS.kJ,b),$CLJS.W9,I9($CLJS.vQ,b),$CLJS.X9,I9($CLJS.JI,
b)],null),d=$CLJS.hG($CLJS.FE);$CLJS.m($CLJS.mG("metabase.lib.js.metadata",d))&&$CLJS.nG("metabase.lib.js.metadata",d,$CLJS.HC.l($CLJS.G(["Created metadata provider for metadata"])),null);a=new Z9(a,b,c,$CLJS.P)}return a},L9=function(a,b){return $CLJS.u_.j($CLJS.u_.j((0,$CLJS.N7)($CLJS.H9($CLJS.VF(a))),$CLJS.oR,function(c){return $CLJS.pn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.DR,$CLJS.P],null),c]))}),$CLJS.QO,function(c){var d=function(){var e=$CLJS.SO.g(c);return $CLJS.m(e)?e:function l(g){return new $CLJS.Cf(null,
function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Ff(u);a:for(var x=0;;)if(x<u){var z=$CLJS.ce(q,x);v.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,z,null],null));x+=1}else{q=!0;break a}return q?$CLJS.If($CLJS.Kf(v),l($CLJS.ad(n))):$CLJS.If($CLJS.Kf(v),null)}v=$CLJS.C(n);return $CLJS.pf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,v,null],null),l($CLJS.Ad(n)))}return null}},null,null)}(b)}();return $CLJS.U.j(c,$CLJS.SO,hwa(d))})};$CLJS.h=Z9.prototype;
$CLJS.h.N=function(a,b){return new Z9(this.Ig,this.kj,this.dd,b)};$CLJS.h.M=function(){return this.hk};$CLJS.h.Si=$CLJS.t;$CLJS.h.Yg=function(a,b){a=this.dd;a=null==a?null:$CLJS.W9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.Zg=function(a,b){return jwa(this.dd,b)};$CLJS.h.Fh=function(a,b){a=this.dd;a=null==a?null:$CLJS.H0.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};
$CLJS.h.Xg=function(a,b){return iwa(this.dd,b)};$CLJS.h.Wg=function(a,b){a=this.dd;a=null==a?null:$CLJS.SO.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.Ug=function(a,b){a=this.dd;a=null==a?null:$CLJS.Ava.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.$g=function(a,b){a=this.dd;a=null==a?null:$CLJS.X9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};
$CLJS.h.Vg=function(){var a=this.dd,b=this.Ig;a=null==a?null:$CLJS.kwa.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};var M9=function M9(a){switch(arguments.length){case 1:return M9.g(arguments[0]);case 2:return M9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.template_tags",M9);M9.g=function(a){return M9.h(a,$CLJS.P)};M9.h=function(a,b){return $CLJS.Nua.l($CLJS.G([$CLJS.Pua.l($CLJS.G([a,$CLJS.Mua.l($CLJS.G([b]))]))]))};M9.v=2;
var N9=function N9(a){return $CLJS.Bf(a)?[$CLJS.sf(a),"/",$CLJS.Ai(a)].join(""):$CLJS.oe(a)?$CLJS.sv(a,N9):$CLJS.ne(a)?$CLJS.xg.h(N9,a):a},O9=function O9(a){switch(arguments.length){case 1:return O9.g(arguments[0]);case 2:return O9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.order_by_clause",O9);O9.g=function(a){return O9.h(a,$CLJS.aK)};
O9.h=function(a,b){return $CLJS.Sua.l($CLJS.G([$CLJS.p9.l($CLJS.G([$CLJS.UF(a,$CLJS.G([$CLJS.lk,!0]))])),$CLJS.Ui.g(b)]))};O9.v=2;var P9=function P9(a){switch(arguments.length){case 2:return P9.h(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.available_binning_strategies",P9);P9.h=function(a,b){return $CLJS.Pe($CLJS.o9.l($CLJS.G([a,b])))};
P9.j=function(a,b,c){return $CLJS.Pe($CLJS.o9.l($CLJS.G([a,b,c])))};P9.v=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.h(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.available_temporal_buckets",Q9);Q9.h=function(a,b){return $CLJS.Pe($CLJS.q9.l($CLJS.G([a,b])))};Q9.j=function(a,b,c){return $CLJS.Pe($CLJS.q9.l($CLJS.G([a,b,c])))};
Q9.v=3;var R9=function R9(a){switch(arguments.length){case 2:return R9.h(arguments[0],arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.query_EQ_",R9);R9.h=function(a,b){return R9.j(a,b,null)};R9.j=function(a,b,c){a=L9(a,c);b=L9(b,c);return $CLJS.F.h(a,b)};R9.v=3;
var S9=function S9(a){switch(arguments.length){case 1:return S9.g(arguments[0]);case 2:return S9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.aggregation_clause",S9);S9.g=function(a){return $CLJS.n9.l($CLJS.G([a]))};S9.h=function(a,b){return $CLJS.n9.l($CLJS.G([a,b]))};S9.v=2;
var T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};$CLJS.Ra("metabase.lib.js.filter_clause",T9);T9.l=function(a,b,c){return $CLJS.Q.F($CLJS.uua,a,b,c)};T9.v=2;T9.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};
var U9=function U9(a){switch(arguments.length){case 2:return U9.h(arguments[0],arguments[1]);case 3:return U9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.expressionable_columns",U9);U9.h=function(a,b){return U9.h(a,b)};U9.j=function(a,b,c){return $CLJS.Pe($CLJS.mua.l($CLJS.G([a,b,c])))};U9.v=3;
module.exports={binning:function(a){return $CLJS.cua.l($CLJS.G([a]))},query:function(a,b,c){c=$CLJS.UF(c,$CLJS.G([$CLJS.lk,!0]));c=$CLJS.m($CLJS.bl.g(c))?c:$CLJS.U.j(c,$CLJS.bl,$CLJS.QO);c=(0,$CLJS.N7)(c);c=$CLJS.u3.g(c);var d=$CLJS.hG($CLJS.FE);$CLJS.m($CLJS.mG("metabase.lib.js",d))&&$CLJS.nG("metabase.lib.js",d,$CLJS.Y_("query map: %s",$CLJS.G([$CLJS.gj.l($CLJS.G([c]))])),null);return $CLJS.Vua.l($CLJS.G([K9(a,b),c]))},columns_group_columns:function(a){return $CLJS.Pe($CLJS.hua.l($CLJS.G([a])))},
join_fields:function(a){return $CLJS.Pe($CLJS.Cua.l($CLJS.G([a])))},aggregate:function(a,b,c){return $CLJS.Yta.l($CLJS.G([a,b,$CLJS.UF(c,$CLJS.G([$CLJS.lk,!0]))]))},join_conditions:function(a){return $CLJS.Pe($CLJS.Bua.l($CLJS.G([a])))},query_EQ_:R9,temporal_bucket:function(a){return $CLJS.dva.l($CLJS.G([a]))},remove_clause:function(a,b,c){return $CLJS.Xua.l($CLJS.G([a,b,$CLJS.p9.l($CLJS.G([$CLJS.UF(c,$CLJS.G([$CLJS.lk,!0]))]))]))},suggested_join_condition:function(a,b,c){return $CLJS.Fua.l($CLJS.G([a,
b,c]))},replace_clause:function(a,b,c,d){return $CLJS.Yua.l($CLJS.G([a,b,$CLJS.p9.l($CLJS.G([$CLJS.UF(c,$CLJS.G([$CLJS.lk,!0]))])),$CLJS.p9.l($CLJS.G([$CLJS.UF(d,$CLJS.G([$CLJS.lk,!0]))]))]))},drop_stage:function(a){return $CLJS.$ua.l($CLJS.G([a]))},available_temporal_buckets:Q9,with_binning:function(a,b){return $CLJS.dua.l($CLJS.G([a,b]))},append_stage:function(a){return $CLJS.Zua.l($CLJS.G([a]))},join:function(a,b,c){return $CLJS.wua.l($CLJS.G([a,b,c]))},aggregations:function(a,b){return $CLJS.Pe($CLJS.$ta.l($CLJS.G([a,
b])))},filterable_column_operators:function(a){return $CLJS.Pe($CLJS.tua.l($CLJS.G([a])))},with_temporal_bucket:function(a,b){return $CLJS.eva.l($CLJS.G([a,b]))},with_join_conditions:function(a,b){return $CLJS.Iua.l($CLJS.G([a,$CLJS.UF(b,$CLJS.G([$CLJS.lk,!0]))]))},available_metrics:function(a){return $CLJS.Pe($CLJS.Lua.l($CLJS.G([a])))},with_fields:function(a,b,c){return $CLJS.oua.l($CLJS.G([a,b,c]))},join_clause:function(a,b){return $CLJS.xua.l($CLJS.G([a,b]))},aggregation_clause:S9,recognize_template_tags:function(a){return $CLJS.sn($CLJS.Oua.l($CLJS.G([a])))},
external_op:function(a){a=$CLJS.jua.l($CLJS.G([a]));var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.kK);var c=$CLJS.M.h(b,$CLJS.Tk);b=$CLJS.M.h(b,$CLJS.pl);return{operator:a,options:$CLJS.sn(c),args:$CLJS.Pe(b)}},with_join_fields:function(a,b){return $CLJS.Gua.l($CLJS.G([a,b]))},available_binning_strategies:P9,expression:function(a,b,c,d){return $CLJS.kua.l($CLJS.G([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.Ui.g(a):a;b="string"===typeof b?$CLJS.Ui.g(b):b;return $CLJS.cva.l($CLJS.G([a,
b]))},order_bys:function(a,b){return $CLJS.Pe($CLJS.Tua.l($CLJS.G([a,b])))},display_info:function(a,b,c){return $CLJS.sn.l($CLJS.Zm.F($CLJS.lG($CLJS.Jua.l($CLJS.G([$CLJS.f4(a,b),b,c])),$CLJS.VG),$CLJS.J0,$CLJS.lG,$CLJS.VG),$CLJS.G([$CLJS.vm,$CLJS.oG]))},fieldable_columns:function(a,b){return $CLJS.Pe($CLJS.pua.l($CLJS.G([a,b])))},template_tags:M9,selected_aggregation_operators:function(a,b){return $CLJS.Pe($CLJS.bua.l($CLJS.G([$CLJS.A(a),b])))},order_by_clause:O9,filterable_columns:function(a,b){return $CLJS.Pe($CLJS.sua.l($CLJS.G([a,
b])))},join_condition_rhs_columns:function(a,b,c,d){return $CLJS.Pe($CLJS.Aua.l($CLJS.G([a,b,c,d])))},filters:function(a,b){return $CLJS.Pe($CLJS.rua.l($CLJS.G([a,b])))},join_condition_lhs_columns:function(a,b,c){return $CLJS.Pe($CLJS.yua.l($CLJS.G([a,b,c])))},orderable_columns:function(a,b){return $CLJS.Pe($CLJS.Uua.l($CLJS.G([a,b])))},suggestedName:function(a){return $CLJS.Kua.l($CLJS.G([a]))},metadataProvider:K9,filter_clause:T9,fields:function(a,b){return $CLJS.Pe($CLJS.nua.l($CLJS.G([a,b])))},
available_aggregation_operators:function(a,b){return $CLJS.Pe($CLJS.aua.l($CLJS.G([a,b])))},breakouts:function(a,b){return $CLJS.Pe($CLJS.gua.l($CLJS.G([a,b])))},group_columns:function(a){return $CLJS.Pe($CLJS.iua.l($CLJS.G([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.Ui.g(b):b;return $CLJS.ava.l($CLJS.G([a,b]))},joins:function(a,b){return $CLJS.Pe($CLJS.Eua.l($CLJS.G([a,b])))},available_join_strategies:function(a,b){return $CLJS.Pe($CLJS.xg.h($CLJS.oG,$CLJS.vua.l($CLJS.G([a,
b]))))},legacy_query:function(a){return $CLJS.sn.l(N9($CLJS.l3.g(a)),$CLJS.G([$CLJS.vm,$CLJS.oG]))},join_strategy:function(a){return $CLJS.oG($CLJS.Dua.l($CLJS.G([a])))},aggregation_operator_columns:function(a){return $CLJS.Pe($CLJS.Zta.l($CLJS.G([a])))},join_condition_operators:function(a,b,c,d){return $CLJS.Pe($CLJS.zua.l($CLJS.G([a,b,c,d])))},expressionable_columns:U9,filter:function(a,b,c){return $CLJS.qua.l($CLJS.G([a,b,$CLJS.UF(c,$CLJS.G([$CLJS.lk,!0]))]))},change_direction:function(a,b){return $CLJS.Qua.l($CLJS.G([a,
b]))},expressions:function(a,b){return $CLJS.Pe($CLJS.lua.l($CLJS.G([a,b])))},with_join_strategy:function(a,b){return $CLJS.Hua.l($CLJS.G([a,$CLJS.Ui.g(b)]))},order_by:function(a,b,c,d){return $CLJS.Rua.l($CLJS.G([a,b,c,$CLJS.Ui.g(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.Ui.g(a):a;b="string"===typeof b?$CLJS.Ui.g(b):b;return $CLJS.bva.l($CLJS.G([a,b]))},breakout:function(a,b,c){return $CLJS.eua.l($CLJS.G([a,b,$CLJS.Wua.l($CLJS.G([c]))]))},breakoutable_columns:function(a,
b){return $CLJS.Pe($CLJS.fua.l($CLJS.G([a,b])))}};