var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var X2,dpa,Y2,Z2,epa,fpa,$2,a3,gpa,hpa,ipa,tpa,jpa,vpa,kpa,lpa,mpa,h3,i3,j3,k3,m3,opa,n3,o3,ppa,b3,d3;X2=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};
dpa=function(){return function(){function a(g,l,n){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl.j?$CLJS.bl.j(g,l,n):$CLJS.bl.call(null,g,l,n),$CLJS.vn.j?$CLJS.vn.j(g,l,n):$CLJS.vn.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl.h?$CLJS.bl.h(g,l):$CLJS.bl.call(null,g,l),$CLJS.vn.h?$CLJS.vn.h(g,l):$CLJS.vn.call(null,g,l)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl.g?$CLJS.bl.g(g):$CLJS.bl.call(null,g),$CLJS.vn.g?$CLJS.vn.g(g):$CLJS.vn.call(null,
g)],null)}function d(){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl.A?$CLJS.bl.A():$CLJS.bl.call(null),$CLJS.vn.A?$CLJS.vn.A():$CLJS.vn.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.y(z,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Q.R($CLJS.bl,n,q,u,v),$CLJS.Q.R($CLJS.vn,n,q,u,v)],null)}g.v=3;g.B=function(n){var q=
$CLJS.C(n);n=$CLJS.D(n);var u=$CLJS.C(n);n=$CLJS.D(n);var v=$CLJS.C(n);n=$CLJS.Ad(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.y(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.v=
3;e.B=f.B;e.A=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};Y2=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Sg(a),b):null};Z2=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.qg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};epa=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.hQ,b),$CLJS.dv(2,2,c))};
fpa=function(){var a=$CLJS.Zp.h($CLJS.L_,null),b=$CLJS.$y(a,$CLJS.taa,function(c){return $CLJS.op(c,$CLJS.Rg)});return function(){function c(f,g,l){return(g=$CLJS.A(b.j?b.j(f,g,l):b.call(null,f,g,l)))?new $CLJS.k(null,3,[$CLJS.km,a,$CLJS.xl,f,$CLJS.Iu,g],null):null}function d(f){return e.j(f,$CLJS.Rg,$CLJS.Rg)}var e=null;e=function(f,g,l){switch(arguments.length){case 1:return d.call(this,f);case 3:return c.call(this,f,g,l)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;return e}()};
$2=function(a){return fpa()(a,$CLJS.Rg,$CLJS.Rg)};a3=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.bs($CLJS.km.g(a));if($CLJS.m(b)){var e=X2($CLJS.Iv.g(b),c);$CLJS.m(e)?(e=$CLJS.js(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:X2($CLJS.ql.g(b),c)}return null};
gpa=function(a,b){a=$CLJS.Uf(a);var c=$CLJS.M.h(a,$CLJS.km),d=$CLJS.M.h(a,$CLJS.bl);b=$CLJS.Uf(b);var e=$CLJS.M.j(b,$CLJS.Iu,$CLJS.Taa),f=$CLJS.M.j(b,$CLJS.Oaa,!0),g=$CLJS.M.h(b,$CLJS.Zv),l=$CLJS.M.j(b,$CLJS.Xv,$CLJS.Vv),n=a3(a,$CLJS.vs.g(c),g,b);if($CLJS.m(n))return n;n=a3(a,$CLJS.av.g(c),g,b);if($CLJS.m(n))return n;n=a3(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=a3(a,function(){var q=$CLJS.Hp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=a3(a,$CLJS.vs.g(c),
l,b);if($CLJS.m(n))return n;n=a3(a,$CLJS.av.g(c),l,b);if($CLJS.m(n))return n;d=a3(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=a3(a,function(){var q=$CLJS.Hp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?a3(a,e.g?e.g($CLJS.Tv):e.call(null,$CLJS.Tv),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?a3(a,e.g?e.g($CLJS.Tv):e.call(null,$CLJS.Tv),l,b):f};
hpa=function(a,b,c){a=$CLJS.T;var d=$CLJS.uh.h;var e=$CLJS.vn.g(b);var f=$CLJS.Uf(b);var g=$CLJS.M.h(f,$CLJS.km),l=$CLJS.Uf(c);f=$CLJS.M.h(l,$CLJS.Zv);l=$CLJS.M.j(l,$CLJS.Xv,$CLJS.Vv);g=$CLJS.vs.g(g);f=X2($CLJS.Wv.g(g),f);f=$CLJS.m(f)?f:X2($CLJS.Wv.g(g),l);d=d.call($CLJS.uh,e,f);return new $CLJS.S(null,2,5,a,[d,gpa(b,c)],null)};
ipa=function(a){var b=$CLJS.Uf(a),c=$CLJS.M.h(b,$CLJS.xl);a=$CLJS.M.h(b,$CLJS.Iu);var d=$CLJS.Uf(null),e=$CLJS.M.j(d,$CLJS.Paa,$CLJS.Ek),f=$CLJS.M.j(d,$CLJS.Raa,hpa);return $CLJS.m(a)?$CLJS.Rb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.Ek,n);l=e.g?e.g(l):e.call(null,l);return b3(g,c,q,l)},null,a):null};tpa=function(a){return $CLJS.wg.h($CLJS.w2(a),$CLJS.x2(a))};
$CLJS.c3=function(a,b){return $CLJS.xv($CLJS.f0(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xl,new $CLJS.k(null,2,[$CLJS.MH,$CLJS.p.g($CLJS.tL()),$CLJS.rk,$CLJS.HL(a)],null),a],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.xM],null),b)};$CLJS.e3=function(){return $CLJS.Um.h(d3,$CLJS.t_($CLJS.G([$CLJS.N_,$CLJS.cG,$CLJS.O_,function(a,b){return d3.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};
jpa=function(a,b,c){var d=new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.ns,null,$CLJS.An,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.tv(2,c):$CLJS.tv(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?Z2(2,c):Z2(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.x_.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.uh.j($CLJS.Rg,$CLJS.Xm.g($CLJS.Bb),l):$CLJS.oe(g)?$CLJS.kG($CLJS.Hb,l):l}):$CLJS.Mm.h(a,f)};
vpa=function(a){return $CLJS.Rb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return jpa(b,upa,d)},a,tpa(a))};
kpa=function(a){for(var b=0;;){var c=$CLJS.Ud($CLJS.IZ.g(a),b);a:{var d=c;for(var e=$CLJS.Rg;;){var f=$CLJS.C($CLJS.xg.h(dpa(),$CLJS.Ag($CLJS.Um.j(f3,$CLJS.C,$CLJS.vn),$CLJS.Iu.g($2(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);var l=jpa(d,f,g),n=$CLJS.hG($CLJS.HE);if($CLJS.m($CLJS.mG("metabase.lib.convert",n))){var q=$CLJS.QG.l($CLJS.G([$CLJS.CF,$CLJS.gj.l($CLJS.G([g]))])),u=$CLJS.QG,v=u.l,x=$CLJS.gj,z=x.l;var B=f;B=$CLJS.m(B)?B:ipa($2(d));$CLJS.nG("metabase.lib.convert",
n,$CLJS.Y_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([q,v.call(u,$CLJS.G([$CLJS.CF,z.call(x,$CLJS.G([B]))])),$CLJS.QG.l($CLJS.G([$CLJS.rF,$CLJS.Z_($CLJS.C($CLJS.kA(d,l)))]))])),null)}if($CLJS.F.h(l,d))break a;d=l;e=$CLJS.mf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[f,g],null))}else break a}}d=vpa(d);if($CLJS.F.h(c,d)){if($CLJS.F.h(b,$CLJS.E($CLJS.IZ.g(a))-1))return a;b+=1}else a=$CLJS.Zm.R(a,$CLJS.IZ,$CLJS.U,b,d)}};
lpa=function(a){var b=$CLJS.e3();return $CLJS.Ym.h(function(c){return $CLJS.F.h($CLJS.RP.g(c),"__join")?$CLJS.Zm.j(c,$CLJS.RP,b):c},a)};mpa=function(a){return"string"===typeof $CLJS.tQ.g(a)?$CLJS.Mm.h($CLJS.U.j(a,$CLJS.QZ,$CLJS.l0($CLJS.tQ.g(a))),$CLJS.tQ):a};h3=function(a,b){var c=$CLJS.M.j(a,b,M3);if($CLJS.F.h(c,M3))throw $CLJS.Ej(["Unable to find ",$CLJS.gj.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[wpa,a,$CLJS.Lv,b],null));return c};
i3=function(){return $CLJS.Xm.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);return $CLJS.Bf(b)&&$CLJS.F.h($CLJS.sf(b),"lib")})};j3=function(a){return $CLJS.uh.j($CLJS.P,i3(),a)};k3=function(a){return $CLJS.Vf($CLJS.uh.j($CLJS.P,$CLJS.Um.h(i3(),$CLJS.Xm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.rk)})),a))};
m3=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.uh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.xg.g($CLJS.l3),c);a=$CLJS.F.h(a,$CLJS.uJ)&&$CLJS.D(c)?$CLJS.mf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.Uj,$CLJS.he(d)],null)):d;b=$CLJS.Vf(k3(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JO,a,b],null):a};opa=function(a){return $CLJS.uh.j($CLJS.Rg,$CLJS.Um.h($CLJS.xg.g(function(b){return $CLJS.lG(b,$CLJS.UG)}),$CLJS.xg.g($CLJS.l3)),$CLJS.D0.g(a))};
n3=function(a){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.IZ);a=$CLJS.C($CLJS.Rb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.l3.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.GR,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.iQ,opa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.PZ.g(c)],null)},null,a));return $CLJS.m($CLJS.oR.g(a))?$CLJS.YN(a,new $CLJS.k(null,1,[$CLJS.oR,$CLJS.QO],null)):a};
o3=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.u_.j(a,b,$CLJS.Um.h($CLJS.l3,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.u_.j(d,b,function(e){return $CLJS.uh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Qj],null),$CLJS.xg.g($CLJS.l3),e)}):d;return $CLJS.YN(a,$CLJS.Tf([b,c]))};ppa=function(a){var b=$CLJS.QZ.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Mm.h(a,$CLJS.QZ),$CLJS.tQ,["card__",$CLJS.p.g(b)].join("")):a};
b3=function b3(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=Y2(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Rg:$CLJS.pe(b)?$CLJS.P:$CLJS.be(b);return $CLJS.m($CLJS.m(c)?$CLJS.Uv.g($CLJS.ge(f)):c)?a:$CLJS.m(c)?(b=Y2(f,c),d=b3.F?b3.F(b,l,g,d):b3.call(null,b,l,g,d),$CLJS.Ce(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.tg(c-$CLJS.E(f),null),b=$CLJS.wg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.uh.h($CLJS.be(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.mf.h(f,d):$CLJS.Q.h($CLJS.Y,$CLJS.U.j($CLJS.Sg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Qaa],null),b3.F?b3.F(f,b,c,d):b3.call(null,f,b,c,d)):$CLJS.m($CLJS.Uv.g($CLJS.ge(f)))?$CLJS.mf.h(f,d):$CLJS.qe($CLJS.Vf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.Uv,!0],null))};d3=function d3(a){switch(arguments.length){case 1:return d3.g(arguments[0]);case 2:return d3.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
d3.g=function(a){return d3.h(a,60)};d3.h=function(a,b){if((new TextEncoder).encode(a).length<=b)return a;var c;a:for(c=($CLJS.sW.str(a,0)>>>0).toString(16);;)if(8>$CLJS.E(c))c=["0",$CLJS.p.g(c)].join("");else break a;b=new Uint8Array(b-9);b=(new TextEncoder).encodeInto(a,b);return[a.substring(0,b.read),"_",$CLJS.p.g(c)].join("")};d3.v=2;
var M3=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),upa=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),N3=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),O3=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),wpa=new $CLJS.N(null,"m","m",1632677161),P3=new $CLJS.N("metabase.lib.convert","aggregation",
"metabase.lib.convert/aggregation",108290790);var s3,t3,f3,xpa,ypa,zpa,Apa,Bpa,Cpa,Dpa,Epa,Fpa,Gpa;s3=$CLJS.P;t3=$CLJS.P;f3=new $CLJS.ti(null,new $CLJS.k(null,7,[$CLJS.I_,null,$CLJS.SO,null,$CLJS.pP,null,$CLJS.jR,null,$CLJS.aQ,null,$CLJS.tS,null,$CLJS.hQ,null],null),null);xpa=$CLJS.ij.g($CLJS.P);ypa=$CLJS.ij.g($CLJS.P);zpa=$CLJS.ij.g($CLJS.P);Apa=$CLJS.ij.g($CLJS.P);Bpa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));
$CLJS.u3=new $CLJS.Aj($CLJS.$i.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.IH,$CLJS.Uj,Bpa,xpa,ypa,zpa,Apa);$CLJS.u3.o(null,$CLJS.Uj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.nW($CLJS.uh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.xg.g($CLJS.u3),d))}else b=a;return b});$CLJS.u3.o(null,$CLJS.H_,function(a){return a});
$CLJS.u3.o(null,$CLJS.UZ,function(a){var b=$CLJS.u3.g($CLJS.hQ.g(a)),c=$CLJS.Vf($CLJS.Ym.h(function(g){var l=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.c3($CLJS.u3.g(g),l)},$CLJS.jR.g(a))),d=t3;t3=$CLJS.uh.j($CLJS.P,$CLJS.Qo(function(g,l){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);l=$CLJS.Uf(l);l=$CLJS.M.h(l,$CLJS.MH);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)}),b);try{var e=epa(mpa(a),b,$CLJS.G([$CLJS.jR,c])),f=$CLJS.Rb(function(g,l){return $CLJS.Fb($CLJS.M.h(g,l))?g:$CLJS.Zm.j(g,l,$CLJS.u3)},
e,$CLJS.Nm.l(f3,$CLJS.hQ,$CLJS.G([$CLJS.jR])));return $CLJS.m($CLJS.pP.g(f))?$CLJS.Zm.j(f,$CLJS.pP,lpa):f}finally{t3=d}});$CLJS.u3.o(null,$CLJS.DZ,function(a){a=$CLJS.Zm.j($CLJS.Zm.j(a,$CLJS.LZ,$CLJS.u3),$CLJS.IZ,$CLJS.u3);var b=$CLJS.m($CLJS.SO.g(a))?$CLJS.Zm.j(a,$CLJS.SO,function(c){return $CLJS.xe(c)?$CLJS.Ym.h($CLJS.u3,c):$CLJS.Ui.g(c)}):a;return $CLJS.Fb($CLJS.RP.g(a))?$CLJS.U.j(b,$CLJS.RP,"__join"):b});$CLJS.u3.o(null,$CLJS.PG,function(a){return $CLJS.Ym.h($CLJS.u3,a)});
$CLJS.u3.o(null,$CLJS.YG,function(a){return $CLJS.m($CLJS.bl.g(a))?kpa($CLJS.Zm.j($CLJS.k0(a),$CLJS.IZ,function(b){return $CLJS.Ym.h($CLJS.u3,b)})):$CLJS.sv(a,$CLJS.u3)});$CLJS.u3.o(null,$CLJS.ZQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.nW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,a,b],null))});
$CLJS.u3.o(null,$CLJS.xl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.YN(a,new $CLJS.k(null,3,[$CLJS.PJ,$CLJS.bH,$CLJS.XP,$CLJS.Ol,$CLJS.CS,$CLJS.VL],null));var c=$CLJS.U.j;var d=$CLJS.rk.g(a);$CLJS.m(d)||(d=$CLJS.bH.g(a),d=$CLJS.m(d)?d:$CLJS.HL(b));a=c.call($CLJS.U,a,$CLJS.rk,d);return $CLJS.nW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xl,a,b],null))});
$CLJS.u3.o(null,$CLJS.uJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.Uj.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uJ,$CLJS.Mm.h(c,$CLJS.Uj),$CLJS.Ym.h($CLJS.u3,b)],null);b=$CLJS.nW(b);return null!=a?$CLJS.mf.h(b,$CLJS.u3.g(a)):b});$CLJS.u3.o(null,$CLJS.ZG,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.nW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.u3.o(null,$CLJS.hQ,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.nW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,h3(t3,c)],null))});$CLJS.u3.o(null,$CLJS.JO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.u3.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.pn.l($CLJS.G([c,a]))],null),d)});Cpa=$CLJS.ij.g($CLJS.P);Dpa=$CLJS.ij.g($CLJS.P);Epa=$CLJS.ij.g($CLJS.P);
Fpa=$CLJS.ij.g($CLJS.P);Gpa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));$CLJS.l3=new $CLJS.Aj($CLJS.$i.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.IH,$CLJS.Uj,Gpa,Cpa,Dpa,Epa,Fpa);
$CLJS.l3.o(null,$CLJS.Uj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.uh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.wg.h;d=$CLJS.xg.h($CLJS.l3,d);a=k3(a);a=c.call($CLJS.uh,b,e.call($CLJS.wg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.uh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.xg.h($CLJS.l3,$CLJS.pf(a,d)))}else a=$CLJS.oe(a)?$CLJS.sv(j3(a),$CLJS.l3):
a;return a});for(var Z3=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[P3,O3],null)),c4=null,d4=0,e4=0;;)if(e4<d4){var spa=c4.$(null,e4);$CLJS.tV(spa,N3);e4+=1}else{var h4=$CLJS.A(Z3);if(h4){var i4=h4;if($CLJS.re(i4)){var j4=$CLJS.$c(i4),Hpa=$CLJS.ad(i4),Ipa=j4,Jpa=$CLJS.E(j4);Z3=Hpa;c4=Ipa;d4=Jpa}else{var Kpa=$CLJS.C(i4);$CLJS.tV(Kpa,N3);Z3=$CLJS.D(i4);c4=null;d4=0}e4=0}else break}
for(var l4=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.VD,$CLJS.YJ,$CLJS.AK,$CLJS.hJ,$CLJS.em,$CLJS.QK,$CLJS.wk,$CLJS.IJ,$CLJS.tK,$CLJS.VK,$CLJS.OK,$CLJS.xL],null)),m4=null,n4=0,o4=0;;)if(o4<n4){var Lpa=m4.$(null,o4);$CLJS.tV(Lpa,P3);o4+=1}else{var p4=$CLJS.A(l4);if(p4){var q4=p4;if($CLJS.re(q4)){var r4=$CLJS.$c(q4),Mpa=$CLJS.ad(q4),Npa=r4,Opa=$CLJS.E(r4);l4=Mpa;m4=Npa;n4=Opa}else{var Qpa=$CLJS.C(q4);$CLJS.tV(Qpa,P3);l4=$CLJS.D(q4);m4=null;n4=0}o4=0}else break}
for(var s4=$CLJS.A($CLJS.Ng([$CLJS.tm,$CLJS.bv,$CLJS.um,$CLJS.zL,$CLJS.uJ,$CLJS.lK,$CLJS.PI,$CLJS.BL,$CLJS.uI,$CLJS.KJ,$CLJS.FI,$CLJS.bK,$CLJS.qK,$CLJS.II,$CLJS.EJ,$CLJS.NJ,$CLJS.IE,$CLJS.aJ,$CLJS.ZJ,$CLJS.QI,$CLJS.yK,$CLJS.$I,$CLJS.tI,$CLJS.wK,$CLJS.SK,$CLJS.CK,$CLJS.ZI,$CLJS.CL,$CLJS.zK,$CLJS.gL,$CLJS.KI,$CLJS.wI,$CLJS.IL,$CLJS.yX,$CLJS.jK,$CLJS.MD,$CLJS.XK,$CLJS.bL,$CLJS.NL,$CLJS.jv,$CLJS.Mv],!0)),t4=null,u4=0,v4=0;;)if(v4<u4){var Rpa=t4.$(null,v4);$CLJS.tV(Rpa,O3);v4+=1}else{var w4=$CLJS.A(s4);
if(w4){var x4=w4;if($CLJS.re(x4)){var y4=$CLJS.$c(x4),Spa=$CLJS.ad(x4),Tpa=y4,Upa=$CLJS.E(y4);s4=Spa;t4=Tpa;u4=Upa}else{var Vpa=$CLJS.C(x4);$CLJS.tV(Vpa,O3);s4=$CLJS.D(x4);t4=null;u4=0}v4=0}else break}$CLJS.l3.o(null,N3,function(a){return m3(a)});$CLJS.l3.o(null,$CLJS.YG,function(a){return $CLJS.uh.j($CLJS.P,$CLJS.Um.h(i3(),$CLJS.xg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.l3.g(b)],null)})),a)});
$CLJS.l3.o(null,$CLJS.hQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);return $CLJS.oe(b)?(a=k3(b),c=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hQ,h3(s3,c)],null),$CLJS.m(a)?$CLJS.mf.h(c,a):c):a});$CLJS.l3.o(null,$CLJS.PG,function(a){return $CLJS.Ym.h($CLJS.l3,a)});
$CLJS.l3.o(null,$CLJS.ZQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,$CLJS.l3.g(a),k3(b)],null)});
$CLJS.l3.o(null,$CLJS.xl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=k3($CLJS.YN(b,new $CLJS.k(null,3,[$CLJS.bH,$CLJS.PJ,$CLJS.Ol,$CLJS.XP,$CLJS.VL,$CLJS.CS],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xl,a,b],null)});
$CLJS.l3.o(null,$CLJS.DZ,function(a){var b=j3(a);a=0==$CLJS.RP.g(a).lastIndexOf("__join",0)?$CLJS.Mm.h(b,$CLJS.RP):b;return $CLJS.pn.l($CLJS.G([$CLJS.sv($CLJS.Mm.l(a,$CLJS.IZ,$CLJS.G([$CLJS.LZ])),$CLJS.l3),o3($CLJS.ri(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.LZ],null)),$CLJS.LZ,$CLJS.vS),n3(a)]))});
$CLJS.l3.o(null,$CLJS.UZ,function(a){var b=s3;s3=$CLJS.uh.j($CLJS.P,$CLJS.Qo(function(c,d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.Uf(d);d=$CLJS.M.h(d,$CLJS.MH);return new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}),$CLJS.hQ.g(a));try{return $CLJS.Rb(function(c,d){return $CLJS.u_.j(c,d,$CLJS.l3)},o3($CLJS.u_.j($CLJS.u_.j(ppa(j3(a)),$CLJS.hQ,function(c){return $CLJS.Ym.h(m3,c)}),$CLJS.jR,function(c){return $CLJS.uh.h($CLJS.P,function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);a:for(var u=0;;)if(u<n){var v=$CLJS.ce(l,u),x=$CLJS.l3.g(v);v=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.g0(v),$CLJS.F.h($CLJS.xl,$CLJS.C(x))?$CLJS.$d(x):x],null);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}q=$CLJS.C(g);l=$CLJS.l3.g(q);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.g0(q),$CLJS.F.h($CLJS.xl,$CLJS.C(l))?$CLJS.$d(l):l],null),f($CLJS.Ad(g)))}return null}},
null,null)}(c)}())}),$CLJS.I_,$CLJS.dP),$CLJS.Nm.l(f3,$CLJS.hQ,$CLJS.G([$CLJS.I_,$CLJS.jR])))}finally{s3=b}});$CLJS.l3.o(null,$CLJS.D_,function(a){return $CLJS.sv(j3(a),$CLJS.l3)});
$CLJS.l3.o(null,$CLJS.H_,function(a){var b=j3(a),c=$CLJS.iP.g(b),d=n3(b),e=$CLJS.F.h($CLJS.Zu.g($CLJS.ae($CLJS.IZ.g(a))),$CLJS.D_)?$CLJS.oR:$CLJS.QO;a=$CLJS.pn.l;b=$CLJS.sv($CLJS.Mm.l(b,$CLJS.IZ,$CLJS.G([$CLJS.iP])),$CLJS.l3);d=$CLJS.Tf([$CLJS.bl,e,e,d]);c=$CLJS.A(c)?$CLJS.U.j(d,$CLJS.iP,c):d;return a.call($CLJS.pn,$CLJS.G([b,c]))});