var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var JF=function(){},KF=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(b),$CLJS.p.g(" ")].join(""),a],null)},LF=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.mf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.IF,$CLJS.DF)),";background-color:",$CLJS.p.g($CLJS.M.h($CLJS.IF,c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],
null)},MF=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.mf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.IF,c))].join(""),$CLJS.G(["color:black"]))],null)},NF=function(a){return $CLJS.M.h($CLJS.zE,a).value},zea=function(a){var b=NF(a);return $CLJS.m(function(){var c=NF($CLJS.ME);return $CLJS.Qm.h?$CLJS.Qm.h(c,b):$CLJS.Qm.call(null,c,b)}())?$CLJS.rF:$CLJS.m(function(){var c=NF($CLJS.LE);return $CLJS.Qm.h?
$CLJS.Qm.h(c,b):$CLJS.Qm.call(null,c,b)}())?$CLJS.BF:$CLJS.m(function(){var c=NF($CLJS.JE);return $CLJS.Qm.h?$CLJS.Qm.h(c,b):$CLJS.Qm.call(null,c,b)}())?$CLJS.uF:$CLJS.m(function(){var c=NF($CLJS.EE);return $CLJS.Qm.h?$CLJS.Qm.h(c,b):$CLJS.Qm.call(null,c,b)}())?$CLJS.AF:$CLJS.m(function(){var c=NF($CLJS.KE);return $CLJS.Qm.h?$CLJS.Qm.h(c,b):$CLJS.Qm.call(null,c,b)}())?$CLJS.CF:$CLJS.m(function(){var c=NF($CLJS.GE);return $CLJS.Qm.h?$CLJS.Qm.h(c,b):$CLJS.Qm.call(null,c,b)}())?$CLJS.xF:$CLJS.m(function(){var c=
NF($CLJS.sE);return $CLJS.Qm.h?$CLJS.Qm.h(c,b):$CLJS.Qm.call(null,c,b)}())?$CLJS.yF:$CLJS.zF},Aea=function(a){function b(c,d){return d>=c}a=NF(a);if(b(NF($CLJS.ME),a))return"error";if(b(NF($CLJS.LE),a))return"warn";if(b(NF($CLJS.JE),a))return"info";b(NF($CLJS.EE),a);return"log"},OF=function(a){return function(b){var c=$CLJS.Uf(b),d=$CLJS.M.h(c,$CLJS.uE),e=$CLJS.M.h(c,$CLJS.Kv);b=$CLJS.M.h(c,$CLJS.NE);e=Aea(e);e=$CLJS.Ua(console,e);e=$CLJS.m(e)?e:console.log;$CLJS.Q.h(e,a.g?a.g(c):a.call(null,c));
return $CLJS.m(b)?(c=["[",$CLJS.p.g(d),"]"].join(""),d=$CLJS.p.g(b),b=b.stack,e.F?e.F(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},PF=function PF(a,b){for(;;){if($CLJS.F.h($CLJS.qF,b))return MF(a,", ",$CLJS.zF);if($CLJS.F.h($CLJS.sF,b))return KF(a);if(b instanceof $CLJS.N)return MF(a,b,$CLJS.uF);if(b instanceof $CLJS.w)return MF(a,b,$CLJS.AF);if("string"===typeof b)return MF(a,$CLJS.gj.l($CLJS.G([b])),$CLJS.tF);if($CLJS.Og(b)){var d=KF(function(){var f=a,g=$CLJS.tc(b);return PF.h?PF.h(f,g):PF.call(null,
f,g)}()),e=$CLJS.uc(b);return PF.h?PF.h(d,e):PF.call(null,d,e)}if(b instanceof $CLJS.k||b instanceof $CLJS.Xh)return d=a,d=MF(d,"{",$CLJS.EF),d=$CLJS.Rb(PF,d,$CLJS.vg($CLJS.qF,b)),MF(d,"}",$CLJS.EF);if($CLJS.oe(b))return d=a,d=MF(d,["#",$CLJS.p.g(function(){var f=$CLJS.Nb(b),g=f.name;return $CLJS.je(g)?$CLJS.gj.l($CLJS.G([f])):g}())," "].join(""),$CLJS.vF),d=MF(d,"{",$CLJS.EF),d=$CLJS.Rb(PF,d,$CLJS.vg($CLJS.qF,b)),MF(d,"}",$CLJS.EF);if($CLJS.le(b))return d=a,d=MF(d,"#{",$CLJS.EF),d=$CLJS.Rb(PF,d,
$CLJS.vg($CLJS.sF,b)),MF(d,"}",$CLJS.EF);if($CLJS.qe(b))return d=a,d=MF(d,"[",$CLJS.EF),d=$CLJS.Rb(PF,d,$CLJS.vg($CLJS.sF,b)),MF(d,"]",$CLJS.EF);if(b instanceof $CLJS.di)d=MF(a,"#queue ",$CLJS.vF),e=$CLJS.uh.h($CLJS.Rg,b),a=d,b=e;else{if($CLJS.we(b))return d=a,d=MF(d,"(",$CLJS.vF),d=$CLJS.Rb(PF,d,$CLJS.vg($CLJS.sF,b)),MF(d,")",$CLJS.vF);if(null!=b?b.K&16384||$CLJS.t===b.Aj||(b.K?0:$CLJS.Mb(JF,b)):$CLJS.Mb(JF,b))d=MF(a,"#atom ",$CLJS.vF),e=$CLJS.r(b),a=d,b=e;else if($CLJS.Bj(b))d=MF(a,"#uuid ",$CLJS.vF),
e=$CLJS.p.g(b),a=d,b=e;else if($CLJS.Ib(b))d=MF(a,"#js ",$CLJS.vF),e=$CLJS.Rb(function(f,g){return function(l,n){return $CLJS.U.j(l,$CLJS.Ui.g(n),$CLJS.Ua(g,n))}}(a,b),$CLJS.P,Object.keys(b)),a=d,b=e;else if($CLJS.Cb(b))d=MF(a,"#js ",$CLJS.vF),e=$CLJS.uh.h($CLJS.Rg,b),a=d,b=e;else return MF(a,$CLJS.gj.l($CLJS.G([b])),$CLJS.wF)}}};$CLJS.Bea=OF(function(a){a=$CLJS.Uf(a);$CLJS.M.h(a,$CLJS.Kv);var b=$CLJS.M.h(a,$CLJS.uE),c=$CLJS.M.h(a,$CLJS.Ek);$CLJS.M.h(a,$CLJS.NE);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),c],null)});
$CLJS.Cea=OF(function(a){var b=$CLJS.Uf(a),c=$CLJS.M.h(b,$CLJS.Kv);a=$CLJS.M.h(b,$CLJS.uE);var d=$CLJS.M.h(b,$CLJS.Ek);$CLJS.M.h(b,$CLJS.NE);b=zea(c);d=PF(KF(LF(LF(LF(new $CLJS.S(null,2,5,$CLJS.T,["",$CLJS.Rg],null),"[",b),a,b),"]",b)),d);a=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.pf(a,d)});
$CLJS.Dea=OF(function(a){a=$CLJS.Uf(a);$CLJS.M.h(a,$CLJS.Kv);var b=$CLJS.M.h(a,$CLJS.uE),c=$CLJS.M.h(a,$CLJS.Ek);$CLJS.M.h(a,$CLJS.NE);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),$CLJS.gj.l($CLJS.G([c]))],null)});