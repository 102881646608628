var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vL,gga,UL,$L;
$CLJS.tL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return $CLJS.hv([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join(""))};$CLJS.uL=function(a){if("string"===typeof a){var b=$CLJS.Ji(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.ro(a));};vL=function(a){return $CLJS.XH(a,$CLJS.il)||$CLJS.XH(a,$CLJS.yf)};
$CLJS.RL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-zero","metabase.lib.schema.common/int-greater-than-zero",1130338092);gga=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.SL=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.TL=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
UL=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.VL=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.WL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.XL=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.ZL=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$L=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.pL($CLJS.TL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.k(null,1,[$CLJS.wk,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,"non-blank string"],null),$CLJS.fg($CLJS.bG)],null)],null));$CLJS.pL($CLJS.WL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.wk,0],null)],null));$CLJS.pL($CLJS.RL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.wk,1],null)],null));
$CLJS.pL($L,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.k(null,2,[$CLJS.wk,36,$CLJS.em,36],null)],null));$CLJS.pL(UL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,2,[$CLJS.ql,"valid semantic type",$CLJS.Iv,function(a){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.xl);return["Not a valid semantic type: ",$CLJS.gj.l($CLJS.G([a]))].join("")}],null),vL],null));
$CLJS.pL($CLJS.ZL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,2,[$CLJS.ql,"valid base type",$CLJS.Iv,function(a){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.xl);return["Not a valid base type: ",$CLJS.gj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.XH(a,$CLJS.yl)&&!vL(a)}],null));
$CLJS.pL($CLJS.XL,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MH,$L],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bH,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.ZL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rk,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.ZL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ol,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,
UL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VL,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.TL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.TL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oJ,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.TL],null)],null)],null));
$CLJS.pL(gga,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.SL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kK,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,$CLJS.Ul,$CLJS.Kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Tk,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.XL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tl,$CLJS.Du],null)],null)],null));