var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var U$=function(a){return new $CLJS.Cf(null,function(){return $CLJS.pf(a.A?a.A():a.call(null),U$(a))},null,null)},V$=function(a,b){return new $CLJS.Cf(null,function(){var c=$CLJS.A(b);if(c){var d=$CLJS.C(c),e=a.g?a.g(d):a.call(null,d),f=$CLJS.pf(d,$CLJS.Bi(function(g){return $CLJS.F.h(e,a.g?a.g(g):a.call(null,g))},$CLJS.D(c)));return $CLJS.pf(f,V$(a,new $CLJS.Cf(null,function(){return $CLJS.qg($CLJS.E(f),c)},null,null)))}return null},null,null)},Sva=function(a,b){switch(a){case "minutes":return $CLJS.f_("Minute",
"Minutes",b);case "hours":return $CLJS.f_("Hour","Hours",b);case "days":return $CLJS.f_("Day","Days",b);case "weeks":return $CLJS.f_("Week","Weeks",b);case "months":return $CLJS.f_("Month","Months",b);case "quarters":return $CLJS.f_("Quarter","Quarters",b);case "years":return $CLJS.f_("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}},Tva=function(a,b,c){b=parseInt(b);c=Sva(c,b);a=new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.F.h(b,1)],null);if($CLJS.F.h(new $CLJS.S(null,
2,5,$CLJS.T,["past",!0],null),a))return $CLJS.cI("Previous {0}",$CLJS.G([c]));if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,["past",!1],null),a))return $CLJS.cI("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,["next",!0],null),a))return $CLJS.cI("Next {0}",$CLJS.G([c]));if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,["next",!1],null),a))return $CLJS.cI("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));},Vva=function(a){return $CLJS.vv(a,
Uva,function(b){return["\\",$CLJS.p.g(b)].join("")})},W$=function(a,b,c,d,e){this.tag=a;this.source=b;this.D=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264},Xva=function(a){var b=$CLJS.Yz(a,Wva),c=$CLJS.xg.h($CLJS.C,$CLJS.$H(X$,a)),d=function(){var e=$CLJS.E(b),f=$CLJS.E(c);return e>f?e:f}();a=$CLJS.wg.h(b,$CLJS.pg(d-$CLJS.E(b),U$($CLJS.gg(""))));d=$CLJS.wg.h(c,$CLJS.pg(d-$CLJS.E(c),U$($CLJS.gg(""))));a=$CLJS.ug.h(a,d);return $CLJS.xg.h(function(e){var f=$CLJS.Ji(X$,e);return $CLJS.m(f)?($CLJS.J(f,
0,null),f=$CLJS.J(f,1,null),new W$(f,e,null,null,null)):e},a)},Yva=function(a){return $CLJS.yg(function(b){return"string"===typeof $CLJS.C(b)?new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ov.g(b)],null):b},$CLJS.G([V$(function(b){return"string"===typeof b||$CLJS.Fb($CLJS.xl.g(b))},a)]))},Zva=function(a,b,c,d){return $CLJS.xg.h(function(e){if(e instanceof W$){var f=$CLJS.U.j;var g=$CLJS.Bl.g(e),l=$CLJS.M.h(a,g);g=$CLJS.xl.g(l);l=$CLJS.bl.g(l);if($CLJS.m(g))try{var n=Y$.j(l,g,b);var q=$CLJS.m(c)?Vva(n):n}catch(u){if(u instanceof
Error)q=Y$.j($CLJS.Uj,g,b);else throw u;}else q=null;e=f.call($CLJS.U,e,$CLJS.xl,q)}return e},d)},bwa=function(a){a=$CLJS.Ov.g($CLJS.xg.h(function(b){return b instanceof W$?b:$CLJS.vv(b,$va,"")},a));return $CLJS.vv(a,awa,$CLJS.$d)},cwa=function(a){return $CLJS.lG($CLJS.C(Z$(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.iP],null),new $CLJS.S(null,1,5,$CLJS.T,[a],null))),$CLJS.Ui)},Z$=function Z$(a,b){if($CLJS.Fb($CLJS.A(a)))a=(0,$CLJS.N7)(b);else{var d=$CLJS.M,e=d.h;var f=$CLJS.zi(a);b=$CLJS.Tf([$CLJS.ae(a),
b]);f=Z$.h?Z$.h(f,b):Z$.call(null,f,b);a=e.call(d,f,$CLJS.ae(a))}return a};var Y$=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.Ui.g(f)},$CLJS.Uj,e,a,b,c,d)}();Y$.o(null,$CLJS.pQ,function(a,b,c){return(0,$CLJS.bM)(b).locale(c).format("MMMM D, YYYY")});
Y$.o(null,$CLJS.mP,function(a,b,c){a=(0,$CLJS.bM)(b,"YYYY-MM").locale(c);return $CLJS.m(a.isValid())?a.format("MMMM, YYYY"):""});Y$.o(null,$CLJS.PP,function(a,b,c){a=(0,$CLJS.bM)(b,"[Q]Q-YYYY").locale(c);return $CLJS.m(a.isValid())?a.format("[Q]Q, YYYY"):""});Y$.o(null,$CLJS.$O,function(a,b,c){b=$CLJS.Yz(b,/~/);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.m($CLJS.m(a)?b:a)?[$CLJS.p.g(Y$.j($CLJS.pQ,a,c))," - ",$CLJS.p.g(Y$.j($CLJS.pQ,b,c))].join(""):""});
Y$.o(null,$CLJS.QP,function(a,b){function c(d,e){return $CLJS.D($CLJS.Ki(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.dI("Today");if(c(/^thisweek$/,b))return $CLJS.dI("This Week");if(c(/^thismonth$/,b))return $CLJS.dI("This Month");if(c(/^thisquarter$/,b))return $CLJS.dI("This Quarter");if(c(/^thisyear$/,b))return $CLJS.dI("This Year");if(c(/^past1days$/,b))return $CLJS.dI("Yesterday");if(c(/^next1days$/,b))return $CLJS.dI("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.Q.h(Tva,
a);throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));});Y$.o(null,$CLJS.wV,function(a,b,c){function d(e,f){return $CLJS.$d($CLJS.Ki(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.m(a))return Y$.j($CLJS.QP,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.m(a))return Y$.j($CLJS.pQ,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.m(a)?Y$.j($CLJS.$O,a,c):Y$.j($CLJS.QP,b,c)});
Y$.o(null,$CLJS.Uj,function(a,b){$CLJS.ne(b)?(a=$CLJS.E(b),b=$CLJS.m($CLJS.F.h?$CLJS.F.h(1,a):$CLJS.F.call(null,1,a))?$CLJS.p.g($CLJS.C(b)):$CLJS.m($CLJS.F.h?$CLJS.F.h(2,a):$CLJS.F.call(null,2,a))?$CLJS.cI("{0} and {1}",$CLJS.G([$CLJS.C(b),$CLJS.$d(b)])):$CLJS.cI("{0}, {1}, and {2}",$CLJS.G([$CLJS.Ov.h(", ",$CLJS.tv(2,b)),$CLJS.Ud(b,$CLJS.E(b)-2),$CLJS.ae(b)]))):b=$CLJS.p.g(b);return b});var Uva=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,X$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,Wva=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.h=W$.prototype;$CLJS.h.toString=function(){var a=$CLJS.xl.g(this);return $CLJS.m(a)?a:this.source};$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bl,this.tag],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UE,this.source],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bl,$CLJS.UE],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-884441273^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.tag,b.tag)&&$CLJS.F.h(this.source,b.source)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.UE,null,$CLJS.Bl,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new W$(this.tag,this.source,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "tag":case "source":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Bl,b):$CLJS.O.call(null,$CLJS.Bl,b))?new W$(c,this.source,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.UE,b):$CLJS.O.call(null,$CLJS.UE,b))?new W$(this.tag,c,this.D,this.m,null):new W$(this.tag,this.source,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Ke($CLJS.Bl,this.tag),new $CLJS.Ke($CLJS.UE,this.source)],null),this.m))};
$CLJS.h.N=function(a,b){return new W$(this.tag,this.source,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};var $va=/\[\[.+\]\]/,awa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.h(arguments[0],arguments[1]);case 4:return $$.F(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.Ra("metabase.shared.parameters.parameters.substitute_tags",$$);$$.h=function(a,b){return $$.F(a,b,"en",!0)};$$.F=function(a,b,c,d){return $CLJS.m(a)?(b=$CLJS.VF(b),b=$CLJS.sv(b,cwa),bwa(Yva(Zva(b,c,d,Xva(a))))):null};$$.v=4;module.exports={tag_names:function(a){a=$CLJS.yi($CLJS.xg.h($CLJS.$d,$CLJS.$H(X$,$CLJS.m(a)?a:"")));return $CLJS.sn(a)},substitute_tags:$$};