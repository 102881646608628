var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");
'use strict';var q2,s2,t2,B2,Loa,H2;q2=new $CLJS.N("metabase.lib.types.constants","primary-key","metabase.lib.types.constants/primary-key",1371730630);$CLJS.r2=new $CLJS.N(null,"exclude","exclude",-1230250334);s2=new $CLJS.N("metabase.lib.types.constants","unknown","metabase.lib.types.constants/unknown",-886227696);t2=new $CLJS.N("metabase.lib.types.constants","string-like","metabase.lib.types.constants/string-like",776315654);
$CLJS.u2=new $CLJS.N("metabase.lib.types.constants","string","metabase.lib.types.constants/string",-2076057579);$CLJS.y2=new $CLJS.N("metabase.lib.types.constants","string_like","metabase.lib.types.constants/string_like",-1907279613);$CLJS.z2=new $CLJS.N("metabase.lib.types.constants","number","metabase.lib.types.constants/number",1385378285);$CLJS.A2=new $CLJS.N("metabase.lib.types.constants","location","metabase.lib.types.constants/location",1597944369);
B2=new $CLJS.N("metabase.lib.types.constants","foreign-key","metabase.lib.types.constants/foreign-key",7703392);$CLJS.C2=new $CLJS.N("metabase.lib.types.constants","category","metabase.lib.types.constants/category",-644284871);$CLJS.D2=new $CLJS.N("metabase.lib.types.constants","foreign_key","metabase.lib.types.constants/foreign_key",-1061052182);$CLJS.E2=new $CLJS.N("metabase.lib.types.constants","boolean","metabase.lib.types.constants/boolean",-2002690319);
$CLJS.F2=new $CLJS.N(null,"include","include",153360230);$CLJS.G2=new $CLJS.N("metabase.lib.types.constants","primary_key","metabase.lib.types.constants/primary_key",1915474107);Loa=new $CLJS.N("metabase.lib.types.constants","dimension","metabase.lib.types.constants/dimension",728254877);H2=new $CLJS.N("metabase.lib.types.constants","entity","metabase.lib.types.constants/entity",-64775675);$CLJS.I2=new $CLJS.N("metabase.lib.types.constants","coordinate","metabase.lib.types.constants/coordinate",-565327840);
$CLJS.J2=new $CLJS.N("metabase.lib.types.constants","temporal","metabase.lib.types.constants/temporal",896988859);$CLJS.K2=new $CLJS.N("metabase.lib.types.constants","integer","metabase.lib.types.constants/integer",1592888555);var c2=$CLJS.Rb(function(a,b){var c=$CLJS.Ai(b);a[c]=b;return a},{},$CLJS.rn.g($CLJS.yg($CLJS.tn,$CLJS.G([new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yl,$CLJS.il,$CLJS.yf],null)]))));$CLJS.Ra("metabase.lib.types.constants.name__GT_type",c2);$CLJS.Ra("metabase.lib.types.constants.key_number",$CLJS.z2);$CLJS.Ra("metabase.lib.types.constants.key_string",$CLJS.u2);$CLJS.Ra("metabase.lib.types.constants.key_string_like",t2);$CLJS.Ra("metabase.lib.types.constants.key_boolean",$CLJS.E2);
$CLJS.Ra("metabase.lib.types.constants.key_temporal",$CLJS.J2);$CLJS.Ra("metabase.lib.types.constants.key_location",$CLJS.A2);$CLJS.Ra("metabase.lib.types.constants.key_coordinate",$CLJS.I2);$CLJS.Ra("metabase.lib.types.constants.key_foreign_KEY",B2);$CLJS.Ra("metabase.lib.types.constants.key_primary_KEY",q2);$CLJS.Ra("metabase.lib.types.constants.key_summable",$CLJS.hn);$CLJS.Ra("metabase.lib.types.constants.key_scope",$CLJS.Lk);$CLJS.Ra("metabase.lib.types.constants.key_category",$CLJS.C2);
$CLJS.Ra("metabase.lib.types.constants.key_unknown",s2);
$CLJS.d2=$CLJS.Yh([$CLJS.I2,$CLJS.y2,H2,$CLJS.D2,$CLJS.K2,$CLJS.Lk,$CLJS.z2,$CLJS.A2,$CLJS.E2,$CLJS.u2,$CLJS.hn,$CLJS.C2,$CLJS.G2,$CLJS.J2,Loa],[new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.sl],null)],null),new $CLJS.k(null,1,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.el],null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ze,$CLJS.zf,$CLJS.$k],null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ze],null)],null),new $CLJS.k(null,
1,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ol],null)],null),new $CLJS.k(null,2,[$CLJS.F2,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.z2,$CLJS.J2,$CLJS.C2,H2,$CLJS.u2],null),$CLJS.r2,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.A2],null)],null),new $CLJS.k(null,2,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Jl],null),$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Jl],null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.yk],null)],null),new $CLJS.k(null,1,[$CLJS.rk,new $CLJS.S(null,1,5,
$CLJS.T,[$CLJS.Gl],null)],null),new $CLJS.k(null,2,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.fn],null),$CLJS.Ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fn,$CLJS.Nl],null)],null),new $CLJS.k(null,2,[$CLJS.F2,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.z2],null),$CLJS.r2,new $CLJS.S(null,3,5,$CLJS.T,[H2,$CLJS.A2,$CLJS.J2],null)],null),new $CLJS.k(null,3,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Gl],null),$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Nl],null),$CLJS.F2,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.A2],
null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.zf],null)],null),new $CLJS.k(null,2,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ql],null),$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ql],null)],null),new $CLJS.k(null,1,[$CLJS.F2,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.J2,$CLJS.C2,H2],null)],null)]);
module.exports={key_scope:$CLJS.Lk,key_summable:$CLJS.hn,key_location:$CLJS.A2,key_coordinate:$CLJS.I2,key_boolean:$CLJS.E2,key_temporal:$CLJS.J2,key_category:$CLJS.C2,key_string:$CLJS.u2,key_foreign_KEY:B2,key_primary_KEY:q2,key_string_like:t2,key_unknown:s2,key_number:$CLJS.z2,name__GT_type:c2};