var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var L2,M2,O2,P2;$CLJS.o2=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.N?a.S:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.p2=function(a,b){a=$CLJS.o2(a);b=$CLJS.m(b)?b:$CLJS.zv;return 0===a?$CLJS.F.h(b,$CLJS.zv)?$CLJS.dI("Today"):$CLJS.cI("This {0}",$CLJS.G([$CLJS.H1.g(b)])):$CLJS.F.h(a,1)?$CLJS.F.h(b,$CLJS.zv)?$CLJS.dI("Tomorrow"):$CLJS.cI("Next {0}",$CLJS.G([$CLJS.H1.g(b)])):$CLJS.F.h(a,-1)?$CLJS.F.h(b,$CLJS.zv)?$CLJS.dI("Yesterday"):$CLJS.cI("Previous {0}",$CLJS.G([$CLJS.H1.g(b)])):0>a?$CLJS.cI("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.H1.h(Math.abs(a),b)])):0<a?$CLJS.cI("Next {0} {1}",$CLJS.G([a,$CLJS.H1.h(a,
b)])):null};L2=new $CLJS.N("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);M2=new $CLJS.N("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);$CLJS.N2=new $CLJS.N(null,"operators","operators",-2064102509);O2=new $CLJS.N("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);P2=new $CLJS.N("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var Q2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Qj,$CLJS.Hk],null)),R2=null,S2=0,T2=0;;)if(T2<S2){var Ooa=R2.$(null,T2);$CLJS.tV(Ooa,O2);T2+=1}else{var U2=$CLJS.A(Q2);if(U2){var V2=U2;if($CLJS.re(V2)){var W2=$CLJS.$c(V2),Poa=$CLJS.ad(V2),Qoa=W2,Roa=$CLJS.E(W2);Q2=Poa;R2=Qoa;S2=Roa}else{var Soa=$CLJS.C(V2);$CLJS.tV(Soa,O2);Q2=$CLJS.D(V2);R2=null;S2=0}T2=0}else break}
for(var g3=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.YK],null)),p3=null,q3=0,r3=0;;)if(r3<q3){var vqa=p3.$(null,r3);$CLJS.tV(vqa,M2);r3+=1}else{var v3=$CLJS.A(g3);if(v3){var w3=v3;if($CLJS.re(w3)){var x3=$CLJS.$c(w3),Toa=$CLJS.ad(w3),Uoa=x3,Voa=$CLJS.E(x3);g3=Toa;p3=Uoa;q3=Voa}else{var Woa=$CLJS.C(w3);$CLJS.tV(Woa,M2);g3=$CLJS.D(w3);p3=null;q3=0}r3=0}else break}
for(var y3=$CLJS.A(new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.pm,$CLJS.Bm,$CLJS.rm,$CLJS.Am,$CLJS.QJ,$CLJS.IK,$CLJS.fK,$CLJS.RJ],null)),z3=null,A3=0,B3=0;;)if(B3<A3){var Xoa=z3.$(null,B3);$CLJS.tV(Xoa,P2);B3+=1}else{var C3=$CLJS.A(y3);if(C3){var D3=C3;if($CLJS.re(D3)){var E3=$CLJS.$c(D3),Yoa=$CLJS.ad(D3),Zoa=E3,$oa=$CLJS.E(E3);y3=Yoa;z3=Zoa;A3=$oa}else{var apa=$CLJS.C(D3);$CLJS.tV(apa,P2);y3=$CLJS.D(D3);z3=null;A3=0}B3=0}else break}
for(var F3=$CLJS.A(new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.TI,$CLJS.JJ,$CLJS.fJ,$CLJS.vJ,$CLJS.Zk],null)),G3=null,H3=0,I3=0;;)if(I3<H3){var bpa=G3.$(null,I3);$CLJS.tV(bpa,L2);I3+=1}else{var J3=$CLJS.A(F3);if(J3){var K3=J3;if($CLJS.re(K3)){var L3=$CLJS.$c(K3),cpa=$CLJS.ad(K3),npa=L3,qpa=$CLJS.E(L3);F3=cpa;G3=npa;H3=qpa}else{var rpa=$CLJS.C(K3);$CLJS.tV(rpa,L2);F3=$CLJS.D(K3);G3=null;H3=0}I3=0}else break}
$CLJS.B1.o(null,$CLJS.I_,function(a,b){var c=$CLJS.Vf($CLJS.I_.g($CLJS.W0(a,b)));return $CLJS.m(c)?$CLJS.cI("Filtered by {0}",$CLJS.G([$CLJS.e2($CLJS.dI("and"),function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);a:for(var u=0;;)if(u<n){var v=$CLJS.ce(l,u);v=$CLJS.c1.F(a,b,v,$CLJS.w1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}q=$CLJS.C(g);
return $CLJS.pf($CLJS.c1.F(a,b,q,$CLJS.w1),f($CLJS.Ad(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.y1.o(null,O2,function(a,b,c,d){c=$CLJS.A(c);var e=$CLJS.C(c);c=$CLJS.D(c);$CLJS.C(c);var f=$CLJS.D(c);return $CLJS.e2(function(){var g=e instanceof $CLJS.N?e.S:null;switch(g){case "and":return $CLJS.dI("and");case "or":return $CLJS.dI("or");default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}(),function(){return function n(l){return new $CLJS.Cf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Ff(v);a:for(var z=0;;)if(z<
v){var B=$CLJS.ce(u,z);B=$CLJS.c1.F(a,b,B,d);x.add(B);z+=1}else{u=!0;break a}return u?$CLJS.If($CLJS.Kf(x),n($CLJS.ad(q))):$CLJS.If($CLJS.Kf(x),null)}x=$CLJS.C(q);return $CLJS.pf($CLJS.c1.F(a,b,x,d),n($CLJS.Ad(q)))}return null}},null,null)}(f)}())});
$CLJS.y1.o(null,M2,function(a,b,c,d){var e=$CLJS.A(c);c=$CLJS.C(e);e=$CLJS.D(e);$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.xg.h(function(g){return $CLJS.c1.F(a,b,g,d)},f);if($CLJS.F.h($CLJS.E(f),2))switch(f=$CLJS.J(e,0,null),e=$CLJS.J(e,1,null),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.cI("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.cI("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}else switch(f=$CLJS.C(e),e=$CLJS.e2($CLJS.dI("or"),
$CLJS.Ad(e)),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.cI("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.cI("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.y1.o(null,P2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);var f=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);f=$CLJS.c1.F(a,b,f,d);a=$CLJS.c1.F(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "\x3c":return $CLJS.cI("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.cI("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.cI("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.cI("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.cI("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.cI("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.cI("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.cI("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.y1.o(null,$CLJS.eJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.cI("{0} is between {1} and {2}",$CLJS.G([$CLJS.c1.F(a,b,e,d),$CLJS.c1.F(a,b,f,d),$CLJS.c1.F(a,b,c,d)]))});
$CLJS.y1.o(null,$CLJS.pJ,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.J(c,2,null),g=$CLJS.J(c,3,null),l=$CLJS.J(c,4,null),n=$CLJS.J(c,5,null),q=$CLJS.J(c,6,null);c=$CLJS.J(c,7,null);return $CLJS.c1.F(a,b,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Qj,e,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.eJ,e,f,q,l],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.eJ,e,g,n,c],null)],null),d)});
$CLJS.y1.o(null,L2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.c1.F(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "is-null":return $CLJS.cI("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.cI("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.cI("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.cI("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.cI("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.g(e)].join(""));}});$CLJS.y1.o(null,$CLJS.MK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.cI("{0} is within {1}",$CLJS.G([$CLJS.c1.F(a,b,e,d),$CLJS.p2(f,c).toLowerCase()]))});$CLJS.y1.o(null,$CLJS.EY,function(a,b,c){a=$CLJS.Uf(c);return $CLJS.M.h(a,$CLJS.oJ)});
$CLJS.E1.o(null,$CLJS.EY,function(a,b,c){b=$CLJS.Uf(c);a=$CLJS.M.h(b,$CLJS.iW);b=$CLJS.M.h(b,$CLJS.oJ);return new $CLJS.k(null,2,[$CLJS.$1,$CLJS.oG(a),$CLJS.oJ,b],null)});