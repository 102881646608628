var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var wA,yA,zA,AA,CA,GA,HA,IA,JA,KA,Aca,NA,Bca,vA,RA,SA,TA,UA,VA,WA,XA,bB,eB,gB,jB,Cca,Dca,oB,Eca,qB,rB,sB,yB,vB,HB,LB,RB,TB,YB,bC,gC,iC,jC,dC,lC,mC,oC,nC,pC,Ica,uC,DC,EC,xC,Kca,IC,LC,NC,OC,Mca,Nca,Oca,SC,WC,XC,YC,ZC,Pca,cD,fD,Qca,Rca,Sca,Tca,Uca,Vca,Wca,Xca,Yca,$ca,lD,Zca,mD,ada,nD,bda,cda,oD,dda,pD,eda,fda,gda,ida,jda,lda,mda,oda,qda,xD,AD,sda,rda,tda,cC,fC,HD,ID,JD,KD,ND,OD,wda,xda,yda,RD,zda,SD,QD,QA,FD,kB,aD,Dda,JB,Eda,Fda,YA,hB,CD,Gda,FB,qC,lB,Hda,Ida,CB,Jda,uD,uB,kC,xB,dD,Kda,iB,
Lda,kD,sD,LD,Mda,BC,UD,GC,EB,aC,PA,rD,Ada,IB,wB,$A,Nda,ZB,Oda,tC,FC,yD,Pda,zD,vda,Qda,Rda,Sda,VB,Tda,iD,UB,Uda,yC,sC,Vda,cB,Wda,Cda,BD,mB,WD,QC,XD,YD,zC,Xda,tB,Yda,Bda,Zda,XB,hD,$B,$da,RC,ED,aea,gD,bea,rC,fB,cea,GB,$D,PC,dea,ZA,qD,KB,uda,tD,dB,TC,vD,$C,UC,VC,jD,AC,aE,bE,eea,jea,uA,OA,kea,nB,lea;wA=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.xA=function(){};
yA=function(a){if(null!=a&&null!=a.Mc)a=a.Mc(a);else{var b=yA[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=yA._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Ob("IWriter.-flush",a);}return a};zA=function(){};AA=function(a,b){return a-b*$CLJS.cf(a,b)};$CLJS.BA=function(a){if(null==$CLJS.zb)throw Error("No *print-fn* fn set for evaluation environment");$CLJS.zb.call(null,a);return null};
CA=function(a,b,c){if(null!=a&&null!=a.o)a.o(a,b,c);else{var d=CA[$CLJS.Na(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=CA._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Ob("IMultiFn.-add-method",a);}};$CLJS.DA=function(a,b){var c=$CLJS.Tc($CLJS.P);a=$CLJS.A(a);for(b=$CLJS.A(b);;)if(a&&b)c=$CLJS.xh.j(c,$CLJS.C(a),$CLJS.C(b)),a=$CLJS.D(a),b=$CLJS.D(b);else return $CLJS.Vc(c)};
GA=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Q.h(EA,0<b.length?new $CLJS.y(b.slice(0),0,null):null);$CLJS.Qc($CLJS.FA,"\n")};HA=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
IA=function(a,b,c){var d=c;for(c=$CLJS.Rg;;){if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null);var e=$CLJS.C(d);d=$CLJS.D(d);e=$CLJS.Q.h(a,new $CLJS.S(null,2,5,$CLJS.T,[e,b],null));b=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);c=$CLJS.mf.h(c,b);b=e}};JA=function(a,b){var c=b;for(b=$CLJS.Rg;;){var d=$CLJS.Q.h(a,new $CLJS.S(null,1,5,$CLJS.T,[c],null));c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if($CLJS.Fb(c))return new $CLJS.S(null,2,5,$CLJS.T,[b,d],null);b=$CLJS.mf.h(b,c);c=d}};
KA=function(a){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uh.h($CLJS.P,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);var v=$CLJS.J(q,0,null);$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[u,v],null));n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=$CLJS.J(f,0,null);$CLJS.J(f,1,null);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[l,g],null),d($CLJS.Ad(e)))}return null}},null,null)}(a)}()),$CLJS.uh.h($CLJS.P,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,
2,5,$CLJS.T,[u,q],null));n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[l,f],null),d($CLJS.Ad(e)))}return null}},null,null)}(a)}())],null)};
Aca=function(a,b){return $CLJS.uh.h($CLJS.P,function(){return function e(d){return new $CLJS.Cf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Ff(l);a:for(var q=0;;)if(q<l){var u=$CLJS.ce(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);n.add(new $CLJS.S(null,2,5,$CLJS.T,[v,new $CLJS.S(null,2,5,$CLJS.T,[u,b],null)],null));q+=1}else{g=!0;break a}return g?$CLJS.If($CLJS.Kf(n),e($CLJS.ad(f))):$CLJS.If($CLJS.Kf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);
g=$CLJS.J(g,1,null);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[n,new $CLJS.S(null,2,5,$CLJS.T,[g,b],null)],null),e($CLJS.Ad(f)))}return null}},null,null)}(a)}())};$CLJS.LA=function(){var a=$CLJS.FA;if(null!=a&&null!=a.Vf)a.Vf(a);else{var b=$CLJS.LA[$CLJS.Na(null==a?null:a)];if(null!=b)b.g?b.g(a):b.call(null,a);else if(b=$CLJS.LA._,null!=b)b.g?b.g(a):b.call(null,a);else throw $CLJS.Ob("IPrettyFlush.-ppflush",a);}};$CLJS.MA=function(a,b){a=$CLJS.r($CLJS.r(a));return b.g?b.g(a):b.call(null,a)};
NA=function(a,b,c){$CLJS.jj.F($CLJS.r(a),$CLJS.U,b,c)};Bca=function(a,b){$CLJS.F.h(b,"\n")?(NA(a,$CLJS.tA,0),NA(a,$CLJS.cl,$CLJS.MA(a,$CLJS.cl)+1)):NA(a,$CLJS.tA,$CLJS.MA(a,$CLJS.tA)+1);return $CLJS.Qc($CLJS.MA(a,uA),b)};vA=function(a,b,c,d){this.Ka=a;this.mg=b;this.Bd=c;this.gi=d;this.C=1074167808;this.K=0};
RA=function(a,b,c,d,e,f,g,l,n,q,u,v,x){this.parent=a;this.Jb=b;this.Bb=c;this.yb=d;this.Db=e;this.Eb=f;this.prefix=g;this.Ib=l;this.Kb=n;this.Fb=q;this.D=u;this.m=v;this.G=x;this.C=2230716170;this.K=139264};SA=function(a,b){for(b=$CLJS.Nv.g(b);;){if(null==b)return!1;if(a===b)return!0;b=$CLJS.Nv.g(b)}};TA=function(a,b,c,d,e,f,g,l){this.ia=a;this.data=b;this.tc=c;this.ha=d;this.ga=e;this.D=f;this.m=g;this.G=l;this.C=2230716170;this.K=139264};
UA=function(a,b,c,d,e,f,g,l){this.ia=a;this.type=b;this.la=c;this.ha=d;this.ga=e;this.D=f;this.m=g;this.G=l;this.C=2230716170;this.K=139264};VA=function(a,b,c,d,e,f,g){this.ia=a;this.la=b;this.ha=c;this.ga=d;this.D=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};WA=function(a,b,c,d,e,f,g){this.ia=a;this.la=b;this.ha=c;this.ga=d;this.D=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};
XA=function(a,b,c,d,e,f,g,l,n){this.ia=a;this.la=b;this.hc=c;this.offset=d;this.ha=e;this.ga=f;this.D=g;this.m=l;this.G=n;this.C=2230716170;this.K=139264};
bB=function(a,b,c){b=$CLJS.A(b);for(var d=null,e=0,f=0;;)if(f<e){var g=d.$(null,f);if(!$CLJS.F.h(OA.g(g),PA)){var l=QA.g($CLJS.r($CLJS.r(a)));$CLJS.m(l)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),l)}aB.h(a,g);$CLJS.jj.F($CLJS.r(a),$CLJS.U,QA,QA.g(g));g=QA.g($CLJS.r($CLJS.r(a)));$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),g),$CLJS.jj.F($CLJS.r(a),$CLJS.U,QA,null));f+=1}else if(b=$CLJS.A(b))$CLJS.re(b)?(d=$CLJS.$c(b),b=$CLJS.ad(b),g=d,e=$CLJS.E(d),d=g):(g=$CLJS.C(b),$CLJS.F.h(OA.g(g),PA)||
(d=QA.g($CLJS.r($CLJS.r(a))),$CLJS.m(d)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),d)),aB.h(a,g),$CLJS.jj.F($CLJS.r(a),$CLJS.U,QA,QA.g(g)),g=QA.g($CLJS.r($CLJS.r(a))),$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),g),$CLJS.jj.F($CLJS.r(a),$CLJS.U,QA,null)),b=$CLJS.D(b),d=null,e=0),f=0;else break};eB=function(a,b){var c=$CLJS.MA(uA.g($CLJS.r($CLJS.r(a))),$CLJS.em),d;(d=null==c)||(a=$CLJS.MA(uA.g($CLJS.r($CLJS.r(a))),$CLJS.tA),b=(b=$CLJS.A(b))?YA.g($CLJS.ae(b))-ZA.g($CLJS.C(b)):0,d=a+b<c);return d};
gB=function(a,b,c){b=$CLJS.r($A.g(b));return $CLJS.m(b)?b:!eB(a,c)};jB=function(a,b,c){var d=cB.g($CLJS.r($CLJS.r(a))),e=$CLJS.MA(uA.g($CLJS.r($CLJS.r(a))),$CLJS.em);return $CLJS.m(d)?$CLJS.m(e)?(d=$CLJS.r(dB.g(b))>=e-d)?gB(a,b,c):d:e:d};Cca=function(a){var b=$CLJS.C(a),c=fB.g(b);b=$CLJS.A($CLJS.Bi(function(d){return!($CLJS.F.h(OA.g(d),PA)&&SA(fB.g(d),c))},$CLJS.D(a)));return new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.A($CLJS.qg($CLJS.E(b)+1,a))],null)};
Dca=function(a){var b=$CLJS.C(a),c=fB.g(b);return $CLJS.A($CLJS.Bi(function(d){var e=fB.g(d);return!($CLJS.F.h(OA.g(d),PA)&&($CLJS.F.h(e,c)||SA(e,c)))},$CLJS.D(a)))};
oB=function(a,b){$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.jj.F($CLJS.r(a),$CLJS.U,QA,null);b=fB.g(b);var c=hB.g(b);$CLJS.m(c)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),c);c=$CLJS.Q.h($CLJS.p,$CLJS.tg($CLJS.r(iB.g(b))-$CLJS.E(c)," "));$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),c);a:for($CLJS.mg(kB.g(b),!0),$CLJS.mg($A.g(b),!0),a=$CLJS.Nv.g(b);;)if($CLJS.m(a))$CLJS.mg($A.g(a),!0),$CLJS.mg(kB.g(a),!0),a=$CLJS.Nv.g(a);else break a;return null};
Eca=function(a){var b=$CLJS.A($CLJS.Bi(function(c){return!$CLJS.F.h(OA.g(c),PA)},a));return new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.A($CLJS.qg($CLJS.E(b),a))],null)};qB=function(a){for(var b=$CLJS.pv.g($CLJS.r($CLJS.r(a)));;){$CLJS.jj.F($CLJS.r(a),$CLJS.U,$CLJS.pv,$CLJS.uh.h($CLJS.Rg,b));if(eB(a,b))return null;var c=pB(a,b);if(b!==c)b=c;else return null}};
rB=function(a,b){$CLJS.jj.F($CLJS.r(a),$CLJS.U,$CLJS.pv,$CLJS.mf.h($CLJS.pv.g($CLJS.r($CLJS.r(a))),b));return eB(a,$CLJS.pv.g($CLJS.r($CLJS.r(a))))?null:qB(a)};sB=function(a){var b=QA.g($CLJS.r($CLJS.r(a)));return $CLJS.m(b)?($CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),b),$CLJS.jj.F($CLJS.r(a),$CLJS.U,QA,null)):null};
yB=function(a,b){var c=$CLJS.wv(b,"\n",-1);if($CLJS.F.h($CLJS.E(c),1))return b;b=hB.g($CLJS.C(lB.g($CLJS.r($CLJS.r(a)))));var d=$CLJS.C(c);if($CLJS.F.h(mB,$CLJS.jk.g($CLJS.r($CLJS.r(a))))){var e=nB.g($CLJS.r($CLJS.r(a))),f=e+$CLJS.E(d);$CLJS.jj.F($CLJS.r(a),$CLJS.U,nB,f);rB(a,new TA(tB,d,null,e,f,null,null,null));qB(a);d=$CLJS.pv.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(bB(a,d,!0),$CLJS.jj.F($CLJS.r(a),$CLJS.U,$CLJS.pv,$CLJS.Rg))}else sB(a),$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),d);$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),
"\n");d=$CLJS.A($CLJS.D($CLJS.zi(c)));e=null;for(var g=f=0;;)if(g<f){var l=e.$(null,g);$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),l);$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.m(b)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),b);g+=1}else if(d=$CLJS.A(d))e=d,$CLJS.re(e)?(d=$CLJS.$c(e),g=$CLJS.ad(e),e=d,f=$CLJS.E(d),d=g):(d=$CLJS.C(e),$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),d),$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),"\n"),$CLJS.m(b)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),b),d=$CLJS.D(e),e=null,f=0),g=0;else break;$CLJS.jj.F($CLJS.r(a),
$CLJS.U,mB,uB);return $CLJS.ae(c)};vB=function(a,b,c,d,e,f){this.Ka=a;this.mg=b;this.Pi=c;this.Ph=d;this.Bd=e;this.hi=f;this.C=1074167808;this.K=0};
$CLJS.DB=function(a){var b=$CLJS.AB,c=BB,d=new RA(null,null,$CLJS.ij.g(0),$CLJS.ij.g(0),$CLJS.ij.g(!1),$CLJS.ij.g(!1),null,null,null,null,null,null,null),e=$CLJS.ij.g,f=$CLJS.ij.g(new $CLJS.k(null,4,[$CLJS.em,b,$CLJS.tA,0,$CLJS.cl,0,uA,a],null));e=e.call($CLJS.ij,$CLJS.Yh([lB,cB,uda,wB,vda,$CLJS.jk,nB,QA,uA,Ada,$CLJS.pv],[d,c,d,!0,null,uB,0,null,new vA(a,b,f,$CLJS.P),1,$CLJS.Rg]));return new vB(a,b,c,d,e,$CLJS.P)};
HB=function(a,b){var c=$CLJS.FA;b=new RA(lB.g($CLJS.r($CLJS.r(c))),null,$CLJS.ij.g(0),$CLJS.ij.g(0),$CLJS.ij.g(!1),$CLJS.ij.g(!1),a,null,b,null,null,null,null);$CLJS.jj.F($CLJS.r(c),$CLJS.U,lB,b);if($CLJS.F.h($CLJS.jk.g($CLJS.r($CLJS.r(c))),uB)){sB(c);var d=xB.g($CLJS.r($CLJS.r(c)));$CLJS.m(d)&&(d.g?d.g($CLJS.zB):d.call(null,$CLJS.zB));$CLJS.m(a)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(c))),a);c=$CLJS.MA(uA.g($CLJS.r($CLJS.r(c))),$CLJS.tA);$CLJS.mg(b.Bb,c);$CLJS.mg(b.yb,c)}else d=nB.g($CLJS.r($CLJS.r(c))),
a=d+($CLJS.m(a)?$CLJS.E(a):0),$CLJS.jj.F($CLJS.r(c),$CLJS.U,nB,a),rB(c,new VA(CB,b,d,a,null,null,null))};
LB=function(){var a=$CLJS.FA,b=lB.g($CLJS.r($CLJS.r(a))),c=EB.g(b);if($CLJS.F.h($CLJS.jk.g($CLJS.r($CLJS.r(a))),uB)){sB(a);$CLJS.m(c)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),c);var d=xB.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(d.g?d.g(FB):d.call(null,FB))}else d=nB.g($CLJS.r($CLJS.r(a))),c=d+($CLJS.m(c)?$CLJS.E(c):0),$CLJS.jj.F($CLJS.r(a),$CLJS.U,nB,c),rB(a,new WA(GB,b,d,c,null,null,null));$CLJS.jj.F($CLJS.r(a),$CLJS.U,lB,$CLJS.Nv.g(b))};
$CLJS.MB=function(a){var b=null!=a?a.C&32768||$CLJS.t===a.Lf?!0:a.C?!1:$CLJS.Mb($CLJS.xA,a):$CLJS.Mb($CLJS.xA,a);return b?wB.g($CLJS.r($CLJS.r(a))):b};$CLJS.QB=function(a){var b=NB;$CLJS.m(b)&&(b=$CLJS.xb,b=$CLJS.m(b)?NB>=$CLJS.xb:b);$CLJS.Fb($CLJS.OB)?EA.call(null,a):$CLJS.m(b)?$CLJS.Qc($CLJS.FA,"..."):($CLJS.m(NB)&&(NB+=1),PB.call(null,a));return b};RB=function(a,b){if($CLJS.Fb(b.g?b.g(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.g(a),". It must be one of ",$CLJS.p.g(b)].join(""));};
TB=function(){var a=$CLJS.Mi;return $CLJS.m(a)?SB>=$CLJS.Mi:a};YB=function(a){RB(a,new $CLJS.ti(null,new $CLJS.k(null,4,[IB,null,JB,null,KB,null,UB,null],null),null));var b=$CLJS.FA;$CLJS.jj.F($CLJS.r(b),$CLJS.U,$CLJS.jk,mB);var c=nB.g($CLJS.r($CLJS.r(b))),d=lB.g($CLJS.r($CLJS.r(b)));rB(b,new UA(PA,a,d,c,c,null,null,null))};
bC=function(a,b){RB(a,new $CLJS.ti(null,new $CLJS.k(null,2,[VB,null,$CLJS.WB,null],null),null));var c=$CLJS.FA,d=lB.g($CLJS.r($CLJS.r(c)));if($CLJS.F.h($CLJS.jk.g($CLJS.r($CLJS.r(c))),uB)){sB(c);var e=iB.g(d);if($CLJS.m($CLJS.F.h?$CLJS.F.h(VB,a):$CLJS.F.call(null,VB,a)))a=$CLJS.r(dB.g(d));else if($CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.WB,a):$CLJS.F.call(null,$CLJS.WB,a)))a=$CLJS.MA(uA.g($CLJS.r($CLJS.r(c))),$CLJS.tA);else throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));$CLJS.mg(e,b+a)}else e=
nB.g($CLJS.r($CLJS.r(c))),rB(c,new XA(XB,d,a,b,e,e,null,null,null))};gC=function(a,b,c){b="string"===typeof b?cC(b):b;c=dC(c);a:{var d=new $CLJS.qb,e=$CLJS.Fb(a)||!0===a?new $CLJS.gd(d):a,f=eC(b)&&$CLJS.Fb($CLJS.MB(e))?$CLJS.m($CLJS.MB(e))?e:$CLJS.DB(e):e,g=$CLJS.FA;$CLJS.FA=f;try{try{fC(b,c)}finally{e!==f&&yA(f)}var l=$CLJS.Fb(a)?$CLJS.p.g(d):!0===a?$CLJS.BA($CLJS.p.g(d)):null;break a}finally{$CLJS.FA=g}l=void 0}return l};
iC=function(a,b){a=[$CLJS.p.g(a),"\n",$CLJS.p.g(hC),"\n",$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(b," "))),"^\n"].join("");throw Error(a);};jC=function(a,b,c,d,e,f){this.Qc=a;this.ub=b;this.rc=c;this.D=d;this.m=e;this.G=f;this.C=2230716170;this.K=139264};dC=function(a){a=$CLJS.A(a);return new jC(a,a,0,null,null,null)};
lC=function(a){var b=$CLJS.Dk.g(a);if($CLJS.m(b))return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(b),new jC(ZB.g(a),$CLJS.D(b),nB.g(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};mC=function(a){var b=lC(a);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);a="string"===typeof a?cC(a):a;return new $CLJS.S(null,2,5,$CLJS.T,[a,b],null)};oC=function(a,b){return b>=nB.g(a)?(b=nB.g(a)-b,nC.h?nC.h(a,b):nC.call(null,a,b)):new jC(ZB.g(a),$CLJS.qg(b,ZB.g(a)),b,null,null,null)};
nC=function(a,b){var c=nB.g(a)+b;return 0>b?oC(a,c):new jC(ZB.g(a),$CLJS.qg(b,$CLJS.Dk.g(a)),c,null,null,null)};pC=function(a,b,c,d,e,f,g){this.xc=a;this.vc=b;this.Ec=c;this.offset=d;this.D=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};
Ica=function(a,b){var c=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);d=$CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,2,[$B,null,aC,null],null),null),c)?new $CLJS.S(null,2,5,$CLJS.T,[d,b],null):$CLJS.F.h(d,kC)?lC(b):$CLJS.F.h(d,qC)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.E($CLJS.Dk.g(b)),b],null):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[c,new $CLJS.S(null,2,5,$CLJS.T,
[b,a],null)],null),d],null)};uC=function(a,b){b=IA(Ica,b,a);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uh.h($CLJS.P,a),b],null)};
DC=function(a,b,c){c=lC(c);var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.Ae(d))if($CLJS.F.h(vC,10))var e=[$CLJS.p.g(d),$CLJS.m(wC)?".":null].join("");else{e=$CLJS.p;var f=e.g;if($CLJS.m(wC)){var g=$CLJS.M.h(Jca,vC);g=$CLJS.m(g)?g:["#",$CLJS.p.g(vC),"r"].join("")}else g=null;e=[f.call(e,g),$CLJS.p.g(xC(vC,d))].join("")}else e=null;a=$CLJS.m(e)?e:a.g?a.g(d):a.call(null,d);d=a.length;e=d+rC.g(b);e=e>=sC.g(b)?e:e+($CLJS.cf(sC.g(b)-e-1,tC.g(b))+1)*tC.g(b);d=$CLJS.Q.h($CLJS.p,$CLJS.tg(e-d,yC.g(b)));
$CLJS.m(aC.g(b))?CC.l($CLJS.G([[$CLJS.p.g(d),$CLJS.p.g(a)].join("")])):CC.l($CLJS.G([[$CLJS.p.g(a),$CLJS.p.g(d)].join("")]));return c};EC=function(a,b){return $CLJS.nf($CLJS.C(JA(function(c){return 0<c?new $CLJS.S(null,2,5,$CLJS.T,[AA(c,a),$CLJS.cf(c,a)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,null],null)},b)))};xC=function(a,b){return 0===b?"0":$CLJS.Q.h($CLJS.p,$CLJS.xg.h(function(c){return 10>c?$CLJS.mv(HA("0")+c):$CLJS.mv(HA("a")+(c-10))},EC(a,b)))};
Kca=function(a,b){return $CLJS.nf($CLJS.C(JA(function(c){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.A($CLJS.nf($CLJS.pg(a,c))),$CLJS.A($CLJS.qg(a,c))],null)},$CLJS.nf(b))))};
IC=function(a,b,c){c=lC(c);var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.Ae(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.F.h(d,Math.floor(d))){var e=0>d,f=xC(a,e?-d:d);a=$CLJS.m($B.g(b))?function(){var g=$CLJS.xg.h(function(n){return $CLJS.Q.h($CLJS.p,n)},Kca(zC.g(b),f)),l=$CLJS.tg($CLJS.E(g),AC.g(b));return $CLJS.Q.h($CLJS.p,$CLJS.D($CLJS.ug.h(l,g)))}():f;a=e?["-",$CLJS.p.g(a)].join(""):$CLJS.m(aC.g(b))?["+",$CLJS.p.g(a)].join(""):a;a=a.length<sC.g(b)?
[$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(sC.g(b)-a.length,yC.g(b)))),$CLJS.p.g(a)].join(""):a;CC.l($CLJS.G([a]))}else DC($CLJS.HC,new $CLJS.k(null,5,[sC,sC.g(b),tC,1,rC,0,yC,yC.g(b),aC,!0],null),dC(new $CLJS.S(null,1,5,$CLJS.T,[d],null)));return c};
LC=function(a){var b=$CLJS.cf(a,100);a=AA(a,100);var c=0<b?[$CLJS.p.g($CLJS.Ud(JC,b))," hundred"].join(""):null,d=$CLJS.p,e=d.g;if(0<a)if(20>a)var f=$CLJS.Ud(JC,a);else{f=$CLJS.cf(a,10);var g=AA(a,10);f=[$CLJS.p.g(0<f?$CLJS.Ud(KC,f):null),0<f&&0<g?"-":null,$CLJS.p.g(0<g?$CLJS.Ud(JC,g):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
NC=function(a,b){var c=$CLJS.E(a),d=$CLJS.Rg;--c;var e=$CLJS.C(a);for(a=$CLJS.D(a);;){if(null==a)return[$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.vg(", ",d))),$CLJS.je(e)||$CLJS.je(d)?null:", ",$CLJS.p.g(e),!$CLJS.je(e)&&0<c+b?[" ",$CLJS.p.g($CLJS.Ud(MC,c+b))].join(""):null].join("");d=$CLJS.je(e)?d:$CLJS.mf.h(d,[$CLJS.p.g(e)," ",$CLJS.p.g($CLJS.Ud(MC,c+b))].join(""));--c;e=$CLJS.C(a);a=$CLJS.D(a)}};
OC=function(a,b){b=lC(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=EC(10,c);c=$CLJS.Rg;for(var e=$CLJS.E(d)-1;;)if($CLJS.je(d)){CC.l($CLJS.G([$CLJS.Q.h($CLJS.p,c)]));break}else{var f=$CLJS.C(d);c=$CLJS.F.h(0,f)?c:$CLJS.mf.h(c,$CLJS.Ud($CLJS.Ud(a,e),f-1));--e;d=$CLJS.D(d)}}else IC(10,new $CLJS.k(null,5,[sC,0,yC," ",AC,",",zC,3,$B,!0],null),dC(new $CLJS.S(null,1,5,$CLJS.T,[c],null)));return b};
Mca=function(a,b){a=lC(b);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);var c=HA(b);b=c&127;c&=128;var d=$CLJS.M.h(Lca,b);0<c&&CC.l($CLJS.G(["Meta-"]));CC.l($CLJS.G([$CLJS.m(d)?d:32>b?["Control-",$CLJS.p.g($CLJS.mv(b+64))].join(""):$CLJS.F.h(b,127)?"Control-?":$CLJS.mv(b)]));return a};
Nca=function(a,b){var c=lC(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);a=BC.g(a);if($CLJS.m($CLJS.F.h?$CLJS.F.h("o",a):$CLJS.F.call(null,"o",a)))gC(!0,"\\o~3,'0o",$CLJS.G([HA(b)]));else if($CLJS.m($CLJS.F.h?$CLJS.F.h("u",a):$CLJS.F.call(null,"u",a)))gC(!0,"\\u~4,'0x",$CLJS.G([HA(b)]));else if($CLJS.m($CLJS.F.h?$CLJS.F.h(null,a):$CLJS.F.call(null,null,a)))$CLJS.Qc($CLJS.FA,$CLJS.m($CLJS.F.h?$CLJS.F.h("\b",b):$CLJS.F.call(null,"\b",b))?"\\backspace":$CLJS.m($CLJS.F.h?$CLJS.F.h(" ",b):$CLJS.F.call(null,
" ",b))?"\\space":$CLJS.m($CLJS.F.h?$CLJS.F.h("\t",b):$CLJS.F.call(null,"\t",b))?"\\tab":$CLJS.m($CLJS.F.h?$CLJS.F.h("\n",b):$CLJS.F.call(null,"\n",b))?"\\newline":$CLJS.m($CLJS.F.h?$CLJS.F.h("\f",b):$CLJS.F.call(null,"\f",b))?"\\formfeed":$CLJS.m($CLJS.F.h?$CLJS.F.h("\r",b):$CLJS.F.call(null,"\r",b))?"\\return":$CLJS.m($CLJS.F.h?$CLJS.F.h('"',b):$CLJS.F.call(null,'"',b))?'\\"':$CLJS.m($CLJS.F.h?$CLJS.F.h("\\",b):$CLJS.F.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.g(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.g(a)].join(""));return c};Oca=function(a,b){b=lC(b);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);CC.l($CLJS.G([a]));return b};SC=function(a){a=$CLJS.C(a);return $CLJS.F.h(FC,a)||$CLJS.F.h(GC,a)};
WC=function(a,b,c){return $CLJS.$d(IA(function(d,e){if(SC(e))return new $CLJS.S(null,2,5,$CLJS.T,[null,e],null);e=uC(PC.g(d),e);var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);var g=KA(f);f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);f=$CLJS.U.j(f,QC,c);return new $CLJS.S(null,2,5,$CLJS.T,[null,$CLJS.Q.h(RC.g(d),new $CLJS.S(null,3,5,$CLJS.T,[f,e,g],null))],null)},b,a))};
XC=function(a){a=$CLJS.p.g(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.p.g(a.length-1)],null):new $CLJS.S(null,2,5,$CLJS.T,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.g(c-1)],null):0>c?new $CLJS.S(null,2,5,$CLJS.T,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.S(null,2,5,$CLJS.T,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);a:if(c=$CLJS.E(b),0<c&&$CLJS.F.h($CLJS.Ud(b,
$CLJS.E(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.F.h($CLJS.Ud(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.E(c);if(0<d&&$CLJS.F.h($CLJS.Ud(c,0),"0"))for(var e=0;;){if($CLJS.F.h(e,d)||!$CLJS.F.h($CLJS.Ud(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.E(b)-$CLJS.E(c);a=0<$CLJS.E(a)&&$CLJS.F.h($CLJS.Ud(a,0),"+")?a.substring(1):a;return $CLJS.je(c)?new $CLJS.S(null,2,5,$CLJS.T,["0",0],null):new $CLJS.S(null,2,5,$CLJS.T,[c,parseInt(a,10)-b],null)};
YC=function(a,b,c,d){if($CLJS.m($CLJS.m(c)?c:d)){var e=$CLJS.E(a);d=$CLJS.m(d)?2>d?2:d:0;$CLJS.m(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.F.h(c,0)?new $CLJS.S(null,4,5,$CLJS.T,[["0",$CLJS.p.g(a)].join(""),b+1,1,e+1],null):new $CLJS.S(null,4,5,$CLJS.T,[a,b,c,e],null);c=$CLJS.J(f,0,null);e=$CLJS.J(f,1,null);d=$CLJS.J(f,2,null);f=$CLJS.J(f,3,null);if($CLJS.m(d)){if(0>d)return new $CLJS.S(null,3,5,$CLJS.T,["0",0,!1],null);if(f>d){b=$CLJS.Ud(c,d);a=c.substring(0,d);if(HA(b)>=HA("5")){a:for(b=
$CLJS.E(a)-1,c=b|0;;){if(0>c){b=$CLJS.Q.j($CLJS.p,"1",$CLJS.tg(b+1,"0"));break a}if($CLJS.F.h("9",a.charAt(c)))--c;else{b=$CLJS.Q.F($CLJS.p,a.substring(0,c),$CLJS.mv(HA(a.charAt(c))+1),$CLJS.tg(b-c,"0"));break a}}a=$CLJS.E(b)>a.length;c=$CLJS.T;a&&(d=$CLJS.E(b)-1,b=b.substring(0,d));return new $CLJS.S(null,3,5,c,[b,e,a],null)}return new $CLJS.S(null,3,5,$CLJS.T,[a,e,!1],null)}}}return new $CLJS.S(null,3,5,$CLJS.T,[a,b,!1],null)};
ZC=function(a,b,c){var d=0>b?new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(-b-1,"0"))),$CLJS.p.g(a)].join(""),-1],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);a=$CLJS.J(d,0,null);var e=$CLJS.J(d,1,null);d=$CLJS.E(a);c=$CLJS.m(c)?e+c+1:e+1;c=d<c?[$CLJS.p.g(a),$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.g(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Pca=function(a,b){return 0>b?[".",$CLJS.p.g(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
cD=function(a,b){var c=TC.g(a),d=UC.g(a);b=lC(b);var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var f=0>e?new $CLJS.S(null,2,5,$CLJS.T,["-",-e],null):new $CLJS.S(null,2,5,$CLJS.T,["+",e],null),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);f=XC(f);var l=$CLJS.J(f,0,null),n=$CLJS.J(f,1,null)+$CLJS.Lv.g(a);f=function(){var x=aC.g(a);return $CLJS.m(x)?x:0>e}();var q=$CLJS.Fb(d)&&$CLJS.E(l)-1<=n,u=YC(l,n,d,$CLJS.m(c)?c-($CLJS.m(f)?1:0):null);l=$CLJS.J(u,0,null);n=$CLJS.J(u,1,null);u=$CLJS.J(u,2,null);l=ZC(l,$CLJS.m(u)?
n+1:n,d);d=$CLJS.m($CLJS.m(c)?$CLJS.m(d)?1<=d&&$CLJS.F.h(l.charAt(0),"0")&&$CLJS.F.h(l.charAt(1),".")&&l.length>c-($CLJS.m(f)?1:0):d:c)?l.substring(1):l;n=$CLJS.F.h($CLJS.C(d),".");if($CLJS.m(c)){l=d.length;l=$CLJS.m(f)?l+1:l;n=n&&!(l>=c);q=q&&!(l>=c);var v=n||q?l+1:l;$CLJS.m(function(){var x=v>c;return x?VC.g(a):x}())?CC.l($CLJS.G([$CLJS.Q.h($CLJS.p,$CLJS.tg(c,VC.g(a)))])):CC.l($CLJS.G([[$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(c-v,yC.g(a)))),$CLJS.p.g($CLJS.m(f)?g:null),n?"0":null,d,q?"0":null].join("")]))}else CC.l($CLJS.G([[$CLJS.p.g($CLJS.m(f)?
g:null),n?"0":null,d,q?"0":null].join("")]));return b};
fD=function(a,b){b=lC(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=XC(0>c?-c:c);$CLJS.J(d,0,null);for($CLJS.J(d,1,null);;){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null),g=TC.g(a),l=UC.g(a),n=$C.g(a),q=$CLJS.Lv.g(a),u=function(){var I=aD.g(a);return $CLJS.m(I)?I:"E"}();d=function(){var I=aC.g(a);return $CLJS.m(I)?I:0>c}();var v=0>=q,x=f-(q-1),z=$CLJS.p.g(Math.abs(x));u=[$CLJS.p.g(u),0>x?"-":"+",$CLJS.p.g($CLJS.m(n)?$CLJS.Q.h($CLJS.p,$CLJS.tg(n-z.length,"0")):null),z].join("");var B=u.length;
x=$CLJS.E(e);e=[$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(-q,"0"))),$CLJS.p.g(e),$CLJS.p.g($CLJS.m(l)?$CLJS.Q.h($CLJS.p,$CLJS.tg(l-(x-1)-(0>q?-q:0),"0")):null)].join("");x=$CLJS.m(g)?g-B:null;e=YC(e,0,$CLJS.F.h(q,0)?l-1:0<q?l:0>q?l-1:null,$CLJS.m(x)?x-($CLJS.m(d)?1:0):null);x=$CLJS.J(e,0,null);$CLJS.J(e,1,null);z=$CLJS.J(e,2,null);e=Pca(x,q);l=$CLJS.F.h(q,$CLJS.E(x))&&null==l;if($CLJS.Fb(z)){if($CLJS.m(g)){f=e.length+B;f=$CLJS.m(d)?f+1:f;var H=(v=v&&!$CLJS.F.h(f,g))?f+1:f;f=l&&H<g;$CLJS.m(function(){var I=
H>g;I||(I=n,I=$CLJS.m(I)?B-2>n:I);return $CLJS.m(I)?VC.g(a):I}())?CC.l($CLJS.G([$CLJS.Q.h($CLJS.p,$CLJS.tg(g,VC.g(a)))])):CC.l($CLJS.G([[$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(g-H-(f?1:0),yC.g(a)))),$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else CC.l($CLJS.G([[$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.S(null,2,5,$CLJS.T,[x,f+1],null)}return b};
Qca=function(a,b,c){var d=lC(b),e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);d=XC(0>e?-e:e);var f=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var g=TC.g(a),l=UC.g(a),n=$C.g(a);d=$CLJS.F.h(e,0)?0:d+1;e=$CLJS.m(n)?n+2:4;g=$CLJS.m(g)?g-e:null;$CLJS.m(l)?f=l:(f=$CLJS.E(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=cD(new $CLJS.k(null,6,[TC,g,UC,d,$CLJS.Lv,0,VC,VC.g(a),yC,yC.g(a),aC,aC.g(a)],null),b,c),CC.l($CLJS.G([$CLJS.Q.h($CLJS.p,$CLJS.tg(e," "))])),a):fD(a,b,c)};
Rca=function(a,b){b=lC(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=XC(Math.abs(c)),e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null),g=UC.g(a),l=$CLJS.bD.g(a);d=TC.g(a);var n=function(){var u=aC.g(a);return $CLJS.m(u)?u:0>c}(),q=YC(e,f,g,null);e=$CLJS.J(q,0,null);f=$CLJS.J(q,1,null);q=$CLJS.J(q,2,null);g=ZC(e,$CLJS.m(q)?f+1:f,g);l=[$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(l-g.indexOf("."),"0"))),g].join("");g=l.length+($CLJS.m(n)?1:0);CC.l($CLJS.G([[$CLJS.m(function(){var u=$B.g(a);return $CLJS.m(u)?n:
u}())?0>c?"-":"+":null,$CLJS.p.g($CLJS.Q.h($CLJS.p,$CLJS.tg(d-g,yC.g(a)))),$CLJS.m(function(){var u=$CLJS.Fb($B.g(a));return u?n:u}())?0>c?"-":"+":null,l].join("")]));return b};Sca=function(a,b){var c=dD.g(a);c=$CLJS.m(c)?new $CLJS.S(null,2,5,$CLJS.T,[c,b],null):lC(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var d=$CLJS.eD.g(a);b=0>b||b>=$CLJS.E(d)?$CLJS.C($CLJS.Cv.g(a)):$CLJS.Ud(d,b);return $CLJS.m(b)?WC(b,c,QC.g(a)):c};
Tca=function(a,b){var c=lC(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var d=$CLJS.eD.g(a);b=$CLJS.m(b)?$CLJS.$d(d):$CLJS.C(d);return $CLJS.m(b)?WC(b,c,QC.g(a)):c};Uca=function(a,b){var c=lC(b),d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var e=$CLJS.eD.g(a);e=$CLJS.m(d)?$CLJS.C(e):null;return $CLJS.m(d)?$CLJS.m(e)?WC(e,b,QC.g(a)):b:c};
Vca=function(a,b){var c=gD.g(a),d=$CLJS.C($CLJS.eD.g(a));d=$CLJS.je(d)?mC(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=lC(d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=0;e=dC(e);for(var g=-1;;){if($CLJS.Fb(c)&&$CLJS.F.h(nB.g(e),g)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");g=$CLJS.je($CLJS.Dk.g(e))&&($CLJS.Fb($B.g(hD.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=WC(b,e,QC.g(a));if($CLJS.F.h(FC,$CLJS.C(g)))return d;
f+=1;var l=nB.g(e);e=g;g=l}};Wca=function(a,b){var c=gD.g(a),d=$CLJS.C($CLJS.eD.g(a));d=$CLJS.je(d)?mC(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=lC(d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);for(var f=0;;){var g=$CLJS.je(e)&&($CLJS.Fb($B.g(hD.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=WC(b,dC($CLJS.C(e)),dC($CLJS.D(e)));if($CLJS.F.h(GC,$CLJS.C(g)))return d;f+=1;e=$CLJS.D(e)}};
Xca=function(a,b){var c=gD.g(a),d=$CLJS.C($CLJS.eD.g(a)),e=$CLJS.je(d)?mC(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(e,0,null);d=0;e=$CLJS.J(e,1,null);for(var f=-1;;){if($CLJS.Fb(c)&&$CLJS.F.h(nB.g(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.je($CLJS.Dk.g(e))&&($CLJS.Fb($B.g(hD.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=WC(b,e,QC.g(a));if($CLJS.F.h(FC,$CLJS.C(f)))return $CLJS.$d(f);d+=1;var g=nB.g(e);e=f;f=g}};
Yca=function(a,b){var c=gD.g(a),d=$CLJS.C($CLJS.eD.g(a)),e=$CLJS.je(d)?mC(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(e,0,null);d=0;for(e=$CLJS.J(e,1,null);;){var f=$CLJS.je($CLJS.Dk.g(e))&&($CLJS.Fb($B.g(hD.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=$CLJS.Dk.g(e);f=$CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(f),new jC(ZB.g(e),$CLJS.D(f),nB.g(e)+1,null,null,null)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,e],null);e=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=WC(b,dC(e),f);
if($CLJS.F.h(GC,$CLJS.C(e)))return f;e=f;d+=1}};$ca=function(a,b){if($CLJS.m($B.g(hD.g(a)))){var c=$CLJS.eD.g(a),d=$CLJS.E(c),e=1<d?$CLJS.Ul.g(PC.g($CLJS.C($CLJS.C(c)))):$CLJS.m($B.g(a))?"(":null,f=$CLJS.Ud(c,1<d?1:0);c=2<d?$CLJS.Ul.g(PC.g($CLJS.C($CLJS.Ud(c,2)))):$CLJS.m($B.g(a))?")":null;d=lC(b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var g=SB,l=NB;SB+=1;NB=0;try{HB(e,c),WC(f,dC(b),QC.g(a)),LB()}finally{NB=l,SB=g}}a=d}else a=Zca(a,b);return a};
lD=function(a,b,c){for(var d=$CLJS.Rg;;){if($CLJS.je(a))return new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);var e=$CLJS.C(a);a:{var f=new $CLJS.qb,g=$CLJS.FA;$CLJS.FA=new $CLJS.gd(f);try{var l=new $CLJS.S(null,2,5,$CLJS.T,[WC(e,b,c),$CLJS.p.g(f)],null);break a}finally{$CLJS.FA=g}l=void 0}b=$CLJS.J(l,0,null);e=$CLJS.J(l,1,null);if($CLJS.F.h(FC,$CLJS.C(b)))return new $CLJS.S(null,2,5,$CLJS.T,[d,$CLJS.$d(b)],null);a=$CLJS.D(a);d=$CLJS.mf.h(d,e)}};
Zca=function(a,b){var c=function(){var X=$CLJS.Cv.g(a);return $CLJS.m(X)?lD(X,b,QC.g(a)):null}(),d=$CLJS.J(c,0,null);d=$CLJS.J(d,0,null);c=$CLJS.J(c,1,null);var e=$CLJS.m(c)?c:b;c=function(){var X=iD.g(a);return $CLJS.m(X)?uC(X,e):null}();var f=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var g=$CLJS.m(c)?c:e;c=function(){var X=$CLJS.C(jD.g(f));return $CLJS.m(X)?X:0}();var l=function(){var X=$CLJS.C(kD.g(f));return $CLJS.m(X)?X:$CLJS.MA($CLJS.FA,$CLJS.em)}(),n=$CLJS.eD.g(a);g=lD(n,g,QC.g(a));var q=$CLJS.J(g,
0,null);g=$CLJS.J(g,1,null);var u=function(){var X=$CLJS.E(q)-1+($CLJS.m($B.g(a))?1:0)+($CLJS.m(aC.g(a))?1:0);return 1>X?1:X}();n=$CLJS.We($CLJS.Om,$CLJS.xg.h($CLJS.E,q));var v=sC.g(a),x=rC.g(a),z=tC.g(a),B=n+u*x;v=B<=v?v:v+z*(1+$CLJS.cf(B-v-1,z));var H=v-n;n=function(){var X=$CLJS.cf(H,u);return x>X?x:X}();z=H-n*u;n=$CLJS.Q.h($CLJS.p,$CLJS.tg(n,yC.g(a)));$CLJS.m($CLJS.m(d)?$CLJS.MA(uA.g($CLJS.r($CLJS.r($CLJS.FA))),$CLJS.tA)+c+v>l:d)&&CC.l($CLJS.G([d]));c=z;for(var I=q,R=function(){var X=$B.g(a);
return $CLJS.m(X)?X:$CLJS.F.h($CLJS.E(I),1)&&$CLJS.Fb(aC.g(a))}();;)if($CLJS.A(I))CC.l($CLJS.G([[$CLJS.p.g($CLJS.Fb(R)?$CLJS.C(I):null),$CLJS.p.g($CLJS.m(function(){var X=R;return $CLJS.m(X)?X:(X=$CLJS.D(I))?X:aC.g(a)}())?n:null),$CLJS.p.g(0<c?yC.g(a):null)].join("")])),--c,I=d=$CLJS.m(R)?I:$CLJS.D(I),R=!1;else break;return g};mD=function(a,b){this.Ka=a;this.ii=b;this.C=1074135040;this.K=0};ada=function(a){return new mD(a,$CLJS.P)};nD=function(a,b){this.Ka=a;this.ji=b;this.C=1074135040;this.K=0};
bda=function(a){return new nD(a,$CLJS.P)};
cda=function(a,b){var c=$CLJS.C(a);return $CLJS.Q.h($CLJS.p,$CLJS.C(JA(function(d){if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.m(e)?e.index+1:e;return $CLJS.m(e)?new $CLJS.S(null,2,5,$CLJS.T,[[d.substring(0,e),$CLJS.Ud(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.S(null,2,5,$CLJS.T,[d,null],null)},$CLJS.m($CLJS.m(b)?$CLJS.m(c)?wA(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
oD=function(a,b,c){this.Ka=a;this.pe=b;this.ki=c;this.C=1074135040;this.K=0};dda=function(a){var b=$CLJS.ij.g(!0);return new oD(a,b,$CLJS.P)};pD=function(a,b,c){this.Ka=a;this.ee=b;this.li=c;this.C=1074135040;this.K=0};eda=function(a){var b=$CLJS.ij.g(!1);return new pD(a,b,$CLJS.P)};fda=function(a,b){var c=$CLJS.m($B.g(a))?$CLJS.WB:VB;bC(c,$CLJS.bD.g(a));return b};gda=function(a,b){a=$CLJS.m($B.g(a))?$CLJS.m(aC.g(a))?IB:KB:$CLJS.m(aC.g(a))?JB:UB;YB(a);return b};
ida=function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null),d=$CLJS.J(a,2,null);a=new RegExp(hda.source,"g");var e=a.exec(b);return $CLJS.m(e)?(d=$CLJS.C(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.F.h(",",$CLJS.Ud(b,0))?new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[d,c],null),new $CLJS.S(null,3,5,$CLJS.T,[b.substring(1),a+1,!0],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[d,c],null),new $CLJS.S(null,3,5,$CLJS.T,[b,a,!1],null)],null)):$CLJS.m(d)?iC("Badly formed parameters in format directive",
c):new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,2,5,$CLJS.T,[b,c],null)],null)};jda=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(b.length,0)?null:$CLJS.F.h(b.length,1)&&$CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,2,["V",null,"v",null],null),null),$CLJS.Ud(b,0))?kC:$CLJS.F.h(b.length,1)&&$CLJS.F.h("#",$CLJS.Ud(b,0))?qC:$CLJS.F.h(b.length,2)&&$CLJS.F.h("'",$CLJS.Ud(b,0))?$CLJS.Ud(b,1):parseInt(b,10),a],null)};
lda=function(a,b){return JA(function(c){var d=$CLJS.J(c,0,null),e=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,3,5,$CLJS.T,[d,e,c],null)],null);var f=$CLJS.M.h(kda,$CLJS.C(d));return $CLJS.m(f)?$CLJS.Je(c,f)?iC(['Flag "',$CLJS.p.g($CLJS.C(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[d.substring(1),e+1,$CLJS.U.j(c,f,new $CLJS.S(null,2,5,$CLJS.T,[!0,e],null))],
null)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,3,5,$CLJS.T,[d,e,c],null)],null)},new $CLJS.S(null,3,5,$CLJS.T,[a,b,$CLJS.P],null))};
mda=function(a,b){var c=qD.g(a);$CLJS.m(function(){var d=$CLJS.Fb(aC.g(c));return d?aC.g(b):d}())&&iC(['"@" is an illegal flag for format directive "',$CLJS.p.g(rD.g(a)),'"'].join(""),$CLJS.Ud(aC.g(b),1));$CLJS.m(function(){var d=$CLJS.Fb($B.g(c));return d?$B.g(b):d}())&&iC(['":" is an illegal flag for format directive "',$CLJS.p.g(rD.g(a)),'"'].join(""),$CLJS.Ud($B.g(b),1));$CLJS.m(function(){var d=$CLJS.Fb(sD.g(c));return d?(d=aC.g(b),$CLJS.m(d)?$B.g(b):d):d}())&&iC(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.g(rD.g(a)),'"'].join(""),function(){var d=$CLJS.Ud($B.g(b),1),e=$CLJS.Ud(aC.g(b),1);return d<e?d:e}())};
oda=function(a,b,c,d){mda(a,c);$CLJS.E(b)>$CLJS.E(PC.g(a))&&iC(gC(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.G([rD.g(a),$CLJS.E(b),$CLJS.E(PC.g(a))])),$CLJS.$d($CLJS.C(b)));$CLJS.Ii($CLJS.xg.j(function(e,f){var g=$CLJS.C(e);return null==g||$CLJS.Je(nda,g)||$CLJS.F.h($CLJS.$d($CLJS.$d(f)),$CLJS.Nb(g))?null:iC(["Parameter ",$CLJS.Ai($CLJS.C(f)),' has bad type in directive "',$CLJS.p.g(rD.g(a)),'": ',$CLJS.p.g($CLJS.Nb(g))].join(""),
$CLJS.$d(e))},b,PC.g(a)));return $CLJS.pn.l($CLJS.G([$CLJS.uh.h($CLJS.P,$CLJS.nf(function(){return function g(f){return new $CLJS.Cf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Ff(q);a:for(var v=0;;)if(v<q){var x=$CLJS.ce(n,v),z=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);x=$CLJS.J(x,0,null);u.add(new $CLJS.S(null,2,5,$CLJS.T,[z,new $CLJS.S(null,2,5,$CLJS.T,[x,d],null)],null));v+=1}else{n=!0;break a}return n?$CLJS.If($CLJS.Kf(u),g($CLJS.ad(l))):
$CLJS.If($CLJS.Kf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);n=$CLJS.J(n,0,null);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[u,new $CLJS.S(null,2,5,$CLJS.T,[n,d],null)],null),g($CLJS.Ad(l)))}return null}},null,null)}(PC.g(a))}())),$CLJS.Rb(function(e,f){return $CLJS.Q.j($CLJS.U,e,f)},$CLJS.P,$CLJS.Ag(function(e){return $CLJS.C($CLJS.Ud(e,1))},$CLJS.DA($CLJS.mi(PC.g(a)),b))),c]))};
qda=function(a,b){b=JA(ida,new $CLJS.S(null,3,5,$CLJS.T,[a,b,!1],null));a=$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);b=lda(b,c);$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);c=$CLJS.C(d);var f=$CLJS.M.h(pda,c.toUpperCase()),g=$CLJS.m(f)?oda(f,$CLJS.xg.h(jda,a),b,e):null;$CLJS.Fb(c)&&iC("Format string ended in the middle of a directive",e);$CLJS.Fb(f)&&iC(['Directive "',$CLJS.p.g(c),'" is undefined'].join(""),
e);return new $CLJS.S(null,2,5,$CLJS.T,[new pC(function(){var l=tD.g(f);return l.h?l.h(g,e):l.call(null,g,e)}(),f,g,e,null,null,null),function(){var l=d.substring(1),n=e+1;if($CLJS.F.h("\n",rD.g(f))&&$CLJS.Fb($B.g(g)))a:{var q=new $CLJS.S(null,2,5,$CLJS.T,[" ","\t"],null);q=$CLJS.ke(q)?$CLJS.yi(q):$CLJS.vi([q]);for(var u=0;;){var v;(v=$CLJS.F.h(u,$CLJS.E(l)))||(v=$CLJS.Ud(l,u),v=q.g?q.g(v):q.call(null,v),v=$CLJS.Fb(v));if(v){q=u;break a}u+=1}}else q=0;return new $CLJS.S(null,2,5,$CLJS.T,[l.substring(q),
n+q],null)}()],null)};xD=function(a,b){return new pC(function(c,d){CC.l($CLJS.G([a]));return d},null,new $CLJS.k(null,1,[$CLJS.Ul,a],null),b,null,null,null)};AD=function(a,b){var c=rda(uD.g(vD.g(a)),$CLJS.Fv.g(a),b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[new pC(RC.g(a),vD.g(a),$CLJS.pn.l($CLJS.G([PC.g(a),Aca(b,$CLJS.Fv.g(a))])),$CLJS.Fv.g(a),null,null,null),c],null)};
sda=function(a,b,c){return JA(function(d){if($CLJS.je(d))return iC("No closing bracket found.",b);var e=$CLJS.C(d);d=$CLJS.D(d);if($CLJS.m($CLJS.wD.g(uD.g(vD.g(e)))))e=AD(e,d);else if($CLJS.F.h($CLJS.wD.g(a),rD.g(vD.g(e))))e=new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[yD,PC.g(e),null,d],null)],null);else{var f=$CLJS.Jv.g(uD.g(vD.g(e)));f=$CLJS.m(f)?$B.g(PC.g(e)):f;e=$CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Cv,null,PC.g(e),d],null)],null):
$CLJS.m($CLJS.Jv.g(uD.g(vD.g(e))))?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Jv,null,null,d],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[e,d],null)}return e},c)};
rda=function(a,b,c){return $CLJS.$d(JA(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);d=$CLJS.J(d,2,null);var g=sda(a,b,d);d=$CLJS.J(g,0,null);var l=$CLJS.J(g,1,null);g=$CLJS.J(l,0,null);var n=$CLJS.J(l,1,null),q=$CLJS.J(l,2,null);l=$CLJS.J(l,3,null);return $CLJS.F.h(g,yD)?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DD.l($CLJS.wg,$CLJS.G([e,$CLJS.Tf([$CLJS.m(f)?$CLJS.Cv:$CLJS.eD,new $CLJS.S(null,1,5,$CLJS.T,[d],null),hD,n])])),l],null)],null):$CLJS.F.h(g,$CLJS.Cv)?
$CLJS.m($CLJS.Cv.g(e))?iC('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Fb($CLJS.Cv.g(a))?iC('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.F.h(zD,$CLJS.Cv.g(a))&&$CLJS.A($CLJS.eD.g(e))?iC('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.F.h(zD,$CLJS.Cv.g(a))?new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DD.l($CLJS.wg,$CLJS.G([e,new $CLJS.k(null,2,[$CLJS.Cv,new $CLJS.S(null,1,5,
$CLJS.T,[d],null),iD,q],null)])),!1,l],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DD.l($CLJS.wg,$CLJS.G([e,new $CLJS.k(null,1,[$CLJS.eD,new $CLJS.S(null,1,5,$CLJS.T,[d],null)],null)])),!0,l],null)],null):$CLJS.F.h(g,$CLJS.Jv)?$CLJS.m(f)?iC('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Fb(BD.g(a))?iC('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.DD.l($CLJS.wg,$CLJS.G([e,new $CLJS.k(null,1,[$CLJS.eD,new $CLJS.S(null,1,5,$CLJS.T,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.eD,$CLJS.Rg],null),!1,c],null)))};tda=function(a){return $CLJS.C(JA(function(b){var c=$CLJS.C(b);b=$CLJS.D(b);var d=uD.g(vD.g(c));return $CLJS.m($CLJS.wD.g(d))?AD(c,b):new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)},a))};
cC=function(a){var b=hC;hC=a;try{return tda($CLJS.C(JA(function(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.S(null,2,5,$CLJS.T,[xD(d,c),new $CLJS.S(null,2,5,$CLJS.T,["",c+d.length],null)],null):0===e?qda(d.substring(1),c+1):new $CLJS.S(null,2,5,$CLJS.T,[xD(d.substring(0,e),c),new $CLJS.S(null,2,5,$CLJS.T,[d.substring(e),e+c],null)],null)},new $CLJS.S(null,2,5,$CLJS.T,[a,0],null))))}finally{hC=
b}};fC=function(a,b){IA(function(c,d){if(SC(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);d=uC(PC.g(c),d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=KA(e);e=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=$CLJS.U.j(e,QC,d);return new $CLJS.S(null,2,5,$CLJS.T,[null,$CLJS.Q.h(RC.g(c),new $CLJS.S(null,3,5,$CLJS.T,[e,d,f],null))],null)},b,a);return null};
HD=function(a){var b=$CLJS.C(a);b=GD.g?GD.g(b):GD.call(null,b);return $CLJS.m($CLJS.m(b)?$CLJS.F.h(2,$CLJS.E(a)):b)?($CLJS.Qc($CLJS.FA,b),$CLJS.QB($CLJS.$d(a)),!0):null};
ID=function(a){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var b=SB,c=NB;SB+=1;NB=0;try{HB("[","]");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Fb($CLJS.xb)||d<$CLJS.xb){if(e&&($CLJS.QB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.FA," ");YB(UB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.FA,"...");break}LB()}finally{NB=c,SB=b}}return null};
JD=function(a){var b=$CLJS.pe(a)?null:function(){var n=new $CLJS.ud(function(){return $CLJS.bj},Bda,$CLJS.Yh([$CLJS.Rl,$CLJS.Jk,$CLJS.V,$CLJS.mm,$CLJS.fk,$CLJS.Gk,$CLJS.cl,$CLJS.Dm,$CLJS.ek,$CLJS.W,$CLJS.lm],[!0,$CLJS.Rj,Cda,"cljs/core.cljs",15,1,10543,10543,$CLJS.Y(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Sk],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.m($CLJS.bj)?$CLJS.bj.J:null]));return n.g?n.g(a):n.call(null,a)}(),c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.m(b)?
b:a,e=$CLJS.m(c)?["#:",$CLJS.p.g(c),"{"].join(""):"{";if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{c=SB;b=NB;SB+=1;NB=0;try{HB(e,"}");e=0;for(var f=$CLJS.A(d);;){if($CLJS.Fb($CLJS.xb)||e<$CLJS.xb){if(f){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{d=SB;var g=NB;SB+=1;NB=0;try{HB(null,null),$CLJS.QB($CLJS.C($CLJS.C(f))),$CLJS.Qc($CLJS.FA," "),YB(UB),NB=0,$CLJS.QB($CLJS.C($CLJS.D($CLJS.C(f)))),LB()}finally{NB=g,SB=d}}if($CLJS.D(f)){$CLJS.Qc($CLJS.FA,", ");YB(UB);d=e+1;var l=$CLJS.D(f);e=d;f=l;continue}}}else $CLJS.Qc($CLJS.FA,
"...");break}LB()}finally{NB=b,SB=c}}return null};KD=function(a){return $CLJS.Qc($CLJS.FA,$CLJS.gj.l($CLJS.G([a])))};ND=function(a){return a instanceof $CLJS.di?CD:(null!=a?a.C&32768||$CLJS.t===a.Lf||(a.C?0:$CLJS.Mb($CLJS.xA,a)):$CLJS.Mb($CLJS.xA,a))?ED:a instanceof $CLJS.w?$CLJS.tk:$CLJS.we(a)?$CLJS.ul:$CLJS.oe(a)?$CLJS.dm:$CLJS.qe(a)?$CLJS.Fl:$CLJS.le(a)?$CLJS.Nk:null==a?null:$CLJS.Uj};
OD=function(a){return $CLJS.qe(a)?new $CLJS.S(null,2,5,$CLJS.T,["[","]"],null):new $CLJS.S(null,2,5,$CLJS.T,["(",")"],null)};
wda=function(a){if($CLJS.ne(a)){var b=OD(a),c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null),e=$CLJS.A(a),f=$CLJS.C(e),g=$CLJS.D(e);if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var l=SB,n=NB,q=SB+1;SB=q;NB=0;try{HB(c,d);(function(){var Fa=PD("~w~:i");return function(){function gb(Sb){var Yb=null;if(0<arguments.length){Yb=0;for(var oc=Array(arguments.length-0);Yb<oc.length;)oc[Yb]=arguments[Yb+0],++Yb;Yb=new $CLJS.y(oc,0,null)}return Gb.call(this,Yb)}function Gb(Sb){Sb=dC(Sb);return fC(Fa,Sb)}gb.v=0;gb.B=function(Sb){Sb=
$CLJS.A(Sb);return Gb(Sb)};gb.l=Gb;return gb}()})()(f);for(var u=g;;)if($CLJS.A(u)){(function(){var Fa=PD(" ");return function(gb,Gb,Sb){return function(){function Yb(Db){var Tb=null;if(0<arguments.length){Tb=0;for(var zd=Array(arguments.length-0);Tb<zd.length;)zd[Tb]=arguments[Tb+0],++Tb;Tb=new $CLJS.y(zd,0,null)}return oc.call(this,Tb)}function oc(Db){Db=dC(Db);return fC(Sb,Db)}Yb.v=0;Yb.B=function(Db){Db=$CLJS.A(Db);return oc(Db)};Yb.l=oc;return Yb}()}(u," ",Fa,l,n,q,0,b,c,d,a,e,f,g,f,g)})()();
var v=$CLJS.C(u);if($CLJS.ne(v)){var x=OD(v),z=$CLJS.J(x,0,null),B=$CLJS.J(x,1,null);if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var H=SB,I=NB,R=SB+1;SB=R;NB=0;try{HB(z,B);if($CLJS.F.h($CLJS.E(v),3)&&$CLJS.$d(v)instanceof $CLJS.N){var X=v,ba=$CLJS.J(X,0,null),ha=$CLJS.J(X,1,null),oa=$CLJS.J(X,2,null);(function(){var Fa=PD("~w ~w ");return function(gb,Gb,Sb){return function(){function Yb(Db){var Tb=null;if(0<arguments.length){Tb=0;for(var zd=Array(arguments.length-0);Tb<zd.length;)zd[Tb]=arguments[Tb+
0],++Tb;Tb=new $CLJS.y(zd,0,null)}return oc.call(this,Tb)}function oc(Db){Db=dC(Db);return fC(Sb,Db)}Yb.v=0;Yb.B=function(Db){Db=$CLJS.A(Db);return oc(Db)};Yb.l=oc;return Yb}()}(u,"~w ~w ",Fa,X,ba,ha,oa,H,I,R,0,x,z,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)})()(ba,ha);$CLJS.ne(oa)?function(){var Fa=$CLJS.qe(oa)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",gb="string"===typeof Fa?PD(Fa):Fa;return function(Gb,Sb,Yb){return function(){function oc(Tb){var zd=null;if(0<arguments.length){zd=0;
for(var ft=Array(arguments.length-0);zd<ft.length;)ft[zd]=arguments[zd+0],++zd;zd=new $CLJS.y(ft,0,null)}return Db.call(this,zd)}function Db(Tb){Tb=dC(Tb);return fC(Yb,Tb)}oc.v=0;oc.B=function(Tb){Tb=$CLJS.A(Tb);return Db(Tb)};oc.l=Db;return oc}()}(u,Fa,gb,X,ba,ha,oa,H,I,R,0,x,z,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()(oa):$CLJS.QB(oa)}else $CLJS.Q.h(function(){var Fa=PD("~w ~:i~@{~w~^ ~:_~}");return function(gb,Gb,Sb){return function(){function Yb(Db){var Tb=null;if(0<arguments.length){Tb=0;for(var zd=
Array(arguments.length-0);Tb<zd.length;)zd[Tb]=arguments[Tb+0],++Tb;Tb=new $CLJS.y(zd,0,null)}return oc.call(this,Tb)}function oc(Db){Db=dC(Db);return fC(Sb,Db)}Yb.v=0;Yb.B=function(Db){Db=$CLJS.A(Db);return oc(Db)};Yb.l=oc;return Yb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Fa,H,I,R,0,x,z,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}(),v);LB()}finally{NB=I,SB=H}}$CLJS.D(u)&&function(){var Fa=PD("~_");return function(gb,Gb,Sb){return function(){function Yb(Db){var Tb=null;if(0<arguments.length){Tb=0;for(var zd=Array(arguments.length-
0);Tb<zd.length;)zd[Tb]=arguments[Tb+0],++Tb;Tb=new $CLJS.y(zd,0,null)}return oc.call(this,Tb)}function oc(Db){Db=dC(Db);return fC(Sb,Db)}Yb.v=0;Yb.B=function(Db){Db=$CLJS.A(Db);return oc(Db)};Yb.l=oc;return Yb}()}(u,"~_",Fa,x,z,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()()}else $CLJS.QB(v),$CLJS.D(u)&&function(){var Fa=PD("~:_");return function(gb,Gb,Sb){return function(){function Yb(Db){var Tb=null;if(0<arguments.length){Tb=0;for(var zd=Array(arguments.length-0);Tb<zd.length;)zd[Tb]=arguments[Tb+0],++Tb;
Tb=new $CLJS.y(zd,0,null)}return oc.call(this,Tb)}function oc(Db){Db=dC(Db);return fC(Sb,Db)}Yb.v=0;Yb.B=function(Db){Db=$CLJS.A(Db);return oc(Db)};Yb.l=oc;return Yb}()}(u,"~:_",Fa,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()();u=$CLJS.D(u)}else break;LB()}finally{NB=n,SB=l}}}else $CLJS.QB(a)};
xda=function(a,b){$CLJS.A(a)&&($CLJS.m(b)?function(){var c=PD(" ~_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.y(l,0,null)}return e.call(this,g)}function e(f){f=dC(f);return fC(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;return d}()}()():function(){var c=PD(" ~@_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<
l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.y(l,0,null)}return e.call(this,g)}function e(f){f=dC(f);return fC(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;return d}()}()(),function(){var c=PD("~{~w~^ ~_~}");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.y(l,0,null)}return e.call(this,g)}function e(f){f=dC(f);return fC(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;
return d}()}()(a))};yda=function(a){$CLJS.A(a)&&function(){var b=PD(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var g=Array(arguments.length-0);f<g.length;)g[f]=arguments[f+0],++f;f=new $CLJS.y(g,0,null)}return d.call(this,f)}function d(e){e=dC(e);return fC(b,e)}c.v=0;c.B=function(e){e=$CLJS.A(e);return d(e)};c.l=d;return c}()}()(a)};
RD=function(a){if($CLJS.D(a)){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d="string"===typeof $CLJS.C(c)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(c),$CLJS.D(c)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,c],null);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var e=$CLJS.oe($CLJS.C(d))?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(d),$CLJS.D(d)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var f=
SB,g=NB;SB+=1;NB=0;try{HB("(",")"),function(){var l=PD("~w ~1I~@_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return q.call(this,v)}function q(u){u=dC(u);return fC(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}()(a,b),$CLJS.m(c)&&function(){var l=PD(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return q.call(this,v)}function q(u){u=dC(u);return fC(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}()(c),$CLJS.m(d)&&function(){var l=PD(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return q.call(this,v)}function q(u){u=dC(u);return fC(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};
n.l=q;return n}()}()(d),$CLJS.qe($CLJS.C(e))?xda(e,$CLJS.m(c)?c:d):yda(e),LB()}finally{NB=g,SB=f}}return null}return QD(a)};
zda=function(a){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var b=SB,c=NB;SB+=1;NB=0;try{HB("[","]");for(var d=0;;){if($CLJS.Fb($CLJS.xb)||d<$CLJS.xb){if($CLJS.A(a)){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var e=SB,f=NB;SB+=1;NB=0;try{HB(null,null),$CLJS.QB($CLJS.C(a)),$CLJS.D(a)&&($CLJS.Qc($CLJS.FA," "),YB(JB),$CLJS.QB($CLJS.$d(a))),LB()}finally{NB=f,SB=e}}if($CLJS.D($CLJS.Ad(a))){$CLJS.Qc($CLJS.FA," ");YB(UB);e=d+1;var g=$CLJS.D($CLJS.Ad(a));d=e;a=g;continue}}}else $CLJS.Qc($CLJS.FA,"...");
break}LB()}finally{NB=c,SB=b}}};
SD=function(a){var b=$CLJS.C(a);if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var c=SB,d=NB;SB+=1;NB=0;try{HB("(",")"),$CLJS.D(a)&&$CLJS.qe($CLJS.$d(a))?(function(){var e=PD("~w ~1I~@_");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.y(q,0,null)}return g.call(this,n)}function g(l){l=dC(l);return fC(e,l)}f.v=0;f.B=function(l){l=$CLJS.A(l);return g(l)};f.l=g;return f}()}()(b),zda($CLJS.$d(a)),function(){var e=
PD(" ~_~{~w~^ ~_~}");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.y(q,0,null)}return g.call(this,n)}function g(l){l=dC(l);return fC(e,l)}f.v=0;f.B=function(l){l=$CLJS.A(l);return g(l)};f.l=g;return f}()}()($CLJS.D($CLJS.Ad(a)))):QD(a),LB()}finally{NB=d,SB=c}}return null};
QD=function(a){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var b=SB,c=NB;SB+=1;NB=0;try{HB("(",")");bC(VB,1);for(var d=0,e=$CLJS.A(a);;){if($CLJS.Fb($CLJS.xb)||d<$CLJS.xb){if(e&&($CLJS.QB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.FA," ");YB(UB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.FA,"...");break}LB()}finally{NB=c,SB=b}}return null};$CLJS.FA=null;
$CLJS.DD=function DD(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return DD.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
$CLJS.DD.l=function(a,b){if($CLJS.m($CLJS.ag($CLJS.$e,b))){var c=function(d,e){var f=$CLJS.tc(e),g=$CLJS.uc(e);if($CLJS.Je(d,f)){e=$CLJS.U.j;var l=$CLJS.M.h(d,f);g=a.h?a.h(l,g):a.call(null,l,g);d=e.call($CLJS.U,d,f,g)}else d=$CLJS.U.j(d,f,g);return d};return $CLJS.We(function(d,e){return $CLJS.Rb(c,$CLJS.m(d)?d:$CLJS.P,$CLJS.A(e))},b)}return null};$CLJS.DD.v=1;$CLJS.DD.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var TD=function TD(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return TD.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};TD.l=function(a){a=$CLJS.A(a);for(var b=$CLJS.Tc($CLJS.wh);;)if(a){var c=$CLJS.D(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.g($CLJS.C(a))].join(""));var d=$CLJS.D(c);b=$CLJS.xh.j(b,$CLJS.C(a),$CLJS.C(c));a=d}else return $CLJS.Vc(b)};TD.v=0;TD.B=function(a){return this.l($CLJS.A(a))};
$CLJS.HC=function HC(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return HC.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};$CLJS.HC.l=function(a){return $CLJS.Zi(a,$CLJS.U.j($CLJS.yb(),$CLJS.sb,!1))};$CLJS.HC.v=0;$CLJS.HC.B=function(a){return this.l($CLJS.A(a))};QA=new $CLJS.N(null,"trailing-white-space","trailing-white-space",1496006996);FD=new $CLJS.N(null,"relative-to","relative-to",-470100051);kB=new $CLJS.N(null,"intra-block-nl","intra-block-nl",1808826875);
aD=new $CLJS.N(null,"exponentchar","exponentchar",1986664222);Dda=new $CLJS.w(null,"when-let","when-let",-1383043480,null);JB=new $CLJS.N(null,"miser","miser",-556060186);$CLJS.WB=new $CLJS.N(null,"current","current",-1088038603);Eda=new $CLJS.w(null,"-\x3e","-\x3e",-2139605430,null);Fda=new $CLJS.w(null,"..","..",-300507420,null);YA=new $CLJS.N(null,"end-pos","end-pos",-1643883926);hB=new $CLJS.N(null,"per-line-prefix","per-line-prefix",846941813);CD=new $CLJS.N(null,"queue","queue",1455835879);
Gda=new $CLJS.w(null,"if-let","if-let",1803593690,null);FB=new $CLJS.N(null,"end","end",-268185958);qC=new $CLJS.N(null,"remaining-arg-count","remaining-arg-count",-1216589335);lB=new $CLJS.N(null,"logical-blocks","logical-blocks",-1466339776);Hda=new $CLJS.w(null,"defmacro","defmacro",2054157304,null);Ida=new $CLJS.w(null,"when-first","when-first",821699168,null);CB=new $CLJS.N(null,"start-block-t","start-block-t",-373430594);Jda=new $CLJS.w(null,"binding","binding",-2114503176,null);
uD=new $CLJS.N(null,"bracket-info","bracket-info",-1600092774);uB=new $CLJS.N(null,"writing","writing",-1486865108);kC=new $CLJS.N(null,"parameter-from-args","parameter-from-args",-758446196);xB=new $CLJS.N(null,"logical-block-callback","logical-block-callback",1612691194);dD=new $CLJS.N(null,"selector","selector",762528866);Kda=new $CLJS.w(null,"struct","struct",325972931,null);iB=new $CLJS.N(null,"indent","indent",-148200125);Lda=new $CLJS.w(null,"loop","loop",1244978678,null);
kD=new $CLJS.N(null,"max-columns","max-columns",1742323262);sD=new $CLJS.N(null,"both","both",-393648840);LD=new $CLJS.N(null,"colnum","colnum",2023796854);Mda=new $CLJS.w(null,"doseq","doseq",221164135,null);$CLJS.MD=new $CLJS.N(null,"length","length",588987862);BC=new $CLJS.N(null,"char-format","char-format",-1016499218);UD=new $CLJS.N(null,"prefix","prefix",-265908465);GC=new $CLJS.N(null,"colon-up-arrow","colon-up-arrow",244853007);EB=new $CLJS.N(null,"suffix","suffix",367373057);
aC=new $CLJS.N(null,"at","at",1476951349);PA=new $CLJS.N(null,"nl-t","nl-t",-1608382114);rD=new $CLJS.N(null,"directive","directive",793559132);Ada=new $CLJS.N(null,"buffer-level","buffer-level",928864731);IB=new $CLJS.N(null,"mandatory","mandatory",542802336);wB=new $CLJS.N(null,"pretty-writer","pretty-writer",-1222834267);$A=new $CLJS.N(null,"done-nl","done-nl",-381024340);Nda=new $CLJS.w(null,"condp","condp",1054325175,null);ZB=new $CLJS.N(null,"seq","seq",-1817803783);
Oda=new $CLJS.w(null,"defn","defn",-126010802,null);tC=new $CLJS.N(null,"colinc","colinc",-584873385);FC=new $CLJS.N(null,"up-arrow","up-arrow",1705310333);yD=new $CLJS.N(null,"right-bracket","right-bracket",951856080);Pda=new $CLJS.N(null,"radix","radix",857016463);zD=new $CLJS.N(null,"first","first",-644103046);vda=new $CLJS.N(null,"sections","sections",-886710106);$CLJS.zB=new $CLJS.N(null,"start","start",-355208981);Qda=new $CLJS.w("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Rda=new $CLJS.w(null,"defn-","defn-",1097765044,null);Sda=new $CLJS.w("cljs.core","deref","cljs.core/deref",1901963335,null);VB=new $CLJS.N(null,"block","block",664686210);Tda=new $CLJS.w(null,"when","when",1064114221,null);iD=new $CLJS.N(null,"else-params","else-params",-832171646);$CLJS.VD=new $CLJS.N(null,"count","count",2139924085);$CLJS.wD=new $CLJS.N(null,"right","right",-452581833);UB=new $CLJS.N(null,"linear","linear",872268697);Uda=new $CLJS.w(null,"when-not","when-not",-1223136340,null);
yC=new $CLJS.N(null,"padchar","padchar",2018584530);sC=new $CLJS.N(null,"mincol","mincol",1230695445);Vda=new $CLJS.N(null,"not-delivered","not-delivered",1599158697);cB=new $CLJS.N(null,"miser-width","miser-width",-1310049437);Wda=new $CLJS.w(null,"with-local-vars","with-local-vars",837642072,null);Cda=new $CLJS.w(null,"lift-ns","lift-ns",602311926,null);BD=new $CLJS.N(null,"allows-separator","allows-separator",-818967742);mB=new $CLJS.N(null,"buffering","buffering",-876713613);
WD=new $CLJS.N(null,"arg1","arg1",951899358);QC=new $CLJS.N(null,"base-args","base-args",-1268706822);XD=new $CLJS.N(null,"arg3","arg3",-1486822496);YD=new $CLJS.N(null,"arg2","arg2",1729550917);zC=new $CLJS.N(null,"commainterval","commainterval",-1980061083);Xda=new $CLJS.N(null,"right-margin","right-margin",-810413306);tB=new $CLJS.N(null,"buffer-blob","buffer-blob",-1830112173);Yda=new $CLJS.w(null,"with-open","with-open",172119667,null);
Bda=new $CLJS.w("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Zda=new $CLJS.N(null,"lines","lines",-700165781);XB=new $CLJS.N(null,"indent-t","indent-t",528318969);hD=new $CLJS.N(null,"right-params","right-params",-1790676237);$B=new $CLJS.N(null,"colon","colon",-965200945);$da=new $CLJS.w(null,"if-not","if-not",-265415609,null);RC=new $CLJS.N(null,"func","func",-238706040);$CLJS.ZD=new $CLJS.N(null,"last","last",1105735132);ED=new $CLJS.N(null,"deref","deref",-145586795);
aea=new $CLJS.w(null,"dotimes","dotimes",-818708397,null);gD=new $CLJS.N(null,"max-iterations","max-iterations",2021275563);bea=new $CLJS.w(null,"cond","cond",1606708055,null);rC=new $CLJS.N(null,"minpad","minpad",323570901);fB=new $CLJS.N(null,"logical-block","logical-block",-581022564);cea=new $CLJS.w(null,"struct-map","struct-map",-1387540878,null);GB=new $CLJS.N(null,"end-block-t","end-block-t",1544648735);$D=new $CLJS.N(null,"stream","stream",1534941648);
PC=new $CLJS.N(null,"params","params",710516235);dea=new $CLJS.N(null,"circle","circle",1903212362);ZA=new $CLJS.N(null,"start-pos","start-pos",668789086);qD=new $CLJS.N(null,"flags","flags",1775418075);KB=new $CLJS.N(null,"fill","fill",883462889);uda=new $CLJS.N(null,"buffer-block","buffer-block",-10937307);tD=new $CLJS.N(null,"generator-fn","generator-fn",811851656);dB=new $CLJS.N(null,"start-col","start-col",668080143);TC=new $CLJS.N(null,"w","w",354169001);$CLJS.bD=new $CLJS.N(null,"n","n",562130025);
vD=new $CLJS.N(null,"def","def",-1043430536);$C=new $CLJS.N(null,"e","e",1381269198);UC=new $CLJS.N(null,"d","d",1972142424);VC=new $CLJS.N(null,"overflowchar","overflowchar",-1620088106);jD=new $CLJS.N(null,"min-remaining","min-remaining",962687677);AC=new $CLJS.N(null,"commachar","commachar",652859327);aE=new $CLJS.N(null,"section","section",-300141526);bE=new $CLJS.N(null,"pretty","pretty",-1916372486);eea=new $CLJS.w(null,"let","let",358118826,null);
$CLJS.eD=new $CLJS.N(null,"clauses","clauses",1454841241);jea=new $CLJS.w(null,"defonce","defonce",-1681484013,null);uA=new $CLJS.N(null,"base","base",185279322);OA=new $CLJS.N(null,"type-tag","type-tag",-1873863267);kea=new $CLJS.w(null,"locking","locking",1542862874,null);nB=new $CLJS.N(null,"pos","pos",-864607220);lea=new $CLJS.N(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.tA=new $CLJS.N(null,"cur","cur",1153190599);var CC=function CC(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return CC.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)},PB,BB,gE,hE,iE,wC,vC,SB,NB,jE;CC.l=function(a){return $CLJS.Qc($CLJS.FA,$CLJS.Q.h($CLJS.HC,a))};CC.v=0;CC.B=function(a){return this.l($CLJS.A(a))};var EA=function EA(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return EA.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};
EA.l=function(a){return $CLJS.Qc($CLJS.FA,$CLJS.Q.h($CLJS.gj,a))};EA.v=0;EA.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=vA.prototype;$CLJS.h.N=function(a,b){return new vA(this.Ka,this.mg,this.Bd,b)};$CLJS.h.M=function(){return this.gi};$CLJS.h.jc=function(){return this.Bd};$CLJS.h.Mc=function(){return yA(this.Ka)};
$CLJS.h.ld=function(a,b){a=$CLJS.Nb(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?NA(this,$CLJS.tA,$CLJS.MA(this,$CLJS.tA)+$CLJS.E(b)):(NA(this,$CLJS.tA,$CLJS.E(b)-a-1),NA(this,$CLJS.cl,$CLJS.MA(this,$CLJS.cl)+$CLJS.E($CLJS.Ag(function(c){return $CLJS.F.h(c,"\n")},b)))),$CLJS.Qc($CLJS.MA(this,uA),b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return Bca(this,b);throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));
};$CLJS.h=RA.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "parent":return this.parent;case "section":return this.Jb;case "start-col":return this.Bb;case "indent":return this.yb;case "done-nl":return this.Db;case "intra-block-nl":return this.Eb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ib;case "suffix":return this.Kb;case "logical-block-callback":return this.Fb;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Nv,this.parent],null),new $CLJS.S(null,2,5,$CLJS.T,[aE,this.Jb],null),new $CLJS.S(null,2,5,$CLJS.T,[dB,this.Bb],null),new $CLJS.S(null,2,5,$CLJS.T,[iB,this.yb],null),new $CLJS.S(null,2,5,$CLJS.T,[$A,this.Db],null),new $CLJS.S(null,2,5,$CLJS.T,[kB,this.Eb],null),new $CLJS.S(null,2,5,
$CLJS.T,[UD,this.prefix],null),new $CLJS.S(null,2,5,$CLJS.T,[hB,this.Ib],null),new $CLJS.S(null,2,5,$CLJS.T,[EB,this.Kb],null),new $CLJS.S(null,2,5,$CLJS.T,[xB,this.Fb],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,10,new $CLJS.S(null,10,5,$CLJS.T,[$CLJS.Nv,aE,dB,iB,$A,kB,UD,hB,EB,xB],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 10+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1977012399^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.parent,b.parent)&&$CLJS.F.h(this.Jb,b.Jb)&&$CLJS.F.h(this.Bb,b.Bb)&&$CLJS.F.h(this.yb,b.yb)&&$CLJS.F.h(this.Db,b.Db)&&$CLJS.F.h(this.Eb,b.Eb)&&$CLJS.F.h(this.prefix,b.prefix)&&$CLJS.F.h(this.Ib,b.Ib)&&$CLJS.F.h(this.Kb,b.Kb)&&$CLJS.F.h(this.Fb,b.Fb)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,10,[EB,null,iB,null,$CLJS.Nv,null,aE,null,$A,null,dB,null,UD,null,hB,null,xB,null,kB,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,this.Eb,this.prefix,this.Ib,this.Kb,this.Fb,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Nv,b):$CLJS.O.call(null,$CLJS.Nv,b))?new RA(c,this.Jb,this.Bb,this.yb,this.Db,this.Eb,this.prefix,this.Ib,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(aE,b):$CLJS.O.call(null,aE,b))?new RA(this.parent,c,this.Bb,this.yb,this.Db,this.Eb,this.prefix,this.Ib,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(dB,b):$CLJS.O.call(null,dB,b))?new RA(this.parent,this.Jb,c,this.yb,this.Db,this.Eb,this.prefix,
this.Ib,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(iB,b):$CLJS.O.call(null,iB,b))?new RA(this.parent,this.Jb,this.Bb,c,this.Db,this.Eb,this.prefix,this.Ib,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($A,b):$CLJS.O.call(null,$A,b))?new RA(this.parent,this.Jb,this.Bb,this.yb,c,this.Eb,this.prefix,this.Ib,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(kB,b):$CLJS.O.call(null,kB,b))?new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,c,this.prefix,
this.Ib,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(UD,b):$CLJS.O.call(null,UD,b))?new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,this.Eb,c,this.Ib,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(hB,b):$CLJS.O.call(null,hB,b))?new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,this.Eb,this.prefix,c,this.Kb,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(EB,b):$CLJS.O.call(null,EB,b))?new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,this.Eb,this.prefix,
this.Ib,c,this.Fb,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(xB,b):$CLJS.O.call(null,xB,b))?new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,this.Eb,this.prefix,this.Ib,this.Kb,c,this.D,this.m,null):new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,this.Eb,this.prefix,this.Ib,this.Kb,this.Fb,this.D,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.Ke($CLJS.Nv,this.parent),new $CLJS.Ke(aE,this.Jb),new $CLJS.Ke(dB,this.Bb),new $CLJS.Ke(iB,this.yb),new $CLJS.Ke($A,this.Db),new $CLJS.Ke(kB,this.Eb),new $CLJS.Ke(UD,this.prefix),new $CLJS.Ke(hB,this.Ib),new $CLJS.Ke(EB,this.Kb),new $CLJS.Ke(xB,this.Fb)],null),this.m))};$CLJS.h.N=function(a,b){return new RA(this.parent,this.Jb,this.Bb,this.yb,this.Db,this.Eb,this.prefix,this.Ib,this.Kb,this.Fb,b,this.m,this.G)};
$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h=TA.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ia;case "data":return this.data;case "trailing-white-space":return this.tc;case "start-pos":return this.ha;case "end-pos":return this.ga;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[OA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hl,this.data],null),new $CLJS.S(null,2,5,$CLJS.T,[QA,this.tc],null),new $CLJS.S(null,2,5,$CLJS.T,[ZA,this.ha],null),new $CLJS.S(null,2,5,$CLJS.T,[YA,this.ga],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,5,new $CLJS.S(null,5,5,$CLJS.T,[OA,$CLJS.hl,QA,ZA,YA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 5+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1809113693^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.data,b.data)&&$CLJS.F.h(this.tc,b.tc)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.ga,b.ga)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,5,[YA,null,QA,null,OA,null,ZA,null,$CLJS.hl,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new TA(this.ia,this.data,this.tc,this.ha,this.ga,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(OA,b):$CLJS.O.call(null,OA,b))?new TA(c,this.data,this.tc,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.hl,b):$CLJS.O.call(null,$CLJS.hl,b))?new TA(this.ia,c,this.tc,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(QA,b):$CLJS.O.call(null,QA,b))?new TA(this.ia,this.data,c,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(ZA,b):$CLJS.O.call(null,ZA,b))?new TA(this.ia,this.data,this.tc,
c,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(YA,b):$CLJS.O.call(null,YA,b))?new TA(this.ia,this.data,this.tc,this.ha,c,this.D,this.m,null):new TA(this.ia,this.data,this.tc,this.ha,this.ga,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.Ke(OA,this.ia),new $CLJS.Ke($CLJS.hl,this.data),new $CLJS.Ke(QA,this.tc),new $CLJS.Ke(ZA,this.ha),new $CLJS.Ke(YA,this.ga)],null),this.m))};
$CLJS.h.N=function(a,b){return new TA(this.ia,this.data,this.tc,this.ha,this.ga,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h=UA.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ia;case "type":return this.type;case "logical-block":return this.la;case "start-pos":return this.ha;case "end-pos":return this.ga;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[OA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl,this.type],null),new $CLJS.S(null,2,5,$CLJS.T,[fB,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[ZA,this.ha],null),new $CLJS.S(null,2,5,$CLJS.T,[YA,this.ga],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,5,new $CLJS.S(null,5,5,$CLJS.T,[OA,$CLJS.bl,fB,ZA,YA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 5+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1640656800^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.type,b.type)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.ga,b.ga)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,5,[YA,null,$CLJS.bl,null,fB,null,OA,null,ZA,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new UA(this.ia,this.type,this.la,this.ha,this.ga,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(OA,b):$CLJS.O.call(null,OA,b))?new UA(c,this.type,this.la,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.bl,b):$CLJS.O.call(null,$CLJS.bl,b))?new UA(this.ia,c,this.la,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(fB,b):$CLJS.O.call(null,fB,b))?new UA(this.ia,this.type,c,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(ZA,b):$CLJS.O.call(null,ZA,b))?new UA(this.ia,this.type,this.la,
c,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(YA,b):$CLJS.O.call(null,YA,b))?new UA(this.ia,this.type,this.la,this.ha,c,this.D,this.m,null):new UA(this.ia,this.type,this.la,this.ha,this.ga,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.Ke(OA,this.ia),new $CLJS.Ke($CLJS.bl,this.type),new $CLJS.Ke(fB,this.la),new $CLJS.Ke(ZA,this.ha),new $CLJS.Ke(YA,this.ga)],null),this.m))};
$CLJS.h.N=function(a,b){return new UA(this.ia,this.type,this.la,this.ha,this.ga,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h=VA.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ia;case "logical-block":return this.la;case "start-pos":return this.ha;case "end-pos":return this.ga;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[OA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[fB,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[ZA,this.ha],null),new $CLJS.S(null,2,5,$CLJS.T,[YA,this.ga],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[OA,fB,ZA,YA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-414877272^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.ga,b.ga)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,4,[YA,null,fB,null,OA,null,ZA,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new VA(this.ia,this.la,this.ha,this.ga,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(OA,b):$CLJS.O.call(null,OA,b))?new VA(c,this.la,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(fB,b):$CLJS.O.call(null,fB,b))?new VA(this.ia,c,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(ZA,b):$CLJS.O.call(null,ZA,b))?new VA(this.ia,this.la,c,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(YA,b):$CLJS.O.call(null,YA,b))?new VA(this.ia,this.la,this.ha,c,this.D,this.m,null):new VA(this.ia,this.la,
this.ha,this.ga,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Ke(OA,this.ia),new $CLJS.Ke(fB,this.la),new $CLJS.Ke(ZA,this.ha),new $CLJS.Ke(YA,this.ga)],null),this.m))};$CLJS.h.N=function(a,b){return new VA(this.ia,this.la,this.ha,this.ga,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h=WA.prototype;
$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ia;case "logical-block":return this.la;case "start-pos":return this.ha;case "end-pos":return this.ga;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[OA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[fB,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[ZA,this.ha],null),new $CLJS.S(null,2,5,$CLJS.T,[YA,this.ga],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[OA,fB,ZA,YA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1365867980^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.ga,b.ga)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,4,[YA,null,fB,null,OA,null,ZA,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new WA(this.ia,this.la,this.ha,this.ga,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(OA,b):$CLJS.O.call(null,OA,b))?new WA(c,this.la,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(fB,b):$CLJS.O.call(null,fB,b))?new WA(this.ia,c,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(ZA,b):$CLJS.O.call(null,ZA,b))?new WA(this.ia,this.la,c,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(YA,b):$CLJS.O.call(null,YA,b))?new WA(this.ia,this.la,this.ha,c,this.D,this.m,null):new WA(this.ia,this.la,
this.ha,this.ga,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Ke(OA,this.ia),new $CLJS.Ke(fB,this.la),new $CLJS.Ke(ZA,this.ha),new $CLJS.Ke(YA,this.ga)],null),this.m))};$CLJS.h.N=function(a,b){return new WA(this.ia,this.la,this.ha,this.ga,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h=XA.prototype;
$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ia;case "logical-block":return this.la;case "relative-to":return this.hc;case "offset":return this.offset;case "start-pos":return this.ha;case "end-pos":return this.ga;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[OA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[fB,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[FD,this.hc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Fv,this.offset],null),new $CLJS.S(null,2,5,$CLJS.T,[ZA,this.ha],null),new $CLJS.S(null,2,5,$CLJS.T,[YA,this.ga],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,6,new $CLJS.S(null,6,5,$CLJS.T,[OA,fB,FD,$CLJS.Fv,ZA,YA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 6+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1602780238^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.hc,b.hc)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.ga,b.ga)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,6,[$CLJS.Fv,null,YA,null,FD,null,fB,null,OA,null,ZA,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new XA(this.ia,this.la,this.hc,this.offset,this.ha,this.ga,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(OA,b):$CLJS.O.call(null,OA,b))?new XA(c,this.la,this.hc,this.offset,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(fB,b):$CLJS.O.call(null,fB,b))?new XA(this.ia,c,this.hc,this.offset,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(FD,b):$CLJS.O.call(null,FD,b))?new XA(this.ia,this.la,c,this.offset,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Fv,b):$CLJS.O.call(null,$CLJS.Fv,b))?new XA(this.ia,
this.la,this.hc,c,this.ha,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(ZA,b):$CLJS.O.call(null,ZA,b))?new XA(this.ia,this.la,this.hc,this.offset,c,this.ga,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(YA,b):$CLJS.O.call(null,YA,b))?new XA(this.ia,this.la,this.hc,this.offset,this.ha,c,this.D,this.m,null):new XA(this.ia,this.la,this.hc,this.offset,this.ha,this.ga,this.D,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.Ke(OA,this.ia),new $CLJS.Ke(fB,this.la),new $CLJS.Ke(FD,this.hc),new $CLJS.Ke($CLJS.Fv,this.offset),new $CLJS.Ke(ZA,this.ha),new $CLJS.Ke(YA,this.ga)],null),this.m))};$CLJS.h.N=function(a,b){return new XA(this.ia,this.la,this.hc,this.offset,this.ha,this.ga,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};
var aB=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("cljs.pprint","write-token"),function(f,g){return OA.g(g)},$CLJS.Uj,e,a,b,c,d)}();
aB.o(null,CB,function(a,b){var c=xB.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g($CLJS.zB):c.call(null,$CLJS.zB));b=fB.g(b);c=UD.g(b);$CLJS.m(c)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),c);a=$CLJS.MA(uA.g($CLJS.r($CLJS.r(a))),$CLJS.tA);$CLJS.mg(dB.g(b),a);return $CLJS.mg(iB.g(b),a)});aB.o(null,GB,function(a,b){var c=xB.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g(FB):c.call(null,FB));b=EB.g(fB.g(b));return $CLJS.m(b)?$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),b):null});
aB.o(null,XB,function(a,b){var c=fB.g(b),d=iB.g(c),e=$CLJS.Fv.g(b);b=FD.g(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(VB,b):$CLJS.F.call(null,VB,b)))a=$CLJS.r(dB.g(c));else if($CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.WB,b):$CLJS.F.call(null,$CLJS.WB,b)))a=$CLJS.MA(uA.g($CLJS.r($CLJS.r(a))),$CLJS.tA);else throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));return $CLJS.mg(d,e+a)});aB.o(null,tB,function(a,b){return $CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),$CLJS.hl.g(b))});
aB.o(null,PA,function(a,b){var c=$CLJS.F.h($CLJS.bl.g(b),IB);c||(c=(c=!$CLJS.F.h($CLJS.bl.g(b),KB))?$CLJS.r($A.g(fB.g(b))):c);$CLJS.m(c)?oB.h?oB.h(a,b):oB.call(null,a,b):(b=QA.g($CLJS.r($CLJS.r(a))),$CLJS.m(b)&&$CLJS.Qc(uA.g($CLJS.r($CLJS.r(a))),b));return $CLJS.jj.F($CLJS.r(a),$CLJS.U,QA,null)});
var fE=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("cljs.pprint","emit-nl?"),function(f){return $CLJS.bl.g(f)},$CLJS.Uj,e,a,b,c,d)}();fE.o(null,UB,function(a,b,c){a=fB.g(a);return gB(b,a,c)});fE.o(null,JB,function(a,b,c){a=fB.g(a);return jB(b,a,c)});
fE.o(null,KB,function(a,b,c,d){a=fB.g(a);var e=$CLJS.r(kB.g(a));return $CLJS.m(e)?e:(d=!eB(b,d))?d:jB(b,a,c)});fE.o(null,IB,function(){return!0});
var pB=function pB(a,b){var d=Eca(b);b=$CLJS.J(d,0,null);var e=$CLJS.J(d,1,null);$CLJS.m(b)&&bB(a,b,!1);if($CLJS.m(e)){d=Cca(e);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=$CLJS.C(e),g=fE.F(f,a,b,Dca(e));$CLJS.m(g)&&(oB(a,f),e=$CLJS.D(e));eB(a,e)?a=e:(e=pB.h?pB.h(a,b):pB.call(null,a,b),$CLJS.F.h(e,b)?(bB(a,b,!1),a=d):a=$CLJS.uh.h($CLJS.Rg,$CLJS.wg.h(e,d)));return a}return null};$CLJS.h=vB.prototype;$CLJS.h.N=function(a,b){return new vB(this.Ka,this.mg,this.Pi,this.Ph,this.Bd,b)};$CLJS.h.M=function(){return this.hi};
$CLJS.h.jc=function(){return this.Bd};
$CLJS.h.ld=function(a,b){a=$CLJS.Nb(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a))){var c=yB(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.jk.g($CLJS.r($CLJS.r(this)));if($CLJS.F.h(d,uB))return sB(this),$CLJS.Qc(uA.g($CLJS.r($CLJS.r(this))),b),$CLJS.jj.F($CLJS.r(this),$CLJS.U,QA,a);d=nB.g($CLJS.r($CLJS.r(this)));c=d+$CLJS.E(c);$CLJS.jj.F($CLJS.r(this),$CLJS.U,nB,c);return rB(this,new TA(tB,b,a,d,c,null,null,null))}if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,
a):$CLJS.F.call(null,Number,a)))return $CLJS.F.h($CLJS.jk.g($CLJS.r($CLJS.r(this))),uB)?(sB(this),b=$CLJS.Qc(uA.g($CLJS.r($CLJS.r(this))),b)):$CLJS.F.h(b,"\n")?b=yB(this,"\n"):(a=nB.g($CLJS.r($CLJS.r(this))),c=a+1,$CLJS.jj.F($CLJS.r(this),$CLJS.U,nB,c),b=$CLJS.mv(b),b=rB(this,new TA(tB,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};$CLJS.h.Mc=function(){this.Vf(null);return yA(uA.g($CLJS.r($CLJS.r(this))))};
$CLJS.h.Vf=function(){return $CLJS.F.h($CLJS.jk.g($CLJS.r($CLJS.r(this))),mB)?(bB(this,$CLJS.pv.g($CLJS.r($CLJS.r(this))),!0),$CLJS.jj.F($CLJS.r(this),$CLJS.U,$CLJS.pv,$CLJS.Rg)):sB(this)};$CLJS.OB=!0;PB=null;$CLJS.AB=72;BB=40;gE=null;hE=null;iE=null;wC=null;vC=10;SB=0;NB=null;jE=function jE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return jE.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
jE.l=function(a,b){var c=$CLJS.pn.l($CLJS.G([new $CLJS.k(null,1,[$D,!0],null),$CLJS.Q.h(TD,b)]));b=vC;var d=hE,e=$CLJS.xb,f=$CLJS.Mi,g=gE,l=BB,n=PB,q=$CLJS.OB,u=wC,v=$CLJS.tb,x=$CLJS.AB,z=iE,B=uA.h(c,vC),H=dea.h(c,hE),I=$CLJS.MD.h(c,$CLJS.xb),R=$CLJS.Kv.h(c,$CLJS.Mi),X=Zda.h(c,gE),ba=cB.h(c,BB),ha=$CLJS.Ck.h(c,PB),oa=bE.h(c,$CLJS.OB),Fa=Pda.h(c,wC),gb=$CLJS.sb.h(c,$CLJS.tb),Gb=Xda.h(c,$CLJS.AB),Sb=lea.h(c,iE);vC=B;hE=H;$CLJS.xb=I;$CLJS.Mi=R;gE=X;BB=ba;PB=ha;$CLJS.OB=oa;wC=Fa;$CLJS.tb=gb;$CLJS.AB=
Gb;iE=Sb;try{var Yb=new $CLJS.qb,oc=$CLJS.Je(c,$D)?$D.g(c):!0,Db=!0===oc||null==oc?new $CLJS.gd(Yb):oc;if($CLJS.m($CLJS.OB)){var Tb=$CLJS.Fb($CLJS.MB(Db));c=$CLJS.FA;$CLJS.FA=Tb?$CLJS.DB(Db):Db;try{$CLJS.QB(a),$CLJS.LA()}finally{$CLJS.FA=c}}else{Tb=$CLJS.FA;$CLJS.FA=Db;try{EA.call(null,a)}finally{$CLJS.FA=Tb}}!0===oc&&$CLJS.BA($CLJS.p.g(Yb));return null==oc?$CLJS.p.g(Yb):null}finally{iE=z,$CLJS.AB=x,$CLJS.tb=v,wC=u,$CLJS.OB=q,PB=n,BB=l,gE=g,$CLJS.Mi=f,$CLJS.xb=e,hE=d,vC=b}};jE.v=1;
jE.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var hC=null;$CLJS.h=jC.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "seq":return this.Qc;case "rest":return this.ub;case "pos":return this.rc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[ZB,this.Qc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,this.ub],null),new $CLJS.S(null,2,5,$CLJS.T,[nB,this.rc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,3,new $CLJS.S(null,3,5,$CLJS.T,[ZB,$CLJS.Dk,nB],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};
$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 3+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-402038447^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Qc,b.Qc)&&$CLJS.F.h(this.ub,b.ub)&&$CLJS.F.h(this.rc,b.rc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,3,[nB,null,ZB,null,$CLJS.Dk,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new jC(this.Qc,this.ub,this.rc,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(ZB,b):$CLJS.O.call(null,ZB,b))?new jC(c,this.ub,this.rc,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Dk,b):$CLJS.O.call(null,$CLJS.Dk,b))?new jC(this.Qc,c,this.rc,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(nB,b):$CLJS.O.call(null,nB,b))?new jC(this.Qc,this.ub,c,this.D,this.m,null):new jC(this.Qc,this.ub,this.rc,this.D,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.Ke(ZB,this.Qc),new $CLJS.Ke($CLJS.Dk,this.ub),new $CLJS.Ke(nB,this.rc)],null),this.m))};$CLJS.h.N=function(a,b){return new jC(this.Qc,this.ub,this.rc,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};$CLJS.h=pC.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "func":return this.xc;case "def":return this.vc;case "params":return this.Ec;case "offset":return this.offset;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[RC,this.xc],null),new $CLJS.S(null,2,5,$CLJS.T,[vD,this.vc],null),new $CLJS.S(null,2,5,$CLJS.T,[PC,this.Ec],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Fv,this.offset],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.jh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[RC,vD,PC,$CLJS.Fv],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-829256337^$CLJS.Fd(this)};
$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.xc,b.xc)&&$CLJS.F.h(this.vc,b.vc)&&$CLJS.F.h(this.Ec,b.Ec)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,4,[$CLJS.Fv,null,RC,null,PC,null,vD,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new pC(this.xc,this.vc,this.Ec,this.offset,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Je(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(RC,b):$CLJS.O.call(null,RC,b))?new pC(c,this.vc,this.Ec,this.offset,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(vD,b):$CLJS.O.call(null,vD,b))?new pC(this.xc,c,this.Ec,this.offset,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(PC,b):$CLJS.O.call(null,PC,b))?new pC(this.xc,this.vc,c,this.offset,this.D,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Fv,b):$CLJS.O.call(null,$CLJS.Fv,b))?new pC(this.xc,this.vc,this.Ec,c,this.D,this.m,null):
new pC(this.xc,this.vc,this.Ec,this.offset,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Ke(RC,this.xc),new $CLJS.Ke(vD,this.vc),new $CLJS.Ke(PC,this.Ec),new $CLJS.Ke($CLJS.Fv,this.offset)],null),this.m))};$CLJS.h.N=function(a,b){return new pC(this.xc,this.vc,this.Ec,this.offset,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};
var Jca=new $CLJS.k(null,3,[2,"#b",8,"#o",16,"#x"],null),JC=new $CLJS.S(null,20,5,$CLJS.T,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),kE=new $CLJS.S(null,20,5,$CLJS.T,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),KC=new $CLJS.S(null,10,5,$CLJS.T,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),fea=new $CLJS.S(null,10,5,$CLJS.T,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),MC=new $CLJS.S(null,22,5,$CLJS.T," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),gea=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,9,5,$CLJS.T,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.S(null,3,5,$CLJS.T,["M","MM","MMM"],null)],null),hea=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,9,5,$CLJS.T,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.S(null,3,5,$CLJS.T,["M","MM","MMM"],null)],null),Lca=new $CLJS.k(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);mD.prototype.N=function(a,b){return new mD(this.Ka,b)};mD.prototype.M=function(){return this.ii};mD.prototype.Mc=function(){return yA(this.Ka)};
mD.prototype.ld=function(a,b){a=$CLJS.Nb(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.Ka,b.toLowerCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Qc(this.Ka,$CLJS.mv(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};nD.prototype.N=function(a,b){return new nD(this.Ka,b)};nD.prototype.M=function(){return this.ji};nD.prototype.Mc=function(){return yA(this.Ka)};
nD.prototype.ld=function(a,b){a=$CLJS.Nb(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.Ka,b.toUpperCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Qc(this.Ka,$CLJS.mv(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};oD.prototype.N=function(a,b){return new oD(this.Ka,this.pe,b)};oD.prototype.M=function(){return this.ki};oD.prototype.Mc=function(){return yA(this.Ka)};
oD.prototype.ld=function(a,b){a=$CLJS.Nb(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.Ka,cda(b.toLowerCase(),$CLJS.r(this.pe))),0<b.length?$CLJS.mg(this.pe,$CLJS.Sa($CLJS.Ud(b,$CLJS.E(b)-1))):null;if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=$CLJS.mv(b),a=$CLJS.m($CLJS.r(this.pe))?b.toUpperCase():b,$CLJS.Qc(this.Ka,a),$CLJS.mg(this.pe,$CLJS.Sa(b));throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
pD.prototype.N=function(a,b){return new pD(this.Ka,this.ee,b)};pD.prototype.M=function(){return this.li};pD.prototype.Mc=function(){return yA(this.Ka)};
pD.prototype.ld=function(a,b){a=$CLJS.Nb(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Fb($CLJS.r(this.ee))?(a=RegExp("\\S","g").exec(b),a=$CLJS.m(a)?a.index:a,$CLJS.m(a)?($CLJS.Qc(this.Ka,[b.substring(0,a),$CLJS.Ud(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.mg(this.ee,!0)):$CLJS.Qc(this.Ka,b)):$CLJS.Qc(this.Ka,b.toLowerCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=$CLJS.mv(b),
a=$CLJS.Fb($CLJS.r(this.ee)),$CLJS.m(a?wA(b):a)?($CLJS.mg(this.ee,!0),$CLJS.Qc(this.Ka,b.toUpperCase())):$CLJS.Qc(this.Ka,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
var pda=$CLJS.Yh("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.k(null,5,[rD,"A",PC,new $CLJS.k(null,4,[sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),tC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),rC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){return DC($CLJS.HC,a,b)}}],null),new $CLJS.k(null,
5,[rD,"S",PC,new $CLJS.k(null,4,[sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),tC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),rC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){return DC($CLJS.gj,a,b)}}],null),new $CLJS.k(null,5,[rD,"D",PC,new $CLJS.k(null,4,[sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,
2,5,$CLJS.T,[" ",String],null),AC,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),zC,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){return IC(10,a,b)}}],null),new $CLJS.k(null,5,[rD,"B",PC,new $CLJS.k(null,4,[sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),AC,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),zC,new $CLJS.S(null,
2,5,$CLJS.T,[3,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){return IC(2,a,b)}}],null),new $CLJS.k(null,5,[rD,"O",PC,new $CLJS.k(null,4,[sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),AC,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),zC,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,
null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){return IC(8,a,b)}}],null),new $CLJS.k(null,5,[rD,"X",PC,new $CLJS.k(null,4,[sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),AC,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),zC,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){return IC(16,a,b)}}],null),new $CLJS.k(null,
5,[rD,"R",PC,new $CLJS.k(null,5,[uA,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),AC,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),zC,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(a){return $CLJS.m($CLJS.C(uA.g(a)))?function(b,c){return IC(uA.g(b),b,c)}:$CLJS.m(function(){var b=aC.g(a);return $CLJS.m(b)?
$B.g(a):b}())?function(b,c){return OC(gea,c)}:$CLJS.m(aC.g(a))?function(b,c){return OC(hea,c)}:$CLJS.m($B.g(a))?function(b,c){b=lC(c);c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);if($CLJS.F.h(0,c))CC.l($CLJS.G(["zeroth"]));else{var d=EC(1E3,0>c?-c:c);if($CLJS.E(d)<=$CLJS.E(MC)){var e=$CLJS.xg.h(LC,$CLJS.tv(1,d));e=NC(e,1);var f=$CLJS.ae(d);d=$CLJS.cf(f,100);f=AA(f,100);var g=0<d?[$CLJS.p.g($CLJS.Ud(JC,d))," hundred"].join(""):null,l=$CLJS.p,n=l.g;if(0<f)if(20>f)var q=$CLJS.Ud(kE,f);else{q=$CLJS.cf(f,10);
var u=AA(f,10);q=0<q&&!(0<u)?$CLJS.Ud(fea,q):[$CLJS.p.g(0<q?$CLJS.Ud(KC,q):null),0<q&&0<u?"-":null,$CLJS.p.g(0<u?$CLJS.Ud(kE,u):null)].join("")}else q=0<d?"th":null;d=[g,0<d&&0<f?" ":null,n.call(l,q)].join("");CC.l($CLJS.G([[0>c?"minus ":null,$CLJS.je(e)||$CLJS.je(d)?$CLJS.je(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else IC(10,new $CLJS.k(null,5,[sC,0,yC," ",AC,",",zC,3,$B,!0],null),dC(new $CLJS.S(null,1,5,$CLJS.T,[c],null))),e=AA(c,100),c=11<e||19>e,e=AA(e,10),CC.l($CLJS.G([1===e&&
c?"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=lC(c);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.F.h(0,b))CC.l($CLJS.G(["zero"]));else{var d=EC(1E3,0>b?-b:b);$CLJS.E(d)<=$CLJS.E(MC)?(d=$CLJS.xg.h(LC,d),d=NC(d,0),CC.l($CLJS.G([[0>b?"minus ":null,d].join("")]))):IC(10,new $CLJS.k(null,5,[sC,0,yC," ",AC,",",zC,3,$B,!0],null),dC(new $CLJS.S(null,1,5,$CLJS.T,[b],null)))}return c}}],null),new $CLJS.k(null,5,[rD,"P",PC,$CLJS.P,qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,
null,sD,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){b=$CLJS.m($B.g(a))?nC(b,-1):b;a=$CLJS.m(aC.g(a))?new $CLJS.S(null,2,5,$CLJS.T,["y","ies"],null):new $CLJS.S(null,2,5,$CLJS.T,["","s"],null);var c=lC(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);CC.l($CLJS.G([$CLJS.F.h(b,1)?$CLJS.C(a):$CLJS.$d(a)]));return c}}],null),new $CLJS.k(null,5,[rD,"C",PC,new $CLJS.k(null,1,[BC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,
sD,null],null),null),uD,$CLJS.P,tD,function(a){return $CLJS.m($B.g(a))?Mca:$CLJS.m(aC.g(a))?Nca:Oca}],null),new $CLJS.k(null,5,[rD,"F",PC,new $CLJS.k(null,5,[TC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),UC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.Lv,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),VC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,1,[aC,null],null),null),uD,$CLJS.P,tD,
function(){return cD}],null),new $CLJS.k(null,5,[rD,"E",PC,new $CLJS.k(null,7,[TC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),UC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$C,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.Lv,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),VC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),aD,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,1,[aC,null],
null),null),uD,$CLJS.P,tD,function(){return fD}],null),new $CLJS.k(null,5,[rD,"G",PC,new $CLJS.k(null,7,[TC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),UC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$C,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.Lv,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),VC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),aD,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,
1,[aC,null],null),null),uD,$CLJS.P,tD,function(){return Qca}],null),new $CLJS.k(null,5,[rD,"$",PC,new $CLJS.k(null,4,[UC,new $CLJS.S(null,2,5,$CLJS.T,[2,Number],null),$CLJS.bD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),TC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(){return Rca}],null),new $CLJS.k(null,5,[rD,"%",PC,new $CLJS.k(null,1,[$CLJS.VD,
new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),qD,$CLJS.ui,uD,$CLJS.P,tD,function(){return function(a,b){a=$CLJS.VD.g(a);for(var c=0;;)if(c<a)GA(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[rD,"\x26",PC,new $CLJS.k(null,1,[$CLJS.VD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,1,[bE,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){a=$CLJS.VD.g(a);0<a&&((null!=$CLJS.FA?$CLJS.FA.C&32768||$CLJS.t===$CLJS.FA.Lf||($CLJS.FA.C?
0:$CLJS.Mb($CLJS.xA,$CLJS.FA)):$CLJS.Mb($CLJS.xA,$CLJS.FA))?$CLJS.F.h(0,$CLJS.MA(uA.g($CLJS.r($CLJS.r($CLJS.FA))),$CLJS.tA))||GA():GA());--a;for(var c=0;;)if(c<a)GA(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[rD,"|",PC,new $CLJS.k(null,1,[$CLJS.VD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),qD,$CLJS.ui,uD,$CLJS.P,tD,function(){return function(a,b){a=$CLJS.VD.g(a);for(var c=0;;)if(c<a)CC.l($CLJS.G(["\f"])),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[rD,"~",PC,new $CLJS.k(null,
1,[$CLJS.bD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),qD,$CLJS.ui,uD,$CLJS.P,tD,function(){return function(a,b){a=$CLJS.bD.g(a);CC.l($CLJS.G([$CLJS.Q.h($CLJS.p,$CLJS.tg(a,"~"))]));return b}}],null),new $CLJS.k(null,5,[rD,"\n",PC,$CLJS.P,qD,new $CLJS.ti(null,new $CLJS.k(null,2,[$B,null,aC,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){$CLJS.m(aC.g(a))&&GA();return b}}],null),new $CLJS.k(null,5,[rD,"T",PC,new $CLJS.k(null,2,[LD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),
tC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,2,[aC,null,bE,null],null),null),uD,$CLJS.P,tD,function(a){return $CLJS.m(aC.g(a))?function(b,c){var d=LD.g(b);b=tC.g(b);var e=d+$CLJS.MA(uA.g($CLJS.r($CLJS.r($CLJS.FA))),$CLJS.tA);e=0<b?AA(e,b):0;d+=$CLJS.F.h(0,e)?0:b-e;CC.l($CLJS.G([$CLJS.Q.h($CLJS.p,$CLJS.tg(d," "))]));return c}:function(b,c){var d=LD.g(b);b=tC.g(b);var e=$CLJS.MA(uA.g($CLJS.r($CLJS.r($CLJS.FA))),$CLJS.tA);d=e<d?d-e:$CLJS.F.h(b,0)?0:b-
AA(e-d,b);CC.l($CLJS.G([$CLJS.Q.h($CLJS.p,$CLJS.tg(d," "))]));return c}}],null),new $CLJS.k(null,5,[rD,"*",PC,new $CLJS.k(null,1,[$CLJS.bD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,2,[$B,null,aC,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){var c=$CLJS.bD.g(a);return $CLJS.m(aC.g(a))?oC(b,c):nC(b,$CLJS.m($B.g(a))?-c:c)}}],null),new $CLJS.k(null,5,[rD,"?",PC,$CLJS.P,qD,new $CLJS.ti(null,new $CLJS.k(null,1,[aC,null],null),null),uD,$CLJS.P,
tD,function(a){return $CLJS.m(aC.g(a))?function(b,c){var d=mC(c);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return WC(c,d,QC.g(b))}:function(b,c){var d=mC(c);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var e=lC(d);d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);d=dC(d);WC(c,d,QC.g(b));return e}}],null),new $CLJS.k(null,5,[rD,"(",PC,$CLJS.P,qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,new $CLJS.k(null,3,[$CLJS.wD,")",BD,null,$CLJS.Cv,null],null),tD,function(a){var b=$CLJS.m(function(){var c=
aC.g(a);return $CLJS.m(c)?$B.g(a):c}())?bda:$CLJS.m($B.g(a))?dda:$CLJS.m(aC.g(a))?eda:ada;return function(c,d){a:{var e=$CLJS.C($CLJS.eD.g(c)),f=$CLJS.FA;$CLJS.FA=b.g?b.g($CLJS.FA):b.call(null,$CLJS.FA);try{var g=WC(e,d,QC.g(c));break a}finally{$CLJS.FA=f}g=void 0}return g}}],null),new $CLJS.k(null,5,[rD,")",PC,$CLJS.P,qD,$CLJS.ui,uD,$CLJS.P,tD,function(){return null}],null),new $CLJS.k(null,5,[rD,"[",PC,new $CLJS.k(null,1,[dD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),qD,new $CLJS.ti(null,
new $CLJS.k(null,2,[$B,null,aC,null],null),null),uD,new $CLJS.k(null,3,[$CLJS.wD,"]",BD,!0,$CLJS.Cv,$CLJS.ZD],null),tD,function(a){return $CLJS.m($B.g(a))?Tca:$CLJS.m(aC.g(a))?Uca:Sca}],null),new $CLJS.k(null,5,[rD,";",PC,new $CLJS.k(null,2,[jD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),kD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,1,[$B,null],null),null),uD,new $CLJS.k(null,1,[$CLJS.Jv,!0],null),tD,function(){return null}],null),new $CLJS.k(null,
5,[rD,"]",PC,$CLJS.P,qD,$CLJS.ui,uD,$CLJS.P,tD,function(){return null}],null),new $CLJS.k(null,5,[rD,"{",PC,new $CLJS.k(null,1,[gD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,new $CLJS.k(null,2,[$CLJS.wD,"}",BD,!1],null),tD,function(a){var b=aC.g(a);b=$CLJS.m(b)?$B.g(a):b;return $CLJS.m(b)?Yca:$CLJS.m($B.g(a))?Wca:$CLJS.m(aC.g(a))?Xca:Vca}],null),new $CLJS.k(null,5,[rD,"}",PC,$CLJS.P,qD,new $CLJS.ti(null,
new $CLJS.k(null,1,[$B,null],null),null),uD,$CLJS.P,tD,function(){return null}],null),new $CLJS.k(null,5,[rD,"\x3c",PC,new $CLJS.k(null,4,[sC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),tC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),rC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),yC,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,4,[$B,null,aC,null,sD,null,bE,null],null),null),uD,new $CLJS.k(null,3,[$CLJS.wD,"\x3e",BD,!0,$CLJS.Cv,zD],null),tD,function(){return $ca}],
null),new $CLJS.k(null,5,[rD,"\x3e",PC,$CLJS.P,qD,new $CLJS.ti(null,new $CLJS.k(null,1,[$B,null],null),null),uD,$CLJS.P,tD,function(){return null}],null),new $CLJS.k(null,5,[rD,"^",PC,new $CLJS.k(null,3,[WD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),YD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),XD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,1,[$B,null],null),null),uD,$CLJS.P,tD,function(){return function(a,b){var c=WD.g(a),d=YD.g(a),e=
XD.g(a),f=$CLJS.m($B.g(a))?GC:FC;return $CLJS.m($CLJS.m(c)?$CLJS.m(d)?e:d:c)?c<=d&&d<=e?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:$CLJS.m($CLJS.m(c)?d:c)?$CLJS.F.h(c,d)?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:$CLJS.m(c)?$CLJS.F.h(c,0)?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:($CLJS.m($B.g(a))?$CLJS.je($CLJS.Dk.g(QC.g(a))):$CLJS.je($CLJS.Dk.g(b)))?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b}}],null),new $CLJS.k(null,5,[rD,"W",PC,$CLJS.P,qD,new $CLJS.ti(null,new $CLJS.k(null,4,[$B,null,aC,null,
sD,null,bE,null],null),null),uD,$CLJS.P,tD,function(a){if($CLJS.m(function(){var c=aC.g(a);return $CLJS.m(c)?c:$B.g(a)}())){var b=$CLJS.wg.h($CLJS.m(aC.g(a))?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Kv,null,$CLJS.MD,null],null):$CLJS.Rg,$CLJS.m($B.g(a))?new $CLJS.S(null,2,5,$CLJS.T,[bE,!0],null):$CLJS.Rg);return function(c,d){d=lC(d);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.m($CLJS.Q.j(jE,c,b))?new $CLJS.S(null,2,5,$CLJS.T,[FC,d],null):d}}return function(c,d){d=lC(d);c=$CLJS.J(d,0,null);d=
$CLJS.J(d,1,null);return $CLJS.m($CLJS.QB(c))?new $CLJS.S(null,2,5,$CLJS.T,[FC,d],null):d}}],null),new $CLJS.k(null,5,[rD,"_",PC,$CLJS.P,qD,new $CLJS.ti(null,new $CLJS.k(null,3,[$B,null,aC,null,sD,null],null),null),uD,$CLJS.P,tD,function(){return gda}],null),new $CLJS.k(null,5,[rD,"I",PC,new $CLJS.k(null,1,[$CLJS.bD,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null)],null),qD,new $CLJS.ti(null,new $CLJS.k(null,1,[$B,null],null),null),uD,$CLJS.P,tD,function(){return fda}],null)]),hda=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
nda=new $CLJS.ti(null,new $CLJS.k(null,2,[qC,null,kC,null],null),null),kda=new $CLJS.k(null,2,[":",$B,"@",aC],null),eC=function eC(a){for(;;){if($CLJS.je(a))return!1;var c=bE.g(qD.g(vD.g($CLJS.C(a))));$CLJS.m(c)||(c=$CLJS.ag(eC,$CLJS.C($CLJS.eD.g(PC.g($CLJS.C(a))))),c=$CLJS.m(c)?c:$CLJS.ag(eC,$CLJS.C($CLJS.Cv.g(PC.g($CLJS.C(a))))));if($CLJS.m(c))return!0;a=$CLJS.D(a)}},PD=$CLJS.kj(cC),GD=new $CLJS.k(null,6,[$CLJS.Ll,"'",$CLJS.Pl,"#'",$CLJS.Laa,"@",$CLJS.Maa,"~",Sda,"@",Qda,"~"],null);
(function(){var a=PD("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=dC(d);return fC(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()})();
var lE=function(){var a=PD("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=dC(d);return fC(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),mE=new $CLJS.k(null,2,["core$future_call","Future","core$promise","Promise"],null),iea=function(){var a=PD("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=dC(d);return fC(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),nE,uba=$CLJS.ij.g($CLJS.P),mea=$CLJS.ij.g($CLJS.P),nea=$CLJS.ij.g($CLJS.P),pea=$CLJS.ij.g($CLJS.P),qea=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));
nE=new $CLJS.Aj($CLJS.$i.h("cljs.pprint","simple-dispatch"),ND,$CLJS.Uj,qea,uba,mea,nea,pea);CA(nE,$CLJS.ul,function(a){if($CLJS.Fb(HD(a)))if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var b=SB,c=NB;SB+=1;NB=0;try{HB("(",")");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Fb($CLJS.xb)||d<$CLJS.xb){if(e&&($CLJS.QB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.FA," ");YB(UB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.FA,"...");break}LB()}finally{NB=c,SB=b}}return null});CA(nE,$CLJS.Fl,ID);
CA(nE,$CLJS.dm,JD);CA(nE,$CLJS.Nk,lE);CA(nE,null,function(){return $CLJS.Qc($CLJS.FA,$CLJS.gj.l($CLJS.G([null])))});CA(nE,$CLJS.Uj,KD);PB=nE;
var oE=function(){var a=PD("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=dC(d);return fC(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),pE=function(){var a=PD("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=dC(d);return fC(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),qE=$CLJS.P,oea=function(a){return $CLJS.uh.h($CLJS.P,$CLJS.yg($CLJS.$e,$CLJS.G([function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n);q=new $CLJS.S(null,
2,5,$CLJS.T,[q,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$i.g($CLJS.Ai($CLJS.C(q))),$CLJS.$d(q)],null)],null);l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}l=$CLJS.C(e);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$i.g($CLJS.Ai($CLJS.C(l))),$CLJS.$d(l)],null)],null),d($CLJS.Ad(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.uh.h($CLJS.P,$CLJS.xg.h(function(b){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,
1,null);var e=$CLJS.sf(c);e=$CLJS.m(e)?e:$CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,24,[$CLJS.Ok,"null",$CLJS.fl,"null",$CLJS.nm,"null",$CLJS.sm,"null",$CLJS.al,"null",$CLJS.Hl,"null",$CLJS.Cl,"null",$CLJS.Il,"null",$CLJS.ak,"null",$CLJS.Sl,"null",$CLJS.vl,"null",$CLJS.jl,"null",$CLJS.Zl,"null",$CLJS.qm,"null",$CLJS.Fk,"null",$CLJS.Ak,"null",$CLJS.Yk,"null",$CLJS.nl,"null",$CLJS.Pk,"null",$CLJS.Pl,"null",$CLJS.Ll,"null",$CLJS.fm,"null",$CLJS.xk,"null",$CLJS.cm,"null"],null),null),c);return $CLJS.Fb(e)?
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$i.h("clojure.core",$CLJS.Ai(c)),d],null):b},a))}($CLJS.Yh([$CLJS.Pk,$CLJS.Ak,Ida,$CLJS.Sl,Nda,Fda,Hda,Oda,Lda,Kda,Mda,$da,Uda,$CLJS.cm,Tda,Yda,Wda,jea,Dda,$CLJS.jl,aea,bea,eea,$CLJS.Ml,Rda,kea,Eda,Gda,Jda,cea],[oE,function(a){var b=$CLJS.$d(a),c=$CLJS.C($CLJS.Ad($CLJS.Ad(a)));if($CLJS.qe(b)){a=qE;qE=$CLJS.F.h(1,$CLJS.E(b))?$CLJS.Tf([$CLJS.C(b),"%"]):$CLJS.uh.h($CLJS.P,$CLJS.xg.j(function(d,e){return new $CLJS.S(null,2,5,$CLJS.T,[d,["%",$CLJS.p.g(e)].join("")],
null)},b,$CLJS.Mj(1,$CLJS.E(b)+1)));try{return function(){var d=PD("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(g){var l=null;if(0<arguments.length){l=0;for(var n=Array(arguments.length-0);l<n.length;)n[l]=arguments[l+0],++l;l=new $CLJS.y(n,0,null)}return f.call(this,l)}function f(g){g=dC(g);return fC(d,g)}e.v=0;e.B=function(g){g=$CLJS.A(g);return f(g)};e.l=f;return e}()}()(c)}finally{qE=a}}else return QD(a)},SD,pE,function(a){if(3<$CLJS.E(a)){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,
"#");else{var b=SB,c=NB;SB+=1;NB=0;try{HB("(",")");bC(VB,1);$CLJS.Q.h(function(){var l=PD("~w ~@_~w ~@_~w ~_");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return q.call(this,v)}function q(u){u=dC(u);return fC(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}(),a);for(var d=0,e=$CLJS.A($CLJS.qg(3,a));;){if($CLJS.Fb($CLJS.xb)||d<$CLJS.xb){if(e){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,
"#");else{a=SB;var f=NB;SB+=1;NB=0;try{HB(null,null),$CLJS.QB($CLJS.C(e)),$CLJS.D(e)&&($CLJS.Qc($CLJS.FA," "),YB(JB),$CLJS.QB($CLJS.$d(e))),LB()}finally{NB=f,SB=a}}if($CLJS.D($CLJS.Ad(e))){$CLJS.Qc($CLJS.FA," ");YB(UB);a=d+1;var g=$CLJS.D($CLJS.Ad(e));d=a;e=g;continue}}}else $CLJS.Qc($CLJS.FA,"...");break}LB()}finally{NB=c,SB=b}}return null}return QD(a)},oE,RD,RD,SD,oE,SD,pE,pE,oE,pE,SD,SD,oE,SD,function(a){if($CLJS.D(a)){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=
"string"===typeof $CLJS.C(c)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(c),$CLJS.D(c)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,c],null);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.oe($CLJS.C(d))?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(d),$CLJS.D(d)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{d=SB;var g=NB;SB+=1;NB=0;try{HB("(",")");(function(){var n=PD("~w ~1I~@_~w");return function(){function q(v){var x=
null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.y(z,0,null)}return u.call(this,x)}function u(v){v=dC(v);return fC(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};q.l=u;return q}()})()(a,b);$CLJS.m($CLJS.m(c)?c:$CLJS.m(e)?e:$CLJS.A(f))&&function(){var n=PD("~@:_");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.y(z,0,null)}return u.call(this,
x)}function u(v){v=dC(v);return fC(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};q.l=u;return q}()}()();$CLJS.m(c)&&gC(!0,'"~a"~:[~;~:@_~]',$CLJS.G([c,$CLJS.m(e)?e:$CLJS.A(f)]));$CLJS.m(e)&&function(){var n=PD("~w~:[~;~:@_~]");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.y(z,0,null)}return u.call(this,x)}function u(v){v=dC(v);return fC(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};
q.l=u;return q}()}()(e,$CLJS.A(f));for(a=f;;){wda($CLJS.C(a));var l=$CLJS.D(a);if(l)b=l,YB(UB),a=b;else break}LB()}finally{NB=g,SB=d}}return null}return $CLJS.QB(a)},SD,function(a){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{var b=SB,c=NB;SB+=1;NB=0;try{HB("(",")");bC(VB,1);$CLJS.QB($CLJS.C(a));if($CLJS.D(a)){$CLJS.Qc($CLJS.FA," ");YB(UB);for(var d=0,e=$CLJS.D(a);;){if($CLJS.Fb($CLJS.xb)||d<$CLJS.xb){if(e){if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{a=SB;var f=NB;SB+=1;NB=0;try{HB(null,null),$CLJS.QB($CLJS.C(e)),
$CLJS.D(e)&&($CLJS.Qc($CLJS.FA," "),YB(JB),$CLJS.QB($CLJS.$d(e))),LB()}finally{NB=f,SB=a}}if($CLJS.D($CLJS.Ad(e))){$CLJS.Qc($CLJS.FA," ");YB(UB);a=d+1;var g=$CLJS.D($CLJS.Ad(e));d=a;e=g;continue}}}else $CLJS.Qc($CLJS.FA,"...");break}}LB()}finally{NB=c,SB=b}}return null},SD,RD,RD,oE,oE,SD,SD,oE]))),rE,rea=$CLJS.ij.g($CLJS.P),sea=$CLJS.ij.g($CLJS.P),tea=$CLJS.ij.g($CLJS.P),vea=$CLJS.ij.g($CLJS.P),wea=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));
rE=new $CLJS.Aj($CLJS.$i.h("cljs.pprint","code-dispatch"),ND,$CLJS.Uj,wea,rea,sea,tea,vea);CA(rE,$CLJS.ul,function(a){if($CLJS.Fb(HD(a))){var b=oea.call(null,$CLJS.C(a));return $CLJS.m(b)?b.g?b.g(a):b.call(null,a):QD(a)}return null});CA(rE,$CLJS.tk,function(a){var b=a.g?a.g(qE):a.call(null,qE);return $CLJS.m(b)?CC.l($CLJS.G([b])):$CLJS.m(iE)?CC.l($CLJS.G([$CLJS.Ai(a)])):EA.call(null,a)});CA(rE,$CLJS.Fl,ID);CA(rE,$CLJS.dm,JD);CA(rE,$CLJS.Nk,lE);CA(rE,CD,iea);
CA(rE,ED,function(a){var b=$CLJS.p,c=b.g,d=$CLJS.Nb(a).name;var e=$CLJS.Ki(/^[^$]+\$[^$]+/,d);e=$CLJS.m(e)?mE.g?mE.g(e):mE.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.m(e)?e:d),"@",$CLJS.p.g($CLJS.Qa(a)),": "].join("");if($CLJS.m(TB()))$CLJS.Qc($CLJS.FA,"#");else{c=SB;d=NB;SB+=1;NB=0;try{HB(b,"\x3e");bC(VB,-(b.length-2));YB(UB);var f=null!=a?a.K&1||$CLJS.t===a.Kj?!0:a.K?!1:$CLJS.Mb(zA,a):$CLJS.Mb(zA,a);var g=f?!$CLJS.kv(a):f;$CLJS.QB(g?Vda:$CLJS.r(a));LB()}finally{NB=d,SB=c}}return null});
CA(rE,null,EA);CA(rE,$CLJS.Uj,KD);PB=nE;