var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Kka,Lka,Mka,Tka,Uka,Vka,Wka;$CLJS.qW=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);Kka=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);Lka=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);
$CLJS.tW=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.vW=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Mka=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
Tka=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);Uka=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Vka=new $CLJS.N("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.wW=new $CLJS.N("type","temporal-bucketing-option","type/temporal-bucketing-option",1870188464);$CLJS.yW=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.zW=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Wka=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var SV=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.sk,$CLJS.Dl,$CLJS.Al,$CLJS.Zj,$CLJS.jn,$CLJS.mk,$CLJS.Be],null),TV=$CLJS.yi(SV),Nka,Pka,bW;$CLJS.pL(Uka,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid date extraction unit"],null)],null),TV));var UV=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.zv,$CLJS.dl,$CLJS.rl,$CLJS.dg,$CLJS.Be],null),VV=$CLJS.yi(UV);
$CLJS.pL(Tka,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid date truncation unit"],null)],null),VV));$CLJS.WV=$CLJS.uh.j($CLJS.Rg,$CLJS.rn.A(),$CLJS.wg.h(UV,SV));Nka=$CLJS.yi($CLJS.WV);$CLJS.pL($CLJS.vW,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid date bucketing unit"],null)],null),Nka));var XV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk,$CLJS.gk],null),YV=$CLJS.yi(XV);
$CLJS.pL(Mka,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid time extraction unit"],null)],null),YV));var ZV=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lt,$CLJS.yn,$CLJS.Mt,$CLJS.iv],null),$V=$CLJS.yi(ZV);$CLJS.pL(Wka,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid time truncation unit"],null)],null),$V));
$CLJS.Oka=$CLJS.uh.j($CLJS.Rg,$CLJS.Um.h($CLJS.Xm.g(new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.yn,null,$CLJS.Lt,null],null),null)),$CLJS.rn.A()),$CLJS.wg.h(ZV,XV));Pka=$CLJS.yi(XV);$CLJS.pL(Lka,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid time bucketing unit"],null)],null),Pka));$CLJS.aW=$CLJS.uh.j($CLJS.Rg,$CLJS.Um.h($CLJS.Xm.g(new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.yn,null,$CLJS.Lt,null],null),null)),$CLJS.rn.A()),$CLJS.wg.l(ZV,UV,$CLJS.G([XV,SV])));
bW=$CLJS.yi($CLJS.aW);$CLJS.pL($CLJS.qW,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid datetime bucketing unit"],null)],null),bW));var Qka=$CLJS.mf.h(bW,$CLJS.Uj);$CLJS.pL($CLJS.yW,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid temporal bucketing unit"],null)],null),Qka));var Rka=$CLJS.lA.h(VV,$V);
$CLJS.pL($CLJS.tW,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid datetime truncation unit"],null)],null),Rka));$CLJS.cW=$CLJS.lA.h(TV,YV);$CLJS.pL(Kka,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid datetime extraction unit"],null)],null),$CLJS.cW));var dW=$CLJS.mf.h(VV,$CLJS.Be);$CLJS.pL($CLJS.zW,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid date interval unit"],null)],null),dW));
$CLJS.pL($CLJS.uW,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid time interval unit"],null)],null),$V));var Ska=$CLJS.lA.h(dW,$V);$CLJS.pL($CLJS.xW,$CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,new $CLJS.k(null,1,[$CLJS.ql,"Valid datetime interval unit"],null)],null),Ska));
$CLJS.pL(Vka,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.wW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bk,$CLJS.yW],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Uj,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.qk],null)],null));