var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var G1,v2,kna,BZ,RZ,qna,SZ,TZ,a_,e_,v_,y_,C_,E_,F_,G_,rna,J_,K_,pna;
G1=function(a,b){a=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rg,a],null)],null);for(var c=$CLJS.Rg;;){var d=$CLJS.he(a);if($CLJS.m(d)){var e=d,f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null),l=e,n=$CLJS.ie(a);a=function(q,u,v,x,z,B,H,I){return function(R){return $CLJS.uh.j(v,$CLJS.xg.g(function(X,ba,ha,oa,Fa){return function(gb){var Gb=$CLJS.J(gb,0,null);gb=$CLJS.J(gb,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mf.h(Fa,Gb),gb],null)}}(q,u,v,x,z,B,H,I)),R)}}(a,c,n,e,f,g,l,d);
$CLJS.m(b.g?b.g(g):b.call(null,g))?(a=n,c=$CLJS.mf.h(c,l)):a=$CLJS.oe(g)?a(g):$CLJS.ne(g)?a($CLJS.jg($CLJS.cn,g)):n}else return c}};v2=function(a,b,c){return $CLJS.qe(c)&&$CLJS.F.h(a,$CLJS.C(c))&&!$CLJS.Je(b,$CLJS.M.h(c,2))};$CLJS.w2=function(a){var b=$CLJS.uh.j($CLJS.ui,$CLJS.xg.g($CLJS.Um.h($CLJS.xM,$CLJS.$d)),$CLJS.jR.g(a));return G1($CLJS.Mm.l(a,$CLJS.pP,$CLJS.G([$CLJS.PZ])),function(c){return v2($CLJS.ZG,b,c)})};
$CLJS.x2=function(a){var b=$CLJS.uh.j($CLJS.ui,$CLJS.xg.g($CLJS.Um.h($CLJS.MH,$CLJS.$d)),$CLJS.hQ.g(a));return G1($CLJS.Mm.l(a,$CLJS.pP,$CLJS.G([$CLJS.PZ])),function(c){return v2($CLJS.hQ,b,c)})};kna=function(a){function b(d){return $CLJS.yg(c,$CLJS.G([$CLJS.pP.g(d)]))}function c(d){return $CLJS.pf($CLJS.RP.g(d),$CLJS.yg(b,$CLJS.G([$CLJS.IZ.g(d)])))}return $CLJS.m($CLJS.QZ.g(a))?$CLJS.gg(!0):$CLJS.yi(b(a))};
BZ=function(a){for(var b=$CLJS.gg(!1),c=$CLJS.A(a),d=$CLJS.C(c),e=$CLJS.D(c),f=b,g=0,l=a;;){var n=f,q=g,u=l,v=$CLJS.A(u),x=$CLJS.C(v),z=$CLJS.D(v),B=x,H=z,I=$CLJS.Wm.h(n,kna(B));f=$CLJS.C($CLJS.A($CLJS.Ag($CLJS.Hb,function(R,X,ba,ha,oa,Fa){return function Yb(Gb,Sb){try{if($CLJS.qe(Sb)&&3===$CLJS.E(Sb))try{var oc=$CLJS.Ud(Sb,0);if($CLJS.O(oc,$CLJS.ZQ))try{var Db=$CLJS.Ud(Sb,1);if($CLJS.m($CLJS.HQ.g(Db)))try{var Tb=$CLJS.Ud(Sb,1);if(null!=Tb?Tb.C&256||$CLJS.t===Tb.Of||(Tb.C?0:$CLJS.Mb($CLJS.kc,Tb)):
$CLJS.Mb($CLJS.kc,Tb))try{var zd=$CLJS.M.j(Tb,$CLJS.HQ,$CLJS.uQ);if($CLJS.m($CLJS.fg(ha)(zd))){var ft=$CLJS.M.h(Tb,$CLJS.HQ);$CLJS.Ud(Sb,2);return new $CLJS.S(null,1,5,$CLJS.T,[["Invalid :field reference in stage ",$CLJS.p.g(Fa),": no join named ",$CLJS.gj.l($CLJS.G([ft]))].join("")],null)}throw $CLJS.Z;}catch(cg){if(cg instanceof Error){var kh=cg;if(kh===$CLJS.Z)throw $CLJS.Z;throw kh;}throw cg;}else throw $CLJS.Z;}catch(cg){if(cg instanceof Error){kh=cg;if(kh===$CLJS.Z)throw $CLJS.Z;throw kh;}throw cg;
}else throw $CLJS.Z;}catch(cg){if(cg instanceof Error){kh=cg;if(kh===$CLJS.Z)throw $CLJS.Z;throw kh;}throw cg;}else throw $CLJS.Z;}catch(cg){if(cg instanceof Error){kh=cg;if(kh===$CLJS.Z)throw $CLJS.Z;throw kh;}throw cg;}else throw $CLJS.Z;}catch(cg){if(cg instanceof Error){kh=cg;if(kh===$CLJS.Z)return $CLJS.CZ(Yb,Gb,Sb);throw kh;}throw cg;}}}(f,g,l,I,n,q,u,v,x,z,B,H,b,0,a,a,c,d,e,d,e)($CLJS.Rg,$CLJS.Mm.l(B,$CLJS.pP,$CLJS.G([pna]))))));if($CLJS.m(f))return f;if($CLJS.A(H))g=q+1,f=I,l=H;else return null}};
RZ=new $CLJS.N("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);qna=new $CLJS.N("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);SZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);TZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.UZ=new $CLJS.N("mbql.stage","mbql","mbql.stage/mbql",1578747798);
a_=new $CLJS.N("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);e_=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);v_=new $CLJS.N("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);y_=new $CLJS.N("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);
C_=new $CLJS.N("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.D_=new $CLJS.N("mbql.stage","native","mbql.stage/native",359422194);E_=new $CLJS.N("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);F_=new $CLJS.N("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);G_=new $CLJS.N("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);
$CLJS.H_=new $CLJS.N("mbql","query","mbql/query",-1285688662);$CLJS.QZ=new $CLJS.N(null,"source-card","source-card",-1580820390);rna=new $CLJS.N("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.I_=new $CLJS.N(null,"filters","filters",974726919);J_=new $CLJS.N("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);K_=new $CLJS.N("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.L_=new $CLJS.N("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);pna=new $CLJS.N("stage","metadata","stage/metadata",1707239131);$CLJS.pL(v_,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.D_],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oR,$CLJS.Lb],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pl,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tl,$CLJS.Lb],null)],null)],null));$CLJS.pL(RZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tl,new $CLJS.k(null,1,[$CLJS.wk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.pW],null)],null));
$CLJS.pL(F_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tl,new $CLJS.k(null,1,[$CLJS.wk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.pW],null)],null));$CLJS.pL(E_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tl,new $CLJS.k(null,1,[$CLJS.wk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.tM],null)],null));
var VZ=$CLJS.Wm.h(function(a){a=$CLJS.C($CLJS.w2(a));return $CLJS.m(a)?["Invalid :expression reference: no expression named ",$CLJS.gj.l($CLJS.G([$CLJS.rv(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null))]))].join(""):null},function(a){a=$CLJS.C($CLJS.x2(a));return $CLJS.m(a)?["Invalid :aggregation reference: no aggregation with uuid ",$CLJS.p.g($CLJS.rv(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null)))].join(""):null});
$CLJS.pL(G_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,2,[$CLJS.ql,"Valid references for a single query stage",$CLJS.Iv,function(a){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.xl);return VZ(a)}],null),$CLJS.fg(VZ)],null));
$CLJS.pL($CLJS.L_,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.UZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pP,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.GZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jR,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.lM],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.aQ,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),RZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hQ,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.NW],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SO,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),F_],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.I_,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),E_],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tS,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.MZ],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tQ,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.PV],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QZ,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.QV],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Je(a,$CLJS.GR)}],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.fg($CLJS.Vm.h($CLJS.tQ,$CLJS.QZ))],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,G_],null)],null));$CLJS.pL(SZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.L_],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tQ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.PV],null)],null)],null)],null));
$CLJS.pL(TZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.L_],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.QV],null)],null)],null)],null));$CLJS.pL(e_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,SZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,TZ],null)],null));
$CLJS.pL(y_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.L_],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.fg($CLJS.Wm.h($CLJS.tQ,$CLJS.QZ))],null)],null));$CLJS.pL(C_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.im,$CLJS.D_,$CLJS.UZ],null));
$CLJS.pL(qna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,C_],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.xu,new $CLJS.k(null,1,[$CLJS.Ck,$CLJS.Zu],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.D_,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,v_],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.L_],null)],null)],null)],null));
$CLJS.pL(K_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,C_],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.xu,new $CLJS.k(null,1,[$CLJS.Ck,$CLJS.Zu],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.D_,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,v_],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,e_],null)],null)],null)],null));$CLJS.pL(a_,y_);
$CLJS.pL(J_,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,2,[$CLJS.ql,"Valid references for all query stages",$CLJS.Iv,function(a){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.xl);return BZ.g?BZ.g(a):BZ.call(null,a)}],null),$CLJS.fg(BZ)],null));
$CLJS.pL($CLJS.EZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,K_],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.um,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,a_],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,J_],null)],null));
$CLJS.pL(rna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.H_],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gR,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,$CLJS.RV,$CLJS.eW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.EZ],null)],null)],null),$CLJS.Zma],null));