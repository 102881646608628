var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var gla,hla,ila,jla,kla,lla,mla,SW,nla,ola,pla,qla,rla,wla,xla,yla,UW,zla;$CLJS.MW=new $CLJS.N(null,"supported-field","supported-field",-2061545519);gla=new $CLJS.N("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.NW=new $CLJS.N("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);hla=new $CLJS.N("mbql.clause","count-where","mbql.clause/count-where",2010666417);
ila=new $CLJS.N("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.OW=new $CLJS.N("mbql.aggregation","operator","mbql.aggregation/operator",-1481602310);$CLJS.PW=new $CLJS.N(null,"requires-column?","requires-column?",1623131448);$CLJS.QW=new $CLJS.N(null,"column-name","column-name",551523580);jla=new $CLJS.N("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.RW=new $CLJS.N(null,"display-info","display-info",-816930907);
kla=new $CLJS.N("mbql.clause","max","mbql.clause/max",1615385533);lla=new $CLJS.N("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);mla=new $CLJS.N("mbql.clause","median","mbql.clause/median",-1026386338);SW=new $CLJS.N("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);nla=new $CLJS.N("mbql.clause","min","mbql.clause/min",2074448531);ola=new $CLJS.N("mbql.clause","percentile","mbql.clause/percentile",2064253651);
pla=new $CLJS.N("mbql.clause","stddev","mbql.clause/stddev",659381076);qla=new $CLJS.N("mbql.clause","var","mbql.clause/var",809434920);rla=new $CLJS.N("mbql.clause","share","mbql.clause/share",-37921592);wla=new $CLJS.N("mbql.clause","count","mbql.clause/count",-454225910);xla=new $CLJS.N("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.TW=new $CLJS.N(null,"driver-feature","driver-feature",1905324248);yla=new $CLJS.N("mbql.clause","avg","mbql.clause/avg",1826863173);
UW=new $CLJS.N("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);zla=new $CLJS.N("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.GV($CLJS.VD,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.uM],null)],null)],null)],null)]));$CLJS.GV($CLJS.hK,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.uM],null)],null)],null)],null)]));
$CLJS.EV($CLJS.YJ,$CLJS.G([$CLJS.bv,$CLJS.fL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));$CLJS.EV($CLJS.hJ,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.uM],null)],null)]));$CLJS.EV($CLJS.AK,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.tM],null)],null)]));
$CLJS.EV($CLJS.em,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.CM],null)],null)]));$CLJS.tV($CLJS.em,$CLJS.BM);$CLJS.EV($CLJS.QK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));$CLJS.tV($CLJS.QK,$CLJS.BM);$CLJS.EV($CLJS.wk,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.CM],null)],null)]));$CLJS.tV($CLJS.wk,$CLJS.BM);
$CLJS.pL(SW,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Qj,new $CLJS.k(null,1,[$CLJS.ql,"valid percentile"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.EV($CLJS.IJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,SW],null)]));$CLJS.tV($CLJS.IJ,$CLJS.BM);
$CLJS.EV($CLJS.tK,$CLJS.G([$CLJS.bv,$CLJS.fL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.tM],null)],null)]));$CLJS.EV($CLJS.VK,$CLJS.G([$CLJS.bv,$CLJS.fL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));$CLJS.EV($CLJS.OK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));
$CLJS.EV($CLJS.AL,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));$CLJS.tV($CLJS.OK,$CLJS.BM);$CLJS.tV($CLJS.AL,$CLJS.BM);$CLJS.EV($CLJS.xL,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.tM],null)],null)]));$CLJS.tV($CLJS.xL,$CLJS.BM);
$CLJS.EV($CLJS.lJ,$CLJS.G([$CLJS.bv,$CLJS.fL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));$CLJS.pL(UW,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.Hk,yla,wla,lla,hla,gla,kla,mla,nla,ola,rla,pla,zla,xla,ila,qla,$CLJS.Lb],null));$CLJS.pL($CLJS.NW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tl,new $CLJS.k(null,1,[$CLJS.wk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,UW],null)],null));
$CLJS.CW=new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,4,[$CLJS.iW,$CLJS.VD,$CLJS.PW,!1,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Count of rows"),$CLJS.QW,$CLJS.dI("Count"),$CLJS.hI,$CLJS.dI("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.OK,$CLJS.MW,$CLJS.hn,$CLJS.PW,!0,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Sum of ..."),$CLJS.QW,$CLJS.dI("Sum"),$CLJS.hI,$CLJS.dI("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.YJ,$CLJS.MW,$CLJS.hn,$CLJS.PW,!0,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Average of ..."),$CLJS.QW,$CLJS.dI("Average"),$CLJS.hI,$CLJS.dI("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.QK,$CLJS.MW,$CLJS.hn,$CLJS.PW,!0,$CLJS.TW,$CLJS.$J,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Median of ..."),$CLJS.QW,$CLJS.dI("Median"),$CLJS.hI,$CLJS.dI("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.hJ,$CLJS.MW,$CLJS.Du,$CLJS.PW,!0,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Number of distinct values of ..."),$CLJS.QW,$CLJS.dI("Distinct values"),$CLJS.hI,$CLJS.dI("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.AL,$CLJS.MW,$CLJS.hn,$CLJS.PW,!0,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Cumulative sum of ..."),
$CLJS.QW,$CLJS.dI("Sum"),$CLJS.hI,$CLJS.dI("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.iW,$CLJS.hK,$CLJS.PW,!1,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Cumulative count of rows"),$CLJS.QW,$CLJS.dI("Count"),$CLJS.hI,$CLJS.dI("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.VK,$CLJS.MW,$CLJS.hn,$CLJS.PW,
!0,$CLJS.TW,$CLJS.GK,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Standard deviation of ..."),$CLJS.QW,$CLJS.dI("SD"),$CLJS.hI,$CLJS.dI("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.wk,$CLJS.MW,$CLJS.Lk,$CLJS.PW,!0,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Minimum of ..."),$CLJS.QW,$CLJS.dI("Min"),$CLJS.hI,$CLJS.dI("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.iW,$CLJS.em,$CLJS.MW,$CLJS.Lk,$CLJS.PW,!0,$CLJS.TW,$CLJS.BI,$CLJS.RW,function(){return new $CLJS.k(null,3,[$CLJS.oJ,$CLJS.dI("Maximum of ..."),$CLJS.QW,$CLJS.dI("Max"),$CLJS.hI,$CLJS.dI("Maximum value of a column")],null)}],null)],null);
$CLJS.pL(jla,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.OW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iW,$CLJS.uh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.im],null),$CLJS.xg.g($CLJS.iW),$CLJS.CW)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MW,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.Kk],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PW,$CLJS.qk],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.TW,$CLJS.Kk],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.RW,$CLJS.ee],null)],null));