var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var hL,iL,jL,kL,Wfa,lL,mL,nL;hL=function(a,b){this.db=a;this.ei=b;this.C=393216;this.K=0};iL=function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return!($CLJS.oe(a)&&!0===$CLJS.Wj.g(a))};jL=function(a,b,c,d,e,f,g,l,n,q,u,v,x,z,B,H,I,R,X){this.form=a;this.options=b;this.Xe=c;this.tf=d;this.forms=e;this.Y=f;this.Ee=g;this.X=l;this.children=n;this.min=q;this.Lb=u;this.Zb=v;this.parent=x;this.type=z;this.cache=B;this.max=H;this.Rj=I;this.Cf=R;this.Mi=X;this.C=393216;this.K=0};
kL=function(a){for(var b=null;;){var c=$CLJS.fs(a,b);a=c;if(!$CLJS.Cp(c))return a}};Wfa=function(a,b,c){var d=kL($CLJS.Zp.h(a,c));return $CLJS.es($CLJS.dp(d),$CLJS.ap(d),function(){var e=$CLJS.cp(d);return b.g?b.g(e):b.call(null,e)}(),function(){var e=$CLJS.bs(d);return $CLJS.m(e)?e:c}())};
lL=function(a){return function(b,c,d){var e=$CLJS.A(c);b=$CLJS.C(e);e=$CLJS.D(e);c=$CLJS.Ym.h(function(f){return $CLJS.Zp.h(f,d)},c);return new $CLJS.S(null,3,5,$CLJS.T,[c,$CLJS.Ym.h($CLJS.Yu,c),$CLJS.Rb(function(f,g){return a.j?a.j(f,g,d):a.call(null,f,g,d)},b,e)],null)}};mL=function(a,b,c,d,e,f,g,l,n){this.Cf=a;this.Xe=b;this.type=c;this.min=d;this.max=e;this.Ee=f;this.Lb=g;this.Zb=l;this.tf=n;this.C=393216;this.K=0};
nL=function(a){var b=$CLJS.Uf(a),c=$CLJS.M.h(b,$CLJS.bl),d=$CLJS.M.h(b,$CLJS.wk),e=$CLJS.M.h(b,$CLJS.em),f=$CLJS.M.h(b,$CLJS.Rv),g=$CLJS.M.h(b,$CLJS.Zq),l=$CLJS.M.h(b,$CLJS.ru);return new mL(a,b,c,d,e,f,g,l,new $CLJS.k(null,1,[$CLJS.bl,$CLJS.cr],null))};$CLJS.pL=function(a,b){$CLJS.jj.F(oL,$CLJS.U,a,b);return null};$CLJS.h=hL.prototype;$CLJS.h.N=function(a,b){return new hL(this.db,b)};$CLJS.h.M=function(){return this.ei};$CLJS.h.ae=$CLJS.t;
$CLJS.h.Jd=function(a,b){return $CLJS.Zn($CLJS.fo($CLJS.r(this.db)),b)};$CLJS.h.Kd=function(){return $CLJS.ao($CLJS.fo($CLJS.r(this.db)))};$CLJS.h=jL.prototype;$CLJS.h.N=function(a,b){return new jL(this.form,this.options,this.Xe,this.tf,this.forms,this.Y,this.Ee,this.X,this.children,this.min,this.Lb,this.Zb,this.parent,this.type,this.cache,this.max,this.Rj,this.Cf,b)};$CLJS.h.M=function(){return this.Mi};$CLJS.h.eb=$CLJS.t;$CLJS.h.Ja=function(){return $CLJS.Yo(this.X)};$CLJS.h.Sa=function(){return this.options};
$CLJS.h.$a=function(a,b,c,d){return $CLJS.Eq(this,new $CLJS.S(null,1,5,$CLJS.T,[this.X],null),b,c,d)};$CLJS.h.gb=function(){return this.Y};$CLJS.h.ya=function(){return this.children};$CLJS.h.Za=function(){return $CLJS.r(this.form)};$CLJS.h.Ya=function(a,b){return $CLJS.op(this.X,b)};$CLJS.h.fb=function(){return this.parent};$CLJS.h.bb=$CLJS.t;$CLJS.h.qa=function(){return this.cache};$CLJS.h.pa=function(a,b,c){return $CLJS.M.j(this.children,b,c)};$CLJS.h.Pe=$CLJS.t;$CLJS.h.rd=function(){return null};
$CLJS.h.re=function(){return this.X};var qL=function qL(a){switch(arguments.length){case 2:return qL.h(arguments[0],arguments[1]);case 3:return qL.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};qL.h=function(a,b){return qL.j(a,b,null)};qL.j=function(a,b,c){var d=$CLJS.yi(b);return Wfa(a,function(e){return $CLJS.Ag(function(f){f=$CLJS.J(f,0,null);return d.g?d.g(f):d.call(null,f)},e)},c)};qL.v=3;
var rL=function rL(a){switch(arguments.length){case 2:return rL.h(arguments[0],arguments[1]);case 3:return rL.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};rL.h=function(a,b){return rL.j(a,b,null)};
rL.j=function(a,b,c){function d(v,x){return $CLJS.F.h($CLJS.dm,v)?new $CLJS.S(null,2,5,$CLJS.T,[null,x],null):$CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.vs.g(x)],null),$CLJS.ws.g(x))}function e(v,x){return $CLJS.m($CLJS.m(v)?x:v)?$CLJS.pn.l($CLJS.G([v,x])):$CLJS.m(v)?v:x}a=$CLJS.m(a)?kL($CLJS.Zp.h(a,c)):null;b=$CLJS.m(b)?kL($CLJS.Zp.h(b,c)):null;var f=$CLJS.m(a)?$CLJS.Hp.g(a):null,g=$CLJS.m(b)?$CLJS.Hp.g(b):null,l=$CLJS.Uf(c),n=$CLJS.M.j(l,$CLJS.Pv,function(v,x){return x}),q=$CLJS.M.j(l,$CLJS.Qv,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Fb(function(){var v=new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.Qj,null,$CLJS.dm,null],null),null);v=v.g?v.g(f):v.call(null,f);return $CLJS.m(v)?(v=new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.Qj,null,$CLJS.dm,null],null),null),v.g?v.g(g):v.call(null,g)):v}()))return n.j?n.j(a,b,c):n.call(null,a,b,c);if($CLJS.F.h(f,$CLJS.dm)&&$CLJS.F.h(g,$CLJS.dm)){l=e($CLJS.ap(a),$CLJS.ap(b));var u=$CLJS.ij.g($CLJS.ui);a=$CLJS.Rb(function(v,
x){var z=$CLJS.J(x,0,null);if($CLJS.m(function(){var B=$CLJS.r(u);return B.g?B.g(z):B.call(null,z)}()))return $CLJS.Rb(function(B,H){var I=$CLJS.J(H,0,null),R=$CLJS.mf.h;if($CLJS.F.h(I,z)){I=$CLJS.J(H,0,null);var X=$CLJS.J(H,1,null),ba=$CLJS.J(H,2,null);$CLJS.J(x,0,null);var ha=$CLJS.J(x,1,null),oa=$CLJS.J(x,2,null);H=iL(H);var Fa=iL(x);H=q.h?q.h(H,Fa):q.call(null,H,Fa);X=$CLJS.pn.l($CLJS.G([X,ha]));oa=new $CLJS.S(null,3,5,$CLJS.T,[I,$CLJS.U.j(X,$CLJS.Wj,$CLJS.Fb(H)),rL.j?rL.j(ba,oa,c):rL.call(null,
ba,oa,c)],null);I=$CLJS.J(oa,0,null);ba=$CLJS.J(oa,1,null);oa=$CLJS.J(oa,2,null);I=$CLJS.Fb(oa)?new $CLJS.S(null,2,5,$CLJS.T,[I,ba],null):$CLJS.m($CLJS.m(ba)?!1===$CLJS.Wj.g(ba)&&$CLJS.F.h(1,$CLJS.E(ba)):ba)?new $CLJS.S(null,2,5,$CLJS.T,[I,oa],null):$CLJS.Fb($CLJS.A(ba))?new $CLJS.S(null,2,5,$CLJS.T,[I,oa],null):!1===$CLJS.Wj.g(ba)?new $CLJS.S(null,3,5,$CLJS.T,[I,$CLJS.Mm.h(ba,$CLJS.Wj),oa],null):new $CLJS.S(null,3,5,$CLJS.T,[I,ba,oa],null)}else I=H;return R.call($CLJS.mf,B,I)},$CLJS.Rg,v);$CLJS.jj.j(u,
$CLJS.mf,z);return $CLJS.mf.h(v,x)},$CLJS.Rg,$CLJS.uh.h($CLJS.cp(a),$CLJS.cp(b)));return $CLJS.es($CLJS.dm,l,a,c)}return function(v,x){var z=$CLJS.A(v);v=$CLJS.C(z);var B=$CLJS.D(z);z=$CLJS.C(B);B=$CLJS.D(B);var H=$CLJS.A(x);x=$CLJS.C(H);var I=$CLJS.D(H);H=$CLJS.C(I);I=$CLJS.D(I);return $CLJS.es($CLJS.Qj,e(v,x),$CLJS.wg.l(new $CLJS.S(null,1,5,$CLJS.T,[rL.j(z,H,c)],null),B,$CLJS.G([I])),c)}(d(f,a),d(g,b))};rL.v=3;
var sL=function sL(a){switch(arguments.length){case 2:return sL.h(arguments[0],arguments[1]);case 3:return sL.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};sL.h=function(a,b){return sL.j(a,b,null)};
sL.j=function(a,b,c){return rL.j(a,b,$CLJS.Zm.j($CLJS.Zm.j(c,$CLJS.Pv,$CLJS.ig($CLJS.$e,function(d,e,f){var g=$CLJS.F.h($CLJS.Yu.h(d,null),$CLJS.Yu.h(e,null));return $CLJS.m(g)?d:$CLJS.Zp.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,d,e],null),f)})),$CLJS.Qv,$CLJS.ig($CLJS.$e,function(d,e){return $CLJS.m(d)?e:d})))};sL.v=3;$CLJS.h=mL.prototype;$CLJS.h.N=function(a,b){return new mL(this.Cf,this.Xe,this.type,this.min,this.max,this.Ee,this.Lb,this.Zb,b)};$CLJS.h.M=function(){return this.tf};$CLJS.h.cb=$CLJS.t;
$CLJS.h.Wa=function(){return this.type};$CLJS.h.Xa=function(){return this.Lb};$CLJS.h.Va=function(a,b,c,d){var e=this;$CLJS.Ap(e.type,b,c,e.min,e.max);a=function(){var u=$CLJS.Sg(c);return e.Zb.j?e.Zb.j(b,u,d):e.Zb.call(null,b,u,d)}();var f=$CLJS.J(a,0,null),g=$CLJS.J(a,1,null),l=$CLJS.J(a,2,null),n=new $CLJS.dj(function(){return $CLJS.eq(e.type,b,g,d)}),q=$CLJS.dq();return new jL(n,d,e.Xe,e.tf,g,b,e.Ee,l,f,e.min,e.Lb,e.Zb,this,e.type,q,e.max,a,e.Cf,new $CLJS.k(null,1,[$CLJS.bl,$CLJS.Lr],null))};
$CLJS.uW=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.xW=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);var oL=$CLJS.ij.g($CLJS.pn.l($CLJS.G([$CLJS.is(),new $CLJS.k(null,3,[$CLJS.Lu,nL(new $CLJS.k(null,2,[$CLJS.bl,$CLJS.Lu,$CLJS.ru,lL(rL)],null)),$CLJS.us,nL(new $CLJS.k(null,2,[$CLJS.bl,$CLJS.us,$CLJS.ru,lL(sL)],null)),$CLJS.Xu,nL(new $CLJS.k(null,5,[$CLJS.bl,$CLJS.Xu,$CLJS.Rv,1,$CLJS.wk,2,$CLJS.em,2,$CLJS.ru,function(a,b,c){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Zm.j(b,0,function(d){return $CLJS.Zp.h(d,c)}),$CLJS.Zm.j(b,0,function(d){return $CLJS.Yu.h(d,c)}),$CLJS.Q.h(qL,$CLJS.mf.h(b,c))],null)}],
null))],null)])));$CLJS.mg($CLJS.Cn,$CLJS.fo(new hL(oL,$CLJS.P)));