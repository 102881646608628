var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.test.check.random.js");require("./clojure.test.check.rose_tree.js");
'use strict';var Ix,Jx,Kx,Lx,Mx,Nx,Ox,Dba,Px,Qx,Eba,Fba,Sx,Ux,Wx,Gba,Yx,Zx,$x,Hba,cy,Iba,Jba,dy,fy,gy,jy,Kba,ky,Lba,oy,py,Mba,Nba,uy,wy,Rba,Tba,Ey,Gy,Hy,Iy,Wba,Rx,hy,ay,Tx,My,by,ly,Xba;$CLJS.Hx=function(a){return a-1};Ix=function(a){if(null!=a&&null!=a.Ie)a=a.Ie(a);else{var b=Ix[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Ix._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Ob("IRandom.rand-long",a);}return a};
Jx=function(a){if(null!=a&&null!=a.Wf)a=a.Wf(a);else{var b=Jx[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Jx._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Ob("IRandom.rand-double",a);}return a};Kx=function(a,b){if(null!=a&&null!=a.Xf)a=a.Xf(a,b);else{var c=Kx[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Kx._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Ob("IRandom.split-n",a);}return a};Lx=function(a){return a.root};
Mx=function(a,b){this.root=a;this.children=b;this.C=16;this.K=0};Nx=function(a,b){return new Mx(a,b)};Ox=function(a,b,c,d,e){this.O=a;this.f=b;this.og=c;this.seed=d;this.next=e;this.C=26083532;this.K=1};Dba=function(a,b){return new Ox(null,a,null,b,null)};
Px=function(a,b,c){return $CLJS.Kd(b)?new $CLJS.jf(null,$CLJS.r(b),null,1,null):$CLJS.pf(b,new $CLJS.Cf(null,function(){var d=$CLJS.A(c);if(d){var e=Px;var f=$CLJS.C(d);f=a.h?a.h(b,f):a.call(null,b,f);d=e(a,f,$CLJS.Ad(d))}else d=null;return d},null,null))};
Qx=function(a){return function d(c){return new $CLJS.Cf(null,function(){for(var e=c;;){var f=$CLJS.A(e);if(f){var g=f,l=$CLJS.C(g),n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);if(f=$CLJS.A(function(u,v,x,z,B,H){return function X(R){return new $CLJS.Cf(null,function(ba,ha,oa,Fa){return function(){for(;;){var gb=$CLJS.A(R);if(gb){if($CLJS.re(gb)){var Gb=$CLJS.$c(gb),Sb=$CLJS.E(Gb),Yb=$CLJS.Ff(Sb);a:for(var oc=0;;)if(oc<Sb){var Db=$CLJS.ce(Gb,oc);Db=$CLJS.U.j(a,Fa,Db);Yb.add(Db);oc+=1}else{Gb=!0;break a}return Gb?
$CLJS.If($CLJS.Kf(Yb),X($CLJS.ad(gb))):$CLJS.If($CLJS.Kf(Yb),null)}Yb=$CLJS.C(gb);return $CLJS.pf($CLJS.U.j(a,Fa,Yb),X($CLJS.Ad(gb)))}return null}}}(u,v,x,z,B,H),null,null)}}(e,l,n,q,g,f)(n.children)))return $CLJS.wg.h(f,d($CLJS.Ad(e)));e=$CLJS.Ad(e)}else return null}},null,null)}($CLJS.xg.j($CLJS.cn,a,$CLJS.Mj(0,Number.MAX_VALUE)))};Eba=function(a){return $CLJS.wg.h($CLJS.jg(function(b){return Rx(b,a)},a),Qx($CLJS.Sg(a)))};
Fba=function(a,b){if(4<=$CLJS.E(b)){var c=$CLJS.cf($CLJS.E(b),2);return new $CLJS.Cf(null,function(){return $CLJS.pf(function(){var d=$CLJS.hm.j(b,0,c);return Sx.h?Sx.h(a,d):Sx.call(null,a,d)}(),new $CLJS.Cf(null,function(){var d=$CLJS.hm.h(b,c);d=Sx.h?Sx.h(a,d):Sx.call(null,a,d);return new $CLJS.jf(null,d,null,1,null)},null,null))},null,null)}return null};Sx=function(a,b){var c=Tx(a,b);return Nx(c.root,$CLJS.wg.h(Fba(a,b),c.children))};
Ux=function(a,b){var c=Sx(a,b);a=Nx(a.A?a.A():a.call(null),$CLJS.Rg);return $CLJS.je(b)?c:Nx(c.root,$CLJS.pf(a,c.children))};$CLJS.Vx=function(a,b,c,d){this.qd=a;this.D=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};Wx=function(a){return new $CLJS.Vx(a,null,null,null)};$CLJS.Xx=function(a,b,c){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.wl);return a.h?a.h(b,c):a.call(null,b,c)};Gba=function(a){return Wx(function(){return a})};
Yx=function(a,b){b=$CLJS.Uf(b);var c=$CLJS.M.h(b,$CLJS.wl);return Wx(function(d,e){d=c.h?c.h(d,e):c.call(null,d,e);return a.g?a.g(d):a.call(null,d)})};Zx=function(a,b){a=$CLJS.Uf(a);var c=$CLJS.M.h(a,$CLJS.wl);return Wx(function(d,e){d=$CLJS.yx(d);var f=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);f=c.h?c.h(f,e):c.call(null,f,e);f=b.g?b.g(f):b.call(null,f);f=$CLJS.Uf(f);f=$CLJS.M.h(f,$CLJS.wl);return f.h?f.h(d,e):f.call(null,d,e)})};
$x=function(a){return Wx(function(b,c){return $CLJS.Ym.j(function(d,e){return $CLJS.Xx(d,e,c)},a,Kx(b,$CLJS.E(a)))})};$CLJS.Yw=function(a,b){return Yx(function(c){return ay(a,c)},b)};$CLJS.bx=function(a){return Gba(Nx(a,$CLJS.Rg))};Hba=function(a){return function(b){return Yx(by,Wx(function(c,d){return ay(function(e){return $CLJS.Xx(a.g?a.g(e):a.call(null,e),c,d)},b)}))}};cy=function(a,b){return Zx(a,Hba(b))};
Iba=function(a){return $CLJS.Bi(function(b){return $CLJS.qi.h(0,b)},Dba(function(b){return $CLJS.cf(b,2)},a))};Jba=function(a){return $CLJS.xg.h(function(b){return a-b},Iba(a))};dy=function(a,b,c){a=Jx(a);return $CLJS.eh(Math.floor(b+(a*(1+c)-a*b)))};$CLJS.ey=function(a){return Wx(function(b,c){var d=a.g?a.g(c):a.call(null,c);return $CLJS.Xx(d,b,c)})};fy=function(a,b){b=$CLJS.Uf(b);var c=$CLJS.M.h(b,$CLJS.wl);return Wx(function(d){return c.h?c.h(d,a):c.call(null,d,a)})};
gy=function(a,b){return $CLJS.ey(function(c){return fy(a.g?a.g(c):a.call(null,c),b)})};jy=function(a,b){return Wx(function(c){c=dy(c,a,b);return hy(function(d){return d>=a&&d<=b},iy(c))})};$CLJS.$w=function(a){return cy(jy(0,$CLJS.E(a)-1),function(b){return $CLJS.Ud(a,b)})};Kba=function(a,b){return $CLJS.E($CLJS.Bi(function(c){return c<=b},$CLJS.Ad(Px($CLJS.Om,0,a))))};
ky=function(a){var b=$CLJS.Ag($CLJS.Um.h($CLJS.ef,$CLJS.C),a),c=$CLJS.Q.h($CLJS.Om,$CLJS.xg.h($CLJS.C,b));return Wx(function(d,e){return $CLJS.Xx(Zx(jy(0,c-1),function(f){var g=Kba($CLJS.xg.h($CLJS.C,b),f.root);return Yx(function(l){return Nx(l.root,new $CLJS.Cf(null,function(){return $CLJS.wg.h(function(){return function u(q){return new $CLJS.Cf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),z=$CLJS.E(x),B=$CLJS.Ff(z);a:for(var H=0;;)if(H<z){var I=$CLJS.ce(x,H);
I=$CLJS.Xx($CLJS.$d($CLJS.Ud(b,I)),d,e);B.add(I);H+=1}else{x=!0;break a}return x?$CLJS.If($CLJS.Kf(B),u($CLJS.ad(v))):$CLJS.If($CLJS.Kf(B),null)}B=$CLJS.C(v);return $CLJS.pf($CLJS.Xx($CLJS.$d($CLJS.Ud(b,B)),d,e),u($CLJS.Ad(v)))}return null}},null,null)}($CLJS.Mj(0,g))}(),l.children)},null,null))},$CLJS.$d($CLJS.Ud(b,g)))}),d,e)})};$CLJS.Zw=function(a){var b=$CLJS.Sg(a);return Yx(function(c){return ay(b,c)},jy(0,$CLJS.E(b)-1))};
Lba=function(a,b,c,d,e){c=$CLJS.Uf(c);var f=$CLJS.M.h(c,ly),g=$CLJS.M.h(c,$CLJS.my);for(c=g;;){if(0===c)throw function(){var q=new $CLJS.k(null,3,[$CLJS.El,a,$CLJS.wl,b,$CLJS.my,g],null);return f.g?f.g(q):f.call(null,q)}();var l=$CLJS.yx(d);d=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=$CLJS.Xx(b,d,e);if($CLJS.m(function(){var q=n.root;return a.g?a.g(q):a.call(null,q)}()))return hy(a,n);d=l;e+=1;--c}};
$CLJS.Uw=function(a){return Zx($CLJS.ey(function(b){return jy(0,b)}),function(b){return Yx(function(c){return Ux($CLJS.Y,c)},$x($CLJS.tg(b.root,a)))})};
oy=function(a,b,c,d,e,f,g,l,n,q){var u=$CLJS.Tc($CLJS.Rg),v=$CLJS.Tc($CLJS.ui),x=e;e=f;for(var z=0;;){if($CLJS.F.h(n,z)&&$CLJS.E(u)<l)throw function(){var I=new $CLJS.k(null,3,[$CLJS.wl,d,$CLJS.my,n,$CLJS.ny,g],null);return q.g?q.g(I):q.call(null,I)}();if($CLJS.F.h(n,z)||$CLJS.F.h($CLJS.E(u),g))return Ux(function(){return function(){function I(X){var ba=null;if(0<arguments.length){ba=0;for(var ha=Array(arguments.length-0);ba<ha.length;)ha[ba]=arguments[ba+0],++ba;ba=new $CLJS.y(ha,0,null)}return R.call(this,
ba)}function R(X){return $CLJS.uh.h(a,X)}I.v=0;I.B=function(X){X=$CLJS.A(X);return R(X)};I.l=R;return I}()}(u,v,x,e,z),function(){var I=x,R=$CLJS.Vc(u);return c.h?c.h(I,R):c.call(null,I,R)}());f=$CLJS.yx(x);var B=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);B=$CLJS.Xx(d,B,e);var H=B.root;H=b.g?b.g(H):b.call(null,H);null!=$CLJS.Fm(v,H)?(e+=1,z+=1,x=f):(z=$CLJS.Uc(u,B),v=$CLJS.Uc(v,H),u=z,x=f,z=0)}};py=function(a,b){var c=$CLJS.je(b);return c?c:$CLJS.Q.h($CLJS.yv,$CLJS.xg.h(a,b))};
Mba=function(a,b){var c=$CLJS.be(b),d=$CLJS.Sg(b);b=$CLJS.E(b);var e=b-1;return $CLJS.uh.h(c,$CLJS.C($CLJS.Rb(function(f,g){var l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);f=$CLJS.yx(f);var n=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);var q=dy(n,g,e);n=$CLJS.T;q=new $CLJS.S(null,2,5,$CLJS.T,[g,q],null);g=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l=$CLJS.U.l(l,q,l.g?l.g(g):l.call(null,g),$CLJS.G([g,l.g?l.g(q):l.call(null,q)]));return new $CLJS.S(null,2,5,n,[l,f],null)},new $CLJS.S(null,2,5,$CLJS.T,[d,a],null),
$CLJS.Mj(0,b))))};
$CLJS.sy=function(a,b,c,d,e,f){f=$CLJS.Uf(f);var g=$CLJS.M.h(f,$CLJS.ny),l=$CLJS.M.h(f,$CLJS.qy),n=$CLJS.M.h(f,$CLJS.ry),q=$CLJS.M.j(f,$CLJS.my,10),u=$CLJS.M.j(f,ly,function(H){return $CLJS.Ej("Couldn't generate enough distinct elements!",H)}),v=$CLJS.m(d)?Mba:function(H,I){return I},x=$CLJS.m(g)?g:$CLJS.m(l)?l:1;if($CLJS.m(g)){var z=function(H){return $CLJS.F.h(g,$CLJS.E(H))};return Wx(function(H,I){return hy($CLJS.m(c)?$CLJS.Vm.h(z,function(R){return py(b,R)}):z,oy(a,b,v,e,H,I,g,x,q,u))})}var B=
$CLJS.m(l)?l:0;z=$CLJS.m(n)?function(H){return B<=$CLJS.E(H)&&$CLJS.E(H)<=n}:function(H){return B<=$CLJS.E(H)};return Zx($CLJS.m(n)?jy(B,n):$CLJS.ey(function(H){return jy(B,B+H)}),function(H){var I=H.root;return Wx(function(R,X){return hy($CLJS.m(c)?$CLJS.Vm.h(z,function(ba){return py(b,ba)}):z,oy(a,b,v,e,R,X,I,x,q,u))})})};
Nba=function(a,b,c,d){for(var e=function(){var g=$CLJS.ka($CLJS.wa(b,64-a));return 0===c?Math.abs(g):g}();;){if(c<=e&&e<=d)return e;var f=-e;if(c<=f&&f<=d)return f;e=function(){var g=e;if(!$CLJS.eg(e)){var l=0>e?$CLJS.lv:$CLJS.Hx;return l.g?l.g(g):l.call(null,g)}return g}()/2}};uy=function(a,b){return $CLJS.ey(function(c){c=1<c?c:1;return Yx(function(d){var e=d.root;d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return iy(Nba(d,e,a,b))},$CLJS.ty.l($CLJS.G([jy(1,54>c?c:54),Oba])))})};
$CLJS.cx=function(a){var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.wk);b=$CLJS.M.h(b,$CLJS.em);var c=$CLJS.m(a)?a:Pba,d=$CLJS.m(b)?b:vy;return $CLJS.ax.h(function(e){return c<=e&&e<=d},0>=c&&0<=d?uy(c,d):0>d?$CLJS.Yw(function(e){return d+e},uy(c-d,0)):$CLJS.Yw(function(e){return c+e},uy(0,d-c)))};wy=function(a){if(0===a)return-1023;var b=Math.abs(a);a=Math.floor(Math.log(b)*Math.LOG2E);b*=Math.pow(2,-a);return 1>b?a-1:2<=b?a+1:a};
Rba=function(a,b){function c(l,n){return $CLJS.ey(function(q){var u=1<<$CLJS.cf(200<q?200:q,8);return 0>=l&&0<=n?jy(function(){var v=-u;return l>v?l:v}(),n<u?n:u):0>n?jy(function(){var v=n-u;return l>v?l:v}(),n):jy(l,function(){var v=l+u;return n<v?n:v}())})}if(null==a&&null==b)return $CLJS.ty.l($CLJS.G([c(-1023,1023),$CLJS.Zw(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))]));var d=$CLJS.m(a)?a:Qba,e=$CLJS.m(b)?b:xy,f=function(){var l=wy(d);return-1023>l?-1023:l}(),g=function(){var l=wy(e);return-1023>
l?-1023:l}();return 0<=d?$CLJS.ty.l($CLJS.G([c(f,g),$CLJS.bx(1)])):0>=e?$CLJS.ty.l($CLJS.G([c(g,f),$CLJS.bx(-1)])):$CLJS.Yw(function(l){var n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);return 0>q&&f<n||0<q&&g<n?new $CLJS.S(null,2,5,$CLJS.T,[n,-q],null):l},$CLJS.ty.l($CLJS.G([c(-1023,g>f?g:f),$CLJS.Zw(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))])))};
Tba=function(a,b){var c=$CLJS.m(a)?$CLJS.m(b)?function(e){return a<=e&&e<=b}:function(e){return a<=e}:$CLJS.m(b)?function(e){return e<=b}:null,d=$CLJS.Yw(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=$CLJS.J(e,1,null)/Math.pow(2,52)+1;var l=e*Math.pow(2,g)*f;var n=(n=null==c)?n:c.g?c.g(l):c.call(null,l);if($CLJS.m(n))return l;f=yy(g,f);g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=$CLJS.m(a)?g>a?g:a:g;f=$CLJS.m(b)?f<b?f:b:f;e=g+(f-g)*(e-1);e=e<f?e:f;return e>g?e:g},$CLJS.ty.l($CLJS.G([Rba(a,
b),Sba])));return $CLJS.m(c)?$CLJS.ax.h(c,d):d};
$CLJS.Dy=function(a){var b=$CLJS.Uf(a),c=$CLJS.M.j(b,$CLJS.zy,!0);a=$CLJS.M.j(b,$CLJS.Ay,!0);var d=$CLJS.M.h(b,$CLJS.wk);b=$CLJS.M.h(b,$CLJS.em);var e=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[95,Tba(d,b)],null)],null);e=(null==d?null==b||0<=b:null==b?0>=d:0>=d&&0<=b)?$CLJS.mf.l(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.bx(0)],null),$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.bx(-0)],null)])):e;b=$CLJS.m($CLJS.m(c)?null==b:c)?$CLJS.mf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.bx(By)],
null)):e;c=$CLJS.m($CLJS.m(c)?null==d:c)?$CLJS.mf.h(b,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.bx(Cy)],null)):b;a=$CLJS.m(a)?$CLJS.mf.h(c,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.bx(Uba)],null)):c;return $CLJS.F.h(1,$CLJS.E(a))?$CLJS.$d($CLJS.C(a)):ky(a)};Ey=function(a){return gy(function(b){return $CLJS.eh(Math.pow(b,.6))},a)};Gy=function(a,b,c){return $CLJS.$w(new $CLJS.S(null,12,5,$CLJS.T,[Fy,$CLJS.jx,a,b,c,Vba,$CLJS.ex,$CLJS.hx,$CLJS.ix,$CLJS.mx,$CLJS.nx,$CLJS.ox],null))};
Hy=function(a){if("number"===typeof a){var b=isNaN(a);a=$CLJS.m(b)?b:$CLJS.F.h(Cy,a)||$CLJS.F.h(By,a);a=$CLJS.Fb(a)}else a=!0;return a};Iy=function(a){return $CLJS.$w(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Xw.g(a),$CLJS.Uw(a),$CLJS.Ww.g($CLJS.ax.h(Hy,a)),gy(function(b){return $CLJS.cf(b,2)},$CLJS.Vw.h($CLJS.ax.h(Hy,a),a))],null))};Wba=function(a){return Wx(function(b,c){var d=$CLJS.yx(b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Xx(a.g?a.g(b):a.call(null,b),d,c)})};
$CLJS.Ky=function(a,b){return $CLJS.ey(function(c){return cy(jy(0,$CLJS.eh(Math.pow(c,1.1))),function(d){return Wba(function(e){e=Jy(d,e);var f=fy(c,b);return $CLJS.Rb(function(g,l){return cy(jy(0,10),function(n){return 0===n?f:fy(l,a.g?a.g(g):a.call(null,g))})},f,e)})})})};
$CLJS.Cx.prototype.Xf=$CLJS.Ma(53,function(a,b){switch(b){case 0:return $CLJS.Rg;case 1:return new $CLJS.S(null,1,5,$CLJS.T,[this],null);default:a=b-1;var c=this.state;for(b=$CLJS.Tc($CLJS.Rg);;){if($CLJS.F.h(a,$CLJS.E(b)))return $CLJS.Vc($CLJS.fh(b,new $CLJS.Cx(this.gamma,c)));var d=this.gamma.add(c);c=this.gamma.add(d);var e=$CLJS.Bx(c);d=new $CLJS.Cx(e,$CLJS.Ax(d));b=$CLJS.Uc(b,d)}}});
$CLJS.Cx.prototype.Wf=$CLJS.Ma(52,function(){var a=this.Ie(null);a=$CLJS.ux(a,11);return $CLJS.rx*(a.Aa>>>0)+$CLJS.vba*a.ra});$CLJS.Cx.prototype.Ie=$CLJS.Ma(51,function(){return $CLJS.Ax(this.state.add(this.gamma))});var Ly={};Mx.prototype.$=function(a,b){if($CLJS.F.h(b,0))return this.root;if($CLJS.F.h(b,1))return this.children;throw Error("Index out of bounds in rose tree");};Mx.prototype.La=function(a,b,c){return $CLJS.F.h(b,0)?this.root:$CLJS.F.h(b,1)?this.children:c};$CLJS.h=Ox.prototype;
$CLJS.h.toString=function(){return $CLJS.hd(this)};$CLJS.h.M=function(){return this.O};$CLJS.h.Ia=function(){return this.Oa(null)};$CLJS.h.xa=function(){return $CLJS.yd};$CLJS.h.kd=function(){return this.seed!==Ly};$CLJS.h.Ma=function(a,b){a=this.Ha(null);var c=this.f.g?this.f.g(a):this.f.call(null,a);for(a=b.h?b.h(a,c):b.call(null,a,c);;){if($CLJS.Kd(a))return $CLJS.r(a);c=this.f.g?this.f.g(c):this.f.call(null,c);a=b.h?b.h(a,c):b.call(null,a,c)}};
$CLJS.h.Na=function(a,b,c){a=this.Ha(null);for(c=b.h?b.h(c,a):b.call(null,c,a);;){if($CLJS.Kd(c))return $CLJS.r(c);a=this.f.g?this.f.g(a):this.f.call(null,a);c=b.h?b.h(c,a):b.call(null,c,a)}};$CLJS.h.Ha=function(){Ly===this.seed&&(this.seed=this.f.g?this.f.g(this.og):this.f.call(null,this.og));return this.seed};$CLJS.h.Oa=function(){null==this.next&&(this.next=new Ox(null,this.f,this.Ha(null),Ly,null));return this.next};$CLJS.h.da=function(){return this};
$CLJS.h.N=function(a,b){return b===this.O?this:new Ox(b,this.f,this.og,this.seed,this.next)};$CLJS.h.ja=function(a,b){return $CLJS.pf(b,this)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,$CLJS.Ti,"("," ",")",c,this)};Rx=function Rx(a,b){return new $CLJS.Cf(null,function(){var d=$CLJS.A(b);if(d)if(0===a)var e=$CLJS.Ad(b);else{e=$CLJS.C(d);var f=a-1;d=$CLJS.Ad(d);f=Rx.h?Rx.h(f,d):Rx.call(null,f,d);e=$CLJS.pf(e,f)}else e=null;return e},null,null)};
hy=function hy(a,b){return Nx(b.root,$CLJS.xg.h(function(d){return hy.h?hy.h(a,d):hy.call(null,a,d)},$CLJS.Ag(function(d){d=d.root;return a.g?a.g(d):a.call(null,d)},b.children)))};ay=function ay(a,b){return Nx(function(){var d=b.root;return a.g?a.g(d):a.call(null,d)}(),$CLJS.xg.h(function(d){return ay.h?ay.h(a,d):ay.call(null,a,d)},b.children))};
Tx=function Tx(a,b){return $CLJS.A(b)?Nx($CLJS.Q.h(a,$CLJS.xg.h(Lx,b)),$CLJS.xg.h(function(d){return Tx.h?Tx.h(a,d):Tx.call(null,a,d)},Eba($CLJS.pg(Infinity,b)))):Nx(a.A?a.A():a.call(null),$CLJS.Rg)};My=function My(a,b){return Nx($CLJS.Q.h(a,$CLJS.xg.h(Lx,b)),$CLJS.xg.h(function(d){return My.h?My.h(a,d):My.call(null,a,d)},Qx(b)))};by=function by(a){var c=a.root,d=c.children;return Nx(c.root,$CLJS.wg.h($CLJS.xg.h(by,a.children),d))};$CLJS.qy=new $CLJS.N(null,"min-elements","min-elements",949370780);
ly=new $CLJS.N(null,"ex-fn","ex-fn",-284925510);Xba=new $CLJS.N(null,"max-tries-or-opts","max-tries-or-opts",-609860571);$CLJS.Ay=new $CLJS.N(null,"NaN?","NaN?",-1917767651);$CLJS.ry=new $CLJS.N(null,"max-elements","max-elements",433034073);$CLJS.ny=new $CLJS.N(null,"num-elements","num-elements",1960422107);$CLJS.my=new $CLJS.N(null,"max-tries","max-tries",-1824441792);$CLJS.zy=new $CLJS.N(null,"infinite?","infinite?",-2017886608);var iy,Yba,Fy,Zba,Oba,vy,Pba,By,Cy,xy,Qba,Uba,Oy,Sba,yy,Py,$ba,Ry,aca,bca,Sy,Ty,Uy,Vba,cca,Vy,Jy;$CLJS.Tw={};$CLJS.h=$CLJS.Vx.prototype;$CLJS.h.ka=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "gen":return this.qd;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.va=function(a,b,c){return $CLJS.Rb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#clojure.test.check.generators.Generator{",", ","}",c,$CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wl,this.qd],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.jh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wl],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Xf())};$CLJS.h.M=function(){return this.D};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=236843149^$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.qd,b.qd)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.za=function(a,b){return $CLJS.Je(new $CLJS.ti(null,new $CLJS.k(null,1,[$CLJS.wl,null],null),null),b)?$CLJS.Mm.h($CLJS.Dc($CLJS.uh.h($CLJS.P,this),this.D),b):new $CLJS.Vx(this.qd,this.D,$CLJS.Vf($CLJS.Mm.h(this.m,b)),null)};
$CLJS.h.ta=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "gen":return!0;default:return $CLJS.Je(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.wl,b):$CLJS.O.call(null,$CLJS.wl,b))?new $CLJS.Vx(c,this.D,this.m,null):new $CLJS.Vx(this.qd,this.D,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.da=function(){return $CLJS.A($CLJS.wg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Ke($CLJS.wl,this.qd)],null),this.m))};
$CLJS.h.N=function(a,b){return new $CLJS.Vx(this.qd,b,this.m,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};iy=function iy(a){return Nx(a,$CLJS.xg.h(iy,Jba(a)))};Yba=new $CLJS.k(null,2,[ly,function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.my);return $CLJS.Ej(["Couldn't satisfy such-that predicate after ",$CLJS.p.g(b)," tries."].join(""),a)},$CLJS.my,10],null);
$CLJS.ax=function ax(a){switch(arguments.length){case 2:return ax.h(arguments[0],arguments[1]);case 3:return ax.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.ax.h=function(a,b){return $CLJS.ax.j(a,b,10)};
$CLJS.ax.j=function(a,b,c){if($CLJS.Ae(c))c=new $CLJS.k(null,1,[$CLJS.my,c],null);else if(!$CLJS.oe(c))throw $CLJS.Ej("Bad argument to such-that!",new $CLJS.k(null,1,[Xba,c],null));var d=$CLJS.pn.l($CLJS.G([Yba,c]));return Wx(function(e,f){return Lba(a,b,d,e,f)})};$CLJS.ax.v=3;$CLJS.ex=$CLJS.Zw(new $CLJS.S(null,2,5,$CLJS.T,[!1,!0],null));$CLJS.ty=function ty(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ty.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};
$CLJS.ty.l=function(a){return Yx(function(b){return My($CLJS.cn,b)},$x(a))};$CLJS.ty.v=0;$CLJS.ty.B=function(a){return this.l($CLJS.A(a))};$CLJS.Ny=$CLJS.ey(function(a){return jy(0,a)});Fy=$CLJS.ey(function(a){return jy(-a,a)});Zba=$CLJS.Yw(function(a){return-1*a},$CLJS.Ny);$CLJS.Yw($CLJS.lv,$CLJS.Ny);$CLJS.Yw($CLJS.Hx,Zba);
$CLJS.Xw=function Xw(a){switch(arguments.length){case 1:return Xw.g(arguments[0]);case 2:return Xw.h(arguments[0],arguments[1]);case 3:return Xw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Xw.g=function(a){return Zx($CLJS.ey(function(b){return jy(0,b)}),function(b){return Yx(function(c){return Ux($CLJS.cn,c)},$x($CLJS.tg(b.root,a)))})};$CLJS.Xw.h=function(a,b){return $CLJS.Q.h($CLJS.ty,$CLJS.tg(b,a))};
$CLJS.Xw.j=function(a,b,c){return Zx(jy(b,c),function(d){return Yx(function(e){return hy(function(f){return $CLJS.E(f)>=b&&$CLJS.E(f)<=c},Ux($CLJS.cn,e))},$x($CLJS.tg(d.root,a)))})};$CLJS.Xw.v=3;$CLJS.Ww=function Ww(a){switch(arguments.length){case 1:return Ww.g(arguments[0]);case 2:return Ww.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ww.g=function(a){return $CLJS.Ww.h(a,$CLJS.P)};
$CLJS.Ww.h=function(a,b){return $CLJS.sy($CLJS.ui,$CLJS.$e,!1,!1,a,b)};$CLJS.Ww.v=2;$CLJS.Vw=function Vw(a){switch(arguments.length){case 2:return Vw.h(arguments[0],arguments[1]);case 3:return Vw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Vw.h=function(a,b){return $CLJS.Vw.j(a,b,$CLJS.P)};$CLJS.Vw.j=function(a,b,c){return $CLJS.sy($CLJS.P,$CLJS.C,!1,!1,$CLJS.ty.l($CLJS.G([a,b])),c)};$CLJS.Vw.v=3;
Oba=Wx(function(a){return Nx(Ix(a),$CLJS.Rg)});vy=$CLJS.Q.h($CLJS.Pm,$CLJS.tg(53,2))-1;Pba=-vy;$CLJS.jx=$CLJS.cx($CLJS.P);By=Number.POSITIVE_INFINITY;Cy=Number.NEGATIVE_INFINITY;xy=Number.MAX_VALUE;Qba=-xy;Uba=Number.NaN;
Oy=function Oy(a){return 32>=a?jy(0,function(){switch($CLJS.eh(a)){case 32:return 4294967295;case 31:return 2147483647;default:return(1<<a)-1}}()):$CLJS.Yw(function(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return 4294967296*d+c},$CLJS.ty.l($CLJS.G([function(){var c=a-32;return Oy.g?Oy.g(c):Oy.call(null,c)}(),Oy.g?Oy.g(32):Oy.call(null,32)])))};
Sba=$CLJS.Yw(function(a){for(var b=0,c=Math.pow(2,52);;){if(1>a)return b*c;var d=a/2;c/=2;b=2*b+(a&1);a=d}},$CLJS.ey(function(a){return Zx(jy(0,52>a?a:52),function(b){return Oy(b.root)})}));
yy=function yy(a,b){return 0>b?(b=-b,b=yy.h?yy.h(a,b):yy.call(null,a,b),a=$CLJS.J(b,0,null),b=$CLJS.J(b,1,null),new $CLJS.S(null,2,5,$CLJS.T,[-b,-a],null)):$CLJS.F.h(-1023,a)?new $CLJS.S(null,2,5,$CLJS.T,[0,(1*Math.pow(2,52)-1)*Math.pow(2,-1074)],null):new $CLJS.S(null,2,5,$CLJS.T,[1*Math.pow(2,a),(1*Math.pow(2,52)-1)*Math.pow(2,a-51)],null)};$CLJS.gx=$CLJS.Dy($CLJS.P);$CLJS.fx=$CLJS.Yw($CLJS.mv,jy(0,255));Py=$CLJS.Yw($CLJS.mv,jy(32,126));
$CLJS.Qy=$CLJS.Yw($CLJS.mv,$CLJS.$w(new $CLJS.S(null,3,5,$CLJS.T,[jy(48,57),jy(65,90),jy(97,122)],null)));$ba=$CLJS.Yw($CLJS.mv,$CLJS.$w(new $CLJS.S(null,2,5,$CLJS.T,[jy(65,90),jy(97,122)],null)));Ry=$CLJS.Zw(new $CLJS.S(null,7,5,$CLJS.T,"*+!-_?.".split(""),null));aca=ky(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[14,$CLJS.Qy],null),new $CLJS.S(null,2,5,$CLJS.T,[7,Ry],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.bx(":")],null)],null));
bca=ky(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[2,$ba],null),new $CLJS.S(null,2,5,$CLJS.T,[1,Ry],null)],null));Sy=$CLJS.Yw($CLJS.Ov,$CLJS.Xw.g($CLJS.fx));Ty=$CLJS.Yw($CLJS.Ov,$CLJS.Xw.g(Py));$CLJS.lx=$CLJS.Yw($CLJS.Ov,$CLJS.Xw.g($CLJS.Qy));
Uy=$CLJS.Yw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);b=$CLJS.Ov.g($CLJS.pf(b,a));return $CLJS.vv($CLJS.vv(b,/:{2,}/,":"),/:$/,"")},$CLJS.ax.h(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);a=$CLJS.J(a,0,null);b=$CLJS.m(a)?(b="+"===b||"-"===b)?!/[^0-9]/.test(a):b:a;return!$CLJS.ye(b)},$CLJS.ty.l($CLJS.G([bca,$CLJS.Xw.g(aca)]))));$CLJS.hx=Ey($CLJS.Yw($CLJS.Ui,Uy));
$CLJS.ix=Ey($CLJS.Yw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ui.h(b,a)},$CLJS.ty.l($CLJS.G([Uy,Uy]))));$CLJS.mx=ky(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[100,Ey($CLJS.Yw($CLJS.$i,Uy))],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.bx($CLJS.Dv)],null)],null));$CLJS.nx=Ey($CLJS.Yw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.$i.h(b,a)},$CLJS.ty.l($CLJS.G([Uy,Uy]))));
Vba=$CLJS.Yw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return b/a},$CLJS.ty.l($CLJS.G([Fy,$CLJS.Yw($CLJS.lv,$CLJS.Ny)])));
$CLJS.ox=function(a){return Yx(function(b){return Nx(b.root,$CLJS.Rg)},a)}($CLJS.Yw(function(a){function b(d){return(a.g?a.g(d):a.call(null,d)).toString(16)}var c=(((a.g?a.g(15):a.call(null,15))&3)+8).toString(16);return $CLJS.hv([$CLJS.p.g(b(0)),$CLJS.p.g(b(1)),$CLJS.p.g(b(2)),$CLJS.p.g(b(3)),$CLJS.p.g(b(4)),$CLJS.p.g(b(5)),$CLJS.p.g(b(6)),$CLJS.p.g(b(7)),"-",$CLJS.p.g(b(8)),$CLJS.p.g(b(9)),$CLJS.p.g(b(10)),$CLJS.p.g(b(11)),"-4",$CLJS.p.g(b(12)),$CLJS.p.g(b(13)),$CLJS.p.g(b(14)),"-",$CLJS.p.g(c),
$CLJS.p.g(b(16)),$CLJS.p.g(b(17)),$CLJS.p.g(b(18)),"-",$CLJS.p.g(b(19)),$CLJS.p.g(b(20)),$CLJS.p.g(b(21)),$CLJS.p.g(b(22)),$CLJS.p.g(b(23)),$CLJS.p.g(b(24)),$CLJS.p.g(b(25)),$CLJS.p.g(b(26)),$CLJS.p.g(b(27)),$CLJS.p.g(b(28)),$CLJS.p.g(b(29)),$CLJS.p.g(b(30))].join(""))},$CLJS.Xw.h(jy(0,15),31)));cca=Gy($CLJS.gx,$CLJS.fx,Sy);$CLJS.kx=Gy($CLJS.gx,Py,Ty);Gy($CLJS.Dy(new $CLJS.k(null,1,[$CLJS.Ay,!1],null)),$CLJS.fx,Sy);Gy($CLJS.Dy(new $CLJS.k(null,1,[$CLJS.Ay,!1],null)),Py,Ty);Vy=Math.log(2);
Jy=function Jy(a,b){if(2>=a)return new $CLJS.S(null,1,5,$CLJS.T,[a],null);var d=Math.log(a);b=$CLJS.yx(b);var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);d=$CLJS.eh(Math.exp(Jx(e)*(d-Vy)+Vy));e=$CLJS.cf(a,d);return 1<d&&1<e?$CLJS.pf(d,Jy.h?Jy.h(e,b):Jy.call(null,e,b)):new $CLJS.S(null,1,5,$CLJS.T,[a],null)};$CLJS.dca=$CLJS.Ky(Iy,cca);$CLJS.dx=$CLJS.Ky(Iy,$CLJS.kx);