var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var HF=function(a,b,c,d,e){this.Uj=a;this.Ni=b;this.O=c;this.Oi=d;this.G=e;this.C=2565220111;this.K=0},WF=new $CLJS.N("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.h=HF.prototype;$CLJS.h.ka=function(a,b){return $CLJS.M.h(this.Ni,b)};$CLJS.h.U=function(a,b,c){return $CLJS.M.j(this.Ni,b,c)};$CLJS.h.W=function(a,b,c){return $CLJS.Oi(b,function(d){return $CLJS.Oi(b,$CLJS.Ti,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.h.M=function(){return this.O};$CLJS.h.ea=function(){return $CLJS.E(this.Ni)};
$CLJS.h.Wc=function(){if(0===$CLJS.E(this.Ni))return null;var a=$CLJS.C(this.Uj),b=$CLJS.C($CLJS.uc(a));return $CLJS.m(this.Oi)?new $CLJS.S(null,2,5,$CLJS.T,[b,this.Ni.g?this.Ni.g(b):this.Ni.call(null,b)],null):new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.tc(a)],null)};
$CLJS.h.Xc=function(){if(0===$CLJS.E(this.Ni))throw Error("Can't pop empty priority map");var a=$CLJS.C(this.Uj),b=$CLJS.uc(a),c=$CLJS.C(b);a=$CLJS.tc(a);return $CLJS.F.h($CLJS.E(b),1)?new HF($CLJS.Mm.h(this.Uj,a),$CLJS.Mm.h(this.Ni,c),this.O,this.Oi,null):new HF($CLJS.U.j(this.Uj,a,$CLJS.Nm.h(b,c)),$CLJS.Mm.h(this.Ni,c),this.O,this.Oi,null)};
$CLJS.h.Vc=function(){var a=this,b=this;return $CLJS.m(a.Oi)?$CLJS.A(function(){return function e(d){return new $CLJS.Cf(null,function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,z,B,H,I,R){return function ha(ba){return new $CLJS.Cf(null,function(){return function(){for(;;){var oa=$CLJS.A(ba);if(oa){if($CLJS.re(oa)){var Fa=$CLJS.$c(oa),gb=$CLJS.E(Fa),Gb=$CLJS.Ff(gb);a:for(var Sb=0;;)if(Sb<gb){var Yb=$CLJS.ce(Fa,Sb);
Yb=new $CLJS.S(null,2,5,$CLJS.T,[Yb,a.Ni.g?a.Ni.g(Yb):a.Ni.call(null,Yb)],null);Gb.add(Yb);Sb+=1}else{Fa=!0;break a}return Fa?$CLJS.If($CLJS.Kf(Gb),ha($CLJS.ad(oa))):$CLJS.If($CLJS.Kf(Gb),null)}Gb=$CLJS.C(oa);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[Gb,a.Ni.g?a.Ni.g(Gb):a.Ni.call(null,Gb)],null),ha($CLJS.Ad(oa)))}return null}}}(v,x,z,B,H,I,R),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.wg.h(g,e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null}},null,null)}($CLJS.Pc(a.Uj))}()):$CLJS.A(function(){return function e(d){return new $CLJS.Cf(null,
function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,z,B,H,I,R){return function ha(ba){return new $CLJS.Cf(null,function(oa,Fa,gb){return function(){for(;;){var Gb=$CLJS.A(ba);if(Gb){if($CLJS.re(Gb)){var Sb=$CLJS.$c(Gb),Yb=$CLJS.E(Sb),oc=$CLJS.Ff(Yb);a:for(var Db=0;;)if(Db<Yb){var Tb=$CLJS.ce(Sb,Db);oc.add(new $CLJS.S(null,2,5,$CLJS.T,[Tb,gb],null));Db+=1}else{Sb=!0;break a}return Sb?$CLJS.If($CLJS.Kf(oc),ha($CLJS.ad(Gb))):
$CLJS.If($CLJS.Kf(oc),null)}oc=$CLJS.C(Gb);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[oc,gb],null),ha($CLJS.Ad(Gb)))}return null}}}(v,x,z,B,H,I,R),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.wg.h(g,e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null}},null,null)}($CLJS.Pc(a.Uj))}())};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=$CLJS.Fd(this)};$CLJS.h.T=function(a,b){return $CLJS.Hc(this.Ni,b)};$CLJS.h.xa=function(){return $CLJS.K($CLJS.XF,this.O)};
$CLJS.h.za=function(a,b){a=this.Ni.h?this.Ni.h(b,WF):this.Ni.call(null,b,WF);if($CLJS.F.h(a,WF))return this;a=this.Oi.g?this.Oi.g(a):this.Oi.call(null,a);var c=this.Uj.g?this.Uj.g(a):this.Uj.call(null,a);return $CLJS.F.h($CLJS.E(c),1)?new HF($CLJS.Mm.h(this.Uj,a),$CLJS.Mm.h(this.Ni,b),this.O,this.Oi,null):new HF($CLJS.U.j(this.Uj,a,$CLJS.Nm.h(c,b)),$CLJS.Mm.h(this.Ni,b),this.O,this.Oi,null)};
$CLJS.h.Z=function(a,b,c){var d=$CLJS.M.j(this.Ni,b,null);if($CLJS.m(d)){if($CLJS.F.h(d,c))return this;a=this.Oi.g?this.Oi.g(c):this.Oi.call(null,c);d=this.Oi.g?this.Oi.g(d):this.Oi.call(null,d);var e=$CLJS.M.h(this.Uj,d);return $CLJS.F.h($CLJS.E(e),1)?new HF($CLJS.U.j($CLJS.Mm.h(this.Uj,d),a,$CLJS.mf.h($CLJS.M.j(this.Uj,a,$CLJS.ui),b)),$CLJS.U.j(this.Ni,b,c),this.O,this.Oi,null):new HF($CLJS.U.l(this.Uj,d,$CLJS.Nm.h($CLJS.M.h(this.Uj,d),b),$CLJS.G([a,$CLJS.mf.h($CLJS.M.j(this.Uj,a,$CLJS.ui),b)])),
$CLJS.U.j(this.Ni,b,c),this.O,this.Oi,null)}a=this.Oi.g?this.Oi.g(c):this.Oi.call(null,c);return new HF($CLJS.U.j(this.Uj,a,$CLJS.mf.h($CLJS.M.j(this.Uj,a,$CLJS.ui),b)),$CLJS.U.j(this.Ni,b,c),this.O,this.Oi,null)};$CLJS.h.ta=function(a,b){return $CLJS.Je(this.Ni,b)};
$CLJS.h.da=function(){var a=this,b=this;return $CLJS.m(a.Oi)?$CLJS.A(function(){return function e(d){return new $CLJS.Cf(null,function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,z,B,H,I,R){return function ha(ba){return new $CLJS.Cf(null,function(){return function(){for(;;){var oa=$CLJS.A(ba);if(oa){if($CLJS.re(oa)){var Fa=$CLJS.$c(oa),gb=$CLJS.E(Fa),Gb=$CLJS.Ff(gb);a:for(var Sb=0;;)if(Sb<gb){var Yb=$CLJS.ce(Fa,Sb);
Yb=new $CLJS.S(null,2,5,$CLJS.T,[Yb,a.Ni.g?a.Ni.g(Yb):a.Ni.call(null,Yb)],null);Gb.add(Yb);Sb+=1}else{Fa=!0;break a}return Fa?$CLJS.If($CLJS.Kf(Gb),ha($CLJS.ad(oa))):$CLJS.If($CLJS.Kf(Gb),null)}Gb=$CLJS.C(oa);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[Gb,a.Ni.g?a.Ni.g(Gb):a.Ni.call(null,Gb)],null),ha($CLJS.Ad(oa)))}return null}}}(v,x,z,B,H,I,R),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.wg.h(g,e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null}},null,null)}(a.Uj)}()):$CLJS.A(function(){return function e(d){return new $CLJS.Cf(null,
function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,z,B,H,I,R){return function ha(ba){return new $CLJS.Cf(null,function(oa,Fa,gb){return function(){for(;;){var Gb=$CLJS.A(ba);if(Gb){if($CLJS.re(Gb)){var Sb=$CLJS.$c(Gb),Yb=$CLJS.E(Sb),oc=$CLJS.Ff(Yb);a:for(var Db=0;;)if(Db<Yb){var Tb=$CLJS.ce(Sb,Db);oc.add(new $CLJS.S(null,2,5,$CLJS.T,[Tb,gb],null));Db+=1}else{Sb=!0;break a}return Sb?$CLJS.If($CLJS.Kf(oc),ha($CLJS.ad(Gb))):
$CLJS.If($CLJS.Kf(oc),null)}oc=$CLJS.C(Gb);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[oc,gb],null),ha($CLJS.Ad(Gb)))}return null}}}(v,x,z,B,H,I,R),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.wg.h(g,e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null}},null,null)}(a.Uj)}())};$CLJS.h.N=function(a,b){return new HF(this.Uj,this.Ni,b,this.Oi,this.G)};$CLJS.h.ja=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.ce(b,0),$CLJS.ce(b,1)):$CLJS.Rb($CLJS.dc,this,b)};
$CLJS.h.call=function(a){switch(arguments.length-1){case 1:return this.g(arguments[1]);case 2:return this.h(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length-1)].join(""));}};$CLJS.h.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.Qb(b)))};$CLJS.h.g=function(a){return this.ka(null,a)};$CLJS.h.h=function(a,b){return this.U(null,a,b)};
$CLJS.XF=new HF(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.A(0<b.length?new $CLJS.y(b.slice(0),0,null):null),d=$CLJS.Jm;;)if(b)c=$CLJS.D($CLJS.D(b)),d=$CLJS.U.j(d,$CLJS.C(b),$CLJS.$d(b)),b=c;else break a;return d}(),$CLJS.P,$CLJS.P,$CLJS.$e,null);$CLJS.M.h($CLJS.r($CLJS.GF),"tailrecursion.priority-map");
$CLJS.jj.F($CLJS.GF,$CLJS.U,"tailrecursion.priority-map",function(a){if($CLJS.oe(a))return $CLJS.uh.h($CLJS.XF,a);throw Error("Priority map literal expects a map for its elements.");});