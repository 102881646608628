var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var vX,wX,hma,ima,jma,RX,SX,TX,UX,WX,kma,YX;vX=function(a){switch(arguments.length){case 2:return $CLJS.vV(arguments[0],arguments[1]);case 3:return $CLJS.uV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};wX=function(a){return["(?:",$CLJS.p.g($CLJS.Q.h($CLJS.p,a)),")?"].join("")};hma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
ima=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.QX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);jma=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);RX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
SX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);TX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);UX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.VX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
WX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);kma=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.XX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);YX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.ZX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.GL.o(null,$CLJS.sH,function(){return $CLJS.yl});$CLJS.pL(YX,$CLJS.qk);$CLJS.GL.o(null,$CLJS.RH,function(){return $CLJS.Gl});$CLJS.pL(YX,$CLJS.qk);$CLJS.pL(kma,$CLJS.ik);$CLJS.GL.o(null,$CLJS.NG,function(){return $CLJS.ol});$CLJS.pL(ima,$CLJS.Cm);$CLJS.GL.o(null,$CLJS.LG,function(){return $CLJS.fL});$CLJS.pL(jma,$CLJS.Ul);
var bma=[":\\d{2}",wX($CLJS.G(["\\.\\d{1,6}"]))].join(""),HX=["\\d{2}:\\d{2}",wX($CLJS.G([bma]))].join(""),IX=["\\d{4}-\\d{2}-\\d{2}T",HX].join(""),JX=["(?:Z|(?:[+-]",HX,"))"].join(""),cma=$CLJS.Li("^\\d{4}-\\d{2}-\\d{2}$"),dma=$CLJS.Li(["^",HX,"$"].join("")),ema=$CLJS.Li(["^",HX,JX,"$"].join("")),fma=$CLJS.Li(["^",IX,"$"].join("")),gma=$CLJS.Li(["^",IX,JX,"$"].join(""));$CLJS.pL(TX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uu,new $CLJS.k(null,1,[$CLJS.ql,"date string literal"],null),cma],null));
$CLJS.pL(SX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uu,new $CLJS.k(null,1,[$CLJS.ql,"local time string literal"],null),dma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uu,new $CLJS.k(null,1,[$CLJS.ql,"offset time string literal"],null),ema],null)],null));
$CLJS.pL(UX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uu,new $CLJS.k(null,1,[$CLJS.ql,"local date time string literal"],null),fma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uu,new $CLJS.k(null,1,[$CLJS.ql,"offset date time string literal"],null),gma],null)],null));
$CLJS.GL.o(null,$CLJS.MG,function(a){return $CLJS.m($CLJS.vV?$CLJS.vV(UX,a):vX.call(null,UX,a))?new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.fn,null,$CLJS.nK,null],null),null):$CLJS.m($CLJS.vV?$CLJS.vV(TX,a):vX.call(null,TX,a))?new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.jJ,null,$CLJS.fn,null],null),null):$CLJS.m($CLJS.vV?$CLJS.vV(SX,a):vX.call(null,SX,a))?new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.ML,null,$CLJS.fn,null],null),null):$CLJS.fn});$CLJS.pL($CLJS.VX,TX);$CLJS.pL(RX,SX);
$CLJS.pL($CLJS.ZX,UX);$CLJS.pL(hma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Hk,$CLJS.VX,RX,$CLJS.ZX],null));$CLJS.KX=$CLJS.Li("^\\d{4}-\\d{2}$");$CLJS.pL($CLJS.XX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uu,new $CLJS.k(null,1,[$CLJS.ql,"year-month string literal"],null),$CLJS.KX],null));$CLJS.LX=$CLJS.Li("^\\d{4}$");$CLJS.pL($CLJS.QX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uu,new $CLJS.k(null,1,[$CLJS.ql,"year string literal"],null),$CLJS.LX],null));
$CLJS.pL(WX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.XL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rk,$CLJS.ZL],null)],null)],null));$CLJS.AV($CLJS.xl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.vu,new $CLJS.k(null,1,[$CLJS.ql,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.xl],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,WX],null),$CLJS.Lb],null));