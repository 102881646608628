var $CLJS = module.exports = {};
/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var ra,ma,ta,ua,Ca,Ja,ya,Oa;$CLJS.ca=function(a,b){return new $CLJS.aa(a,b)};$CLJS.ja=function(a){return 0<a?0x7fffffffffffffff<=a?da:new $CLJS.aa(a,a/4294967296):0>a?-0x7fffffffffffffff>=a?ea:$CLJS.fa(new $CLJS.aa(-a,-a/4294967296)):$CLJS.ia};$CLJS.fa=function(a){var b=~a.Aa+1|0;return $CLJS.ca(b,~a.ra+!b|0)};$CLJS.ka=function(a){return 4294967296*a.ra+(a.Aa>>>0)};
ra=function(a,b){if($CLJS.la(b))throw Error("division by zero");if(0>a.ra){if(ma(a,ea)){if(ma(b,$CLJS.na)||ma(b,qa))return ea;if(ma(b,ea))return $CLJS.na;var c=ra($CLJS.wa(a,1),b).shiftLeft(1);if(ma(c,$CLJS.ia))return 0>b.ra?$CLJS.na:qa;var d=b.multiply(c);a=a.add($CLJS.fa(d));return c.add(ra(a,b))}return 0>b.ra?ra($CLJS.fa(a),$CLJS.fa(b)):$CLJS.fa(ra($CLJS.fa(a),b))}if($CLJS.la(a))return $CLJS.ia;if(0>b.ra)return ma(b,ea)?$CLJS.ia:$CLJS.fa(ra(a,$CLJS.fa(b)));for(d=$CLJS.ia;0<=a.compare(b);){c=Math.max(1,
Math.floor($CLJS.ka(a)/$CLJS.ka(b)));var e=Math.ceil(Math.log(c)/Math.LN2);e=48>=e?1:Math.pow(2,e-48);for(var f=$CLJS.ja(c),g=f.multiply(b);0>g.ra||0<g.compare(a);)c-=e,f=$CLJS.ja(c),g=f.multiply(b);$CLJS.la(f)&&(f=$CLJS.na);d=d.add(f);a=a.add($CLJS.fa(g))}return d};$CLJS.la=function(a){return 0==a.Aa&&0==a.ra};ma=function(a,b){return a.Aa==b.Aa&&a.ra==b.ra};$CLJS.wa=function(a,b){b&=63;if(0==b)return a;var c=a.ra;return 32>b?$CLJS.ca(a.Aa>>>b|c<<32-b,c>>b):$CLJS.ca(c>>b-32,0<=c?0:-1)};
ta=function(){var a=$CLJS.sa.navigator;return a&&(a=a.userAgent)?a:""};ua=function(a){return-1!=ta().indexOf(a)};Ca=function(){return ua("Firefox")||ua("FxiOS")};Ja=function(){return(ua("Chrome")||ua("CriOS"))&&!ua("Edge")||ua("Silk")};
$CLJS.xa=function(a,b){const c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\- \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(e,f,g,l,n,q,u,v){if("%"==q)return"%";const x=c.shift();if("undefined"==typeof x)throw Error("[goog.string.format] Not enough arguments");arguments[0]=x;return $CLJS.xa.Zc[q].apply(null,arguments)})};ya=function(){return ua("iPhone")&&!ua("iPod")&&!ua("iPad")};
Oa=function(){ya()||ua("iPad")||ua("iPod")};$CLJS.Aa=$CLJS.Aa||{};$CLJS.sa=global;$CLJS.Ba="closure_uid_"+(1E9*Math.random()>>>0);$CLJS.sa=global;var qa,da,ea;
$CLJS.aa=class{constructor(a,b){this.Aa=a|0;this.ra=b|0}isSafeInteger(){var a=this.ra>>21;return 0==a||-1==a&&!(0==this.Aa&&-2097152==this.ra)}toString(a){a=a||10;if(2>a||36<a)throw Error("radix out of range: "+a);if(this.isSafeInteger()){var b=$CLJS.ka(this);return 10==a?""+b:b.toString(a)}b=14-(a>>2);var c=Math.pow(a,b),d=$CLJS.ca(c,c/4294967296);c=ra(this,d);var e=Math,f=e.abs;d=c.multiply(d);d=this.add($CLJS.fa(d));e=f.call(e,$CLJS.ka(d));f=10==a?""+e:e.toString(a);f.length<b&&(f="0000000000000".slice(f.length-
b)+f);e=$CLJS.ka(c);return(10==a?e:e.toString(a))+f}compare(a){return this.ra==a.ra?this.Aa==a.Aa?0:this.Aa>>>0>a.Aa>>>0?1:-1:this.ra>a.ra?1:-1}add(a){var b=this.ra>>>16,c=this.ra&65535,d=this.Aa>>>16,e=a.ra>>>16,f=a.ra&65535,g=a.Aa>>>16;a=(this.Aa&65535)+(a.Aa&65535);g=(a>>>16)+(d+g);d=g>>>16;d+=c+f;b=(d>>>16)+(b+e)&65535;return $CLJS.ca((g&65535)<<16|a&65535,b<<16|d&65535)}multiply(a){if($CLJS.la(this))return this;if($CLJS.la(a))return a;var b=this.ra>>>16,c=this.ra&65535,d=this.Aa>>>16,e=this.Aa&
65535,f=a.ra>>>16,g=a.ra&65535,l=a.Aa>>>16;a=a.Aa&65535;var n=e*a;var q=(n>>>16)+d*a;var u=q>>>16;q=(q&65535)+e*l;u+=q>>>16;u+=c*a;var v=u>>>16;u=(u&65535)+d*l;v+=u>>>16;u=(u&65535)+e*g;v=v+(u>>>16)+(b*a+c*l+d*g+e*f)&65535;return $CLJS.ca((q&65535)<<16|n&65535,v<<16|u&65535)}and(a){return $CLJS.ca(this.Aa&a.Aa,this.ra&a.ra)}or(a){return $CLJS.ca(this.Aa|a.Aa,this.ra|a.ra)}xor(a){return $CLJS.ca(this.Aa^a.Aa,this.ra^a.ra)}shiftLeft(a){a&=63;if(0==a)return this;var b=this.Aa;return 32>a?$CLJS.ca(b<<
a,this.ra<<a|b>>>32-a):$CLJS.ca(0,b<<a-32)}};$CLJS.ia=$CLJS.ca(0,0);$CLJS.na=$CLJS.ca(1,0);qa=$CLJS.ca(-1,-1);da=$CLJS.ca(4294967295,2147483647);ea=$CLJS.ca(0,2147483648);$CLJS.Va=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};var Da=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};$CLJS.Za=RegExp("^(?:([^:/?#.]+):)?(?://(?:([^\\\\/?#]*)@)?([^\\\\/?#]*?)(?::([0-9]+))?(?\x3d[\\\\/?#]|$))?([^?#]+)?(?:\\?([^#]*))?(?:#([\\s\\S]*))?$");$CLJS.Ga=class{constructor(a,b){this.name=a;this.value=b}toString(){return this.name}};$CLJS.Ha=new $CLJS.Ga("OFF",Infinity);$CLJS.Ia=[$CLJS.Ha,new $CLJS.Ga("SHOUT",1200),new $CLJS.Ga("SEVERE",1E3),new $CLJS.Ga("WARNING",900),new $CLJS.Ga("INFO",800),new $CLJS.Ga("CONFIG",700),new $CLJS.Ga("FINE",500),new $CLJS.Ga("FINER",400),new $CLJS.Ga("FINEST",300),new $CLJS.Ga("ALL",0)];$CLJS.xa.Zc={};$CLJS.xa.Zc.s=function(a,b,c){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+Da(" ",Number(c)-a.length):Da(" ",Number(c)-a.length)+a};
$CLJS.xa.Zc.f=function(a,b,c,d,e){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));let f;f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+Da(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+Da(b,a)+d);return d};
$CLJS.xa.Zc.d=function(a,b,c,d,e,f,g,l){return $CLJS.xa.Zc.f(parseInt(a,10),b,c,d,0,f,g,l)};$CLJS.xa.Zc.i=$CLJS.xa.Zc.d;$CLJS.xa.Zc.u=$CLJS.xa.Zc.d;ua("Opera");ua("Trident")||ua("MSIE");ua("Edge");!ua("Gecko")||-1!=ta().toLowerCase().indexOf("webkit")&&!ua("Edge")||ua("Trident")||ua("MSIE")||ua("Edge");-1!=ta().toLowerCase().indexOf("webkit")&&!ua("Edge")&&ua("Mobile");ua("Macintosh");ua("Windows");ua("Linux")||ua("CrOS");var $a=$CLJS.sa.navigator||null;$a&&($a.appVersion||"").indexOf("X11");ua("Android");ya();ua("iPad");ua("iPod");Oa();ta().toLowerCase().indexOf("kaios");Date.now();Ca();ya()||ua("iPod");ua("iPad");!ua("Android")||Ja()||Ca()||ua("Opera")||ua("Silk");Ja();!ua("Safari")||Ja()||ua("Coast")||ua("Opera")||ua("Edge")||ua("Edg/")||ua("OPR")||Ca()||ua("Silk")||ua("Android")||Oa();