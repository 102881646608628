var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var jA,Fca,Gca,nA,Hca,pA,qA;
$CLJS.iA=function(a,b,c){return function(){function d(q,u,v){return a.R?a.R(b,c,q,u,v):a.call(null,b,c,q,u,v)}function e(q,u){return a.F?a.F(b,c,q,u):a.call(null,b,c,q,u)}function f(q){return a.j?a.j(b,c,q):a.call(null,b,c,q)}function g(){return a.h?a.h(b,c):a.call(null,b,c)}var l=null,n=function(){function q(v,x,z,B){var H=null;if(3<arguments.length){H=0;for(var I=Array(arguments.length-3);H<I.length;)I[H]=arguments[H+3],++H;H=new $CLJS.y(I,0,null)}return u.call(this,v,x,z,H)}function u(v,x,z,B){return $CLJS.Q.l(a,
b,c,v,x,$CLJS.G([z,B]))}q.v=3;q.B=function(v){var x=$CLJS.C(v);v=$CLJS.D(v);var z=$CLJS.C(v);v=$CLJS.D(v);var B=$CLJS.C(v);v=$CLJS.Ad(v);return u(x,z,B,v)};q.l=u;return q}();l=function(q,u,v,x){switch(arguments.length){case 0:return g.call(this);case 1:return f.call(this,q);case 2:return e.call(this,q,u);case 3:return d.call(this,q,u,v);default:var z=null;if(3<arguments.length){z=0;for(var B=Array(arguments.length-3);z<B.length;)B[z]=arguments[z+3],++z;z=new $CLJS.y(B,0,null)}return n.l(q,u,v,z)}throw Error("Invalid arity: "+
arguments.length);};l.v=3;l.B=n.B;l.A=g;l.g=f;l.h=e;l.j=d;l.l=n.l;return l}()};jA=function(a,b){return $CLJS.F.h(a,b)?new $CLJS.S(null,3,5,$CLJS.T,[null,null,a],null):new $CLJS.S(null,3,5,$CLJS.T,[a,b,null],null)};Fca=function(a){return $CLJS.A(a)?$CLJS.Rb(function(b,c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return $CLJS.U.j(b,d,c)},$CLJS.Sg($CLJS.tg($CLJS.Q.h($CLJS.Rm,$CLJS.mi(a)),null)),a):null};
Gca=function(a,b,c){var d=$CLJS.M.h(a,c),e=$CLJS.M.h(b,c),f=$CLJS.kA(d,e),g=$CLJS.J(f,0,null),l=$CLJS.J(f,1,null);f=$CLJS.J(f,2,null);a=$CLJS.Je(a,c);b=$CLJS.Je(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.S(null,3,5,$CLJS.T,[!a||null==g&&d?null:$CLJS.Tf([c,g]),!b||null==l&&d?null:$CLJS.Tf([c,l]),d?$CLJS.Tf([c,f]):null],null)};
nA=function(a,b){var c=$CLJS.xg.h,d=mA.j,e=$CLJS.qe(a)?a:$CLJS.Sg(a),f=$CLJS.qe(b)?b:$CLJS.Sg(b);a=$CLJS.E(a);b=$CLJS.E(b);return $CLJS.Sg(c.call($CLJS.xg,Fca,d.call(mA,e,f,$CLJS.Mj(0,a>b?a:b))))};Hca=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Vf($CLJS.oA.h(a,b)),$CLJS.Vf($CLJS.oA.h(b,a)),$CLJS.Vf($CLJS.sA.h(a,b))],null)};
pA=function(a){if(null!=a&&null!=a.Mh)a=a.Mh(a);else{var b=pA[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=pA._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Ob("EqualityPartition.equality-partition",a);}return a};qA=function(a,b){if(null!=a&&null!=a.Gh)a=a.Gh(a,b);else{var c=qA[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=qA._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Ob("Diff.diff-similar",a);}return a};
$CLJS.kA=function(a,b){return $CLJS.F.h(a,b)?new $CLJS.S(null,3,5,$CLJS.T,[null,null,a],null):$CLJS.F.h(pA(a),pA(b))?qA(a,b):jA(a,b)};$CLJS.oA=function oA(a){switch(arguments.length){case 1:return oA.g(arguments[0]);case 2:return oA.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return oA.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.oA.g=function(a){return a};
$CLJS.oA.h=function(a,b){return $CLJS.E(a)<$CLJS.E(b)?$CLJS.Rb(function(c,d){return $CLJS.Je(b,d)?$CLJS.Nm.h(c,d):c},a,a):$CLJS.Rb($CLJS.Nm,a,b)};$CLJS.oA.l=function(a,b,c){return $CLJS.Rb($CLJS.oA,a,$CLJS.mf.h(c,b))};$CLJS.oA.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};$CLJS.oA.v=2;
$CLJS.sA=function sA(a){switch(arguments.length){case 1:return sA.g(arguments[0]);case 2:return sA.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return sA.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.sA.g=function(a){return a};
$CLJS.sA.h=function(a,b){for(;;)if($CLJS.E(b)<$CLJS.E(a)){var c=a;a=b;b=c}else return $CLJS.Rb(function(d,e){return function(f,g){return $CLJS.Je(e,g)?f:$CLJS.Nm.h(f,g)}}(a,b),a,a)};$CLJS.sA.l=function(a,b,c){a=$CLJS.eA(function(d){return-$CLJS.E(d)},$CLJS.mf.l(c,b,$CLJS.G([a])));return $CLJS.Rb($CLJS.sA,$CLJS.C(a),$CLJS.Ad(a))};$CLJS.sA.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};$CLJS.sA.v=2;var rA=new $CLJS.N(null,"atom","atom",-397043653);var mA=function mA(a){switch(arguments.length){case 2:return mA.h(arguments[0],arguments[1]);case 3:return mA.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};mA.h=function(a,b){return mA.j(a,b,$CLJS.lA.h($CLJS.mi(a),$CLJS.mi(b)))};mA.j=function(a,b,c){return $CLJS.Rb(function(d,e){return $CLJS.Ii($CLJS.xg.j($CLJS.pn,d,e))},new $CLJS.S(null,3,5,$CLJS.T,[null,null,null],null),$CLJS.xg.h($CLJS.iA(Gca,a,b),c))};mA.v=3;
pA["null"]=function(){return rA};pA.string=function(){return rA};pA.number=function(){return rA};pA.array=function(){return $CLJS.tl};pA["function"]=function(){return rA};pA["boolean"]=function(){return rA};
pA._=function(a){return(null!=a?a.C&1024||$CLJS.t===a.uh||(a.C?0:$CLJS.Mb($CLJS.rc,a)):$CLJS.Mb($CLJS.rc,a))?$CLJS.dm:(null!=a?a.C&4096||$CLJS.t===a.yh||(a.C?0:$CLJS.Mb($CLJS.vc,a)):$CLJS.Mb($CLJS.vc,a))?$CLJS.Nk:(null!=a?a.C&16777216||$CLJS.t===a.Rf||(a.C?0:$CLJS.Mb($CLJS.Lc,a)):$CLJS.Mb($CLJS.Lc,a))?$CLJS.tl:rA};qA["null"]=function(a,b){return jA(a,b)};qA.string=function(a,b){return jA(a,b)};qA.number=function(a,b){return jA(a,b)};qA.array=function(a,b){return nA(a,b)};
qA["function"]=function(a,b){return jA(a,b)};qA["boolean"]=function(a,b){return jA(a,b)};qA._=function(a,b){var c=function(){var d=pA(a);d=d instanceof $CLJS.N?d.S:null;switch(d){case "atom":return jA;case "set":return Hca;case "sequential":return nA;case "map":return mA;default:throw Error(["No matching clause: ",$CLJS.p.g(d)].join(""));}}();return c.h?c.h(a,b):c.call(null,a,b)};