var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var r9,t9,s9,fva,y9,z9,zva,Cva,Qva,Rva,yva,dwa,Y9,ewa,fwa,gwa;r9=function(a,b){return $CLJS.m(a)?$CLJS.Ua(a,b):null};t9=function(a,b){return s9(a,b,$CLJS.P)};
s9=function(a,b,c){c=$CLJS.Uf(c);c=$CLJS.M.j(c,yva,!0);if($CLJS.oe(b))return $CLJS.uh.j($CLJS.P,a,b);c=$CLJS.m(c)?function(){var d=r9(b,"_plainObject");d=null==d?null:$CLJS.VF(d);return null==d?null:$CLJS.Vf(d)}():null;return $CLJS.m(c)?$CLJS.uh.j($CLJS.P,a,c):$CLJS.uh.j($CLJS.P,$CLJS.Um.j($CLJS.xg.g(function(d){return new $CLJS.S(null,2,5,$CLJS.T,[d,r9(b,d)],null)}),$CLJS.Xm.g(function(d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.F.h($CLJS.Na(d),"function")}),a),$CLJS.Ta(b))};
fva=function(a){var b=v9.g(a),c=w9.g(a);return $CLJS.Um.j($CLJS.xg.g(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g((0,$CLJS.pG)(e)),d],null)}),$CLJS.je(b)?$CLJS.$e:$CLJS.Xm.g(function(d){var e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);return $CLJS.Je(b,e)}),$CLJS.Fb(c)?$CLJS.$e:$CLJS.xg.g(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[e,c.h?c.h(e,d):c.call(null,e,d)],null)}))};
y9=function(a,b){var c=fva(a),d=x9.g(a);return function(e){try{var f=$CLJS.U.j(s9(c,e,b),$CLJS.Zu,d),g=$CLJS.hG($CLJS.FE);$CLJS.m($CLJS.mG("metabase.lib.js.metadata",g))&&$CLJS.nG("metabase.lib.js.metadata",g,$CLJS.Y_("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Mk.g(f),$CLJS.Z_(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,g=$CLJS.hG($CLJS.Gv),$CLJS.m($CLJS.mG("metabase.lib.js.metadata",g))&&(f instanceof Error?$CLJS.nG("metabase.lib.js.metadata",g,$CLJS.Y_("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.gj.l($CLJS.G([e])),$CLJS.YZ(f)])),f):$CLJS.nG("metabase.lib.js.metadata",g,$CLJS.Y_(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.gj.l($CLJS.G([e])),$CLJS.YZ(f)])),null)),null;throw l;}}};z9=function(a){return $CLJS.Ym.h(y9($CLJS.ZQ,$CLJS.P),a)};zva=new $CLJS.N(null,"plain-object","plain-object",-943264281);$CLJS.Ava=new $CLJS.N(null,"cards","cards",169174038);Cva=new $CLJS.N(null,"db","db",993250759);$CLJS.W9=new $CLJS.N(null,"metrics","metrics",394093469);
Qva=new $CLJS.N(null,"fks","fks",2041987076);$CLJS.X9=new $CLJS.N(null,"segments","segments",1937535949);Rva=new $CLJS.N(null,"dimensions","dimensions",-254818097);yva=new $CLJS.N(null,"use-plain-object?","use-plain-object?",-1174595475);dwa=new $CLJS.N(null,"metadata","metadata",1799301597);Y9=new $CLJS.N(null,"dimension-options","dimension-options",2122379680);ewa=new $CLJS.N(null,"schema-name","schema-name",1666725119);fwa=new $CLJS.N(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);
gwa=new $CLJS.N(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.kwa=new $CLJS.N(null,"databases","databases",2040134125);var v9,lwa=$CLJS.ij.g($CLJS.P),mwa=$CLJS.ij.g($CLJS.P),nwa=$CLJS.ij.g($CLJS.P),owa=$CLJS.ij.g($CLJS.P),pwa=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null)),G9,Awa,Bwa,Cwa,Dwa,Ewa;v9=new $CLJS.Aj($CLJS.$i.h("metabase.lib.js.metadata","excluded-keys"),$CLJS.Ui,$CLJS.Uj,pwa,lwa,mwa,nwa,owa);v9.o(null,$CLJS.Uj,function(){return null});
var w9,qwa=$CLJS.ij.g($CLJS.P),rwa=$CLJS.ij.g($CLJS.P),swa=$CLJS.ij.g($CLJS.P),twa=$CLJS.ij.g($CLJS.P),uwa=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));w9=new $CLJS.Aj($CLJS.$i.h("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Ui,$CLJS.Uj,uwa,qwa,rwa,swa,twa);w9.o(null,$CLJS.Uj,function(){return null});var x9,vwa=$CLJS.ij.g($CLJS.P),wwa=$CLJS.ij.g($CLJS.P),xwa=$CLJS.ij.g($CLJS.P),ywa=$CLJS.ij.g($CLJS.P),zwa=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));
x9=new $CLJS.Aj($CLJS.$i.h("metabase.lib.js.metadata","lib-type"),$CLJS.Ui,$CLJS.Uj,zwa,vwa,wwa,xwa,ywa);$CLJS.F9=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Ui.g(f)},$CLJS.Uj,e,a,b,c,d)}();Awa=$CLJS.ij.g($CLJS.P);Bwa=$CLJS.ij.g($CLJS.P);Cwa=$CLJS.ij.g($CLJS.P);Dwa=$CLJS.ij.g($CLJS.P);
Ewa=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));G9=new $CLJS.Aj($CLJS.$i.h("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Ui,$CLJS.Uj,Ewa,Awa,Bwa,Cwa,Dwa);$CLJS.F9.o(null,$CLJS.Uj,function(a,b){var c=y9(a,$CLJS.P);return t9($CLJS.xg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uL(e),new $CLJS.dj(function(){return c.g?c.g(f):c.call(null,f)})],null)}),r9(b,G9.g(a)))});x9.o(null,$CLJS.gR,function(){return $CLJS.qoa});
v9.o(null,$CLJS.gR,function(){return new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.SO,null,$CLJS.H0,null],null),null)});w9.o(null,$CLJS.gR,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "dbms-version":return $CLJS.UF(b,$CLJS.G([$CLJS.lk,!0]));case "features":return $CLJS.uh.j($CLJS.ui,$CLJS.xg.g($CLJS.Ui),b);case "native-permissions":return $CLJS.Ui.g(b);default:return b}}});G9.o(null,$CLJS.gR,function(){return"databases"});x9.o(null,$CLJS.J0,function(){return $CLJS.G0});
v9.o(null,$CLJS.J0,function(){return new $CLJS.ti(null,new $CLJS.k(null,5,[Y9,null,$CLJS.SO,null,$CLJS.X9,null,$CLJS.gR,null,$CLJS.W9,null],null),null)});w9.o(null,$CLJS.J0,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "entity-type":return $CLJS.Ui.g(b);case "field-order":return $CLJS.Ui.g(b);case "initial-sync-status":return $CLJS.Ui.g(b);case "visibility-type":return $CLJS.Ui.g(b);default:return b}}});
$CLJS.F9.o(null,$CLJS.J0,function(a,b){var c=y9(a,$CLJS.P);return t9($CLJS.Um.h($CLJS.Xm.g(function(d){var e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.xg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uL(e),new $CLJS.dj(function(){return c.g?c.g(f):c.call(null,f)})],null)})),r9(b,"tables"))});x9.o(null,$CLJS.ZQ,function(){return $CLJS.KW});
v9.o(null,$CLJS.ZQ,function(){return new $CLJS.ti(null,new $CLJS.k(null,7,[Y9,null,$CLJS.J0,null,Rva,null,gwa,null,$CLJS.gR,null,fwa,null,$CLJS.W9,null],null),null)});
w9.o(null,$CLJS.ZQ,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "base-type":return $CLJS.Ui.g(b);case "coercion-strategy":return $CLJS.Ui.g(b);case "effective-type":return $CLJS.Ui.g(b);case "fingerprint":return $CLJS.oe(b)?$CLJS.d_(b):$CLJS.UF(b,$CLJS.G([$CLJS.lk,!0]));case "has-field-values":return $CLJS.Ui.g(b);case "semantic-type":return $CLJS.Ui.g(b);case "visibility-type":return $CLJS.Ui.g(b);case "id":return $CLJS.qe(b)?$CLJS.$d(b):b;default:return b}}});
$CLJS.F9.o(null,$CLJS.ZQ,function(a,b){var c=y9(a,$CLJS.P),d=r9(b,"fields");return t9($CLJS.Hn(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null);e=function(){var l=$CLJS.uL(f);if($CLJS.m(l))return l;l=$CLJS.Ji(/card__\d+:(\d+)/,f);if($CLJS.m(l)){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=null==r9(d,l);return n?$CLJS.uL(l):n}return null}();return $CLJS.m(e)?new $CLJS.S(null,2,5,$CLJS.T,[e,new $CLJS.dj(function(){return c.g?c.g(g):c.call(null,g)})],null):null}),d)});x9.o(null,$CLJS.kJ,function(){return $CLJS.K0});
v9.o(null,$CLJS.kJ,function(){return new $CLJS.ti(null,new $CLJS.k(null,11,[Y9,null,$CLJS.km,null,Qva,null,$CLJS.J0,null,Cva,null,zva,null,$CLJS.X9,null,$CLJS.gR,null,dwa,null,$CLJS.W9,null,ewa,null],null),null)});
w9.o(null,$CLJS.kJ,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "result-metadata":return $CLJS.m($CLJS.Wm.h($CLJS.ne,$CLJS.Cb)(b))?z9(b):$CLJS.UF(b,$CLJS.G([$CLJS.lk,!0]));case "fields":return z9(b);case "visibility-type":return $CLJS.Ui.g(b);case "dataset-query":return $CLJS.UF(b,$CLJS.G([$CLJS.lk,!0]));default:return b}}});
$CLJS.F9.o(null,$CLJS.kJ,function(a,b){return $CLJS.uh.j($CLJS.P,$CLJS.xg.g(function(c){return new $CLJS.S(null,2,5,$CLJS.T,[c,new $CLJS.dj(function(){var d=y9($CLJS.kJ,new $CLJS.k(null,1,[yva,!1],null)),e=y9($CLJS.kJ,$CLJS.P),f=$CLJS.pn.l;d=$CLJS.U.j(d(r9(r9(b,"tables"),["card__",$CLJS.p.g(c)].join(""))),$CLJS.Mk,c);var g=r9(r9(b,"questions"),$CLJS.p.g(c));var l=r9(g,"_card");g=$CLJS.m(l)?l:g;e=e.g?e.g(g):e.call(null,g);return f.call($CLJS.pn,$CLJS.G([d,e]))})],null)}),$CLJS.uh.j($CLJS.uh.j($CLJS.ui,
$CLJS.Hn($CLJS.l0),$CLJS.Ta(r9(b,"tables"))),$CLJS.xg.g($CLJS.uL),$CLJS.Ta(r9(b,"questions"))))});x9.o(null,$CLJS.vQ,function(){return $CLJS.LW});v9.o(null,$CLJS.vQ,function(){return new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.J0,null,$CLJS.gR,null],null),null)});w9.o(null,$CLJS.vQ,function(){return function(a,b){return b}});G9.o(null,$CLJS.vQ,function(){return"metrics"});x9.o(null,$CLJS.JI,function(){return $CLJS.F0});
v9.o(null,$CLJS.JI,function(){return new $CLJS.ti(null,new $CLJS.k(null,2,[$CLJS.J0,null,$CLJS.gR,null],null),null)});w9.o(null,$CLJS.JI,function(){return function(a,b){return b}});G9.o(null,$CLJS.JI,function(){return"segments"});