var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var goa,h0,hoa,ioa,koa,ooa;$CLJS.Y_=function(a,b){return $CLJS.Q.j($CLJS.xa,a,b)};goa=function(a){var b=new $CLJS.qb,c=$CLJS.FA;$CLJS.FA=new $CLJS.gd(b);try{var d=$CLJS.FA,e=$CLJS.Fb($CLJS.MB(d)),f=$CLJS.FA;$CLJS.FA=e?$CLJS.DB(d):d;try{d=$CLJS.OB;$CLJS.OB=!0;try{$CLJS.QB(a)}finally{$CLJS.OB=d}$CLJS.F.h(0,$CLJS.MA($CLJS.FA,$CLJS.tA))||$CLJS.Qc($CLJS.FA,"\n");$CLJS.LA()}finally{$CLJS.FA=f}$CLJS.BA($CLJS.p.g(b))}finally{$CLJS.FA=c}};
$CLJS.Z_=function(a){var b=new $CLJS.qb,c=$CLJS.zb;$CLJS.zb=function(d){return b.append(d)};try{goa(a)}finally{$CLJS.zb=c}return $CLJS.p.g(b)};$CLJS.f0=function(a){return $CLJS.qe(a)&&1<$CLJS.E(a)&&$CLJS.C(a)instanceof $CLJS.N&&$CLJS.oe($CLJS.$d(a))&&$CLJS.Je($CLJS.$d(a),$CLJS.MH)};$CLJS.g0=function(a){return $CLJS.f0(a)?$CLJS.rv(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.xM],null)):null};
h0=function(a,b,c){var d=$CLJS.Je(a,b)?$CLJS.Zm.j(a,b,function(e){return $CLJS.qe(e)&&$CLJS.F.h($CLJS.C(e),$CLJS.Qj)?$CLJS.Sg($CLJS.qg(1,e)):new $CLJS.S(null,1,5,$CLJS.T,[e],null)}):a;return $CLJS.Je(a,b)?$CLJS.YN(d,$CLJS.Tf([b,c])):d};hoa=function(a){var b=$CLJS.ri(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tQ,$CLJS.GR],null));b=i0.g?i0.g(b):i0.call(null,b);return $CLJS.nW($CLJS.U.l(h0($CLJS.Mm.l(a,$CLJS.tQ,$CLJS.G([$CLJS.GR])),$CLJS.vS,$CLJS.LZ),$CLJS.Zu,$CLJS.DZ,$CLJS.G([$CLJS.IZ,b])))};
ioa=function(a){return $CLJS.Ym.h(hoa,a)};koa=function(a){return $CLJS.m(a)?$CLJS.U.j($CLJS.Zm.j($CLJS.xe(a)?new $CLJS.k(null,1,[$CLJS.D0,a],null):a,$CLJS.D0,function(b){return $CLJS.Ym.h(function(c){return $CLJS.U.j($CLJS.lG(c,$CLJS.pG),$CLJS.Zu,$CLJS.KW)},b)}),$CLJS.Zu,ooa):null};
$CLJS.k0=function(a){if($CLJS.F.h($CLJS.Zu.g(a),$CLJS.H_))return a;var b=$CLJS.bl.g(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return $CLJS.pn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.Zu,$CLJS.H_,$CLJS.IZ,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.pn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.Zu,$CLJS.D_],null),$CLJS.YN($CLJS.oR.g(a),new $CLJS.k(null,1,[$CLJS.QO,$CLJS.oR],null))]))],null)],null),$CLJS.Mm.l(a,$CLJS.bl,$CLJS.G([$CLJS.oR]))]));case "query":return $CLJS.pn.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.Zu,$CLJS.H_,$CLJS.IZ,i0($CLJS.QO.g(a))],null),$CLJS.Mm.l(a,$CLJS.bl,$CLJS.G([$CLJS.QO]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.l0=function(a){return"string"===typeof a&&(a=$CLJS.Ki(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.J(a,0,null),a=$CLJS.J(a,1,null),$CLJS.uL(a)):null};$CLJS.E0=new $CLJS.N("lib","source","lib/source",-434086550);$CLJS.F0=new $CLJS.N("metadata","segment","metadata/segment",-1286738015);
$CLJS.G0=new $CLJS.N("metadata","table","metadata/table",-882499405);$CLJS.poa=new $CLJS.N(null,"num-stages","num-stages",1426797535);$CLJS.H0=new $CLJS.N(null,"tables","tables",1334623052);$CLJS.I0=new $CLJS.N(null,"table-id","table-id",-766649466);$CLJS.D0=new $CLJS.N(null,"columns","columns",1998437288);$CLJS.qoa=new $CLJS.N("metadata","database","metadata/database",-924828824);$CLJS.J0=new $CLJS.N(null,"table","table",-564943036);$CLJS.K0=new $CLJS.N("metadata","card","metadata/card",-1039333889);
ooa=new $CLJS.N("metadata","results","metadata/results",330329298);var i0;
i0=function i0(a){a=$CLJS.Uf(a);var c=$CLJS.M.h(a,$CLJS.GR),d=$CLJS.M.h(a,$CLJS.iQ);c=$CLJS.m(c)?i0.g?i0.g(c):i0.call(null,c):$CLJS.Rg;d=koa(d);var e=$CLJS.E(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.xv(c,new $CLJS.S(null,2,5,$CLJS.T,[e,$CLJS.PZ],null),d):c;d=$CLJS.m($CLJS.oR.g(a))?$CLJS.D_:$CLJS.UZ;a=$CLJS.pn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.Zu,d],null),$CLJS.Mm.l(a,$CLJS.GR,$CLJS.G([$CLJS.iQ]))]));a=$CLJS.A($CLJS.pP.g(a))?$CLJS.Zm.j(a,$CLJS.pP,ioa):a;a=h0(a,$CLJS.dP,$CLJS.I_);return $CLJS.mf.h(c,a)};
$CLJS.t0=RegExp(" id$","i");