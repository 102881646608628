var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var FL,KL,lga,mga,pga,qga,rga,sga;FL=function(a){return $CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N};$CLJS.HL=function(a){var b=function(){var c=FL(a);return c?(c=$CLJS.oe($CLJS.$d(a)))?(c=$CLJS.rk.g($CLJS.$d(a)),$CLJS.m(c)?c:$CLJS.bH.g($CLJS.$d(a))):c:c}();return $CLJS.m(b)?b:$CLJS.GL.g(a)};
KL=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,2,[$CLJS.ql,"valid MBQL clause",$CLJS.Iv,function(c){c=$CLJS.Uf(c);c=$CLJS.M.h(c,$CLJS.xl);return["invalid MBQL clause: ",$CLJS.gj.l($CLJS.G([c]))].join("")}],null),$CLJS.fg(FL)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.aM],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,b],null),function(c){c=$CLJS.HL(c);
return JL(c,a)}],null)],null)};lga=new $CLJS.N("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);mga=new $CLJS.N("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);pga=new $CLJS.N("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.hM=new $CLJS.N(null,"expr","expr",745722291);
$CLJS.lM=new $CLJS.N("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.rM=new $CLJS.N("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.tM=new $CLJS.N("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);$CLJS.uM=new $CLJS.N("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);
$CLJS.vM=new $CLJS.N("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.xM=new $CLJS.N("lib","expression-name","lib/expression-name",-1799326590);qga=new $CLJS.N("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.yM=new $CLJS.N("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);
$CLJS.BM=new $CLJS.N("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.CM=new $CLJS.N("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);rga=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);sga=new $CLJS.N("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);
$CLJS.aM=new $CLJS.N("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.DM=new $CLJS.N("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.IM=new $CLJS.N("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.JM=new $CLJS.N("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.GL=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.schema.expression","type-of-method"),function(f){var g=$CLJS.IH(f);return $CLJS.F.h(g,$CLJS.aH)?$CLJS.Nb(f):g},$CLJS.Uj,e,a,b,c,d)}();
$CLJS.pL(mga,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.vM],null),$CLJS.ZL],null));$CLJS.GL.o(null,$CLJS.Uj,function(a){throw $CLJS.Ej($CLJS.cI("{0}: Don''t know how to determine the type of {1}",$CLJS.G([rga,$CLJS.gj.l($CLJS.G([a]))])),new $CLJS.k(null,1,[$CLJS.hM,a],null));});$CLJS.GL.o(null,$CLJS.BM,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.HL(a)});
var JL=function JL(a,b){return $CLJS.le(a)?$CLJS.ag(function(d){return JL.h?JL.h(d,b):JL.call(null,d,b)},a):$CLJS.le(b)?$CLJS.ag(function(d){return JL.h?JL.h(a,d):JL.call(null,a,d)},b):$CLJS.F.h(a,$CLJS.vM)?!0:$CLJS.XH(a,b)};$CLJS.pL($CLJS.tM,KL($CLJS.Gl,"expression returning a boolean"));$CLJS.pL($CLJS.DM,KL($CLJS.fn,"expression returning a string"));$CLJS.pL($CLJS.JM,KL($CLJS.ol,"expression returning an integer"));$CLJS.pL(pga,KL($CLJS.fL,"expression returning a non-integer real number"));
$CLJS.pL($CLJS.IM,KL($CLJS.Jl,"expression returning a number"));$CLJS.pL(qga,KL($CLJS.jJ,"expression returning a date"));$CLJS.pL(sga,KL($CLJS.ML,"expression returning a time"));$CLJS.pL(lga,KL($CLJS.nK,"expression returning a date time"));$CLJS.pL($CLJS.rM,KL($CLJS.Ql,"expression returning a date, time, or date time"));$CLJS.YL=new $CLJS.ti(null,new $CLJS.k(null,4,[$CLJS.Jl,null,$CLJS.fn,null,$CLJS.Ql,null,$CLJS.Gl,null],null),null);$CLJS.pL($CLJS.CM,KL($CLJS.YL,"an expression that can be compared with :\x3e or :\x3c"));
$CLJS.pL($CLJS.yM,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,KL(new $CLJS.ti(null,new $CLJS.k(null,4,[$CLJS.Jl,null,$CLJS.fn,null,$CLJS.Ql,null,$CLJS.Gl,null],null),null),"an expression that can appear in :\x3d or :!\x3d")],null));$CLJS.pL($CLJS.uM,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,KL($CLJS.yl,"any type of expression")],null));
$CLJS.pL($CLJS.lM,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tl,new $CLJS.k(null,1,[$CLJS.wk,1],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.uM],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.iu,$CLJS.Du,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xM,$CLJS.Ul],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.um,$CLJS.Du],null)],null)],null)],null));